import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';
import LinearProgress from '@material-ui/core/LinearProgress';
import { lighten, withStyles } from '@material-ui/core/styles';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swiper from 'react-id-swiper';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import cookie from 'react-cookies';
import functions from '../../assets/functions/common_functions';
import EnterPhone from './enterphone';
import Enterdob from './entercontestdob';
import EnterTerms from './terms_page';
import EnterPolicyTerms from './enterpolicyterms';
import EnterGuardTreeText from './enterGuardTreeText';
import EnterCSTConsent from './enterCSTConsent';
import BabyRegistry from './baby_registry';
import PolicyPopup from './enterpolicyterms'
import EnterRelative from './enterrelative';
import EnterPolicyAdvisor from './enterpolicyadvisorconsent';
import EpilogueQuestion from './epilogue_question';
import SuveyQuestions from './survey_questions';
import EpilogueSuveyQuestions from './epilogue_survey_questions';
import LottieLoader from '../LottieLoader';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import $ from "jquery";
import { func } from 'prop-types';

import ParentInvitaionPopup from './parentInvitation';
import { isMobileSafari } from 'react-device-detect';
import { connect } from 'react-redux';
import TermsCondition from '../TermsCondition/index'
import Footer from './footer';
const C = CSSPlugin;

// import "../../../src/assets/images/11.png"

var initials = require("initials");
const Month = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
    "2": "Feb",
    "3": "Mar",
    "4": "Apr",
    "5": "May",
    "6": "Jun",
    "7": "Jul",
    "8": "Aug",
    "9": "Sep",
    "1": "Jan"
};


var embark_survey_questions = [
    {
        "answer_data": {
            "buttonData": [{ "text": "Yes" }, { "text": "No" }],
            "no_of_button": "2"
        },
        "is_personal_data": true,
        "personal_data_map": "",
        "questionText": "Do all of your children have an RESP?",
        "question_option_type": "button"
    }]

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const BorderLinearProgress = withStyles({
    root: {
        height: 6,
        backgroundColor: lighten('#ffe36570', 0.2),
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#FFDD3F',
    },
})(LinearProgress);

class Sharegaindetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            program_array: [],
            edit_delete_modal: false,
            freebie_and_contest: [],
            current_program: {},
            upselling_offers: [],
            show_t_and_c: false,
            delete_confirm_modal: false,
            modal_message: "Do you want to delete this relation?",
            program_notifyme_loader: false,
            locked_number: 0,
            show_error_message: "",
            error: 0,
            modal: false,
            relation_status: '',
            show_arrows: false,
            not_el_redeem: false,
            load_more_loader: false,
            show_popup: false,
            show_terms: localStorage.getItem("show_terms") == "true" ? true : false,
            show_dob: localStorage.getItem("show_dob") == "true" ? true : false,
            program_to_unlock: [],
            show_phone: localStorage.getItem("show_phone") == "true" ? true : false,
            show_cst_consent: localStorage.getItem("show_cst_consent") == "true" ? true : false,
            show_guardTreeText: localStorage.getItem("show_guardTreeText") == "true" ? true : false,
            show_policyadvisor_consent: localStorage.getItem("show_policyadvisor_consent") == "true" ? true : false,
            show_policy_terms: localStorage.getItem("show_policy_terms") == "true" ? true : false,
            show_baby_registry: localStorage.getItem("show_baby_registry") == "true" ? true : false,
            entered: false,
            current_program_id: "",
            loader: false,
            phone_entered: false,
            program_type: "",
            show_winner_data: [],
            program_notified: false,
            dynamic_content: "",
            facebook_link: "",
            twitter_link: "",
            load_more: false,
            child_data: localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : [],
            relations: localStorage.getItem("relation_data") ? JSON.parse(localStorage.getItem("relation_data")) : [],
            pending_criteria: [],
            current_criteria: "",
            showInviteBox: false,
            showAll: false
        };
        this.toggleEditDelete = this.toggleEditDelete.bind(this);
        this.swiper = null
    }

    toggleEditDelete() {
        this.setState({
            edit_delete_modal: !this.state.edit_delete_modal
        });
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    componentDidMount = () => {
        try {
            let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
            if (dynamic_content !== "") {
                this.setState({ dynamic_content: dynamic_content });
            }

            let newsLetter = localStorage.getItem("newsLetter") ? localStorage.getItem("newsLetter") : false;
            let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            auto_id = auto_id ? auto_id : "";

            if (auto_id !== "") {
                let program = this.props.program_detail;
                let all_program = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                this.handleRequiredProgramToUnlocked(all_program, program, "yes", dynamic_content);

                setTimeout(() => {
                    if (this.props.program_detail.partner_id === "10") {
                        const programPage = document.querySelector(".program-page");
                        if (programPage) {
                            programPage.scrollTo({ top: 500, behavior: "smooth" });
                        }
                    }
                }, 1000);

                if (program && program.program_id === "9769" && program.video_url && program.video_url.length > 0) {
                    this.getSignedUrlForVideoView(program.video_url);
                }

                if (program && typeof program === 'object') {
                    this.setState({ program_detail: program });
                }

                let phone_verified = (localStorage.getItem("phone_number_verified") === "true" || localStorage.getItem("phone_verified") === "true") ? true : false;

                if (program && (program.partner_id === "15" || program.partner_id === "14") && (!program.pending_fields || !program.pending_fields.includes("phone_number")) && !phone_verified && newsLetter) {
                    localStorage.removeItem("newsLetter");
                    let pending_fields = typeof program.pending_fields === "string" ? JSON.parse(program.pending_fields) : (Array.isArray(program.pending_fields) ? program.pending_fields : []);
                    program["pending_fields"] = pending_fields.length ? pending_fields.concat("phone_number") : ["phone_number"];
                    this.GetFreebieAndContestNotLocked(program)
                    this.updateProgram(program);
                    
                }

                if (program && (program.partner_id === "15" || program.partner_id === "14") && program.pending_fields && program.pending_fields.includes("phone_number")) {
                    let embark_cst_entry = document.getElementById("pending_button_" + program.program_id);
                    if (embark_cst_entry) {
                        embark_cst_entry.click();
                    } else {
                        setTimeout(() => {
                            let embark_cst_entry2 = document.getElementById("pending_button_" + program.program_id);
                            if (embark_cst_entry2) embark_cst_entry2.click();
                        }, 300);
                    }
                }

                if (program && program.partner_id === "14" && newsLetter && !program.pending_fields.includes("phone_number")) {
                    let embark_cst_entry = document.getElementById("clicked_giveaway_" + program.program_id);
                    localStorage.removeItem("newsLetter");
                    if (embark_cst_entry) {
                        const contestEnterFixed = embark_cst_entry.querySelector(".contest_enter_fixed");
                        if (contestEnterFixed) {
                            contestEnterFixed.click();
                        }
                    } else {
                        setTimeout(() => {
                            let embark_cst_entry2 = document.getElementById("clicked_giveaway_" + program.program_id);
                            if (embark_cst_entry2) {
                                const contestEnterFixed2 = embark_cst_entry2.querySelector(".contest_enter_fixed");
                                if (contestEnterFixed2) contestEnterFixed2.click();
                            }
                        }, 300);
                    }
                }
            }

            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 0);

            // Ensure TimelineMax is defined and loaded
            if (typeof TimelineMax !== "undefined") {
                var timeline = new TimelineMax();
                timeline.fromTo('#root', 2, { opacity: "0" }, { opacity: "1" });
            }

            this.handleFixedHeader();
        } catch (error) {
            console.log("An error occurred in componentDidMount:", error);
        }
    }




    handleScroll = () => {
        $(".program-page").animate({ scrollTop: 500 });
    }


    dateFormat = (value) => {
        let final_date = ""
        if (value && value != "" && typeof value != undefined && value != "undefined") {
            let date_array = value.split("-");
            let year = date_array[0];
            let date = date_array[2];
            let month = date_array[1];
            let month_name = Month[month];
            final_date = date + " " + month_name + " " + year;
        }

        return final_date;
    }

    dateFormatone = (value) => {
        let final_date = ""
        if (value && value != "" && typeof value != undefined && value != "undefined") {
            let date_array = value.split("/");
            let month = date_array[0];
            let date = date_array[1];
            let year = date_array[2];
            // console.log("month", month)
            let month_name = Month[month];
            final_date = date + " " + month_name + " " + year;
        }

        return final_date;
    }

    removeAnimationEffect = () => {
        let TL = new TimelineMax();
        this.setState(({ animationStart: false }))
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 0 }, { opacity: 1, height: 'auto' })
            .to('.program_entry_loader', 0.5, { opacity: 0 })
    }

    handleClick = (program, animation_flag, recommend, entry_flag, actionSurvey = 'null') => {
        let timeout = 1000
        if (program.partner_id == "10" || program.partner_id == "11" || program.partner_id == "12") {
            timeout = 1
        }
        if (program.program_type == "contest" || program.program_type == "survey") {
            var scroll_to1 = $('.instant_upr2').offset()?.top + $('.instant_upr2').height();
            $(".program-page").animate({ scrollTop: 400 });
        } else if (program.program_type == "freebie") {
            var scroll_to1 = $('.descp_all').offset()?.top + $('.descp_all').height();
            $(".program-page").animate({ scrollTop: 400 });
        }

        let success_action = "User clicked on Enter button on program detail page " + program.program_id
        let image = 'enter_button_program_detail_page_' + program.program_id + '.png'
        if (program.program_type != "freebie") {
            functions.logUserActivity(success_action, image);
        }
        if (animation_flag != 'stop_animation') {
            this.setState(({ animationStart: true }))
            this.handleAnimation();
        }
        let self = this;
        self.setState({ error: 0 });
        let consent = "";
        setTimeout(() => {
            self.setState({ loader: true });
            let is_sub_id = 'yes';
            let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
            sub_id = sub_id ? sub_id : ""
            if (sub_id != "") {
                let program_id = program.program_id;
                let term_id = program.term_id ? program.term_id : "null";
                let partner_id = program.partner_id;
                let program_type = program.program_type;
                let registration_source = program.registration_source ? program.registration_source : ""
                let point_type = 'entry';
                let is_recommend = recommend == 'recommend' ? "true" : "false";
                let is_direct = 1;
                let user_eligible = ""
                let terms_finished = localStorage.getItem("terms_finished") == "true" ? "true" : "false";
                let show_terms = localStorage.getItem("show_terms") == "true" ? true : false
                let show_phone = localStorage.getItem("show_phone") == "true" ? true : false
                let show_cst_consent = localStorage.getItem("show_cst_consent") == "true" ? true : false
                let show_guardTreeText = localStorage.getItem("show_guardTreeText") == "true" ? true : false
                let show_policyadvisor_consent = localStorage.getItem("show_policyadvisor_consent") == "true" ? true : false
                let show_policy_terms = localStorage.getItem("show_policy_terms") == "true" ? true : false
                let show_baby_registry = localStorage.getItem("show_baby_registry") == "true" ? true : false
                let show_dob = localStorage.getItem("show_dob") == "true" ? true : false
                let entry_criteria = program.entry_criteria ? JSON.parse(program.entry_criteria) : []
                this.setState({ show_dob: show_dob, show_terms: show_terms, show_policyadvisor_consent: show_policyadvisor_consent, show_phone: show_phone, show_cst_consent: show_cst_consent, show_policy_terms: show_policy_terms, show_baby_registry: show_baby_registry, show_guardTreeText: show_guardTreeText })
                if (is_recommend == "true") {
                    // console.log("contestEntry1")
                    functions.contestEntry(self, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, is_recommend, program_type, term_id, consent, "", '', function (response_json) {
                        let response = JSON.parse(response_json);
                        if (response.status == 200) {
                            let kff_past_entry = localStorage.getItem("kff_past_entry") && localStorage.getItem("kff_past_entry") == "true" ? true : false
                            if (partner_id == "1") {
                                localStorage.setItem("kff_past_entry", "true")
                            }
                            let nestle_past_entry = localStorage.getItem("nestle_past_entry") && localStorage.getItem("nestle_past_entry") == "true" ? true : false
                            if (partner_id == "10") {
                                localStorage.setItem("nestle_past_entry", "true")
                            }
                            let enfamil_past_entry = localStorage.getItem("enfamil_past_entry") && localStorage.getItem("enfamil_past_entry") == "true" ? true : false
                            if (partner_id == "3" && program.program_type !== "freebie") {
                                localStorage.setItem("enfamil_past_entry", "true")
                            }
                            let mydiv = document.getElementsByClassName("enter_program")

                            if (!kff_past_entry && partner_id == "1") {
                                let kff_fresh_entry = document.getElementById("kff_fresh_entry")
                                kff_fresh_entry.click();
                            }

                            for (let i = 0; i < mydiv.length; i++) {
                                let element = mydiv[i]
                                if (element.id == program.program_id) {
                                    localStorage.setItem("clicked_id", program.program_id)
                                    localStorage.setItem("clicked_partner", program.partner_name)
                                    setTimeout(() => {
                                        if (mydiv && mydiv[i]) mydiv[i].click()
                                    }, 500)
                                }
                            }
                            if (program_type == "contest" || program_type == "freebie" || program_type == "offer") {
                                // let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
                                // if (child_data && child_data.length) {
                                //     let isOpen = false
                                //     child_data.map(checkChild => {
                                //         if (!checkChild.parent_id && checkChild.relation_with_child !== 'parent') {
                                //             isOpen = true
                                //         }
                                //     })

                                //     setTimeout(() => {
                                //         self.setState({ isOpen })
                                //     }, 10000);
                                // }
                                self.childInvitation(10000 * 9)
                            }
                            let success_action = "User get success on contestEntry function on program detail page " + program_id
                            functions.logUserActivity(success_action, null);
                            functions.logUserTermsOnEntryActivity(success_action, program)
                            let state_program_array = self.state.program_array;
                            self.setState({ loader: false });
                            self.SendReminder(program)
                            self.setState(({ animationStart: false }))
                            self.removeAnimationEffect();
                            let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                            if (program_array) {
                                var program_index = program_array.findIndex(p => p.program_id == program_id)

                                program_array[program_index].has_enter = 1;
                                program_array[program_index].recommended = "true";
                                program_array[program_index].pending_fields = [];
                                let state_program_array = self.state.program_array
                                state_program_array[0].has_enter = 1;
                                state_program_array[0].recommended = "true";
                                state_program_array[0].pending_fields = [];
                                self.setState({ program_array: state_program_array })
                                localStorage.removeItem("session_entered_id")
                                localStorage.removeItem("pending_id")
                                self.CheckAndUpdateProgramVisibility(program_array, state_program_array)
                            }
                            let program_only = program ? program : [];
                            if (program_only) {
                                program_only.has_enter = 1;
                                program_only.recommended = "true";
                                program_only.pending_fields = [];
                                localStorage.removeItem("session_entered_id")
                                localStorage.removeItem("pending_id")
                                localStorage.setItem("program", JSON.stringify(program_only))
                                self.setState(({ animationStart: false }))
                                self.handleAnimationAfterEntry(program);
                            }
                            self.GetFreebieAndContestNotLocked(program)
                            self.updateProgram(program)
                            
                        }
                    })

                } else if (terms_finished == "true" && program.program_id == "6350") {
                    localStorage.setItem("terms_finished", "false")
                    functions.contestEntry(self, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, '', program_type, term_id, consent, "", '', function (response_json) {
                        let response = JSON.parse(response_json);
                        if (response.status == 200) {

                            let mydiv = document.getElementsByClassName("enter_program")
                            for (let i = 0; i < mydiv.length; i++) {
                                let element = mydiv[i]
                                if (element.id == program.program_id) {
                                    localStorage.setItem("clicked_id", program.program_id)
                                    localStorage.setItem("clicked_partner", program.partner_name)
                                    setTimeout(() => {
                                        if (mydiv && mydiv[i]) mydiv[i].click()
                                    }, 500)
                                }
                            }
                            if (program_type == "contest" || program_type == "freebie" || program_type == "offer") {
                                // let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
                                // if (child_data && child_data.length) {
                                //     let isOpen = false
                                //     child_data.map(checkChild => {
                                //         if (!checkChild.parent_id && checkChild.relation_with_child !== 'parent') {
                                //             isOpen = true
                                //         }
                                //     })

                                //     setTimeout(() => {
                                //         self.setState({ isOpen })
                                //     }, 10000);
                                // }
                                self.childInvitation(10000 * 9)
                            }
                            let success_action = "User get success on contestEntry function on program detail page " + program_id
                            functions.logUserActivity(success_action, null);
                            functions.logUserTermsOnEntryActivity(success_action, program)
                            let state_program_array = self.state.program_array;
                            state_program_array[0].term_and_condition_required = false;
                            self.setState({ loader: false });
                            localStorage.setItem("term_and_condition_required", "false")
                            self.setState(({ animationStart: false }))
                            self.removeAnimationEffect();
                            let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                            if (program_array) {
                                var program_index = program_array.findIndex(p => p.program_id == program_id)
                                program_array[program_index].has_enter = 1;
                                program_array[program_index].pending_fields = [];
                                let state_program_array = self.state.program_array
                                state_program_array[0].has_enter = 1;
                                state_program_array[0].pending_fields = [];
                                self.setState({ program_array: state_program_array })
                                localStorage.removeItem("session_entered_id")
                                localStorage.removeItem("pending_id")
                                localStorage.setItem("program_list", JSON.stringify(program_array))
                                self.CheckAndUpdateProgramVisibility(program_array, state_program_array)
                            }
                            let program_only = program ? program : [];
                            if (program_only) {
                                program_only.has_enter = 1;
                                program_only.pending_fields = [];
                                localStorage.removeItem("session_entered_id")
                                localStorage.removeItem("pending_id")
                                localStorage.setItem("program", JSON.stringify(program_only))
                                self.setState(({ animationStart: false }))
                                self.handleAnimationAfterEntry(program);
                            }
                            self.GetFreebieAndContestNotLocked(program)
                            self.updateProgram(program)
                            
                        }
                    })

                } else if (program.program_type == "survey" && actionSurvey == 'survey') {
                    self.setState({ current_criteria: "show_survey_question", pending_criteria: ["show_survey_question"] })
                    let state_program_array = self.state.program_array
                    state_program_array[0].term_and_condition_required = false;
                    state_program_array[0].birthdate_required = false;
                    state_program_array[0].phone_number_required = false;
                    state_program_array[0].cst_consent_required = false;
                    state_program_array[0].guardtree_text_required = false;
                    state_program_array[0].policy_terms_required = false;
                    state_program_array[0].policyadvisor_consent_required = false;
                    state_program_array[0].epilogue_question_required = false
                    state_program_array[0].show_survey_question = true;
                    state_program_array[0].add_relative_required = false;
                    state_program_array[0].baby_registry_required = false;
                    self.setState({ program_array: state_program_array, show_phone: false, show_cst_consent: false, show_survey_question: true, epilogue_question_required: false, show_policyadvisor_consent: true, show_policy_terms: false, show_baby_registry: false, show_guardTreeText: false, loader: false })
                    var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
                    $(".program-page").animate({ scrollTop: 800 });
                } else if (program.partner_id == "7") {
                    self.setState({ current_criteria: "phone_number_call", pending_criteria: ["phone_number_call"] })
                    let state_program_array = self.state.program_array
                    state_program_array[0].term_and_condition_required = false;
                    state_program_array[0].birthdate_required = false;
                    state_program_array[0].phone_number_required = true;
                    state_program_array[0].cst_consent_required = false;
                    state_program_array[0].guardtree_text_required = false;
                    state_program_array[0].policy_terms_required = false;
                    state_program_array[0].policyadvisor_consent_required = false;
                    state_program_array[0].epilogue_question_required = false
                    state_program_array[0].show_survey_question = false;
                    state_program_array[0].add_relative_required = false;
                    state_program_array[0].baby_registry_required = false;
                    self.setState({ program_array: state_program_array, show_phone: true, show_cst_consent: false, show_policyadvisor_consent: false, show_policy_terms: false, show_baby_registry: false, show_guardTreeText: false, loader: false })
                    var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
                    $(".program-page").animate({ scrollTop: 400 });
                } else if (program.partner_id == "11") {
                    self.setState({ current_criteria: "policyadvisor_consent", pending_criteria: ["policyadvisor_consent"] })
                    let state_program_array = self.state.program_array
                    state_program_array[0].term_and_condition_required = false;
                    state_program_array[0].birthdate_required = false;
                    state_program_array[0].phone_number_required = false;
                    state_program_array[0].cst_consent_required = false;
                    state_program_array[0].guardtree_text_required = false;
                    state_program_array[0].policy_terms_required = false;
                    state_program_array[0].policyadvisor_consent_required = true;
                    state_program_array[0].epilogue_question_required = false
                    state_program_array[0].show_survey_question = false;
                    state_program_array[0].add_relative_required = false;
                    state_program_array[0].baby_registry_required = false;
                    self.setState({ program_array: state_program_array, show_phone: false, show_cst_consent: false, show_policyadvisor_consent: true, show_policy_terms: false, show_baby_registry: false, show_guardTreeText: false, loader: false })
                    var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
                    $(".program-page").animate({ scrollTop: 800 });
                } else if (program.partner_id == "12") {
                    self.setState({ current_criteria: "epilogue_question", pending_criteria: ["epilogue_question"] })
                    let state_program_array = self.state.program_array
                    state_program_array[0].term_and_condition_required = false;
                    state_program_array[0].birthdate_required = false;
                    state_program_array[0].phone_number_required = false;
                    state_program_array[0].cst_consent_required = false;
                    state_program_array[0].guardtree_text_required = false;
                    state_program_array[0].policy_terms_required = false;
                    state_program_array[0].policyadvisor_consent_required = false;
                    state_program_array[0].epilogue_question_required = true
                    state_program_array[0].show_survey_question = false;
                    state_program_array[0].add_relative_required = false;
                    state_program_array[0].baby_registry_required = false;
                    self.setState({ program_array: state_program_array, show_phone: false, show_cst_consent: false, show_policyadvisor_consent: false, show_policy_terms: false, show_baby_registry: false, show_guardTreeText: false, loader: false })
                    var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
                    $(".program-page").animate({ scrollTop: 800 });
                    // } else if (program.partner_id == '15' && (localStorage.getItem("phone_verified") == "true" || localStorage.getItem('phone_number_verified') == 'true')) {
                    //     // self.setState({ current_criteria: "show_survey_question", pending_criteria: ["show_survey_question"] })
                    //     // self.handleAnimationAfterEntry(program);
                    //     let state_program_array = self.state.program_array;
                    //     state_program_array[0].show_survey_question = true;
                    //     state_program_array[0].survey_questions = embark_survey_questions;
                    //     self.setState({ program_array: state_program_array, animationStart: false, show_phone: false, show_upload_video: false, show_cst_consent: false, show_survey_question: true, epilogue_question_required: false, show_policyadvisor_consent: true, show_policy_terms: false, show_baby_registry: false, show_guardTreeText: false, loader: false })
                    //   if( $('.instant_upr2')&& $('.instant_upr2').offset())  var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
                    //     $(".program-page").animate({ scrollTop: 800 });
                } else if (program.program_id == '5147' || program.program_id == '7755') {
                    // let response = JSON.parse(response_json);

                    let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
                    functions.updateUsercoupon(program.program_id, sub_id, function (callback) {
                        // console.log("callback", callback);
                        let respo = JSON.parse(callback)
                        if (respo?.data?.coupon_code) program["coupon_code"] = respo.data.coupon_code
                        functions.contestEntry(self, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, '', program_type, term_id, consent, '', '', function (response_json) {
                            let response = JSON.parse(response_json);
                            //console.log("response 481", response)

                            if (response.status == 200) {
                                let kff_past_entry = localStorage.getItem("kff_past_entry") && localStorage.getItem("kff_past_entry") == "true" ? true : false

                                let mydiv = document.getElementsByClassName("enter_program")
                                for (let i = 0; i < mydiv.length; i++) {
                                    let element = mydiv[i]
                                    if (element.id == program.program_id) {
                                        localStorage.setItem("clicked_id", program.program_id)
                                        localStorage.setItem("clicked_partner", program.partner_name)
                                        setTimeout(() => {
                                            if (mydiv && mydiv[i]) mydiv[i].click()
                                        }, 500)
                                    }
                                }
                                if (program_type == "contest" || program_type == "freebie" || program_type == "offer") {
                                    self.childInvitation(10000 * 9)
                                }
                                localStorage.removeItem("enter_relative_program_id")
                                let success_action = "User get success on contestEntry function on program detail page " + program_id
                                functions.logUserActivity(success_action, null);
                                functions.logUserTermsOnEntryActivity(success_action, program)
                                let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                                let program_only = program ? program : [];
                                if (program_array) {
                                    var program_index = program_array.findIndex(p => p.program_id == program_id)

                                    program_array[program_index].has_enter = 1;
                                    program_array[program_index].pending_fields = [];
                                    let state_program_array = self.state.program_array
                                    state_program_array[0].has_enter = 1;
                                    state_program_array[0].pending_fields = [];
                                    localStorage.removeItem("session_entered_id")
                                    localStorage.removeItem("pending_id")
                                    self.setState({ program_array: state_program_array })
                                    localStorage.setItem("program_list", JSON.stringify(program_array))
                                    self.CheckAndUpdateProgramVisibility(program_array, state_program_array)
                                    if (program_only) {
                                        program_only.has_enter = 1;
                                        program_only.pending_fields = [];
                                        localStorage.removeItem("session_entered_id")
                                        localStorage.removeItem("pending_id")
                                        localStorage.setItem("program", JSON.stringify(program_only))
                                        self.setState(({ animationStart: false }))
                                        self.handleAnimationAfterEntry(program);
                                    }
                                }
                                self.GetFreebieAndContestNotLocked(program_only)
                                self.updateProgram(program_only)
                               
                            }
                        })
                    })
                } else {
                    functions.checkProgramCriteria(self, program_id, sub_id, function (response_json) {
                        let response = JSON.parse(response_json);
                        let success_action = "User get success on checkProgramCriteria function on program detail page " + program_id
                        functions.logUserActivity(success_action, null);
                        if (response.success == 1) {

                            //console.log("program is", program);
                            if (program && program.eligibilty_criteria && program.eligibilty_criteria.length > 0) {
                                let eCriteria = program.eligibilty_criteria.split('\\')[0];
                                let isRelativeAllowed = eCriteria.search("relative_allowed");
                                if (isRelativeAllowed > 0) {
                                    let relative_allowed = program.relative_allowed.split('\\')[0];

                                    let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
                                    if (child_data && child_data.length) {
                                        let showInviteBox = false
                                        child_data.map(childItem => {
                                            if (childItem.relation_with_child && childItem.relation_with_child !== 'parent') {

                                                let relativeAllowedMatch = relative_allowed.search(childItem.relation_with_child);
                                                if (relativeAllowedMatch > 0) {
                                                    showInviteBox = true
                                                }
                                            }
                                        })
                                        //console.log("showInviteBox value is", showInviteBox);
                                    }
                                }
                            }


                            if (response.past_entry && response.past_entry == 1) {
                                if (partner_id == "1") {
                                    localStorage.setItem("kff_past_entry", "true")
                                }
                                if (partner_id == "15") {
                                    localStorage.setItem("embark_past_entry", "true")
                                }
                                if (partner_id == "10") {
                                    localStorage.setItem("nestle_past_entry", "true")
                                }
                                if (partner_id == "3" && program.program_type !== "freebie") {
                                    localStorage.setItem("enfamil_past_entry", "true")
                                }
                            }
                            // console.log("contestEntry3")
                            let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
                            functions.contestEntry(self, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, '', program_type, term_id, consent, '', '', function (response_json) {
                                let response = JSON.parse(response_json);
                                // console.log("response 481", response)
                                if (response.status == 200) {
                                    let kff_past_entry = localStorage.getItem("kff_past_entry") && localStorage.getItem("kff_past_entry") == "true" ? true : false
                                    if (partner_id == "1") {
                                        localStorage.setItem("kff_past_entry", "true")
                                    }

                                    let nestle_past_entry = localStorage.getItem("nestle_past_entry") && localStorage.getItem("nestle_past_entry") == "true" ? true : false
                                    if (partner_id == "10" && program_id != "1262") {
                                        let userdata = [];
                                        let dynamo_userdata = { 'nestle_redeem_type': "default_coupon" };
                                        localStorage.setItem("nestle_redeem_type", "default_coupon");
                                        functions.updateUserDataPass(self, userdata, dynamo_userdata, function (err, passData) {
                                            if (err) {
                                            }
                                            else {
                                                let program_array_nestle = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                                                if (program_array_nestle) {
                                                    var program_index_nestle = program_array_nestle.findIndex(p => p.program_id == "1262")

                                                    program_array_nestle[program_index_nestle].has_enter = 1;
                                                    program_array_nestle[program_index_nestle].pending_fields = [];
                                                    localStorage.removeItem("session_entered_id")
                                                    localStorage.removeItem("pending_id")
                                                    localStorage.setItem("program_list", JSON.stringify(program_array_nestle))
                                                    self.CheckAndUpdateProgramVisibility(program_array_nestle)
                                                }
                                                functions.contestEntry(self, is_sub_id, sub_id, tracking_id, "1262", partner_id, registration_source, point_type, is_direct, user_eligible, '', "freebie", term_id, consent, "", "", function (response_json) {
                                                    self.GetFreebieAndContestNotLocked(program)
                                                    self.updateProgram(program)
                                                    
                                                })
                                            }
                                        })
                                        localStorage.setItem("nestle_past_entry", "true")
                                    }
                                    let enfamil_past_entry = localStorage.getItem("enfamil_past_entry") && localStorage.getItem("enfamil_past_entry") == "true" ? true : false
                                    if (partner_id == "3" && program.program_type !== "freebie") {
                                        localStorage.setItem("enfamil_past_entry", "true")
                                    }
                                    // console.log("enfamil_past_entry enfamil_past_entry13", enfamil_past_entry)
                                    if (!kff_past_entry && partner_id == "1") {
                                        let kff_fresh_entry = document.getElementById("kff_fresh_entry")
                                        kff_fresh_entry.click();
                                    }

                                    let mydiv = document.getElementsByClassName("enter_program")
                                    for (let i = 0; i < mydiv.length; i++) {
                                        let element = mydiv[i]
                                        if (element.id == program.program_id) {
                                            localStorage.setItem("clicked_id", program.program_id)
                                            localStorage.setItem("clicked_partner", program.partner_name)
                                            setTimeout(() => {
                                                if (mydiv && mydiv[i]) mydiv[i].click()
                                            }, 500)
                                        }
                                    }
                                    if (program_type == "contest" || program_type == "freebie" || program_type == "offer") {
                                        // let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
                                        // if (child_data && child_data.length) {
                                        //     let isOpen = false
                                        //     child_data.map(checkChild => {
                                        //         if (!checkChild.parent_id && checkChild.relation_with_child !== 'parent') {
                                        //             isOpen = true
                                        //         }
                                        //     })

                                        //     setTimeout(() => {
                                        //         self.setState({ isOpen })
                                        //     }, 10000);
                                        // }
                                        self.childInvitation(10000 * 9)
                                    }
                                    localStorage.removeItem("enter_relative_program_id")
                                    let success_action = "User get success on contestEntry function on program detail page " + program_id
                                    functions.logUserActivity(success_action, null);
                                    functions.logUserTermsOnEntryActivity(success_action, program)
                                    let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                                    if (program_array) {
                                        var program_index = program_array.findIndex(p => p.program_id == program_id)

                                        program_array[program_index].has_enter = 1;
                                        program_array[program_index].pending_fields = [];
                                        let state_program_array = self.state.program_array
                                        if (partner_id == '15') {
                                            localStorage.setItem(`embark_${program_id}_survey`, 'null')
                                            state_program_array[0].show_survey_question = true;
                                            state_program_array[0].survey_questions = embark_survey_questions;
                                        }
                                        else if(partner_id=="14"){
                                            program_array[program_index].cst_consent = 'yes';
                                            state_program_array[0].cst_consent = 'yes';
                                        }
                                        state_program_array[0].has_enter = 1;
                                        state_program_array[0].pending_fields = [];
                                        localStorage.removeItem("session_entered_id")
                                        localStorage.removeItem("pending_id")
                                        self.setState({ program_array: state_program_array })
                                        localStorage.setItem("program_list", JSON.stringify(program_array))
                                        self.CheckAndUpdateProgramVisibility(program_array, state_program_array)
                                        let program_only = program ? program : [];
                                        if (program_only) {
                                            program_only.has_enter = 1;
                                            program_only.pending_fields = [];
                                            localStorage.removeItem("session_entered_id")
                                            localStorage.removeItem("pending_id")
                                            localStorage.setItem("program", JSON.stringify(program_only))
                                            self.setState(({ animationStart: false }))
                                            self.handleAnimationAfterEntry(program);
                                        }
                                    }
                                    self.GetFreebieAndContestNotLocked(program)
                                    self.updateProgram(program)
                                   
                                }
                            })
                        }
                        else {
                            if (response.past_entry && response.past_entry == 1) {
                                if (partner_id == "1") {
                                    localStorage.setItem("kff_past_entry", "true")
                                }
                                if (partner_id == "15") {
                                    localStorage.setItem("embark_past_entry", "true")
                                }
                                if (partner_id == "10") {
                                    localStorage.setItem("nestle_past_entry", "true")
                                }
                                if (partner_id == "3" && program.program_type !== "freebie") {
                                    localStorage.setItem("enfamil_past_entry", "true")
                                }
                            }
                            if (response.pending && response.pending.length > 0) {
                                let key = response.pending[0];
                                self.setState({ current_criteria: key, pending_criteria: response.pending })
                                let state_program_array = self.state.program_array
                                state_program_array[0].term_and_condition_required = false;
                                state_program_array[0].birthdate_required = false;
                                state_program_array[0].phone_number_required = false;
                                state_program_array[0].cst_consent_required = false;
                                state_program_array[0].guardtree_text_required = false;
                                state_program_array[0].policy_terms_required = false;
                                state_program_array[0].policyadvisor_consent_required = false;
                                state_program_array[0].epilogue_question_required = false
                                state_program_array[0].show_survey_question = false;
                                state_program_array[0].add_relative_required = false;
                                state_program_array[0].baby_registry_required = false;
                                self.setState({
                                    show_phone: false,
                                    show_cst_consent: false,
                                    show_policyadvisor_consent: false,
                                    show_guardTreeText: false,
                                    show_policy_terms: false,
                                    show_baby_registry: false,
                                    loader: false
                                })
                                if (key == "phone_number" || (key == "cst_consent" && (localStorage.getItem("phone_number_verified") !== "true" && localStorage.getItem("phone_verified") !== "true"))) {
                                    state_program_array[0].phone_number_required = true;
                                    self.setState({
                                        show_phone: true,
                                        current_criteria: "phone_number"
                                    })

                                    localStorage.setItem("phone_number_required", "true")
                                    var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
                                    $(".program-page").animate({ scrollTop: 600 });
                                    // setTimeout(() => {
                                    //     $('html, body').animate({
                                    //         scrollTop: ($('#phoneTop').offsetTop)
                                    //     }, 500);
                                    // }, 200);

                                }
                                if (key == "cst_consent" && (localStorage.getItem("phone_number_verified") == "true" || localStorage.getItem("phone_verified") == "true")) {
                                    state_program_array[0].cst_consent_required = true;
                                    self.setState({
                                        show_cst_consent: true
                                    })

                                    localStorage.setItem("cst_consent_required", "true")
                                    var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
                                    $(".program-page").animate({ scrollTop: 900 });
                                }
                                if (key == "policy_terms") {
                                    state_program_array[0].policy_terms_required = true;
                                    self.setState({
                                        show_policy_terms: true
                                    })

                                    localStorage.setItem("policy_terms_required", "true")
                                    var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
                                    $(".program-page").animate({ scrollTop: 900 });
                                }
                                if (key == "enter_relative") {
                                    state_program_array[0].add_relative_required = true;

                                    localStorage.setItem("add_relative_required", "true")
                                    var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
                                    $(".program-page").animate({ scrollTop: 900 });
                                }
                                if (key == "birthdate") {
                                    state_program_array[0].birthdate_required = true;
                                    self.setState({
                                        show_dob: true
                                    })

                                    localStorage.setItem("birthdate_required", "true")
                                    var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
                                    $(".program-page").animate({ scrollTop: 900 });
                                }
                                if (key == "term_and_condition" || key == "terms") {
                                    state_program_array[0].term_and_condition_required = true;
                                    self.setState({
                                        show_terms: true
                                    })

                                    localStorage.setItem("term_and_condition_required", "true")
                                    localStorage.setItem("show_terms", "true")
                                    var scroll_to1 = $('.declaration_short').offset().top + $('.declaration_short').height();
                                    $(".program-page").animate({ scrollTop: 700 });
                                }
                                if (key == "baby_registry") {
                                    state_program_array[0].baby_registry_required = true;
                                    state_program_array[0].show_baby_registry = true;
                                    self.setState({
                                        show_baby_registry: true
                                    })

                                    localStorage.setItem("baby_registry_required", "true")
                                    localStorage.setItem("show_baby_registry", "true")
                                    var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
                                    $(".program-page").animate({ scrollTop: 700 });
                                }
                                if (key == "guardtree_text") {
                                    state_program_array[0].guardtree_text_required = true;
                                    self.setState({
                                        show_guardTreeText: true
                                    })

                                    localStorage.setItem("guardtree_text_required", "true")
                                    var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
                                    $(".program-page").animate({ scrollTop: 900 });
                                }
                                self.setState({ program_array: state_program_array })
                                self.setState(({ animationStart: false }))
                                self.handleAnimationAfterEntry(program);
                            }
                        }
                    })
                }
            } else {
                functions.pushToRoute(self, '', 'main')
            }
        }, timeout)
    }

    CheckAndUpdateProgramVisibility = (program_data, program_arr) => {
        let after_entry_of_programs = program_data?.length && program_data.filter((e) => e.after_entry_type && e.after_entry_type != "");
        let notentry_after_of_programs = program_data?.length && program_data.filter((e) => e.notentry_entry_type && e.notentry_entry_type != "");
        if (after_entry_of_programs && after_entry_of_programs.length > 0) {
            after_entry_of_programs.forEach(element => {
                let after_entry_of = JSON.parse(element.after_entry_of)
                let program_visible = localStorage.getItem("program_visible") ? localStorage.getItem("program_visible") : ""
                // let element_visible = false
                // if (program_visible && program_visible != "" && program_visible == element.program_id) {
                //     this.props.updateMainState()
                // } else if (element.has_enter == 1) {
                //     this.props.updateMainState()
                // }
                // else 
                if (element.after_entry_type == "any") {
                    let element_visible = false
                    after_entry_of?.length > 0 && after_entry_of.forEach((program_element, program_index) => {
                        var temp_index = program_data.findIndex(p => p.program_id == program_element)
                        if (temp_index != -1) {
                            let detail = program_data[temp_index]
                            if (detail.has_enter == 1 && detail.is_eligible == 1) {
                                element_visible = true
                            }
                        }
                    })
                    if (element_visible == true) {
                        // console.log("is called 1")
                        // this.updateMainState()
                    }

                } else if (element.after_entry_type == "all") {
                    let element_visible = true
                    after_entry_of?.length > 0 && after_entry_of.forEach((program_element, program_index) => {
                        var temp_index = program_data.findIndex(p => p.program_id == program_element)
                        if (temp_index != -1) {
                            let detail = program_data[temp_index]
                            if (detail.has_enter == 0 || detail.is_eligible == 0) {
                                element_visible = false
                            }
                        }
                    })
                    if (element_visible == true) {
                        // console.log("is called 2")
                        // this.updateMainState()
                        if (program_arr?.length > 0) {
                            this.props.handleProgramDetail(program_arr[0])
                        }
                    }
                } else {
                }
            });
        }
        if (notentry_after_of_programs && notentry_after_of_programs.length > 0) {
            notentry_after_of_programs.forEach(element => {
                let element_visible = this.check_notentry_of_after(element, program_data);
                if (element_visible == true) {
                    // console.log("is called3")
                    // this.updateMainState()
                    if (program_arr?.length > 0) {
                        this.props.handleProgramDetail(program_arr[0])
                    }
                }
            });
        }
    }



    handleAnimation = () => {
        this.setState(({ animationStart: true }))
        let TL = new TimelineMax();
        TL.fromTo('.instant_redem2 button,.lend_hendupr_ne1,.lend_hendupr_ne1 button, .freebies_redem button', 0.3, { opacity: 1 }, { opacity: 0, y: -40, display: "none" })
            .fromTo('.instant_hend2', 0.3, { opacity: 1 }, { opacity: 0 }, 0.15)
            .to('.ht-200', 0.1, { display: "block" }, "-0.15")
            .to('.program_entry_loader', 0.1, { opacity: 1 })
    }

    handleAnimationAfterEntry = (program) => {
        this.setState(({ animationStart: false }))
        let TL11 = new TimelineMax();

        TL11.fromTo('.fiveone_pts3', 0.4, { opacity: 0, y: 20 }, { opacity: 1, y: 0 })
            .fromTo('.social_connect_upr', 0.4, { opacity: 0 }, { opacity: 1 })
            .fromTo('.borderProgress, .one_social, .social_activleft', 0.4, { opacity: 0, scaleX: 0.5, transformOrigin: "right" }, { opacity: 1, scaleX: 1, transformOrigin: "left" }, 0.4)
            .fromTo('.one_social_connect', 0.4, { opacity: 0, scaleX: 0.5, transformOrigin: "left" }, { opacity: 1, scaleX: 1, transformOrigin: "right" }, 0.4)
            .fromTo('.social_activityleft', 0.4, { opacity: 0, scaleX: 0.5, transformOrigin: "right" }, { opacity: 1, scaleX: 1, transformOrigin: "left" }, 0.6)
            .fromTo('.social_activityright', 1, { opacity: 0, scaleX: 0.5, transformOrigin: "left" }, { opacity: 1, scaleX: 1, transformOrigin: "right" }, 0.6)
            .to('.ht-200', 0.4, { display: "none" })
            .to('.instant_redem2 button,.lend_hendupr_ne1,.lend_hendupr_ne1 button,.freebies_redem button', 0.1, { y: 0 })
            .to('.instant_redem2 button,.lend_hendupr_ne1,.lend_hendupr_ne1 button,.instant_hend2,.freebies_redem button', 0.1, { opacity: 1 })
            .to('.instant_redem2 button,.lend_hendupr_ne1,.lend_hendupr_ne1 button,.freebies_redem button', 0.1, { display: "initial" })
            .to('.program_entry_loader', 0.1, { opacity: 0 }, 0)
        if (program?.program_type == "contest" && program?.has_enter && $('.instant_upr3')) {
            // var scroll_to1 = $('.instant_upr3').offset()?.top + $('.instant_upr3').height();
            // $(".program-page").animate({ scrollTop: scroll_to1 });
        }

        if (program?.program_type == "freebie" && program?.has_enter) {
            if (program.partner_id != "10") {
                // var scroll_to1 = $('.freebie_redeeemedNw').offset()?.top + $('.freebie_redeeemedNw').height();
                // $(".program-page").animate({ scrollTop: scroll_to1 + 200 });
            } else {
                // var scroll_to1 = $('.instant_upr3')?.offset().top + $('.instant_upr3').height();
                // $(".program-page").animate({ scrollTop: scroll_to1 - 100 });
            }
        }
        // setTimeout(() => {
        //     var scroll_to1 = $('.social_activity').offset().top + $('.social_activity').height();
        //     $(".program-page").animate({ scrollTop: scroll_to1 });
        // }, 100);
    }

    updateProgram = (program) => {
        let self = this;
        let program_data = this.props.all_program;
        let locked_id = localStorage.getItem('locked_id') ? JSON.parse(localStorage.getItem('locked_id')) : '';
        let all_program = []
        if (program_data) {
            let cst_consent = ""

            if (program.partner_id == '14') {
                cst_consent = program.cst_consent
            }
            let cst_visibility = this.checkCSTVisibilty(program_data);
            //console.log("program.pending_fields", program.pending_fields)
            program_data.map((pro) => {
                if (pro.program_id === program.program_id && program.partner_id == '14') {
                    pro.cst_consent = program.cst_consent
                }
                if (program.partner_id == "10" && pro.program_id == "1262") {
                    pro.has_enter = 1
                    pro.pending_fields = []
                }
                if ((pro.partner_id == "14" && (program.partner_id == "15" || program.partner_id == "1"))) {
                    //console.log("pro.partner_id", pro.partner_id)
                    pro.is_visible = false;
                }
                if (pro.program_status == "running" || pro.perk_location) {
                    if (pro.program_id == program.program_id) {
                        pro.has_enter = 1
                    }
                    if (((cst_consent && cst_consent == 'yes') || !cst_visibility) && (pro.partner_id == '1' || pro.partner_id == '15') && (program.pending_fields && program.pending_fields.length == 0 || !program.pending_fields)) {
                        //console.log("program.pending_fields", pro.is_visible)
                        pro.is_visible = false
                    }
                    else {
                        if (pro.after_entry_of && pro.after_entry_of != "" && pro.after_entry_of != "[]") {
                            let element_visibility = self.CheckProgramVisibility(pro, program_data)

                            if (pro.hasOwnProperty('visible_to_user_signup') && pro.visible_to_user_signup === false) {
                                pro.is_visible = pro.visible_to_user_signup
                            } else {
                                if (pro.hasOwnProperty("visible_to_user") && !pro.notentry_after_of) {
                                    pro.is_visible = pro.visible_to_user
                                } else {
                                    pro.is_visible = element_visibility ? element_visibility : false
                                }
                            }
                            if (pro.has_enter == 0 && pro.is_eligible == 0 && pro.show_to_eligible_user == 1) {
                                pro.show_program = false
                            } else {
                                pro.show_program = true
                            }

                        } else {
                            let element_visibility = true
                            if (pro.notentry_entry_type) {
                                element_visibility = self.check_notentry_of_after(pro, program_data);
                            }
                            if (pro.hasOwnProperty('visible_to_user_signup') && pro.visible_to_user_signup === false) {
                                pro.is_visible = pro.visible_to_user_signup
                            } else {
                                if (pro.hasOwnProperty("visible_to_user") && !pro.notentry_after_of) {
                                    pro.is_visible = pro.visible_to_user
                                } else {
                                    pro.is_visible = element_visibility ? element_visibility : false
                                }
                            }
                            if (pro.partner_id == "14") {
                                //console.log("element_visibility partner cst", element_visibility)
                            }
                            if (pro.has_enter == 0 && pro.is_eligible == 0 && pro.show_to_eligible_user == 1) {
                                pro.show_program = false
                            } else {
                                pro.show_program = true
                            }

                        }

                    }
                    if (locked_id) {
                        let required_program_to_unlock = self.GetFreebieAndContestNotLocked(pro);
                        let required_program_to_unlock_ids = []
                        if (pro.partner_id != "6" && pro.program_id !== "1989") {
                            if (pro.program_id !== "2629") {
                                required_program_to_unlock_ids = required_program_to_unlock.map(element => {
                                    return element.program_id;
                                })
                            } else {
                                let show_partner = ["1", "3", "10"];
                                let show_partner_id_array = required_program_to_unlock?.length && required_program_to_unlock.filter((e) => show_partner.includes(e.partner_id));
                                required_program_to_unlock_ids = show_partner_id_array.map(element => {
                                    return element.program_id
                                });
                            }

                        } else {
                            let show_partner = ["1", "2", "3", "10", "14"]
                            let show_partner_id_array = required_program_to_unlock?.length && required_program_to_unlock.filter((e) => show_partner.includes(e.partner_id));
                            required_program_to_unlock_ids = show_partner_id_array.map(element => {
                                return element.program_id
                            });
                        }
                        if (required_program_to_unlock_ids && required_program_to_unlock_ids.length > 0) {
                            let program_to_unlock = [];
                            let locked_number = 0;
                            //console.log("required_program_to_unlock_ids manin", required_program_to_unlock_ids)
                            program_data?.length > 0 && program_data.forEach(element => {
                                if (required_program_to_unlock_ids.indexOf(element.program_id) > -1) {
                                    if (element.partner_id == "14" && (program.partner_id == "15" && program.has_enter == 1)) {
                                    }
                                    else {
                                        if (element.has_enter == 0 && element.is_eligible == 1 && element.program_type !== "story" && !element?.is_lock_program && (typeof element.is_visible == 'undefined' || element.is_visible != false)) {
                                            program_to_unlock.push(element);
                                            locked_number = locked_number + 1;
                                            pro.is_still_locked = true;
                                            pro.locked_number = locked_number;
                                            pro.program_to_unlock = program_to_unlock;
                                        }
                                    }
                                }

                            });
                            //console.log("locked_number1 main", locked_number)
                        } else {
                            let program_to_unlock = [];
                            let locked_number = 0;
                            locked_number = locked_number;
                            pro.is_still_locked = false;
                            pro.locked_number = locked_number;
                            pro.program_to_unlock = program_to_unlock;
                            //console.log("locked_number2 main", locked_number)
                        }

                    }

                    
                    all_program.push(pro)
                }
            })
            
            this.props.handleProgramDetail(program)
            this.props.handleprogramList(all_program)
            this.props.handleAllprogramList(all_program)
            this.setState({ all_program })

        }
       

    }


    childInvitation = (timeSet) => {
        let self = this;
        let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
        if (child_data && child_data.length) {
            let isOpen = false
            child_data.map(checkChild => {
                if (!checkChild.parent_id && (checkChild.relation_with_child !== 'parent' || !checkChild.relation_with_child)) {
                    isOpen = true
                }
            })
            setTimeout(() => {
                self.setState({ isOpen })
                if (this.invitaitonref) this.invitaitonref.calltoupdateIndex()
            }, timeSet);
        }
    }

    handleAnimationOnBack = () => {
        let self = this;
        $(document).on('click', '.artBoard_btnbackdiv, .phone_verify_backlink', function () {
            let TL = new TimelineMax();
            TL.fromTo('.instant_redem2 button, .freebies_redem button', 0.4, { opacity: 0, y: -20 }, { opacity: 1, y: 0, display: "initial" })
        })
    }

    getProgress = (enter_point, gain_family_points, total_family_points) => {
        let percentage = enter_point;
        if (gain_family_points != 0) {
            percentage = (gain_family_points + enter_point) / total_family_points * 100;
        }
        return percentage;
    }

    handleLocked = (program) => {
        let success_action = "User clicked on lets do it button on program detail page " + program.program_id
        let image = 'lets_do_it_button_program_detail_page_' + program.program_id + '.png'
        functions.logUserActivity(success_action, image);
        localStorage.setItem("detail_page", 'false')
        this.props.props.history.push("/buildyourfamily");
        // $('.program-page.active').find('.close_btn').trigger('click');
    }

    handleBack = () => {
        localStorage.setItem("phone_number_required", "false");
        let state_program_array = this.state.program_array
        state_program_array[0].phone_number_required = false;
        this.setState({ loader: false })
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        if (session_entered_id != "") {
            localStorage.removeItem("session_entered_id")
            localStorage.removeItem("pending_id")
            // localStorage.setItem("previous_state", 'program_detail')
        }

    }

    handleGuardTreeTextBack = () => {
        localStorage.setItem("guardtree_text_required", "false");
        let state_program_array = this.state.program_array
        state_program_array[0].guardtree_text_required = false;
        this.setState({ loader: false })
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        if (session_entered_id != "") {
            localStorage.removeItem("session_entered_id")
            localStorage.removeItem("pending_id")
            localStorage.setItem("previous_state", 'program_detail')
        }

    }

    handlePolicyTermsBack = () => {
        localStorage.setItem("policy_terms_required", "false");
        let state_program_array = this.state.program_array
        state_program_array[0].policy_terms_required = false;
        this.setState({ loader: false })
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        if (session_entered_id != "") {
            localStorage.removeItem("session_entered_id")
            localStorage.removeItem("pending_id")
            localStorage.setItem("previous_state", 'program_detail')
        }

    }

    handlePolicyAdvisorBack = () => {
        localStorage.setItem("policyadvisor_consent_required", "false");
        let state_program_array = this.state.program_array
        state_program_array[0].policyadvisor_consent_required = false;
        this.setState({ loader: false })
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        if (session_entered_id != "") {
            localStorage.removeItem("session_entered_id")
            localStorage.removeItem("pending_id")
            localStorage.setItem("previous_state", 'program_detail')
        }
    }

    handleEpilogueBack = () => {
        localStorage.setItem("epilogue_question_required", "false");
        let state_program_array = this.state.program_array
        state_program_array[0].epilogue_question_required = false;
        this.setState({ loader: false })
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        if (session_entered_id != "") {
            localStorage.removeItem("session_entered_id")
            localStorage.removeItem("pending_id")
            localStorage.setItem("previous_state", 'program_detail')
        }
    }

    handleCSTBack = () => {
        localStorage.setItem("cst_consent_required", "false");
        let state_program_array = this.state.program_array
        state_program_array[0].cst_consent_required = false;
        this.setState({ loader: false })
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        if (session_entered_id != "") {
            localStorage.removeItem("session_entered_id")
            localStorage.removeItem("pending_id")
            localStorage.setItem("previous_state", 'program_detail')
        }
    }

    handleSurveyBack = () => {
        localStorage.setItem("survey_question_required", "false");
        let state_program_array = this.state.program_array
        state_program_array[0].show_survey_question = false;
        this.setState({ loader: false, survey_load: false })
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        if (session_entered_id != "") {
            localStorage.removeItem("session_entered_id")
            localStorage.removeItem("pending_id")
            localStorage.setItem("previous_state", 'program_detail')
        }
        if (state_program_array[0].partner_id == "15" && state_program_array[0].has_enter) this.handleDetailBack(state_program_array[0])
    }

    handleRelativeBack = (program) => {
        let success_action = "User clicked on relative back button on program detail page " + program.program_id + " function"
        let image = 'relative_back_button_program_detail_page_' + program.program_id + '.png'
        functions.logUserActivity(success_action, image);
        localStorage.setItem("add_relative_required", "false");
        let state_program_array = this.state.program_array
        state_program_array[0].add_relative_required = false;
        this.setState({ loader: false })
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        if (session_entered_id != "") {
            localStorage.removeItem("session_entered_id")
            localStorage.removeItem("pending_id")
            localStorage.setItem("previous_state", 'program_detail')
        }

    }

    handleBabyRegistryBack = () => {
        let state_program_array = this.state.program_array
        let success_action = "User clicked on back button on program detail page baby registry component " + state_program_array[0].program_id
        let image = 'back_button_baby_registery_component_program_detail_' + state_program_array[0].program_id + '.png'
        functions.logUserActivity(success_action, image);
        localStorage.setItem("baby_registry_required", "false");
        state_program_array[0].baby_registry_required = false;
        this.setState({ loader: false })
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        if (session_entered_id != "") {
            localStorage.removeItem("session_entered_id")
            localStorage.removeItem("pending_id")
            localStorage.setItem("previous_state", 'program_detail')
        }

    }

    handleDOBBack = () => {
        let state_program_array = this.state.program_array
        let success_action = "User clicked on back button on program detail page dob component " + state_program_array[0].program_id
        let image = 'back_button_dob_component_program_detail_page_' + state_program_array[0].program_id + '.png'
        functions.logUserActivity(success_action, image);
        localStorage.setItem("birthdate_required", "false");
        state_program_array[0].birthdate_required = false;
        this.setState({ loader: false })
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        if (session_entered_id != "") {
            localStorage.removeItem("session_entered_id")
            localStorage.removeItem("pending_id")
            localStorage.setItem("previous_state", 'program_detail')
        }
    }

    handleTermsBack = () => {
        let state_program_array = this.state.program_array
        let success_action = "User clicked on back button on program detail page terms and condition component " + state_program_array[0].program_id
        let image = 'back_button_terms_and_condition_component_program_detail_page_' + state_program_array[0].program_id + '.png'
        functions.logUserActivity(success_action, image);
        localStorage.setItem("term_and_condition_required", "false");
        state_program_array[0].term_and_condition_required = false;
        this.setState({ loader: false })
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : ""
        localStorage.removeItem("session_entered_id")
        localStorage.removeItem("pending_id")
        if (session_entered_id != "") {
            localStorage.setItem("previous_state", 'program_detail')
        }
    }

    allwinners = (program) => {
        let success_action = "User clicked on all winners button on program detail page " + program.program_id
        let image = 'all_winners_button_program_detail_page_' + program.program_id + '.png'
        functions.logUserActivity(success_action, image);
        functions.pushToRoute(this, 'allwinners', 'program_detail')
    }

    handlePageBack = () => {
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        if (previous_state == "profile") {
            localStorage.removeItem("session_entered_id")
            localStorage.removeItem("pending_id")
        }
        else {
            localStorage.removeItem("session_entered_id")
            localStorage.removeItem("pending_id")
            localStorage.setItem("previous_state", 'program_detail')
        }
    }

    handleCircleGrowEffect = () => {
        var body = document.getElementById("some-program");
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var piTwo = Math.PI * 2;

        // Viewport
        var vw = canvas.width = body.offsetWidth;
        var vh = canvas.height = body.offsetHeight;


        if (document.getElementById('one_social_connect')) {
            document.getElementById('one_social_connect').addEventListener("click", createRipple);
        }

        function createRipple(event) {

            // Exit if target is the canvas. That means it's still active.
            if (event.target === canvas) return;

            body.appendChild(canvas);

            // Event coords
            var x = event.clientX;
            var y = event.clientY;


            // Delta - difference between event and farthest corner
            var dx = x < vw / 2 ? vw - x : x;
            var dy = y < vh / 2 ? vh - y : y;


            var radius = Math.sqrt(dx * dx + dy * dy);


            var ripple = {
                alpha: 0,
                radius: 0,
                x: x,
                y: (radius / 2) + (y / 2)
            };

            var tl = new TimelineMax({ onUpdate: drawRipple, onComplete: removeCanvas })
                .to(ripple, 2, { alpha: 1, radius: radius })
                .to(ripple, 0.1, { alpha: 0 }, 1.9);

            function drawRipple() {
                ctx.clearRect(0, 0, vw, vh);
                ctx.beginPath();
                ctx.arc(ripple.x, ripple.y, ripple.radius, 0, piTwo, false);
                ctx.fillStyle = "rgba(252,241,239," + ripple.alpha + ")";
                ctx.fill();
            }
        }
        function removeCanvas() {
            if (canvas !== null) {
                // Remove canvas from the document body
                if (body != null && body.contains(canvas)) {
                    body.removeChild(canvas);
                }
            }

        }

        function onResize() {
            vw = canvas.width = window.innerWidth;
            vh = canvas.height = window.innerHeight;
        }
    }

    handleAddRelation = (program) => {
        let success_action = "User clicked on add relation button on program detail page " + program.program_id
        let image = 'add_relation_button_program_detail_page_' + program.program_id + '.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        if (self.state.relations && self.state.relations.length < 1) {
            localStorage.setItem("form_type", "add_relation");
            localStorage.setItem("detail_page", 'true')
            setTimeout(function () {
                functions.pushToRoute(self, 'addrelative', 'program_detail')
            }, 500)
        }
        else {
            localStorage.setItem("detail_page", 'true')
            setTimeout(function () {
                functions.pushToRoute(self, 'familymembers', 'program_detail')
            }, 500)
        }
    }

    handleGiveawayAddRelation = (program) => {
        let success_action = "User clicked on add relation button on program detail page " + program.program_id
        let image = 'add_relation_button_program_detail_page_' + program.program_id + '.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        localStorage.setItem("form_type", "add_relation");
        localStorage.setItem("enter_relative_program", "true");
        localStorage.setItem("enter_relative_program_id", "5667");
        localStorage.setItem("detail_page", 'true')
        // setTimeout(function () {
        functions.pushToRoute(self, 'addrelative', 'program_detail')
        // }, 500)
    }

    handleFreebie = (program, redeem_type) => {
        let self = this
        // var scroll_to = $('.declaration_frrebies').offset().top + $('.declaration_frrebies').height();
        $(".program-page").animate({ scrollTop: 400 });
        let success_action = "User clicked on Enter button on program detail page " + program.program_id
        if (redeem_type != "") {
            success_action = success_action + " " + redeem_type
        }
        let image = 'enter_button_program_detail_page_' + program.program_id + '.png'
        functions.logUserActivity(success_action, image);
        let child_data = this.state.child_data
        if (child_data && child_data.length > 0) {
            let child_end_span = program.child_age.end_span
            let child_start_span = program.child_age.start_span
            let user_eligible = ""
            let child_eligible = false;
            if (program.partner_id == "3") {
                let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                var temp_enfamil_array = program_array.filter(obj => {
                    return (obj.partner_id === "3" && obj.program_type !== "story" && obj.program_type !== "contest")
                })
                let entry_count = 1;
                let enfamil_array = self.findAndMoveToStart(temp_enfamil_array, "program_id", program.program_id)
                enfamil_array && enfamil_array.length > 0 && enfamil_array.forEach((element, index) => {
                    setTimeout(function () {
                        child_data && child_data.length > 0 && child_data.forEach((child_element, child_index) => {
                            let child_date = new Date(child_element.birthdate_timestamp);
                            var today = new Date()
                            var diff = self.monthDiff(today, child_date);
                            // diff /= (60 * 60 * 24 * 7 * 4);
                            let age_diff = parseInt(diff)
                            if (age_diff >= element.child_age.start_span && age_diff <= element.child_age.end_span) {
                                child_eligible = true
                            }
                            if (child_index == (child_data.length - 1)) {
                                // call click function
                                if (child_eligible == true) {
                                    if (entry_count == 1) {
                                        self.handleClick(element, '', '', 'redeem/enter')
                                    }
                                    else {
                                        let is_sub_id = 'yes';
                                        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
                                        sub_id = sub_id ? sub_id : ""
                                        let program_id = element.program_id;
                                        let term_id = element.term_id ? element.term_id : "null";
                                        let partner_id = element.partner_id;
                                        let program_type = element.program_type;
                                        let registration_source = element.registration_source ? element.registration_source : ""
                                        let point_type = 'entry';
                                        let is_direct = 1;
                                        let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
                                        setTimeout(() => {
                                            functions.contestEntry(self, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, '', program_type, term_id, '', '', '', function (response_json) {
                                                let response = JSON.parse(response_json);
                                                if (response.status == 200) {

                                                    let mydiv = document.getElementsByClassName("enter_program")
                                                    for (let i = 0; i < mydiv.length; i++) {
                                                        let element = mydiv[i]
                                                        if (element.id == program.program_id) {
                                                            localStorage.setItem("clicked_id", program.program_id)
                                                            localStorage.setItem("clicked_partner", program.partner_name)
                                                            setTimeout(() => {
                                                                if (mydiv && mydiv[i]) mydiv[i].click()
                                                            }, 500)
                                                        }
                                                    }

                                                    if (program_type == "contest" || program_type == "freebie" || program_type == "offer") {
                                                        let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
                                                        if (child_data && child_data.length) {
                                                            let isOpen = false
                                                            child_data.map(checkChild => {
                                                                if (!checkChild.parent_id && checkChild.relation_with_child !== 'parent') {
                                                                    isOpen = true
                                                                }
                                                            })

                                                            setTimeout(() => {
                                                                self.setState({ isOpen })
                                                            }, 10000);
                                                        }
                                                    }
                                                    program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                                                    let success_action = "User get success on contestEntry function on program detail page " + program.program_id
                                                    functions.logUserActivity(success_action, null);
                                                    functions.logUserTermsOnEntryActivity(success_action, element)
                                                    var program_index = program_array.findIndex(p => p.program_id == element.program_id)
                                                    program_array[program_index].has_enter = 1;
                                                    program_array[program_index].pending_fields = [];
                                                    localStorage.setItem("program_list", JSON.stringify(program_array))
                                                }
                                            })
                                        }, 10000)

                                    }
                                    entry_count = entry_count + 1
                                    child_eligible = false
                                } else {
                                    self.setState({ error_message: "worked" })
                                    let user_action = "User got error on innerpages/program_detail file handleFreebie function";
                                    let body = url.dynamic_content?.error_message?.child_not_eligible?.en
                                    functions.logUserError(user_action, body);
                                    self.setState({ error: 1, show_error_message: url.dynamic_content?.error_message?.child_not_eligible?.en })
                                    // if (entry_count == 1) {
                                    //     self.handleClick(element, '', '', 'redeem/enter')
                                    // }
                                    // else {
                                    //     let is_sub_id = 'yes';
                                    //     let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
                                    //     sub_id = sub_id ? sub_id : ""
                                    //     let program_id = element.program_id;
                                    //     let term_id = element.term_id ? element.term_id : "null";
                                    //     let partner_id = element.partner_id;
                                    //     let program_type = element.program_type;
                                    //     let registration_source = element.registration_source ? element.registration_source : ""
                                    //     let point_type = 'entry';
                                    //     let is_direct = 1;
                                    //     let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";

                                    //     setTimeout(() => {
                                    //         functions.contestEntry(self, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, '', program_type, term_id, function (response_json) {
                                    //             let response = JSON.parse(response_json);
                                    //             if (response.status == 200) {

                                    //                 let mydiv = document.getElementsByClassName("enter_program")
                                    //                 for (let i = 0; i < mydiv.length; i++) {
                                    //                     let element = mydiv[i]
                                    //                     if (element.id == program.program_id) {
                                    //                         localStorage.setItem("clicked_id", program.program_id)
                                    //                         setTimeout(() => {
                                    //                             if(mydiv && mydiv[i]) mydiv[i].click()
                                    //                         }, 500)
                                    //                     }
                                    //                 }
                                    //                 if (program_type == "contest" || program_type == "freebie" || program_type == "offer") {
                                    //                     let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
                                    //                     if (child_data && child_data.length) {
                                    //                         let isOpen = false
                                    //                         child_data.map(checkChild => {
                                    //                             if (!checkChild.parent_id && checkChild.relation_with_child !== 'parent') {
                                    //                                 isOpen = true
                                    //                             }
                                    //                         })

                                    //                         setTimeout(() => {
                                    //                             self.setState({ isOpen })
                                    //                         }, 10000);
                                    //                     }
                                    //                 }
                                    //                 let success_action = "User get success on contestEntry function on program detail page " + program.program_id
                                    //                 functions.logUserActivity(success_action, null);
                                    //                 functions.logUserTermsOnEntryActivity(success_action, element)
                                    //                 var program_index = program_array.findIndex(p => p.program_id == element.program_id)
                                    //                 program_array[program_index].has_enter = 1;
                                    //                 program_array[program_index].pending_fields = [];
                                    //                 localStorage.setItem("program_list", JSON.stringify(program_array))
                                    //             }
                                    //         })
                                    //     }, 10000)

                                    // }
                                    entry_count = entry_count + 1
                                    child_eligible = false
                                }
                            }
                        })
                    }, index * 10000)
                })
            } else {
                child_data && child_data.length > 0 && child_data.forEach((element, index) => {
                    let child_date = new Date(element.birthdate_timestamp);
                    var today = new Date()
                    var diff = self.monthDiff(today, child_date);
                    // diff /= (60 * 60 * 24 * 7 * 4);
                    let age_diff = parseInt(diff)
                    if (age_diff >= child_start_span && age_diff <= child_end_span) {
                        child_eligible = true
                    }
                    if (index == (child_data.length - 1)) {
                        // call click function
                        if (child_eligible == true) {
                            if (program.partner_id == "10") {
                                this.handleAnimation()
                                let userdata = [];
                                let dynamo_userdata = { 'nestle_redeem_type': redeem_type };
                                localStorage.setItem("nestle_redeem_type", redeem_type);
                                functions.updateUserDataPass(self, userdata, dynamo_userdata, function (err, passData) {
                                    if (err) {
                                    }
                                    else {
                                        self.handleClick(program, 'stop_animation', '', 'redeem/enter')
                                    }
                                })
                            } else {
                                this.handleClick(program, '', '', 'redeem/enter');
                            }
                        }
                        else {
                            this.handleClick(program, '', '', 'redeem/enter');
                            // this.setState({ error_message: "worked" })
                            // let user_action = "User got error on innerpages/program_detail file handleFreebie function";
                            // let body = url.dynamic_content.error_message.child_not_eligible.en
                            // functions.logUserError(user_action, body);
                            // this.setState({ error: 1, show_error_message: url.dynamic_content.error_message.child_not_eligible.en })
                        }
                    }
                });
            }

        }
        else if (child_data?.length == 0) {
            let user_action = "User got error on innerpages/program_detail file handleFreebie function";
            let body = url.dynamic_content?.error_message?.child_missing?.en
            functions.logUserError(user_action, body);
            this.setState({ error: 1, show_error_message: url.dynamic_content?.error_message?.child_missing?.en })
        }
        if (program.program_id === "9127") {
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");

            let fintelApiUrl = `https://api.fintelconnect.com/t/l/finteltag/a-28153b-25049c-${sub_id}`;
            window.open(fintelApiUrl);
        }
    }

    findAndMoveToStart = (arr, key, value) => {
        // Find the index of the object in the array
        const index = arr.findIndex(obj => obj[key] === value);

        // Check if the object is found
        if (index !== -1) {
            // Remove the object from the array
            const [element] = arr.splice(index, 1);
            // Add the object to the start of the array
            arr.unshift(element);
        }

        return arr;
    }

    monthDiff = (d1, d2) => {
        var m = parseInt(d1.getFullYear() - d2.getFullYear()) * 12 + parseInt(d1.getMonth() - d2.getMonth());
        if (d1.getDate() < d2.getDate()) --m;
        return m;
    }

    handleHelp = (program) => {
        let success_action = "User clicked on help button on program detail page " + program.program_id
        let image = 'help_button_program_detail_page_' + program.program_id + '.png'
        functions.logUserActivity(success_action, image);
        localStorage.setItem('show_terms', 'false');
        localStorage.setItem('show_support', 'false');
        localStorage.setItem('detail_page', 'true');
        functions.pushToRoute(this, 'help', 'program_detail')
    }

    handleDetailBack = (program) => {
        this.setState({ program_array: this.state.origin_program_array, program_type: this.state.origin_program_type, animationStart: false });
        this.handleAnimationAfterEntry(program)
        let previous_state = localStorage.getItem("previous_state")
        let state_program_array = this.state.program_array
        localStorage.removeItem("enter_relative_program")
        localStorage.removeItem("enter_relative_program_id")
        if (state_program_array[0].phone_number_required == true) {
            this.handleBack()
        }
        if (state_program_array[0].cst_consent_required == true) {
            this.handleCSTBack()
        }
        if (state_program_array[0].policy_terms_required == true) {
            this.handlePolicyTermsBack()
        }
        if (state_program_array[0].policyadvisor_consent_required == true) {
            this.handlePolicyAdvisorBack()
        }
        if (state_program_array[0].epilogue_question_required == true) {
            this.handleEpilogueBack()
        }
        if (state_program_array[0].show_survey_question == true) {
            this.handleSurveyBack()
        }
        if (state_program_array[0].add_relative_required == true) {
            this.handleRelativeBack(state_program_array[0])
        }
        if (state_program_array[0].birthdate_required == true) {
            this.handleDOBBack()
        }
        if (state_program_array[0].baby_registry_required == true) {
            this.handleBabyRegistryBack()
        }
        if (state_program_array[0].term_and_condition_required == true) {
            this.handleTermsBack()
        }
        if (state_program_array[0].guardtree_text_required == true) {
            this.handleGuardTreeTextBack()
        }
        localStorage.removeItem('detail_page');
        if (localStorage.getItem("programSONSuccess") == "true") {
            localStorage.removeItem("programSONSuccess");
            localStorage.removeItem("CheckMainIndex");
            localStorage.removeItem('session_entered_id');
            functions.pushToRoute(this, 'main', 'program_detail')
        } else if (previous_state == 'profile' || localStorage.getItem("CheckProfileRoute") == 'profile') {
            functions.pushToRoute(this, 'profile', 'program_detail')
            localStorage.removeItem("CheckProfileRoute")
        } else if (previous_state == 'main_index') {
            functions.pushToRoute(this, 'main', 'program_detail')
        }
        else {
            // console.log("cst current state")
            localStorage.setItem("current_state", 'program_detail')
            localStorage.setItem("detail_page", 'false')
        }
    }

    handleProgramClick = (program) => {
        let state_program_array = this.state.program_array
        state_program_array[0].term_and_condition_required = false;
        state_program_array[0].birthdate_required = false;
        state_program_array[0].phone_number_required = false;
        state_program_array[0].cst_consent_required = false;
        state_program_array[0].policy_terms_required = false;
        state_program_array[0].policyadvisor_consent_required = false;
        state_program_array[0].epilogue_question_required = false
        state_program_array[0].show_survey_question = false;
        state_program_array[0].add_relative_required = false;
        state_program_array[0].baby_registry_required = false;
        state_program_array[0].guardtree_text_required = false;
        this.setState({ program_array: state_program_array })
        localStorage.setItem("program_index", program.program_index)
        localStorage.setItem("program_id", program.program_id)
        localStorage.setItem("program", JSON.stringify(program))
        localStorage.setItem("main_id", program.program_type + program.program_id)
        localStorage.setItem("entered_id", program.program_id)
        let self = this;
        let program_id = program.program_id

        functions.getProgramWinners(this, program_id, function (response_json) {
            let response = JSON.parse(response_json);
            if (response.error == 1) {
            }
            else {
                self.setState({ show_winner_data: response.data })
                localStorage.setItem("show_winner_data", JSON.stringify(response.data))
            }
        });
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : "";

        let pending_id = localStorage.getItem("pending_id") ? localStorage.getItem("pending_id") : ""
        let program_temp = program
        let id = session_entered_id ? session_entered_id : pending_id
        if (id != "") {
            localStorage.setItem("program_id", id)
            if (program_temp.program_type == 'freebie') {
                this.handleFreebie(program, "")
            }
            else {
                this.handleClick(program_temp, '', '', 'redeem/enter')
            }
        }
        else {
            this.setState(({ animationStart: false }))
            this.handleAnimationAfterEntry(program);
            this.setState({ error: 0, show_error_message: '' })
        }
    }

    handleClickSubmit = (program, user_eligible, consent = null, survey_ques, from_phone) => {
        let success_action = "User clicked on submit button on program detail page of child component " + program.program_id
        let image = 'submit_button_on_child_component_program_detail_page_' + program.program_id + '.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        // console.log("consent", consent)
        let state_program_array = self.state.program_array
        if (program.partner_id == "14" && (localStorage.getItem("phone_number_verified") == "true" || localStorage.getItem("phone_verified") == "true")) {
            let pending_fields = state_program_array[0].pending_fields
            var filtered = pending_fields?.length && pending_fields.filter(function (value, index, arr) {
                return value != "phone_number";
            });
            state_program_array[0].pending_fields = filtered
        }
        state_program_array[0].consent_yes = false
        state_program_array[0].term_and_condition_required = false;
        state_program_array[0].birthdate_required = false;
        state_program_array[0].phone_number_required = false;
        state_program_array[0].cst_consent_required = false;
        state_program_array[0].policy_terms_required = false;
        state_program_array[0].policyadvisor_consent_required = false;
        state_program_array[0].epilogue_question_required = false
        state_program_array[0].show_survey_question = false;
        state_program_array[0].add_relative_required = false;
        state_program_array[0].baby_registry_required = false;
        state_program_array[0].guardtree_text_required = false;
        if (consent) state_program_array[0].cst_consent = consent;
        self.setState({ survey_load: true, show_phone: false, show_cst_consent: false, show_policyadvisor_consent: false, show_policy_terms: false, show_baby_registry: false, show_guardTreeText: false, loader: false })
        self.setState({ program_array: state_program_array })
        // console.log("state_program_array", state_program_array)
        this.CheckEntryCriteriaAndMakeEntry(program, user_eligible, consent, survey_ques, from_phone);
    }

    handlePopup = (program) => {
        this.setState({ show_popup: true })
    }

    CheckEntryCriteriaAndMakeEntry = (program, user_eligible, consent, survey_ques, from_phone) => {
        let self = this;
        let pending_criteria = self.state.pending_criteria;
        let current_criteria = self.state.current_criteria;
        let precurrent_criteria = self.state.current_criteria;

        if (pending_criteria && pending_criteria.length > 0 && current_criteria != "baby_registry" && (program.partner_id == "14" && consent != "no") && current_criteria == "phone_number") {
            pending_criteria = pending_criteria.filter(ele => ele !== "cst_consent")
            self.setState({ pending_criteria })
        }
        if (current_criteria && current_criteria != "" && current_criteria != "baby_registry") {
            pending_criteria.splice(0, 1);
            current_criteria = pending_criteria[0] ? pending_criteria[0] : "";
        }
        self.setState({ current_criteria: current_criteria, pending_criteria: pending_criteria })
        if (pending_criteria && pending_criteria.length > 0 && current_criteria != "baby_registry" && (program.partner_id == "14" && consent != "no")) {
            if (precurrent_criteria == "phone_number") {
                consent = 'yes';
                program.cst_consent = "yes"
            }
            //console.log("current_criteria", current_criteria)
            //console.log("pending_criteria", pending_criteria)
            let is_sub_id = 'yes';
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
            sub_id = sub_id ? sub_id : ""
            let { program_id, partner_id, program_type, term_id, registration_source } = program
            registration_source = registration_source ? registration_source : ""
            user_eligible = user_eligible ? user_eligible : ""
            let state_program_array = self.state.program_array
            state_program_array[0].consent_yes = false
            state_program_array[0].term_and_condition_required = false;
            state_program_array[0].birthdate_required = false;
            state_program_array[0].phone_number_required = false;
            state_program_array[0].cst_consent_required = false;
            state_program_array[0].policy_terms_required = false;
            state_program_array[0].policyadvisor_consent_required = false;
            state_program_array[0].epilogue_question_required = false
            state_program_array[0].show_survey_question = false;
            state_program_array[0].add_relative_required = false;
            state_program_array[0].baby_registry_required = false;
            state_program_array[0].guardtree_text_required = false;
            self.setState({
                show_phone: false,
                show_cst_consent: false,
                show_policyadvisor_consent: false,
                show_guardTreeText: false,
                show_policy_terms: false,
                loader: false,
            })
            if (current_criteria == "phone_number") {
                state_program_array[0].phone_number_required = true;
                self.setState({
                    show_phone: true
                })
                localStorage.setItem("phone_number_required", "true")
            }
            if (current_criteria == "cst_consent") {
                state_program_array[0].cst_consent_required = true;
                self.setState({
                    show_cst_consent: true
                })
                localStorage.setItem("cst_consent_required", "true")
            }
            if (current_criteria == "policy_terms") {
                state_program_array[0].policy_terms_required = true;
                self.setState({
                    show_policy_terms: true
                })
                localStorage.setItem("policy_terms_required", "true")
            }
            if (current_criteria == "enter_relative") {
                state_program_array[0].add_relative_required = true;
                localStorage.setItem("add_relative_required", "true")
            }
            if (current_criteria == "birthdate") {
                state_program_array[0].birthdate_required = true;
                self.setState({
                    show_dob: true
                })
                localStorage.setItem("birthdate_required", "true")
            }
            if (current_criteria == "term_and_condition" || current_criteria == "terms") {
                state_program_array[0].term_and_condition_required = true;
                self.setState({
                    show_terms: true
                })
                localStorage.setItem("term_and_condition_required", "true")
                localStorage.setItem("show_terms", "true")
            }
            if (current_criteria == "guardtree_text") {
                state_program_array[0].guardtree_text_required = true;
                self.setState({
                    show_guardTreeText: true
                })
                localStorage.setItem("guardtree_text_required", "true")
            }
            if (consent == "yes" && program.partner_id == "14") {
                let is_sub_id = 'yes';
                let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
                sub_id = sub_id ? sub_id : ""
                let { program_id, partner_id, program_type, term_id, registration_source } = program
                registration_source = registration_source ? registration_source : ""
                let point_type = 'entry';
                let is_direct = 1;

                let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
                // console.log("contestEntry6 1616")
                user_eligible = user_eligible ? user_eligible : ""
                let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                if (program_array) {
                    var program_index = program_array.findIndex(p => p.program_id == program_id)
                    program_array[program_index].has_enter = 1;
                    program_array[program_index].consent_yes = false
                    program_array[program_index]["cst_consent"] = consent
                    program_array[program_index].pending_fields = [];
                    if (current_criteria == "phone_number") {
                        program_array[program_index].pending_fields = ["phone_number"];
                    }
                    let state_program_array = self.state.program_array
                    state_program_array[0].has_enter = 1;
                    state_program_array[0].pending_fields = [];
                    if (partner_id == "14" && consent) state_program_array[0]["cst_consent"] = consent
                    if (current_criteria == "phone_number") {
                        state_program_array[0].pending_fields = ["phone_number"];
                    }
                    localStorage.removeItem("session_entered_id")
                    localStorage.removeItem("pending_id")
                    self.setState({ program_array: state_program_array });
                    localStorage.setItem("program_list", JSON.stringify(program_array))
                    if (partner_id !== "14") self.CheckAndUpdateProgramVisibility(program_array, state_program_array)
                    let program_only = program ? program : [];
                    if (program_only) {
                        program_only.has_enter = 1;
                        program_only.pending_fields = [];
                        if (current_criteria == "phone_number") {
                            program_only.pending_fields = ["phone_number"];
                        }
                        localStorage.removeItem("session_entered_id")
                        localStorage.removeItem("pending_id")
                        localStorage.setItem("program", JSON.stringify(program_only))
                    }
                }
                functions.contestEntry(self, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, '', program_type, term_id, consent, "", survey_ques, function (response_json) {
                    let response = JSON.parse(response_json);
                    if (response.status == 200) {
                        if (partner_id == '15') {
                            const { program_array } = this.state;
                            localStorage.setItem(`emabark${program_id}_survey`, 'null');
                            program_array[0].show_survey_question = true;
                            program_array[0].survey_question = embark_survey_questions;
                            self.setState({ program_array });
                        }
                        let success_action = "User get success on contestEntry function on program detail page " + program.program_id
                        functions.logUserActivity(success_action, null);
                        functions.logUserTermsOnEntryActivity(success_action, program)
                        let mydiv = document.getElementsByClassName("enter_program")

                        for (let i = 0; i < mydiv.length; i++) {
                            let element = mydiv[i]
                            if (element.id == program.program_id) {
                                localStorage.setItem("clicked_id", program.program_id)
                                localStorage.setItem("clicked_partner", program.partner_name)
                                setTimeout(() => {
                                    if (mydiv[i]) {
                                        mydiv[i].click()
                                    }
                                }, 500)
                            }
                        }
                        if (current_criteria !== "phone_number") {
                            if (program_type == "contest" || program_type == "freebie" || program_type == "offer") {
                                self.childInvitation(10000 * 9)
                            }
                        }
                        self.GetFreebieAndContestNotLocked(program)
                        self.updateProgram(program)
                        
                    }
                }, from_phone)
            }
            // })
        } else {
            this.setState(({ animationStart: true }))
            this.handleAnimation();
            let is_sub_id = 'yes';
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
            sub_id = sub_id ? sub_id : ""
            let { program_id, partner_id, program_type, term_id, registration_source } = program
            registration_source = registration_source ? registration_source : ""
            let point_type = 'entry';
            let is_direct = 1;

            let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
            // console.log("contestEntry6")
            user_eligible = user_eligible ? user_eligible : ""
            if (precurrent_criteria == "phone_number") {
                consent = 'yes'
                program.cst_consent = "yes"
            }
            if (partner_id !== '14' || consent == 'no' || (precurrent_criteria == "phone_number")) {
                functions.contestEntry(self, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, '', program_type, term_id, consent, "", survey_ques, function (response_json) {
                    let response = JSON.parse(response_json);
                    if (response.status == 200) {
                        let kff_past_entry = localStorage.getItem("kff_past_entry") && localStorage.getItem("kff_past_entry") == "true" ? true : false
                        if (partner_id == "1") {
                            localStorage.setItem("kff_past_entry", "true")
                        }
                        let nestle_past_entry = localStorage.getItem("nestle_past_entry") && localStorage.getItem("nestle_past_entry") == "true" ? true : false
                        if (partner_id == "10") {
                            localStorage.setItem("nestle_past_entry", "true")
                        }
                        let enfamil_past_entry = localStorage.getItem("enfamil_past_entry") && localStorage.getItem("enfamil_past_entry") == "true" ? true : false
                        if (partner_id == "3" && program.program_type !== "freebie") {
                            localStorage.setItem("enfamil_past_entry", "true")
                        }

                        let success_action = "User get success on contestEntry function on program detail page " + program.program_id
                        functions.logUserActivity(success_action, null);
                        functions.logUserTermsOnEntryActivity(success_action, program)
                        let mydiv = document.getElementsByClassName("enter_program")

                        if (!kff_past_entry && partner_id == "1") {
                            let kff_fresh_entry = document.getElementById("kff_fresh_entry")
                            kff_fresh_entry.click();
                        }

                        for (let i = 0; i < mydiv.length; i++) {
                            let element = mydiv[i]
                            if (element.id == program.program_id) {
                                localStorage.setItem("clicked_id", program.program_id)
                                localStorage.setItem("clicked_partner", program.partner_name)
                                setTimeout(() => {
                                    if (mydiv[i]) {
                                        mydiv[i].click()
                                    }
                                }, 500)
                            }
                        }
                        if (program_type == "contest" || program_type == "freebie" || program_type == "offer") {
                            // let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
                            // if (child_data && child_data.length) {
                            //     let isOpen = false
                            //     child_data.map(checkChild => {
                            //         if (!checkChild.parent_id && checkChild.relation_with_child !== 'parent') {
                            //             isOpen = true
                            //         }
                            //     })

                            //     setTimeout(() => {
                            //         self.setState({ isOpen })
                            //     }, 10000);
                            // }
                            self.childInvitation(10000 * 9)
                        }
                        let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                        if (program_array) {
                            var program_index = program_array.findIndex(p => p.program_id == program_id)
                            program_array[program_index].has_enter = 1;
                            program_array[program_index].consent_yes = false
                            program_array[program_index].pending_fields = [];
                            if (current_criteria == "baby_registry") {
                                program_array[program_index].pending_fields = ["baby_registry"];
                            }
                            if (partner_id == "14" && consent) {
                                // console.log("consent", consent)
                                program_array[program_index]["cst_consent"] = consent
                            }
                            let state_program_array = self.state.program_array
                            state_program_array[0].has_enter = 1;
                            state_program_array[0].pending_fields = [];
                            if (partner_id == "14" && consent) state_program_array[0]["cst_consent"] = consent
                            if (current_criteria == "baby_registry") {
                                state_program_array[0].pending_fields = ["baby_registry"];
                            }
                            localStorage.removeItem("session_entered_id")
                            localStorage.removeItem("pending_id")
                            if (partner_id == '15' && survey_ques && survey_ques != (null || undefined) && localStorage.getItem(`embark_${program_id}_survey`)) {
                                localStorage.setItem(`embark_${program_id}_survey`, 'completed');
                                state_program_array[0].show_survey_question = false;
                                state_program_array[0].survey_questions = null;
                            } else if (partner_id == '15' && precurrent_criteria == 'phone_number' && !survey_ques) {
                                localStorage.setItem(`embark_${program_id}_survey`, 'null');
                                state_program_array[0].show_survey_question = true;
                                state_program_array[0].survey_questions = embark_survey_questions;
                            }
                            self.setState({ program_array: state_program_array })
                            localStorage.setItem("program_list", JSON.stringify(program_array))
                            if (partner_id !== "14") self.CheckAndUpdateProgramVisibility(program_array, state_program_array)
                            let program_only = program ? program : [];
                            if (program_only) {
                                program_only.has_enter = 1;
                                program_only.pending_fields = [];
                                if (current_criteria == "baby_registry") {
                                    program_only.pending_fields = ["baby_registry"];
                                }
                                localStorage.removeItem("session_entered_id")
                                localStorage.removeItem("pending_id")
                                localStorage.setItem("program", JSON.stringify(program_only))
                                self.setState(({ animationStart: false }))
                                self.handleAnimationAfterEntry(program);
                            }
                        }
                        self.GetFreebieAndContestNotLocked(program)
                        self.updateProgram(program)
                        
                        if (partner_id == "14" && consent == "no") {
                            // console.log("back cst")
                            self.handleCSTBack()
                            localStorage.setItem("detail_page", 'false')
                            localStorage.removeItem("session_entered_id")
                            $('.program-page.active').find('.close_btn').trigger('click');
                        }
                    }
                }, from_phone)
            } else if (consent == "yes") {
                let kff_past_entry = localStorage.getItem("kff_past_entry") && localStorage.getItem("kff_past_entry") == "true" ? true : false
                if (partner_id == "1") {
                    localStorage.setItem("kff_past_entry", "true")
                }
                let nestle_past_entry = localStorage.getItem("nestle_past_entry") && localStorage.getItem("nestle_past_entry") == "true" ? true : false
                if (partner_id == "10") {
                    localStorage.setItem("nestle_past_entry", "true")
                }
                let enfamil_past_entry = localStorage.getItem("enfamil_past_entry") && localStorage.getItem("enfamil_past_entry") == "true" ? true : false
                if (partner_id == "3") {
                    localStorage.setItem("enfamil_past_entry", "true")
                }

                let success_action = "User get success on contestEntry function on program detail page " + program.program_id
                functions.logUserActivity(success_action, null);
                functions.logUserTermsOnEntryActivity(success_action, program)
                let mydiv = document.getElementsByClassName("enter_program")

                if (!kff_past_entry && partner_id == "1") {
                    let kff_fresh_entry = document.getElementById("kff_fresh_entry")
                    kff_fresh_entry.click();
                }

                for (let i = 0; i < mydiv.length; i++) {
                    let element = mydiv[i]
                    if (element.id == program.program_id) {
                        localStorage.setItem("clicked_id", program.program_id)
                        localStorage.setItem("clicked_partner", program.partner_name)
                        setTimeout(() => {
                            if (mydiv[i]) {
                                mydiv[i].click()
                            }
                        }, 500)
                    }
                }
                if (program_type == "contest" || program_type == "freebie" || program_type == "offer") {
                    // let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
                    // if (child_data && child_data.length) {
                    //     let isOpen = false
                    //     child_data.map(checkChild => {
                    //         if (!checkChild.parent_id && checkChild.relation_with_child !== 'parent') {
                    //             isOpen = true
                    //         }
                    //     })

                    //     setTimeout(() => {
                    //         self.setState({ isOpen })
                    //     }, 10000);
                    // }
                    self.childInvitation(10000 * 9)
                }
                let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                if (program_array) {
                    var program_index = program_array.findIndex(p => p.program_id == program_id)
                    program_array[program_index].has_enter = 1;
                    program_array[program_index].consent_yes = false
                    program_array[program_index].pending_fields = [];
                    if (current_criteria == "baby_registry") {
                        program_array[program_index].pending_fields = ["baby_registry"];
                    }
                    if (partner_id == "14" && consent) {
                        program_array[program_index]["cst_consent"] = consent
                    }
                    let state_program_array = self.state.program_array
                    state_program_array[0].has_enter = 1;
                    state_program_array[0].pending_fields = [];
                    if (partner_id == "14" && consent) state_program_array[0]["cst_consent"] = consent
                    if (current_criteria == "baby_registry") {
                        state_program_array[0].pending_fields = ["baby_registry"];
                    }

                    localStorage.removeItem("session_entered_id")
                    localStorage.removeItem("pending_id")
                    self.setState({ program_array: state_program_array })
                    localStorage.setItem("program_list", JSON.stringify(program_array))
                    if (partner_id !== "14") self.CheckAndUpdateProgramVisibility(program_array, state_program_array)
                    let program_only = program ? program : [];
                    if (program_only) {
                        program_only.has_enter = 1;
                        program_only.pending_fields = [];
                        if (current_criteria == "baby_registry") {
                            program_only.pending_fields = ["baby_registry"];
                        }
                        localStorage.removeItem("session_entered_id")
                        localStorage.removeItem("pending_id")
                        localStorage.setItem("program", JSON.stringify(program_only))
                        self.setState(({ animationStart: false }))
                        self.handleAnimationAfterEntry(program);
                    }
                }
                self.GetFreebieAndContestNotLocked(program)
                self.updateProgram(program)
                
                if (partner_id == "14" && consent == "no") {
                    // console.log("back cst")
                    self.handleCSTBack()
                    localStorage.setItem("detail_page", 'false')
                    localStorage.removeItem("session_entered_id")
                    $('.program-page.active').find('.close_btn').trigger('click');
                }

            }
            else {
                let success_action = "User get success on contestEntry function on program detail page " + program.program_id
                functions.logUserActivity(success_action, null);
                functions.logUserTermsOnEntryActivity(success_action, program)
                let mydiv = document.getElementsByClassName("enter_program")


                for (let i = 0; i < mydiv.length; i++) {
                    let element = mydiv[i]
                    if (element.id == program.program_id) {
                        localStorage.setItem("clicked_id", program.program_id)
                        localStorage.setItem("clicked_partner", program.partner_name)
                        setTimeout(() => {
                            if (mydiv[i]) {
                                mydiv[i].click()
                            }
                        }, 500)
                    }
                }
                if (program_type == "contest" || program_type == "freebie" || program_type == "offer") {
                    // let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
                    // if (child_data && child_data.length) {
                    //     let isOpen = false
                    //     child_data.map(checkChild => {
                    //         if (!checkChild.parent_id && checkChild.relation_with_child !== 'parent') {
                    //             isOpen = true
                    //         }
                    //     })

                    //     setTimeout(() => {
                    //         self.setState({ isOpen })
                    //     }, 10000);
                    // }
                    self.childInvitation(10000 * 9)
                }
                let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                if (program_array) {
                    var program_index = program_array.findIndex(p => p.program_id == program_id)
                    program_array[program_index].has_enter = 1;
                    program_array[program_index].consent_yes = false
                    program_array[program_index].pending_fields = [];
                    if (current_criteria == "baby_registry") {
                        program_array[program_index].pending_fields = ["baby_registry"];
                    }
                    if (partner_id == "14" && consent) {
                        program_array[program_index]["cst_consent"] = consent
                    }
                    let state_program_array = self.state.program_array
                    state_program_array[0].has_enter = 1;
                    state_program_array[0].pending_fields = [];
                    if (partner_id == "14" && consent) state_program_array[0]["cst_consent"] = consent
                    if (current_criteria == "baby_registry") {
                        state_program_array[0].pending_fields = ["baby_registry"];
                    }
                    localStorage.removeItem("session_entered_id")
                    localStorage.removeItem("pending_id")
                    self.setState({ program_array: state_program_array })
                    localStorage.setItem("program_list", JSON.stringify(program_array))
                    if (partner_id !== "14") self.CheckAndUpdateProgramVisibility(program_array, state_program_array)
                    let program_only = program ? program : [];
                    if (program_only) {
                        program_only.has_enter = 1;
                        program_only.pending_fields = [];
                        if (current_criteria == "baby_registry") {
                            program_only.pending_fields = ["baby_registry"];
                        }
                        localStorage.removeItem("session_entered_id")
                        localStorage.removeItem("pending_id")
                        localStorage.setItem("program", JSON.stringify(program_only))
                        self.setState(({ animationStart: false }))
                        self.handleAnimationAfterEntry(program);
                    }
                }
                self.GetFreebieAndContestNotLocked(program)
                self.updateProgram(program)
                self.handleAnimationAfterEntry(program);
            }
        }
    }

    contestEntryCST = (program, consent, user_eligible) => {
        let self = this;
        let is_sub_id = 'yes';
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""
        let { program_id, partner_id, program_type, term_id, registration_source } = program
        registration_source = registration_source ? registration_source : ""
        let point_type = 'entry';
        let is_direct = 1;
        let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
        this.setState(({ animationStart: true }))
        this.handleAnimation()
        let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
        // console.log("contestEntry6 1616")
        user_eligible = user_eligible ? user_eligible : "";

        functions.contestEntry(self, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, '', program_type, term_id, consent, "", '', function (response_json) {
            let response = JSON.parse(response_json);
            // console.log("response", response, consent)
            if (response.status == 200) {
                // setTimeout(() => {
                self.handleClickSubmit(program, undefined, consent)
                // }, 2000);
            }

        })
    }

    contestEntryKff = (program, consent = '', user_eligible) => {
        let self = this;
        let is_sub_id = 'yes';
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""
        let { program_id, partner_id, program_type, term_id, registration_source } = program
        registration_source = registration_source ? registration_source : ""
        let point_type = 'entry';
        let is_direct = 1;

        let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";

        user_eligible = user_eligible ? user_eligible : "";

        functions.contestEntry(self, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, '', program_type, term_id, consent, "", '', function (response_json) {
            let response = JSON.parse(response_json);

            if (response.status == 200) {
                // console.log("Succeeded");
            }

        })
    }
    handleAddChild = (program) => {
        let success_action = "User clicked on add child on program detail page " + program.program_id
        let image = 'add_child_program_detail_page_' + program.program_id + '.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        localStorage.setItem("form_type", 'add_child')
        localStorage.removeItem("family_activeTab")
        localStorage.setItem("add_as_relative", "false")
        setTimeout(function () {
            functions.pushToRoute(self, "addchild", 'family');
        }, 1000)
    }

    handleEditChildInfo = () => {
        let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
        if (child_data && child_data.length > 0) {
            functions.pushToRoute(this, "buildfamily", 'program_detail');
        }
        else {
            localStorage.setItem("form_type", 'add_child')
            functions.pushToRoute(this, "addchild", 'program_detail')
        }
    }

    handleNotifyMe = (program) => {
        let success_action = "User clicked on notify me on program detail page " + program.program_id
        let image = 'notify_me_button_program_detail_page_' + program.program_id + '.png'
        functions.logUserActivity(success_action, image);
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""
        functions.insertInNotifyMe(this, program.program_id, sub_id, function (response_json) {
            let response = JSON.parse(response_json);
        });
    }

    GetFreebieAndContest = (program) => {
        let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
        let show_program_id = localStorage.getItem("show_program_id") ? JSON.parse(JSON.stringify(localStorage.getItem("show_program_id"))) : "";
        let locked_partner = []
        let locked_program = []
        let programs = {}
        if (show_program_id) {
            programs = program_array.find(pro =>
                pro.program_id == show_program_id
            )
        }
        else if (program && program?.is_lock_program && program.locked_type == "custom") {
            programs = program
        }
        if (programs && programs?.is_lock_program && programs.locked_type == "custom") {
            locked_program = programs.locked_program_list
            locked_partner = programs.locked_partner_list
        }

        let contest = program_array?.length && program_array.filter(function (p) {
            return p.program_type === "contest";
        });
        let freebie = program_array?.length && program_array.filter(function (p) {
            return p.program_type === "freebie";
        });
        let offers = program_array?.length && program_array.filter(function (p) {
            return p.program_type === "offers";
        });
        let survey = program_array?.length && program_array.filter(function (p) {
            return p.program_type === "survey";
        });
        let temp_freebie_and_contest_1 = contest && contest.concat(freebie);
        let temp_freebie_and_contest2 = temp_freebie_and_contest_1;
        if ((locked_program?.length) || locked_partner?.length) temp_freebie_and_contest2 = temp_freebie_and_contest_1 && temp_freebie_and_contest_1.concat(survey);
        let temp_freebie_and_contest = temp_freebie_and_contest2 && temp_freebie_and_contest2.concat(offers);
        let temp_array = temp_freebie_and_contest && temp_freebie_and_contest.sort(function (a, b) {
            return a.order_number - b.order_number
        })
        let freebie_and_contest = []
        let cst_visibility = this.checkCSTVisibilty(temp_array)
        temp_array && temp_array.length > 0 && temp_array.forEach((element, index) => {
            if ((!element?.is_lock_program || !show_program_id || (element?.is_lock_program && element.program_id == show_program_id)) && (locked_program?.length == 0 && locked_partner?.length == 0 || ((locked_program.includes(element.program_id) || (locked_partner.includes(element.partner_id)))))) {
                if (element.after_entry_of && element.after_entry_of != "" && element.after_entry_of != "[]") {
                    if (cst_visibility || (element.partner_id !== '1' && element.partner_id !== "15")) {
                        let element_visibility = this.CheckProgramVisibility(element, program_array)
                        if (element.hasOwnProperty('visible_to_user_signup') && element.visible_to_user_signup === false) {
                            element.is_visible = element.visible_to_user_signup
                        } else {
                            if (element.hasOwnProperty("visible_to_user") && !element.visible_to_user) {
                                element.is_visible = element.visible_to_user
                            } else {
                                element.is_visible = element_visibility ? element_visibility : false
                            }
                        }
                    }
                    else {
                        element.is_visible = false
                    }
                    if (element.has_enter == 0 && element.is_visible) {
                        freebie_and_contest.push(element)
                    }
                }
                else {
                    let element_visibility = true
                    if (cst_visibility || (element.partner_id !== '1' && element.partner_id !== "15")) {
                        if (element.notentry_entry_type) {
                            element_visibility = this.check_notentry_of_after(element, temp_array)
                        }
                        // element.program_index = index + 1
                        if (element.hasOwnProperty('visible_to_user_signup') && element.visible_to_user_signup === false) {
                            element.is_visible = element.visible_to_user_signup
                        } else {
                            if (element.hasOwnProperty("visible_to_user") && !element.notentry_after_of) {
                                element.is_visible = element.visible_to_user
                            } else {
                                element.is_visible = element_visibility ? element_visibility : false
                            }
                        }
                    }
                    else {
                        element.is_visible = false
                    }
                    if (element.has_enter == 0 && element.is_eligible == 0 && element.show_to_eligible_user == 1) {
                        element.show_program = false
                    } else {
                        element.show_program = true
                    }
                    if (element.has_enter == 0 && element.show_program && element.is_visible) {
                        freebie_and_contest.push(element)
                    }
                }
            }
        })
        if (programs && programs?.is_lock_program && programs.locked_type == "custom" && programs.locked_program_list) {
            freebie_and_contest && freebie_and_contest.sort(function (a, b) {
                return locked_program.indexOf(a.program_id) - locked_program.indexOf(b.program_id);
            });
        }
        this.setState({ freebie_and_contest: freebie_and_contest })
        return freebie_and_contest;
    }

    CheckProgramVisibility = (element, program_data) => {
        let after_entry_of = JSON.parse(element.after_entry_of)
        let program_visible = localStorage.getItem("program_visible") ? localStorage.getItem("program_visible") : ""
        if (program_visible && program_visible != "" && program_visible == element.program_id) {
            return true
        } else if (element.has_enter == 1) {
            return true
        }
        else if (element.after_entry_type == "any") {
            let element_visible = false
            after_entry_of?.length > 0 && after_entry_of.forEach((program_element, program_index) => {
                var temp_index = program_data.findIndex(p => p.program_id == program_element)
                if (temp_index != -1) {
                    let detail = program_data[temp_index]
                    if (detail.has_enter == 1 && detail.is_eligible == 1) {
                        element_visible = true
                    }
                }
            })
            if (element_visible && element.notentry_entry_type) {
                element_visible = this.check_notentry_of_after(element, program_data)
                return element_visible
            }
            else return element_visible
        } else if (element.after_entry_type == "all") {
            let element_visible = true
            after_entry_of && after_entry_of.length > 0 && after_entry_of.forEach((program_element, program_index) => {
                var temp_index = program_data.findIndex(p => p.program_id == program_element)
                if (temp_index != -1) {
                    let detail = program_data[temp_index]
                    if (detail.has_enter == 0 || detail.is_eligible == 0) {
                        element_visible = false
                    }
                }
            })
            if (element_visible && element.notentry_entry_type) {
                element_visible = this.check_notentry_of_after(element, program_data)
                return element_visible
            }
            else return element_visible
        } else {
            return false
        }
    }

    check_notentry_of_after = (element, program_data) => {
        let not_etnery_type = element.notentry_entry_type;
        let type = element.notentry_after_of_type ? element.notentry_after_of_type : 'program';
        let notentry_after_of = JSON.parse(element.notentry_after_of)
        let notentry_after_of_partner = JSON.parse(element.notentry_after_of_partner)
        if (not_etnery_type == "any") {
            let element_visible = true
            if (notentry_after_of && notentry_after_of.length > 0) {
                notentry_after_of.forEach((program_element, program_index) => {
                    var temp_index = program_data.findIndex(p => (type == "program" && p.program_id == program_element) || (type == "partner" && p.partner_id == program_element))
                    if (temp_index != -1) {
                        let detail = program_data[temp_index]
                        if (detail.has_enter == 1) {
                            element_visible = false
                        }
                    }
                })
            }
            else if (notentry_after_of_partner?.length > 0) {
                notentry_after_of_partner.forEach((program_element, program_index) => {
                    program_data.map(p => {
                        if (p.partner_id == program_element) {
                            if (p.has_enter == 1) {
                                element_visible = false
                            }
                        }
                    })
                })
            }
            return element_visible
        } else if (not_etnery_type == "all") {
            let element_visible = false
            if (notentry_after_of?.length > 0) {
                notentry_after_of.forEach((program_element, program_index) => {
                    var temp_index = program_data.findIndex(p => (type == "program" && p.program_id == program_element) || (type == "partner" && p.partner_id == program_element))
                    if (temp_index != -1) {
                        let detail = program_data[temp_index]
                        if (detail.has_enter == 0 || detail.is_eligible == 0) {
                            element_visible = true
                        }
                    }
                })
            }
            else if (notentry_after_of_partner?.length > 0)
                // notentry_after_of_partner.forEach((program_element, program_index) => {
                notentry_after_of_partner.forEach((program_element, program_index) => {
                    program_data.map(p => {
                        if (p.partner_id == program_element) {
                            if (p.has_enter == 0 || p.is_eligible == 0) {
                                element_visible = true
                            }
                        }
                    })
                })
            // })
            return element_visible
        } else {
            return true
        }
    }

    checkCSTVisibilty = (program_data) => {
        let cst = program_data?.length && program_data.filter(ele => ele.partner_id == "14" && ele.cst_consent == "yes" && (ele?.pending_fields?.length == 0 || !ele.pending_fields))
        if (cst && cst.length) {
            return false
        } else {
            return true
        }
    }

    GetFreebieAndContestNotLocked = (program) => {
        let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
        let show_program_id = localStorage.getItem("show_program_id") ? JSON.parse(JSON.stringify(localStorage.getItem("show_program_id"))) : "";
        let locked_partner = []
        let locked_program = []
        let programs = {}

        if (show_program_id && !program?.is_lock_program) {
            programs = program_array.find(pro =>
                pro.program_id == show_program_id
            )
        }
        else if (program && program?.is_lock_program && program.locked_type == "custom") {
            programs = program
        }
        if (programs && programs?.is_lock_program && programs.locked_type == "custom") {
            locked_program = programs.locked_program_list ? programs.locked_program_list : []
            locked_partner = programs.locked_partner_list ? programs.locked_partner_list : []
        }

        let contest = program_array?.length && program_array.filter(function (p) {
            return p.program_type === "contest";
        });
        let freebie = program_array?.length && program_array.filter(function (p) {
            return p.program_type === "freebie";
        });
        let offers = program_array?.length && program_array.filter(function (p) {
            return p.program_type === "offers";
        });
        let survey = program_array?.length && program_array.filter(function (p) {
            return p.program_type === "survey";
        });
        let temp_freebie_and_contest_1 = contest && contest.concat(freebie);
        let temp_freebie_and_contest2 = temp_freebie_and_contest_1;
        if (locked_program?.length || locked_partner?.length) temp_freebie_and_contest2 = temp_freebie_and_contest_1 && temp_freebie_and_contest_1.concat(survey);
        let temp_freebie_and_contest = temp_freebie_and_contest2 && temp_freebie_and_contest2.concat(offers);
        let temp_array = temp_freebie_and_contest && temp_freebie_and_contest.sort(function (a, b) {
            return a.order_number - b.order_number
        })
        let freebie_and_contest = [];
        let cst_visibility = this.checkCSTVisibilty(temp_array)
        temp_array?.length > 0 && temp_array.forEach((element, index) => {

            if ((!element?.is_lock_program || !show_program_id || (element?.is_lock_program && element.program_id == show_program_id)) && (locked_program?.length == 0 && locked_partner?.length == 0 || (element?.is_lock_program && element.program_id == show_program_id) || ((locked_program.includes(element.program_id) || (locked_partner.includes(element.partner_id)))))) {

                if (element.after_entry_of && element.after_entry_of != "" && element.after_entry_of != "[]") {
                    if (cst_visibility || (element.partner_id !== '1' && element.parent_id !== "15")) {
                        if (element.hasOwnProperty('visible_to_user_signup') && element.visible_to_user_signup === false) {
                            element.is_visible = element.visible_to_user_signup
                        } else {
                            let element_visibility = this.CheckProgramVisibility(element, temp_array)

                            if (element.hasOwnProperty("visible_to_user") && !element.visible_to_user) {
                                element.is_visible = element.visible_to_user
                            } else {
                                element.is_visible = element_visibility ? element_visibility : false
                            }
                        }
                    }
                    else {
                        element.is_visible = false
                    }
                    if (element.has_enter == 0 && element.is_visible) {
                        freebie_and_contest.push(element)
                    }

                }
                else {
                    let element_visibility = true

                    if (cst_visibility || (element.partner_id !== '1' && element.parent_id !== "15")) {
                        if (element.notentry_entry_type) {
                            element_visibility = this.check_notentry_of_after(element, temp_array)
                        }
                        // element.program_index = index + 1
                        if (element.hasOwnProperty('visible_to_user_signup') && element.visible_to_user_signup === false) {
                            element.is_visible = element.visible_to_user_signup
                        } else {
                            if (element.hasOwnProperty("visible_to_user") && !element.notentry_after_of) {
                                element.is_visible = element.visible_to_user
                            } else {
                                element.is_visible = element_visibility ? element_visibility : false
                            }
                        }
                    }
                    else {
                        element.is_visible = false
                    }
                    if (element.has_enter == 0 && element.is_eligible == 0 && element.show_to_eligible_user == 1) {
                        element.show_program = false
                    } else {
                        element.show_program = true
                    }

                    if (element.has_enter == 0 && element?.is_lock_program != 1 && element.is_still_locked != true && element.show_program && element.is_visible) {
                        freebie_and_contest.push(element)
                    }
                }

            }
        })
        if (programs && programs?.is_lock_program && programs.locked_type == "custom" && programs.locked_program_list) {
            // program.locked_program_list
            freebie_and_contest && freebie_and_contest.sort(function (a, b) {
                return locked_program.indexOf(a.program_id) - locked_program.indexOf(b.program_id);
            });
        }
        const entered_embark = program_array.filter(ele => ele.partner_id == '15' && ele.has_enter)
        const entered_cst = program_array.filter(ele => ele.partner_id == '14' && ele.has_enter)
        if (entered_embark && entered_embark.length > 0) {
            freebie_and_contest = freebie_and_contest.filter(ele => ele && ele.partner_id != '14');
        }
        
        if (entered_cst && entered_cst.length > 0) {
            freebie_and_contest = freebie_and_contest.filter(ele => ele && ele.partner_id != '15');
        }
        
       if(program.partner_id=="2") console.log("freebie_and_contest", freebie_and_contest)
        localStorage.setItem("freebie_and_contest", JSON.stringify(freebie_and_contest));
        this.setState({ freebie_and_contest: freebie_and_contest });
        return freebie_and_contest;
    }

    handleClickArrows = (program, direction) => {
        let freebie_and_contest_ls = localStorage.getItem("freebie_and_contest") ? JSON.parse(localStorage.getItem("freebie_and_contest")) : [];
        let program_list;
        if (!freebie_and_contest_ls || freebie_and_contest_ls.length <= 0) {
            program_list = this.GetFreebieAndContest(program);
            localStorage.setItem("freebie_and_contest", JSON.stringify(program_list));
        } else {
            program_list = freebie_and_contest_ls;
        }
        // Filter program array for CST and Embark in next/prev button
        if (program.partner_id == '15') program_list = program_list.filter(ele => ele.partner_id != '14')
        else if (program.partner_id == '14') program_list = program_list.filter(ele => ele.partner_id != '15')

        if (program_list && program_list.length > 1) {
            this.setState({ show_arrows: true })
        }
        else {
            this.setState({ show_arrows: false })
        }
        if (direction == "backward") {
            this.handleBackward(program, program_list)
        } else if (direction == "forward") {
            this.handleForward(program, program_list)
        }
    }

    handleLetsdoit = (program) => {
        let self = this

        let program_list = self.GetFreebieAndContestNotLocked(program)
        // console.log("suvey program  list", program_list)
        let program_listing = []
        if (program_list && program_list.length > 1) {
            self.setState({ show_arrows: true })
        }
        else {
            self.setState({ show_arrows: false })
        }

        if (program?.is_lock_program == 1) {
            localStorage.setItem('locked_id', JSON.stringify({ program_id: program.program_id, partner_id: program.partner_id }));
        }
        let required_program_to_unlock_ids = []
        if (program.partner_id != "6" && program.program_id !== "1989") {
            required_program_to_unlock_ids = program_list?.length && program_list.map(element => {
                if (program.program_id !== "2629") {
                    program_listing.push(element)
                    return element.program_id;
                }
                else if (element.partner_id === "1" || element.partner_id === "3" || element.partner_id === "10") {
                    program_listing.push(element)
                    return element.program_id
                }
            });
        } else {
            let show_partner = ["1", "2", "3", "10", "14"];
            let show_partner_id_array = Array.isArray(program_list)
                ? program_list.filter((e) => show_partner.includes(e.partner_id))
                : [];

            required_program_to_unlock_ids = show_partner_id_array.map(element => {
                program_listing.push(element);
                return element.program_id;
            });
        }
        // console.log("program_listing", program_listing)
        if (required_program_to_unlock_ids && required_program_to_unlock_ids.length > 0) {
            self.handleForward(program, program_listing, true)

        }
        if (required_program_to_unlock_ids && required_program_to_unlock_ids.length == 0) {
            program.is_still_locked = false
            let program_array = []
            program_array.push(program)
            self.setState({ program_array: program_array, program_type: program.program_type })
        }
    }

    handleBackward = (program, program_list) => {
        let dynamic_content = this.state.dynamic_content
        let success_action = "User clicked on backward arrow on program detail page " + program.program_id
        let image = 'backword_arrow_button_program_detail_page_' + program.program_id + '.png'
        functions.logUserActivity(success_action, image);

        let index = program_list.findIndex(p => p.program_id == program.program_id)
        if (index == 0) {
            let last_index = program_list?.length > 0 ? program_list.length - 1 : null;
            let new_program = program_list && program_list[last_index - 1];
            this.handleRequiredProgramToUnlocked(program_list, new_program, "no", dynamic_content)
        } else {
            let new_program = program_list[index - 1];
            this.handleRequiredProgramToUnlocked(program_list, new_program, "no", dynamic_content)
        }
    }

    getNewProgramInSequence = (programs_in_sequence) => {
        let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];

        let value = program_array?.length && program_array.filter(pro =>
            (pro.program_id == programs_in_sequence[0]) && (programs_in_sequence[0].has_enter === 0)
        )

        // console.log("value is", value);
        return value[0];
    }

    handleForward = (program, program_list, is_lock = false) => {
        // console.log("program here is", program);
        let dynamic_content = this.state.dynamic_content
        let success_action = "User clicked on forward arrow on program detail page " + program.program_id
        let image = 'forward_arrow_button_program_detail_page_' + program.program_id + '.png'
        functions.logUserActivity(success_action, image);
        let index = program_list.findIndex(p => p.program_id == program.program_id)
        let last_index = program_list?.length > 0 ? program_list.length - 1 : null;
        this.setState({ survey_load: false })
        // if(is_lock){
        //     program.is_still_locked = false
        //     let program_array = []
        //     program_array.push(program)
        //     this.setState({ program_array: program_array, program_type: program.program_type })
        // }
        // console.log("program_list length", program_list.length)
        let show_program_id = localStorage.getItem("show_program_id") ? JSON.parse(JSON.stringify(localStorage.getItem("show_program_id"))) : "";

        if (program_list?.length == 0 && show_program_id) {
            let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
            program_list = program_array?.length && program_array.filter(pro =>
                pro.program_id == show_program_id
            )

        }
        if (index == last_index) {
            let new_program = program_list[0];
            this.handleRequiredProgramToUnlocked(program_list, new_program, "no", dynamic_content)
        } else {

            let next_new_program;
            let new_program = program_list[index + 1];

            if (program && program.next_programs_in_sequence) {
                next_new_program = this.getNewProgramInSequence(program.next_programs_in_sequence);
            }

            if (next_new_program) {
                new_program = next_new_program;
            }
            
            this.handleRequiredProgramToUnlocked(program_list, new_program, "no", dynamic_content)
        }
    }

    handleRequiredProgramToUnlocked = (program_list, program, onload, dynamic_content) => {
        $(".program-page").animate({ scrollTop: 0 });
        let self = this
        let next_program_list = self.GetFreebieAndContestNotLocked(program)
        let required_program_to_unlock = self.GetFreebieAndContestNotLocked(program)

        if (next_program_list && next_program_list.length > 0 && program) {
            program["show_next_button"] = true
        }
        else {
            program["show_next_button"] = false
        }

        if (program.short_description) {
            let id = program.program_id
            let terms = program.short_description
            let replace_terms = dynamic_content.static_text?.program_detail?.terms_and_conditions?.en ? dynamic_content.static_text.program_detail.terms_and_conditions.en : "Terms and Conditions"
            let new_terms = terms.replace("Terms and Conditions", "<span id = '" + id + "' class='terms_all terms_active'>" + replace_terms + "</span>");

            let replace_email = dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.notification_will_sent.en : ""
            let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
            let new_email = replace_email.replace("%EMIAL%", email);
            dynamic_content.static_text.program_detail.notification_will_sent.en = new_email

            program.short_description = new_terms
        }

        if (program.partner_id == "14" && program.cst_consent_message) {
            let id = program.program_id
            let terms = program.cst_consent_message
            let replace_terms = dynamic_content.static_text?.program_detail?.terms_and_conditions?.en ? dynamic_content.static_text.program_detail.terms_and_conditions.en : "Terms and Conditions"
            // terms.replace("Terms and Conditions", "<span id = '" + id + "' class='terms_all terms_active'>" + replace_terms + "</span>");
            let new_terms = this.replaceLast("Terms and Conditions", "<span id = '" + id + "' class='terms_all terms_active'>" + replace_terms + "</span>", terms)
            let replace_email = dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.notification_will_sent.en : ""
            let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
            let new_email = replace_email.replace("%EMIAL%", email);
            dynamic_content.static_text.program_detail.notification_will_sent.en = new_email

            program.cst_consent_message = new_terms
        }

        let required_program_to_unlock_ids = []
        if (program.partner_id != "6" && program.program_id !== "1989") {
            required_program_to_unlock_ids = required_program_to_unlock?.length ? required_program_to_unlock.map(element => {
                if (program.program_id !== "2629")
                    return element.program_id
                else if (element.partner_id === "1" || element.partner_id === "3" || element.partner_id === "10")
                    return element.program_id
            }) : [];

            if (program.program_id === "9127") {
                required_program_to_unlock_ids = ["9127"];
            }

        } else {
            let show_partner = ["1", "2", "3", "10", "14"]
            let show_partner_id_array = required_program_to_unlock.filter((e) => show_partner.includes(e.partner_id));
            required_program_to_unlock_ids = show_partner_id_array.map(element => {
                return element.program_id
            });
        }


        program.is_still_locked = false;
        if (program?.is_lock_program == 1) {
            if (required_program_to_unlock_ids && required_program_to_unlock_ids.length > 0) {
                let program_to_unlock = [];
                let locked_number = 0;
                // console.log("required_program_to_unlock_ids", required_program_to_unlock_ids)
                program_list?.length > 0 && program_list.forEach(element => {
                    if (required_program_to_unlock_ids.indexOf(element.program_id) > -1) {
                        if (element && element.has_enter == 0 && element.is_eligible == 1 && !element.is_lock_program && program.program_id !== element.program_id) {
                            program_to_unlock.push(element);
                            locked_number = locked_number + 1;
                            program.is_still_locked = true;
                            program.locked_number = locked_number;
                            program.program_to_unlock = program_to_unlock;
                        }
                    }
                });
                // console.log("locked_number1", locked_number)
            } else {
                let program_to_unlock = [];
                let locked_number = 0;
                locked_number = locked_number;
                program.is_still_locked = false;
                program.locked_number = locked_number;
                program.program_to_unlock = program_to_unlock;
                // console.log("locked_number2", locked_number)
            }
        }

        this.props.handleProgramDetail(program)
        this.setState({ current_program_id: program.program_id })
        let program_array = []
        program_array.push(program)
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""
        functions.getShareLink(this, program.program_id, sub_id, function (response_json) {
            let response = JSON.parse(response_json);
            program_array[0].facebook_link = response.facebook_link;
            program_array[0].twitter_link = response.twitter_link;
            self.setState({ program_array: program_array, program_type: program.program_type })
        })
        // this.setState({ program_array: program_array,  })

        if (onload == "yes") {
            let program_list = this.GetFreebieAndContest(program)
            if (program_list && program_list.length > 1) {
                this.setState({ show_arrows: true })
            }
            else {
                this.setState({ show_arrows: false })
            }

            this.setState({ origin_program_array: program_array, origin_program_type: program.program_type })
        }
        else if (onload == "load_more") {
            let program_list = this.GetFreebieAndContest(program)
            if (program_list && program_list.length > 1) {
                this.setState({ show_arrows: true })
            }
            else {
                this.setState({ show_arrows: false })
            }
            this.toggle()
        } else if (onload == "no") {
            let camp_id = cookie.load("camp_id") ? cookie.load("camp_id") : ""
            if (camp_id != "" && sub_id != "") {
                let finaljson = {
                    program_id: program.program_id,
                    sub_id: sub_id,
                    action: "add_view",
                    campaign_id: camp_id,
                    visited_page: "program_detail"
                }
                functions.saveCampaignView(this, finaljson)
            }
        }
    }

    // clickOnline = () => {
    //     this.openAccounts()
    //     localStorage.setItem("perks_detail", JSON.stringify(this.props.detail))
    //     functions.pushToRoute(this.props, 'perksonlinecode', 'perksdetails')
    // }
    replaceLast = (find, replace, string) => {
        var lastIndex = string.lastIndexOf(find);

        if (lastIndex === -1) {
            return string;
        }

        var beginString = string.substring(0, lastIndex);
        var endString = string.substring(lastIndex + find?.length ?? 0);

        return beginString + replace + endString;
    }

    handleLoadMore = (program) => {
        let success_action = "User clicked on load more button on program detail page " + program.program_id
        let image = 'loadmore_button_program_detail_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""
        if (sub_id != "") {
            let final_json = {
                action: "get",
                notify_sub_id: sub_id,
                notify_program_id: program.program_id
            }

            self.setState({ load_more_loader: true })
            functions.insertInProgramNotifyMe(this, final_json, function (response_json) {
                let response = JSON.parse(response_json);
                self.setState({ load_more_loader: false })
                if (response.data && response.data?.data?.length > 0 && response.data.success == 1) {
                    self.setState({ program_notifyme_loader: false, program_notified: true, program_notifyme_auto_id: response.data.data.auto_id })
                }
                else {
                    self.setState({ program_notifyme_loader: false })
                }

                let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
                let temp_array = [];
                let cst_visibility = self.checkCSTVisibilty(program_array)
                program_array?.length > 0 && program_array.forEach((element, index) => {
                    // if (element.is_eligible == 1 && element.has_enter == 0 && element.program_type != "story" && element.program_type != "review") {
                    //     temp_array.push(element);
                    // }
                    // console.log("element", element)
                    if (element.after_entry_of && element.after_entry_of != "" && element.after_entry_of != "[]") {
                        if (cst_visibility || (element.partner_id !== '1' && element.partner_id !== "15")) {
                            if (element.hasOwnProperty('visible_to_user_signup') && element.visible_to_user_signup === false) {
                                element.is_visible = element.visible_to_user_signup
                            } else {
                                let element_visibility = self.CheckProgramVisibility(element, program_array)

                                if (element.hasOwnProperty("visible_to_user") && !element.visible_to_user) {
                                    element.is_visible = element.visible_to_user
                                } else {
                                    element.is_visible = element_visibility ? element_visibility : false
                                }
                            }
                        }
                        else {
                            element.is_visible = false
                        }
                        if (program.has_enter == 1 && ((program.partner_id == '14' && element.partner_id == '15') || (program.partner_id == '15' && element.partner_id == '14'))) {
                            element.is_visible = false;
                        }
                        if (element.is_eligible == 1 && element.has_enter == 0 && element.is_visible && element.program_type != "story" && element.program_type != "review") {
                            temp_array.push(element)
                        }

                    }
                    else {
                        let element_visibility = true
                        if (cst_visibility || (element.partner_id !== '1' && element.partner_id !== "15")) {
                            if (element.notentry_entry_type) {
                                element_visibility = self.check_notentry_of_after(element, program_array)
                            }
                            // element.program_index = index + 1
                            if (element.hasOwnProperty('visible_to_user_signup') && element.visible_to_user_signup === false) {
                                element.is_visible = element.visible_to_user_signup
                            } else {
                                if (element.hasOwnProperty("visible_to_user") && !element.notentry_after_of) {
                                    element.is_visible = element.visible_to_user
                                } else {
                                    element.is_visible = element_visibility ? element_visibility : false
                                }
                            }
                        }
                        else {
                            element.is_visible = false
                        }
                        if (element.has_enter == 0 && element.is_eligible == 0 && element.show_to_eligible_user == 1) {
                            element.show_program = false
                        } else {
                            element.show_program = true
                        }
                        if (element.has_enter == 0 && element?.is_lock_program != 1 && element.is_still_locked != true && element.show_program && element.is_visible && element.program_type != "story" && element.program_type != "review") {
                            temp_array.push(element)
                        }
                    }

                })
                let load_more = temp_array;
                let upsell_exist = false;
                let temp_final_array = [];
                if (temp_array && temp_array.length > 0) {
                    if (program.upselling_offers && JSON.parse(program.upselling_offers) && JSON.parse(program.upselling_offers)?.length > 0) {
                        let upselling_offers = program.upselling_offers ? JSON.parse(program.upselling_offers) : [];
                        let count = 0;
                        upselling_offers && upselling_offers.length > 0 && upselling_offers.forEach((element, index) => {
                            let index_prog = temp_array.findIndex(p => p.program_id == element)
                            if (index_prog != -1) {
                                upsell_exist = true
                                temp_final_array[count] = temp_array[index_prog];
                                count++;
                            }
                        })

                        if (upsell_exist == true) {
                            temp_array?.length > 0 && temp_array.forEach((element, index) => {
                                let index_prog = temp_final_array.findIndex(p => p.program_id == element.program_id)
                                if (index_prog == -1) {
                                    temp_final_array.push(element)
                                }
                            })
                            self.setState({ all_load_programs: temp_final_array })
                            self.makeLoadMoreDate(temp_final_array, 1)
                        } else {
                            self.setState({ all_load_programs: load_more })
                            self.makeLoadMoreDate(load_more, 1)
                        }
                    }
                    else {
                        self.setState({ all_load_programs: load_more })
                        self.makeLoadMoreDate(load_more, 1)
                    }
                }
                else {
                    self.setState({ all_load_programs: load_more })
                    self.makeLoadMoreDate(load_more, 1)
                }
            });
        }

    }

    makeLoadMoreDate = (load_more, load_number) => {
        let new_load_more;
        if (load_number == 1) {
            if (load_more && load_more.length == 3) {
                new_load_more = load_more
            } else {
                new_load_more = load_more.slice(0, 3)
            }
        }
        else {
            new_load_more = load_more
        }
        if (load_more && load_more.length > 3) {
            this.setState({ load_more_programs: new_load_more, load_more: true, load_notify_me: false, load_number: load_number })
        }
        else if (load_more && load_more.length == 3) {
            this.setState({ load_more_programs: new_load_more, load_more: false, load_notify_me: false })
        }
        else if (load_more && load_more.length < 3) {
            this.setState({ load_more_programs: new_load_more, load_more: false, load_notify_me: true })
        }
        if (load_number == 0) {
            this.setState({ load_more: false, load_notify_me: true })
        }
        if (load_number == 1) {
            this.toggle()
        }
    }

    handleFindMore = () => {
        let success_action = "User clicked on find more button on program detail page"
        let image = 'findmore_button_program_detail_page.png'
        functions.logUserActivity(success_action, image);
        let load_number = this.state.load_number;
        let temp_load_more = this.state.all_load_programs;
        let new_load_number = load_number + 1;
        let slice_second = new_load_number * 3;
        let load_more = temp_load_more.slice(0, slice_second);
        if (temp_load_more?.length == load_more?.length) {
            new_load_number = 0;
        }
        this.makeLoadMoreDate(load_more, new_load_number)
    }

    handleClickLoad = (program) => {
        let dynamic_content = this.state.dynamic_content
        let success_action = "User clicked on load more program button on program detail page " + program.program_id
        let image = 'loadmore_program_button_program_detail_page.png'
        functions.logUserActivity(success_action, image);
        let program_list = this.state.all_load_programs
        if (program.program_type == "survey") this.setState({ survey_load: false })
        this.handleRequiredProgramToUnlocked(program_list, program, "load_more", dynamic_content)
    }

    handleProgramNotify = () => {
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""
        if (sub_id != "") {

            let self = this;
            let all_load_more_programs_ids = []
            let all_load_programs = this.state.all_load_programs
            all_load_programs?.length > 0 && all_load_programs.forEach((element, index) => {
                if (element.program_id) {
                    all_load_more_programs_ids.push(element.program_id)
                }
            })
            let program_id = this.state.program_array[0].program_id;
            let success_action = "User clicked on load more notify me button on program detail page " + program_id
            let image = 'loadmore_notifyme_button_program_detail_page.png'
            functions.logUserActivity(success_action, image);
            let final_json = {
                program_id: program_id,
                all_load_more_programs_ids: all_load_more_programs_ids,
                sub_id: sub_id
            }
            self.setState({ program_notifyme_loader: true })
            functions.insertInProgramNotifyMe(this, final_json, function (response_json) {
                let response = JSON.parse(response_json);
                self.setState({ program_notifyme_loader: false, program_notified: true, program_notifyme_auto_id: response.data.data.auto_id })
            });
        }
    }

    handleCancelProgramNotify = () => {
        let success_action = "User clicked on load more cancel button on program detail page"
        // functions.logUserActivity(success_action);
        let self = this;
        let final_json = {
            action: "delete",
            program_notifyme_auto_id: this.state.program_notifyme_auto_id
        }
        self.setState({ program_notifyme_loader: true })
        functions.insertInProgramNotifyMe(this, final_json, function (response_json) {
            let response = JSON.parse(response_json);
            self.setState({ program_notifyme_loader: false, program_notified: false, program_notifyme_auto_id: "" })
        });
    }

    SendReminder = (program) => {
        let self = this
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""
        if (sub_id != "") {
            let final_json = {
                "sub_id": sub_id,
                "program_id": program.program_id,
                "program_title": program.program_title,
                "sender_name": localStorage.getItem("given_name")
            }
            functions.SendReminder(this, final_json, function (response_json) {
                let response = JSON.parse(response_json);
            });
        }
    }

    handleColonClick = (element, index, is_child) => {
        let action = "User open edit relation popup on program detail page"
        let image = 'edit_relation_popup_program_detail_page.png'
        functions.logUserActivity(action, image);

        let relations = this.state.relations
        let relation_status = relations[index].relation_status
        this.setState({ relation_status: relation_status });
        this.setState({ relation_index: index });
        this.toggleEditDelete()
    }

    closeToggle(value, state) {
        let action = "User clicked on " + state + " on program detail page"
        let image = state + '_program_detail_page.png'
        functions.logUserActivity(action, image);
        value.setState({
            [state]: !value.state[state]
        });
    }

    handleToggleClick = (action, relation_status) => {

        if (action == "edit") {
            if (relation_status == "pending_invitation") {
                let action = "User clicked on accept button on program detail page"
                let image = 'accept_button_program_detail_page.png'
                functions.logUserActivity(action, image);
                this.handleAcceptRelation()
                this.toggleEditDelete()
            }
            else {
                let action = "User clicked on edit relation button on program detail page"
                let image = 'edit_relation_button_program_detail_page.png'
                functions.logUserActivity(action, image);
                this.handleEditRelation()
                this.toggleEditDelete()
            }
        }
        else if (action == "delete") {
            if (relation_status == "pending_invitation") {
                let action = "User clicked on decline relation button on program detail page"
                let image = 'decline_relation_button_program_detail_page.png'
                functions.logUserActivity(action, image);
                this.setState({ action: "handleDeclineRelation", modal_message: "Do you want to decline this relation?" })
                this.toggleEditDelete()
                this.toggleDeleteConfirm()
            }
            else {
                let action = "User clicked on delete relation button on program detail page"
                let image = 'delete_relation_button_program_detail_page.png'
                functions.logUserActivity(action, image);
                this.setState({ action: "handleDeleteRelation", modal_message: "Do you want to delete this relation?" })
                this.toggleEditDelete()
                this.toggleDeleteConfirm()
            }
        }
    }

    handleEditRelation = () => {
        localStorage.setItem("edit_index", this.state.relation_index)
        localStorage.setItem("form_type", 'edit_relation')
        localStorage.removeItem("family_activeTab")
        functions.pushToRoute(this, "addrelative", 'program_detail');
        this.toggleRelation()
    }

    toggleRelation() {
        this.setState({
            relation_modal: !this.state.relation_modal
        });
    }

    handleAcceptRelation = () => {
        let relations = this.state.relations;
        let final_relations = [];
        relations?.length > 0 && relations.forEach((element, index) => {
            if (index == this.state.relation_index) {
                element.relation_status = 'approved_relations';
                let self = this;
                let sub_id = cookie.load('auto_id') ? cookie.load('auto_id') : localStorage.getItem("auto_id");
                let user_email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email");
                functions.AcceptRelation(self, sub_id, element.relative_id, user_email, function (response_json) {
                });
            }
            final_relations.push(element)

        });
        this.togglePendingRelation()
        this.setState({ relations: final_relations, open_edit: false })

        localStorage.setItem("relation_data", JSON.stringify(final_relations))
    }

    togglePendingRelation() {
        this.setState({
            relation_pending_modal: !this.state.relation_pending_modal
        });
    }

    handleClosePopup = () => {
        let success_action = "User clicked on close button on handleClosePopup"
        // let image = 'user_clicked_on_close_button_terms_and_condition.png'
        functions.logUserActivity(success_action);
        let self = this;
        this.setState({ show_popup: false })
    }

    toggleDeleteConfirm() {
        let { delete_confirm_modal } = this.state;
        this.setState({
            delete_confirm_modal: !delete_confirm_modal
        });
    }

    handleYes = () => {
        let action1 = "User clicked on yes button on family page"
        let image = 'yes_button_family_page.png'
        functions.logUserActivity(action1, image);
        let action = this.state.action
        if (action == "handleDeclineRelation") {
            this.handleDeclineRelation()
        }
        else if (action == "handleDeleteRelation") {
            this.handleDeleteRelation()
        }
        else {
        }
        this.toggleDeleteConfirm()
    }

    handleDeclineRelation = () => {
        let relations = this.state.relations
        let final_relations = []
        relations && relations.length > 0 && relations.forEach((element, index) => {
            if (index !== this.state.relation_index) {
                final_relations.push(element)
            }
            else {
                let self = this;
                let sub_id = cookie.load('auto_id') ? cookie.load('auto_id') : localStorage.getItem("auto_id");
                let user_email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email");
                functions.DeclineRelation(this, sub_id, element.relative_id, user_email, function (response_json) {
                });
            }
        });

        this.setState({ relations: final_relations, open_edit: false })
        // this.togglePendingRelation()
        //api to delete relation 
        if (final_relations?.length < 1) {
            localStorage.removeItem("relation_data")
        } else {
            localStorage.setItem("relation_data", JSON.stringify(final_relations))
        }
    }

    handleDeleteRelation = () => {
        let relations = this.state.relations
        let final_relations = []
        relations && relations.length > 0 && relations.forEach((element, index) => {
            if (index !== this.state.relation_index) {
                final_relations.push(element)
            }
            else {
                let user_email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email");
                let relative_id = element.relative_id ? element.relative_id : element.id;
                functions.deleteRelation(this, element.email, user_email, relative_id);
            }
        });
        this.setState({ relations: final_relations, open_edit: false })

        // api to delete relation 
        if (final_relations?.length < 1) {
            localStorage.removeItem("relation_data")
        } else {
            localStorage.setItem("relation_data", JSON.stringify(final_relations))
        }

    }

    takeSurvey = () => {
        let self = this;
        let state_program_array = self.state.program_array
        state_program_array[0].term_and_condition_required = false;
        state_program_array[0].birthdate_required = false;
        state_program_array[0].phone_number_required = false;
        state_program_array[0].upload_video_required = false;
        state_program_array[0].cst_consent_required = false;
        state_program_array[0].guardtree_text_required = false;
        state_program_array[0].policy_terms_required = false;
        state_program_array[0].policyadvisor_consent_required = false;
        state_program_array[0].epilogue_question_required = false
        state_program_array[0].show_survey_question = true;
        state_program_array[0].add_relative_required = false;
        state_program_array[0].baby_registry_required = false;
        self.setState({ program_array: state_program_array, show_phone: false, show_upload_video: false, show_cst_consent: false, show_survey_question: true, epilogue_question_required: false, show_policyadvisor_consent: true, show_policy_terms: false, show_baby_registry: false, show_guardTreeText: false, loader: false })
        var scroll_to1 = $('.instant_upr2').offset().top + $('.instant_upr2').height();
        $(".program-page").animate({ scrollTop: 800 });
    }

    showMore = () => { this.setState({ showAll: true }) }

    showLess = () => this.setState({ showAll: false });

    renderShortDescription = (short_description) => {

        let new_termsfirst = short_description.replace("<p>", "");
        let new_terms_last = this.replaceLast("</p>", "", new_termsfirst)
        //console.log("new_termsfirst", new_terms_last)


        return (new_terms_last)
    }

    handleMenu = () => {
        localStorage.setItem("detail_page", 'false')
        localStorage.removeItem("session_entered_id")
        $('.program-page.active').find('.close_btn').trigger('click');
    }

    handleCloseTerms = () => {
        let success_action = "User clicked on close button on terms and condition"
        let image = 'user_clicked_on_close_button_terms_and_condition.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        this.setState({ show_t_and_c: false })
    }

    handleTerms = (program) => {
        this.setState({ current_program: program, show_t_and_c: true });
    }

    toggleParentInvitation = (open) => {
        let self = this;
        self.setState({ isOpen: open })
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleTermsClick);
    }
    handleTermsClick = (event) => {
        if (event.target.classList.contains('terms_all') && event.target.classList.contains('terms_active')) {
            this.handleTerms();
        }
    }

    handleFixedHeader = () => {
        let self = this;
        document.addEventListener("scroll", function () {
            var navbar = document.getElementById("navbar");
            if (navbar) {
                var sticky = navbar?.offsetTop;
                if ($(window).scrollTop() > (sticky - 39)) {
                    $('.fix_main').addClass('fixed-header');
                }
                else {
                    $('.fix_main').removeClass('fixed-header');
                }
            }
        });
        $(document).on('click', '.terms_active', function () {

            let program = self.state.program_detail;
            if (program.program_id) {
                self.handleTerms(program)
            }

        })
    }



    render() {
        const { dynamic_content, showAll, program_array, program_detail, delete_confirm_modal } = this.state;

        if (program_array?.length > 0 && program_array[0].partner_id === "15" && program_array[0].has_enter === 1 && (!program_array[0].survey_entered || program_array[0].survey_entered == null) && (!localStorage.getItem(`embark_${program_array[0].program_id}_survey`) || localStorage.getItem(`embark_${program_array[0].program_id}_survey`) != "completed") && !program_array[0].pending_fields.includes('phone_number')) {
            program_array[0].show_survey_question = true;
            program_array[0].survey_questions = embark_survey_questions;
            localStorage.setItem(`embark_${program_array[0].program_id}_survey`, 'null');
        }
        const params = {
            loop: true,
            activeSlideKey: 1,
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 2,
            coverflowEffect: {
                rotate: 40,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            renderPrevButton: () => <button className="swiper-button-prev al_middle">Prev</button>,
            renderNextButton: () => <button className="swiper-button-next ar_middle">Next</button>
        }
        return (
            <div className="program-page-container">
                {program_detail &&
                    <div key={program_detail.program_id ? "all_main_detail_" + program_detail.program_id : "all_main_detail_" + program_detail.auto_id}>
                        <div id={program_array && program_array.length > 0 ? program_array[0].program_type + program_array[0].program_id : ""} className={program_array && program_array.length > 0 ? "program-page hero-1 hero_" + this.state.program_array[0].program_id : ""}>
                            <ParentInvitaionPopup ref={refrence => this.invitaitonref = refrence} history={this.props.history} isOpen={this.state.isOpen} toggle={(open) => this.toggleParentInvitation(open)} />
                            {dynamic_content != "" &&
                                <div className="common_mainAddOn">
                                    <div className="common_main detailspace_rmv 2323">
                                        {program_array && program_array.length > 0 && program_array.map((program, index) => (

                                            <div key={index} className={`contests_dtl contests_cstm ${program.program_type === "contest" ? "giveAwayDetail" : ""} ${program.program_type === "freebie" ? "freebieDetail" : ""}`}>
                                                <div className="enter_program" id={program.program_id}></div>
                                                <div className="freeies_babyImg2">

                                                    <div className="detailback_section detailback_section_program close_btn white_circle_div">
                                                        <div className="back_section">
                                                            <div className="backLinkWidth" onClick={() => { this.handleDetailBack(program) }}>
                                                                <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                                            </div>
                                                        </div>
                                                        <div className="helpdiv"><a onClick={() => { this.handleHelp(program) }}>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.help.en : "Help"}</a></div>
                                                    </div>

                                                    <div id={"detail" + program.program_id} className="contests_dtlImges">
                                                    <img src={(program.show_alternative_detail && program.show_alternative_detail == "true" ? (program.program_id == "1636" ? url.family_one_image_url : url.cloudflare_image_path) + program.alt_program_image : (program.program_id == "1636" ? url.family_one_image_url : url.cloudflare_image_path) + program.program_image) + (program.program_id == "1636" ? '' : url.varient)} alt="" title="" />
                                                    </div>
                                                    {program.program_id !== "9127" &&
                                                        <div className="program_detail_logo">
                                                            {program.program_type != "review" && program.program_type != "story" &&
                                                                program.logo && program.logo.map((logo1) => (
                                                                <a key={logo1}><img src={(program.program_id == "1636" ? url.family_one_image_url : url.cloudflare_image_path) + logo1 + (program.program_id == "1636" ? '' : url.varient)} alt="" title="" /></a>
                                                                ))
                                                            }
                                                        </div>
                                                    }

                                                    {program.program_id === "9127" &&
                                                        <div className="family_fun rom_collection">
                                                            <div className="perks_tag margin-left-five-per"
                                                            ><img src={url.family_one_image_url + 'white-icon-PERKS_128.png'} alt="" title="" /> Insurance
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                                <div className={isMobileSafari ? program.has_enter == 1 ? "dtailsCstm height_ios_enter" : "dtailsCstm height_ios" : "dtailsCstm"} style={program.partner_id == "10" ? { marginBottom: "50%" } : {}}>
                                                    {program.direct_member == 1 && program.program_id !== "9127" &&
                                                        <div className="instant_details">
                                                            <a className={`instant_tags 8989 ${program.program_type === "contest" ? "giveAwayWrap" : ""}${program.program_type === "freebie" ? "freebieMainWrap" : ""}${program.program_type === "survey" ? "surveyMainWrap" : ""}`}>
                                                                {program.program_type == "contest" &&
                                                                    <img src={require('../../assets/images/giveAwayCardIcon.svg')} alt="" title="" />
                                                                }
                                                                {program.program_type == "survey" &&
                                                                    <img src={require('../../assets/images/surveyIcon.svg')} alt="" title="" />
                                                                }
                                                                {program.program_type == "freebie" &&
                                                                    <img src={require('../../assets/images/FreebieIcon.svg')} alt="" title="" />
                                                                }
                                                                {program.program_type == "story" &&
                                                                    <img src={url.family_one_image_url + 'orange-icon-STORY_128.png'} alt="" title="" />
                                                                }
                                                                {program.program_type == "review" &&
                                                                    <img src={url.family_one_image_url + 'orange-icon-REVIEW_128.png'} alt="" title="" />
                                                                }
                                                            <span className='giveAwayTag'>{capitalize(program.program_type == "contest" ? "Giveaways" : program.program_id == '1636' ? "Perks" : program.program_type)}</span>
                                                            </a>
                                                            {program.program_category == 'Instant' &&
                                                                <a className="instant_tags"><img src={url.family_one_image_url + 'Vector3.png'} alt="" title="" />
                                                                    <span>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.instant.en : "Instant"}</span></a>
                                                            }
                                                            {program?.is_lock_program == 1 &&
                                                                <a className="bonusTagNw">
                                                                    <img src={require('../../assets/images/BounsStar.svg')} alt="" title="" />
                                                                    <span>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.exclusive.en : "Bonus"}</span></a>
                                                            }
                                                            {program.program_type == "contest" && program.has_enter > 0 && program.recommended == "false" &&
                                                                <a className="entered_points">{program.family_activity.gain_family_points + program.social_activity.total_gain_social_share_points + program.has_enter} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</a>
                                                            }
                                                        </div>
                                                    }

                                                    <div className="sim_section freebies_iner program_title01">
                                                        <label>{program.show_alternative_detail && program.show_alternative_detail == "true" ? ReactHtmlParser(program.alt_program_title) : ReactHtmlParser(program.program_title)}</label>
                                                        <p>{program.show_alternative_detail && program.show_alternative_detail == "true" ? ReactHtmlParser(program.alt_program_subtitle) : ReactHtmlParser(program.program_subtitle)}</p>
                                                    </div>

                                                    <div className="descp_all">
                                                        <label>Description</label>
                                                        {ReactHtmlParser(program.program_description)}
                                                        {program.hide_expiry_date == "true" || typeof program.expiry_date == undefined ?
                                                            <div></div> :
                                                            <div className="ends_date">
                                                                <p> Ends {this.dateFormat(program.expiry_date)} </p>
                                                            </div>
                                                        }

                                                    </div>
                                                    {/* div for direct user and survey not entered */}
                                                    {program.program_type == "survey" && program.direct_member == 1 &&
                                                        <div className="lend_hendupr">

                                                            {(program.pending_fields && program.pending_fields.length == 0 && program.has_enter == 1 && program.recommended == "false" && program.short_description && program.short_description.trim()?.length) ?
                                                                <div className="lend_hendupr mb-25 aa">
                                                                    <div className="instant_upr2">
                                                                        <div className="instant_redem2">
                                                                            <div className="declaration_instant2 declaration_short" style={{ marginTop: "0px" }}>
                                                                                <p>{ReactHtmlParser(program.short_description)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : ""
                                                            }

                                                            {/* div for direct user and survey entered */}
                                                            {program.has_enter == 1 ?
                                                                <div>
                                                                    <div className="instant_upr3 surveyDetailUpr">
                                                                        <div className="social_connect_uprmain surveyDetailInr">
                                                                            <div className="instant_hendupr3">
                                                                                <div className="instant_hend3">
                                                                                    <div className="fiveone_pts3 333">
                                                                                        <a><img src={require('../../assets/images/surveyRight2.svg')} alt="" title="" />
                                                                                            {/* {program.family_activity.gain_family_points + program.social_activity.total_gain_social_share_points + program.has_enter} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"} */}
                                                                                        </a>
                                                                                    </div>

                                                                                    <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.entered.en : "Entered"}</h3>
                                                                                    {program.program_id == "5667" ?
                                                                                        <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.invitation_to_join.en : "An invitation to join was sent to your friends"} </p> :
                                                                                        <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.your_friend_entered.en : "Share contest to earn more points"} </p>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            <div className="social_connect_upr">
                                                                                <div className="social_activ">
                                                                                    <div className="social_activleft">
                                                                                        <label>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.family_activity.en : "Family Activity"}</label>
                                                                                    </div>
                                                                                    <div className="social_activright 4646">
                                                                                        <label>
                                                                                            <span>{program.family_activity.gain_family_points} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"} / </span><span>{program.family_activity.total_family_points} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="borderProgress">
                                                                                    <BorderLinearProgress
                                                                                        variant="determinate"
                                                                                        color="secondary"
                                                                                        value={this.getProgress(program.has_enter, program.family_activity.gain_family_points, program.family_activity.total_family_points)}
                                                                                    />
                                                                                    {/* <img src={url.family_one_image_url + 'Star 1.png'} alt="" title="" /> */}
                                                                                    <img src={require('../../assets/images/circlePurpal.svg')} alt="" title="" />
                                                                                </div>
                                                                                <div className="social_connect_inr">
                                                                                    <div className="one_social">
                                                                                        <p><span>+2 {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</span> per qualifying relative</p>
                                                                                    </div>
                                                                                    <div className="one_social_connect" id="one_social_connect">
                                                                                        {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                                                                            <div key={index} className="custom_align">
                                                                                                {relation.picture && relation.picture?.trim()?.length > 0 && relation.picture.trim() !== 'no_image' ?
                                                                                                    <a><img src={url.cloudflare_image_path + relation.picture + url.varient} alt="" title="" /></a>
                                                                                                    :
                                                                                                    <p className=""><span className="initials_class">{initials((relation.given_name + " " + relation.family_name).toUpperCase()).substring(0, 2)}</span> </p>
                                                                                                }
                                                                                            </div>
                                                                                        ))}
                                                                                        <div className="custom_align">
                                                                                            <a><img onClick={() => { this.handleAddRelation(program) }} src={require('../../assets/images/plusOrange2.png')} alt="" title="" /></a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="social_activity">
                                                                            <div className="social_activityleft">
                                                                                <label>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.social_share.en : "Social Share"}</label>
                                                                                <p><b>+1 {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</b> for each entered</p>
                                                                            </div>
                                                                            <div className="social_activityright">
                                                                                <label>{program.social_activity.total_gain_social_share_points} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</label>
                                                                                <div>
                                                                                    {program.social_activity.total_gain_social_share_points > 0 &&
                                                                                        <a><img src={url.family_one_image_url + 'Grouptwiter166.png'} alt="" title="" />
                                                                                            <img src={url.family_one_image_url + 'Groupright237.png'} alt="" title="" className="rightsymbol" />
                                                                                        </a>
                                                                                    }
                                                                                    {program.social_activity.total_gain_social_share_points == 0 &&
                                                                                        <a href={program.twitter_link} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'Group166.png'} alt="" title="" /></a>
                                                                                    }
                                                                                    <a href={program.facebook_link} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'Groupfb165.png'} alt="" title="" /></a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* Load More section */}
                                                                    {!this.state.load_more_loader &&
                                                                        <div className="load_more want_more_div" style={{ marginTop: "9%" }}>
                                                                            {/* <Button size="medium" color="primary" className="next_offer" onClick={() => { this.handleClickArrows(program, "forward") }}>
                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.next_offer.en : "Next Offer"}
                                                            </Button> */}
                                                                            <Button className="explore_div" size="medium" color="primary" onClick={() => { this.handleLoadMore(program) }} >
                                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_more.en : "Quick View"}
                                                                            </Button>
                                                                            {program && program?.show_next_button &&
                                                                                <div className="instant_redem2">
                                                                                    <Button className="next_offer_div" size="medium" color="primary" onClick={() => { this.handleClickArrows(program, "forward") }} >
                                                                                        {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.next_offer.en : "Next"}
                                                                                    </Button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {this.state.load_more_loader &&
                                                                        <div className="load_more" >
                                                                            <LottieLoader style={{ color: "red" }} />
                                                                        </div>
                                                                    }
                                                                    {/* End of Load More section */}
                                                                </div>
                                                                : ""
                                                            }

                                                            {/* div for direct user and survey entered  and pending data*/}
                                                            {program.has_enter == 1 && program.pending_fields && program.pending_fields.length > 0 && program.pending_fields.includes("upload_video") &&
                                                                <div>

                                                                    <div className="rel_block ht-200 345">
                                                                        <div className="program_entry_loader">
                                                                            <LottieLoader />
                                                                        </div>
                                                                    </div>
                                                                    {!this.state.survey_load &&
                                                                        <div className="lend_hendupr_ne1 346">
                                                                            <div className="instant_upr3_ne1">
                                                                                <div className="social_ne1">
                                                                                    <div className="instant_hendupr3_ne1">
                                                                                        <div className="instant_hend3_ne1">
                                                                                            <div className="survey_img"><a><img src={url.family_one_image_url + "survey-icon-pink.png"} alt="" title="" /></a>
                                                                                                {/* <a><img src={url.family_one_image_url + "Group2.png"} alt="" title="" /></a> */}
                                                                                            </div>
                                                                                            <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.survey_title.en : "Survey to win"}</h3>
                                                                                            <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.survey_subtitle.en : "Answer a quick survey for a chance to win"}</p>

                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="survey_start">
                                                                                        <div className="instant_redem2">
                                                                                            {program?.survey_questions?.length ? <Button id={program.program_id} size="medium" color="primary"
                                                                                                onClick={() => {
                                                                                                    this.setState({ survey_load: true })
                                                                                                    this.handleClick(program, '', '', 'redeem/enter', 'survey')
                                                                                                }}>
                                                                                                {"Take Survey"}
                                                                                            </Button> : ""}
                                                                                            {program.entry_criteria && (JSON.parse(program.entry_criteria))?.length && (JSON.parse(program.entry_criteria)).includes('upload_video') ? <Button id={program.program_id} size="medium" color="primary"
                                                                                                onClick={() => {
                                                                                                    this.setState({ survey_load: true })
                                                                                                    this.handleClick(program, '', '', 'redeem/enter', 'record')
                                                                                                }}>
                                                                                                {"Record a video"}
                                                                                            </Button> : ""}
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {/* <div className="instant_upr2 348">
                                                        <div className="instant_redem2">
                                                            <div className="declaration_instant2 declaration_short">
                                                                <p>{ReactHtmlParser(program.short_description)}</p>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                                </div>
                                                            }

                                                            {/* div for direct user and survey not entered */}
                                                            {program.has_enter == 0 && !program.show_survey_question &&
                                                                <div>

                                                                    <div className="rel_block ht-200 345">
                                                                        <div className="program_entry_loader">
                                                                            <LottieLoader />
                                                                        </div>
                                                                    </div>
                                                                    {!this.state.survey_load &&
                                                                        <div className="lend_hendupr_ne1 surveyWinCard 346">
                                                                            <div className="instant_upr3_ne1">
                                                                                <div className="social_ne1">
                                                                                    <div className="instant_hendupr3_ne1">
                                                                                        <div className="instant_hend3_ne1">
                                                                                            {/* <div className="survey_img"><a><img src={url.family_one_image_url + "survey-icon-pink.png"} alt="" title="" /></a>
                                                                                                {/* <a><img src={url.family_one_image_url + "Group2.png"} alt="" title="" /></a> 
                                                                                            </div> */}
                                                                                            <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.survey_title.en : "Survey to win"}</h3>
                                                                                            <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.survey_subtitle.en : "Answer a quick survey for a chance to win"}</p>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="survey_start survey_startCstm">
                                                                                        <div className="instant_redem2">
                                                                                            <Button id={program.program_id} size="medium" color="primary"
                                                                                                onClick={() => {
                                                                                                    this.setState({ survey_load: true })
                                                                                                    this.handleClick(program, '', '', 'redeem/enter', 'survey')
                                                                                                }}>
                                                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.start.en : "Start"}
                                                                                            </Button>
                                                                                        </div>
                                                                                        {!program.notify_me &&
                                                                                            <div>

                                                                                                {this.state.show_arrows == true &&
                                                                                                    <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left  44" >
                                                                                                        <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                    </div>
                                                                                                }

                                                                                                {this.state.show_arrows == true &&
                                                                                                    <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                                                        <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                    </div>
                                                                                                }
                                                                                                {/* End of Add left right arrows */}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {/* <div className="instant_upr2 348">
                                                                        <div className="instant_redem2">
                                                                            <div className="declaration_instant2 declaration_short">
                                                                                <p>{ReactHtmlParser(program.short_description)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            }

                                                            {program.has_enter == 0 && program.show_survey_question &&
                                                                <div className="instant_upr2_cstm srvyMainUpr 347 555">
                                                                    <div className="instant_upr2">
                                                                        <div className="rel_block">
                                                                            <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                                                {program.program_id == "5763" ?
                                                                                    <EpilogueSuveyQuestions props={this.props} Back={this.handleSurveyBack} onSubmit={(e) => { this.handleClickSubmit(program, e) }} onScroll={(e) => { this.handleScroll() }} />
                                                                                    :
                                                                                    <SuveyQuestions props={this.props} program={program} Back={this.handleSurveyBack} onSubmit={(e, ques) => { this.handleClickSubmit(program, e) }} />
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        {/* } */}
                                                                        <div className="instant_redem2">
                                                                            <div className="declaration_instant2 declaration_short">
                                                                                {ReactHtmlParser(program.short_description)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="rel_block ht-200">
                                                                            <div className="program_entry_loader">
                                                                                <LottieLoader />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    }

                                                    {/* div for direct user and freebie not entered */}
                                                    {program.program_type == "freebie" && program.direct_member == 1 && !program.cst_consent_required && !program.phone_number_required && !program.policyadvisor_consent_required && !program.epilogue_question_required && !program.policy_terms_required && !program.add_relative_required && !program.birthdate_required && !program.baby_registry_required && !program.term_and_condition_required && !program.guardtree_text_required &&
                                                        <div className="lend_hendupr">
                                                            {(program.pending_fields && program.pending_fields.length > 0 && program.has_enter == 1) ?
                                                                <div className="lend_hendupr">


                                                                    <div className="rel_block ht-200">
                                                                        <div className="program_entry_loader">
                                                                            <LottieLoader />
                                                                        </div>
                                                                    </div>

                                                                    {(this.state.error != 1 && !this.state.not_el_redeem) ?
                                                                        <div className="freebies_redem 1">
                                                                            <div>
                                                                                <Button id={"pending_button_" + program.program_id} size="medium" color="primary" onClick={() => { this.handleFreebie(program, "") }}>
                                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pending.en : "Pending"}
                                                                                </Button>
                                                                                {/* Add left right arrows */}
                                                                                {this.state.show_arrows == true &&
                                                                                    <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left ww" >
                                                                                        <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                    </div>
                                                                                }

                                                                                {this.state.show_arrows == true &&
                                                                                    <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                                        <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                    </div>
                                                                                }
                                                                                {/* End of Add left right arrows */}
                                                                            </div>
                                                                        </div> : ""
                                                                    }
                                                                    {/* new child edit design  */}
                                                                    {this.state.error == 1 &&
                                                                        <div className="lend_hendupr_ne1">
                                                                            <div className="instant_upr3_ne1">
                                                                                <div className="social_ne1">
                                                                                    <div className="instant_hendupr3_ne1">
                                                                                        <div className="instant_hend3_ne1">
                                                                                            <div className="cmbImg"><a><img src={url.family_one_image_url + "Group241.png"} alt="" title="" /></a>
                                                                                                <a><img src={url.family_one_image_url + "Group2.png"} alt="" title="" /></a>
                                                                                            </div>
                                                                                            <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_this_freebie.en : "Want this Freebie?"}</h3>
                                                                                            <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_this_freebie_desc.en : "It looks like you need to add a qualifying child to redeem."}</p>
                                                                                            <div className="not_eligible_add_child">
                                                                                                <Button id={program.program_id} size="medium" color="primary"
                                                                                                    onClick={() => {
                                                                                                        this.handleAddChild(program)
                                                                                                    }}>
                                                                                                    {"Add Child"}
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="social_connect_ne1">
                                                                                        <div className="social_connect_inr_ne1">
                                                                                            <div className="one_social_ne1">
                                                                                                <p> {"Don't have a qualifying child?"}</p>
                                                                                            </div>
                                                                                            <div className="one_social_connect_ne1">
                                                                                                {/* {this.state.child_data && this.state.child_data.length > 0 && this.state.child_data.map((child, index) => (
                                                                                        <div key={child.child_id} className="custom_align_ne1">
                                                                                            <p><span>{initials(child.child_name.toUpperCase()).substring(0, 2)}</span></p>
                                                                                        </div>
                                                                                    ))} */}
                                                                                                <div className="not_eligible_enter plus_ne1">
                                                                                                    <a onClick={() => { this.handleFreebie(program, "") }}>Redeem</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {/* End of new child edit design  */}
                                                                    <div className="instant_upr2">
                                                                        <div className="instant_redem2">
                                                                            <div className="declaration_instant2 declaration_short">
                                                                                <p>{ReactHtmlParser(program.short_description)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ""
                                                            }

                                                            {program.pending_fields && program.pending_fields.length == 0 && program.has_enter == 1 && program.recommended == "false" && program.short_description && program.short_description.trim()?.length &&
                                                                <div className="lend_hendupr mb-25 aa">
                                                                    <div className="instant_upr2">
                                                                        <div className="instant_redem2">
                                                                            <div className="declaration_instant2 declaration_short" style={{ marginTop: "0px" }}>
                                                                                <p>{ReactHtmlParser(program.short_description)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {/* div for direct user and freebie entered */}
                                                            {program.pending_fields && program.pending_fields.length == 0 && program.has_enter == 1 && program.recommended == "false" &&
                                                                <div className="lend_henduprNw">
                                                                    <div className="freebie_redeeemedNw">
                                                                    {program.program_id == "1636" ?
                                                                        <div className="lend_hendNw 6767">
                                                                            <div><img src={require('../../assets/images/purpalTick.svg')} alt="" title="" /></div>
                                                                            <h3>{"Thank You"}</h3>
                                                                            <p>{"We’ve shared your information with Abbott Nutrition, a division of Abbott Laboratories Co. Share this perk with friends and family."}</p>
                                                                        </div>
                                                                    : program.program_id !== "9127" ?
                                                                            <div className="lend_hendNw 6767">
                                                                                <div><img src={require('../../assets/images/purpalTick.svg')} alt="" title="" /></div>
                                                                                <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.redeemed.en : "Redeemed"}</h3>
                                                                                <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.share_freebie.en : "Share freebie to friends and family"}</p>
                                                                            </div>
                                                                            :
                                                                            <div className="lend_hendNw">
                                                                                <div><img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" /></div>
                                                                                <h3>{"Done"}</h3>
                                                                                <p>{"Share freebie with friends and family"}</p>
                                                                            </div>
                                                                        }
                                                                        <div className="plus_connect_uprNw">
                                                                            <div className="familyshare_submitNw">
                                                                                <Button size="medium" color="primary" onClick={() => { this.handleAddRelation(program) }}>
                                                                                    Family Share
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="detailsSocial_shareNw">
                                                                            <div><p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.social_share.en : "Social Share"}</p></div>
                                                                            <div>
                                                                                <a href={program.twitter_link} rel="noopener noreferrer" target="_blank">
                                                                                    <img src={require('../../assets/images/Xicon.svg')} alt="" title="" className="social_nw" />
                                                                                </a>
                                                                                <a href={program.facebook_link} rel="noopener noreferrer" target="_blank">
                                                                                    <img src={require('../../assets/images/faceBook3.svg')} alt="" title="" className="social_nw" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* Load More section */}
                                                                    {!this.state.load_more_loader &&
                                                                        <div style={{ marginTop: "9%" }}>
                                                                            {/* <Button size="medium" color="primary" className="next_offer" onClick={() => { this.handleClickArrows(program, "forward") }}>
                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.next_offer.en : "Next Offer"}
                                                            </Button> */}
                                                                            <div className="load_more want_more_div ">
                                                                                <Button className="explore_div" size="medium" color="primary" onClick={() => { this.handleLoadMore(program) }} >
                                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_more.en : "Quick View"}
                                                                                </Button>
                                                                            </div>
                                                                            {/* <div className="load_more want_more_div "> */}
                                                                            {program && program?.show_next_button &&
                                                                                <div className="instant_redem2">
                                                                                    <Button className="next_offer_div" size="medium" color="primary" onClick={() => { this.handleClickArrows(program, "forward") }} >
                                                                                        {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.next_offer.en : "Next"}
                                                                                    </Button>
                                                                                </div>
                                                                            }
                                                                            {/* </div> */}
                                                                        </div>
                                                                    }
                                                                    {this.state.load_more_loader &&
                                                                        <div className="load_more" >
                                                                            <LottieLoader style={{ color: "red" }} />
                                                                        </div>
                                                                    }
                                                                    {/* End of Load More section */}
                                                                </div>
                                                            }

                                                            {program.pending_fields && program.pending_fields.length == 0 && program.has_enter == 1 && program.recommended == "true" && program.relative_has_eligible_child == true &&
                                                                <div className="lend_hendupr">
                                                                    <div className="lend_hendBG freebie_redeeemedNw">
                                                                         <div className="lend_hend">

                                                                            <div><img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" /></div>
                                                                            <h3>Recommended</h3>
                                                                            <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.share_freebie.en : "Share freebie to friends and family"}</p>
                                                                        </div>
                                                                        <div className="plus_connect_upr">
                                                                            <div className="familyshare_submit">
                                                                                <Button size="medium" color="primary" onClick={() => { this.handleAddRelation(program) }}>
                                                                                    Family Share
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="detailsSocial_share redeem_social_share">
                                                                            <div><p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.social_share.en : "Social Share"}</p></div>
                                                                            <div>
                                                                                <a href={program.twitter_link} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'Group166.png'} alt="" title="" className="social_nw" /></a>
                                                                                <a href={program.facebook_link} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'Group165.png'} alt="" title="" className="social_nw" /></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    {/* Load More section */}
                                                                    {!this.state.load_more_loader &&
                                                                        <div className="load_more want_more_div" style={{ marginTop: "9%" }}>
                                                                            {/* <Button size="medium" color="primary" className="next_offer" onClick={() => { this.handleClickArrows(program, "forward") }}>
                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.next_offer.en : "Next Offer"}
                                                            </Button> */}
                                                                            <Button className="explore_div" size="medium" color="primary" onClick={() => { this.handleLoadMore(program) }} >
                                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_more.en : "Quick View"}
                                                                            </Button>
                                                                            {program && program?.show_next_button &&
                                                                                <div className="instant_redem2">
                                                                                    <Button className="next_offer_div" size="medium" color="primary" onClick={() => { this.handleClickArrows(program, "forward") }} >
                                                                                        {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.next_offer.en : "Next"}
                                                                                    </Button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {this.state.load_more_loader &&
                                                                        <div className="load_more" >
                                                                            <LottieLoader style={{ color: "red" }} />
                                                                        </div>
                                                                    }
                                                                    {/* End of Load More section */}
                                                                </div>
                                                            }

                                                            {/* div for direct user and freebie not entered */}
                                                            {program?.has_enter == 1 && program?.recommended == "true" && program.relative_has_eligible_child == false &&
                                                                <div className="ends_date_cutm 1">

                                                                    <div className="rel_block ht-200">
                                                                        <div className="program_entry_loader">
                                                                            <LottieLoader />
                                                                        </div>
                                                                    </div>

                                                                    {/* new no edit design  */}
                                                                    {program?.allowed_relative_data?.length > 0 && program?.relative_has_eligible_child == false &&
                                                                        <div className="lend_hendupr_ne1">
                                                                            <div className="instant_upr3_ne1">
                                                                                <div className="social_ne1">
                                                                                    <div className="instant_hendupr3_ne1">
                                                                                        <div className="instant_hend3_ne1">
                                                                                            <div className="cmbImg"><a><img src={url.family_one_image_url + "Group241.png"} alt="" title="" /></a>
                                                                                                <a><img src={url.family_one_image_url + "Group2.png"} alt="" title="" /></a>
                                                                                            </div>
                                                                                            <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.recommend_pending_eligible_child.en : "Your recommendation is pending connection with a qualifying friend/relative. Connect with qualifying friends/relatives now."}</p>
                                                                                            <div className="editChildInfo">
                                                                                                <Button size="medium" color="primary" onClick={() => { this.handleAddRelation(program) }}>
                                                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.add_a_relative.en : "Add a relative"}
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {program?.allowed_relative_data?.length == 0 &&
                                                                        <div className="lend_hendupr_ne1">
                                                                            <div className="instant_upr3_ne1">
                                                                                <div className="social_ne1">
                                                                                    <div className="instant_hendupr3_ne1">
                                                                                        <div className="instant_hend3_ne1">
                                                                                            <div className="cmbImg"><a><img src={url.family_one_image_url + "Group241.png"} alt="" title="" /></a>
                                                                                                <a><img src={url.family_one_image_url + "Group2.png"} alt="" title="" /></a>
                                                                                            </div>
                                                                                            <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.recommend_pending.en : "Your recommendation is pending with your connection. Remind them to connect on family.one"}</p>
                                                                                            <div className="editChildInfo">
                                                                                                <Button size="medium" color="primary" onClick={() => {
                                                                                                    this.SendReminder(program)
                                                                                                }}>
                                                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.send_reminder.en : "SEND REMINDER"}
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="social_connect_ne1">
                                                                                        <div className="social_connect_inr_ne1">
                                                                                            <div className="one_social_ne1">
                                                                                                <p> {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.add_a_relative.en : "Add a relative"}</p>
                                                                                            </div>
                                                                                            <div className="one_social_connect_ne1">
                                                                                                {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                                                                                    <div key={index} className="custom_align">
                                                                                                        {relation.picture && relation.picture.trim()?.length > 0 && relation.picture.trim() !== 'no_image' ?
                                                                                                            <a><img src={url.cloudflare_image_path + relation.picture + url.varient} alt="" title="" /></a>
                                                                                                            :
                                                                                                            <p className=""><span className="initials_class">{initials((relation.given_name + " " + relation.family_name).toUpperCase()).substring(0, 2)}</span> </p>
                                                                                                        }
                                                                                                    </div>
                                                                                                ))}
                                                                                                <div className="custom_align_ne1 plus_ne1">
                                                                                                    <a onClick={() => { this.handleAddRelation(program) }}><img src={require('../../assets/images/plusOrange2.png')} alt="" title="" /></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {/* End of no child edit design  */}
                                                                    <div className="instant_upr2">
                                                                        <div className="instant_redem2">
                                                                            <div className="declaration_instant2 declaration_short">
                                                                                <p>{ReactHtmlParser(program.short_description)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {/* div for direct user and freebie not entered */}
                                                            {program.has_enter == 0 &&
                                                                <div className="ends_date_cutm 2">

                                                                    <div className="rel_block ht-200">
                                                                        <div className="program_entry_loader">
                                                                            <LottieLoader />
                                                                        </div>
                                                                    </div>

                                                                    {(program.is_eligible == 1 || (!program.pending_data_fields || !program.pending_data_fields.includes("child_age"))) ?
                                                                        <div>
                                                                            <div>
                                                                                <div className="freebie_redeem_fixed freebies_redem 2">
                                                                                    {!program.notify_me && !this.state.not_el_redeem && program.partner_id != "10" &&
                                                                                        <Button id={program.program_id} size="medium" color="primary" onClick={() => { this.handleFreebie(program, "") }}>
                                                                                        {program.program_id == '1636' ? "Continue" : program.program_id !== "9127" ? "Redeem" : "Get a Free Quote"}
                                                                                        </Button>
                                                                                    }
                                                                                    {!program.notify_me && !this.state.not_el_redeem && program.partner_id == "10" && program.program_id != "1262" && program.program_id != "6984" &&
                                                                                        <div className="freebie_redeem_fixed freebies_redem 2">
                                                                                            <Button id={program.program_id + "_nestle_coupon"} size="medium" color="primary" style={{ marginBottom: "20px" }} onClick={() => { this.handleFreebie(program, "coupon") }}>
                                                                                            {program.program_id == '1636' ? "Continue" : dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.receive_coupon_pack.en : "Receive Coupon Pack"}
                                                                                            </Button>
                                                                                            <Button id={program.program_id + "_nestle_sample"} size="medium" color="primary" className="receive_samples" onClick={() => { this.handleFreebie(program, "sample") }}>
                                                                                            {program.program_id == '1636' ? "Continue" : dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.receive_sample_pack.en : "Receive Sample Pack"}
                                                                                            </Button>
                                                                                        </div>}
                                                                                    {!program.notify_me && !this.state.not_el_redeem && program.partner_id == "10" && (program.program_id == "1262" || program.program_id == "6984") &&
                                                                                        <div className="freebie_redeem_fixed freebies_redem 2">
                                                                                            <Button id={program.program_id + "_nestle_coupon"} size="medium" color="primary" style={{ marginBottom: "20px" }} onClick={() => { this.handleFreebie(program, program.program_id == "6984" ? "coupon" : "default_coupon") }}>
                                                                                                {program.program_id == '1636' ? "Continue" :"Redeem"}
                                                                                            </Button>
                                                                                        </div>
                                                                                    }
                                                                                    {program.notify_me && program.notify_me == 'true' &&
                                                                                        <Button size="medium" color="primary" onClick={() => { this.handleNotifyMe(program) }}>
                                                                                            {program.program_id == '1636' ? "Continue" : dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.notify_me.en : "Notify Me"}
                                                                                        </Button>
                                                                                    }
                                                                                </div>

                                                                                {/* Add left right arrows */}
                                                                                {this.state.show_arrows == true &&
                                                                                    <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left 99" >
                                                                                        <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                    </div>
                                                                                }

                                                                                {this.state.show_arrows == true &&
                                                                                    <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                                        <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                    </div>
                                                                                }
                                                                                {/* End of Add left right arrows */}

                                                                            </div>
                                                                            {/* <div className="instant_upr2 1" style={{marginTop:"-33px",paddingTop:"41px"}}>
                                                                    <div className="instant_redem2"> */}
                                                                            <div className="instant_upr2">
                                                                                <div className="instant_redem2">
                                                                                    <div className={program.partner_id == "10" ? "declaration_instant_nestle declaration_short_nestle" : "declaration_instant2 declaration_short"}>
                                                                                        <p>{ReactHtmlParser(program.short_description)}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> :
                                                                        <div>
                                                                            {program.relative_eligible == false &&
                                                                                <div>
                                                                                    <div className="wantFreebieUpr">
                                                                                        <div className="wantFreebieInr">
                                                                                            <div className="wantFreebieSocl">
                                                                                                <div className="wantFreebieInstnt">
                                                                                                    <div className="wantFreeInstntInr">
                                                                                                        <div className="wantFreeImg">
                                                                                                            <a><img src={require('../../assets/images/freebeiIcon1.svg')} alt="" title="" /></a>
                                                                                                            <a><img src={require('../../assets/images/freebeiIcon2.svg')} alt="" title="" /></a>
                                                                                                        </div>
                                                                                                        <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_this_freebie.en : "Want this Freebie?"}</h3>
                                                                                                        <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_this_freebie_desc.en : "It looks like you need to add a qualifying child to redeem."}</p>
                                                                                                        <div className="freeBeiEligibBtn 222">
                                                                                                            <Button id={program.program_id} size="medium" color="primary" onClick={() => {
                                                                                                                this.handleAddChild(program)
                                                                                                            }}>
                                                                                                                {"Add Child"}
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>

                                                                                                <div className="social_connect_ne1">
                                                                                                    <div className="social_connect_inr_ne1">
                                                                                                        <div className="one_social_ne1">
                                                                                                            <p> {"Don't have a qualifying child?"}</p>
                                                                                                        </div>
                                                                                                        <div className="one_social_connect_ne1">
                                                                                                            {/* {this.state.child_data && this.state.child_data.length > 0 && this.state.child_data.map((child, index) => (
                                                                                            <div key={child.child_id} className="custom_align_ne1">
                                                                                                <p><span>{initials(child.child_name.toUpperCase()).substring(0, 2)}</span></p>
                                                                                            </div>
                                                                                        ))} */}
                                                                                                            <div className="not_eligible_enter plus_ne1">
                                                                                                                <a onClick={() => { this.handleFreebie(program, "") }}>Redeem</a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="instant_upr2 2">
                                                                                        <div className="instant_redem2">
                                                                                            <div className="declaration_instant2 declaration_short">
                                                                                                <p>{ReactHtmlParser(program.short_description)}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {program.relative_eligible == true &&
                                                                                <div>
                                                                                    <div className="lend_hendupr_ne1">
                                                                                        <div className="instant_upr3_ne1">
                                                                                            <div className="social_ne1">
                                                                                                <div className="instant_hendupr3_ne1">
                                                                                                    <div className="instant_hend3_ne1">
                                                                                                        <div className="cmbImg"><a><img src={url.family_one_image_url + "Group241.png"} alt="" title="" /></a>
                                                                                                            <a><img src={url.family_one_image_url + "Group2.png"} alt="" title="" /></a>
                                                                                                        </div>
                                                                                                        <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.like_this_freebie.en : "Like this Freebie?"}</h3>
                                                                                                        <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.recommend_text.en : "Help friends/relatives redeem this freebie!"}</p>
                                                                                                        <div className="editChildInfo">
                                                                                                            <Button size="medium" color="primary" onClick={() => {
                                                                                                                this.handleClick(program, '', 'recommend', '')
                                                                                                            }}>
                                                                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.recommend.en : "RECOMMEND"}
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>

                                                                                                <div className="social_connect_ne1">
                                                                                                    <div className="social_connect_inr_ne1">
                                                                                                        <div className="one_social_ne1">
                                                                                                            <p> {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.add_a_relative.en : "Add a relative"}</p>
                                                                                                        </div>
                                                                                                        <div className="one_social_connect_ne1">
                                                                                                            {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                                                                                                <div key={index} className="custom_align">
                                                                                                                    {relation.picture && relation.picture.trim()?.length > 0 && relation.picture.trim() !== 'no_image' ?
                                                                                                                        <a><img src={url.cloudflare_image_path + relation.picture + url.varient} alt="" title="" /></a>
                                                                                                                        :
                                                                                                                        <p className=""><span className="initials_class">{initials((relation.given_name + " " + relation.family_name).toUpperCase()).substring(0, 2)}</span> </p>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            ))}
                                                                                                            <div className="custom_align_ne1 plus_ne1">
                                                                                                                <a onClick={() => { this.handleAddRelation(program) }}><img src={require('../../assets/images/plusOrange2.png')} alt="" title="" /></a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="instant_upr2 3">
                                                                                        <div className="instant_redem2">
                                                                                            <div className="declaration_instant2 declaration_short">
                                                                                                <p>{ReactHtmlParser(program.short_description)}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }

                                                                </div>
                                                            }

                                                        </div>
                                                    }

                                                    {/* div for direct user and contest not entered */}
                                                    {program.program_type == "contest" && program.direct_member == 1 && !program.phone_number_required && !program.policyadvisor_consent_required && !program.epilogue_question_required && !program.policy_terms_required && !program.add_relative_required && !program.term_and_condition_required && !program.birthdate_required && !program.baby_registry_required && !program.guardtree_text_required &&
                                                        <div className="lend_hendupr">
                                                            {(program.pending_fields && program.pending_fields.length > 0 && program.has_enter == 1 && !this.state.not_el_redeem && program.recommended == "false") ?
                                                                <div className="instant_upr2">
                                                                    <div className="instant_hendupr2 rel_block">
                                                                        <div className="instant_hend2">
                                                                            {program.pending_fields && program.pending_fields.length > 0 &&
                                                                                <div className="fiveone_pts"><a>{program.has_enter} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</a></div>
                                                                            }
                                                                            {program.pending_fields && program.pending_fields.length == 0 &&
                                                                                <div className="fiveone_pts"><a>{program.family_activity.gain_family_points + program.social_activity.total_gain_social_share_points} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</a></div>
                                                                            }
                                                                            <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.are_pending_entry.en : "Are Pending for Entry"}</h3>
                                                                            {program.program_id == "5667" ?
                                                                                <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.invitation_to_join.en : "An invitation to join was sent to your friends"} </p> :
                                                                                <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.your_friend_entered.en : "Share contest to earn more points"} </p>
                                                                            }

                                                                        </div>
                                                                        <div className="program_entry_loader">
                                                                            <LottieLoader />
                                                                        </div>
                                                                    </div>
                                                                    <div className="instant_redem2">
                                                                        <div>
                                                                            {program.program_id == "9749" &&
                                                                                <Button size="medium" color="primary" onClick={() => { this.handleClick(program, '', '', '') }}>
                                                                                    Continue
                                                                                </Button>
                                                                            }
                                                                            {program.program_id != "9749" &&
                                                                                <Button id={"pending_button_" + program.program_id} size="medium" color="primary" onClick={() => { this.handleClick(program, '', '', '') }}>
                                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pending.en : "Pending"}
                                                                                </Button>
                                                                            }
                                                                            {/* Add left right arrows */}
                                                                            {this.state.show_arrows == true &&
                                                                                <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left  88" >
                                                                                    <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                </div>
                                                                            }

                                                                            {this.state.show_arrows == true &&
                                                                                <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                                    <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                </div>
                                                                            }
                                                                            {/* End of Add left right arrows */}
                                                                        </div>
                                                                        <div className="declaration_instant2 declaration_short">
                                                                            <p>{ReactHtmlParser(program.short_description)}</p>
                                                                        </div>
                                                                    </div>
                                                                </div> : ""
                                                            }

                                                            {(program.pending_fields && program.pending_fields.length == 0 && program.has_enter == 1 && program.recommended == "false" && program.relative_eligible == false && program.short_description && program.short_description.trim()?.length) ?
                                                                <div className="lend_hendupr ab">
                                                                    <div className="instant_upr2">
                                                                        <div className="instant_redem2">
                                                                            <div className="declaration_instant2 declaration_short">
                                                                                <p>{ReactHtmlParser(program.short_description)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : ""
                                                            }

                                                            {(program.pending_fields && program.pending_fields.length == 0 && program.has_enter == 1 && program.recommended == "false" && program.relative_eligible == false) ?
                                                                <div className="instant_upr3 instant_upr3Cstm">
                                                                    <div className="social_connect_uprmain">
                                                                        <div className="instant_hendupr3">
                                                                            <div className="instant_hend3">
                                                                                {/* <div className="fiveone_pts3 444">
                                                                                    <a>
                                                                                        <img src={url.family_one_image_url + 'REWARD STARS.png'} alt="" title="" />
                                                                                        <span className="numPts">
                                                                                            <b>{program.family_activity.gain_family_points + program.social_activity.total_gain_social_share_points + program.has_enter}</b>
                                                                                            {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}
                                                                                        </span>
                                                                                    </a>
                                                                                </div> */}

                                                                                <div className='enterdIcon'><img src={require('../../assets/images/entredIcon.svg')} alt="" title="" /></div>

                                                                                <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.entered.en : "Entered"}</h3>
                                                                                {program.program_id == "5667" ?
                                                                                    <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.invitation_to_join.en : "An invitation to join was sent to your friends"} </p> :
                                                                                    <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.your_friend_entered.en : "Share contest to earn more points"} </p>
                                                                                }
                                                                                {(program.program_id == "5147" || program.program_id == "7755") && program.coupon_code &&
                                                                                    <h5>We’ve emailed you special discounts on Tommee Tippee products. Check your inbox!</h5>
                                                                                }
                                                                            </div>
                                                                        </div>


                                                                        <div className="social_connect_upr">
                                                                            <div className="social_activ">
                                                                                <div className="social_activleft">
                                                                                    <label>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.family_activity.en : "Family Activity"}</label>
                                                                                </div>
                                                                                <div className="social_activright 4747">
                                                                                    <label><b>{program.family_activity.gain_family_points} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"} / </b><strong>{program.family_activity.total_family_points} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</strong></label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="borderProgress">
                                                                                <BorderLinearProgress
                                                                                    variant="determinate"
                                                                                    color="secondary"
                                                                                    value={this.getProgress(program.has_enter, program.family_activity.gain_family_points, program.family_activity.total_family_points)}
                                                                                />
                                                                                <img src={require('../../assets/images/purpalCircle.svg')} alt="" title="" />
                                                                            </div>
                                                                            <div className="social_connect_inr">
                                                                                <div className="one_social 12122">
                                                                                    <p><span>+2 {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</span> per qualifying relative</p>
                                                                                </div>
                                                                                <div className="one_social_connect" id="one_social_connect">
                                                                                    {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                                                                        <div key={index} className="custom_align 3434">
                                                                                            {relation.picture && relation.picture.trim()?.length > 0 && relation.picture.trim() !== 'no_image' ?
                                                                                                <a><img src={url.cloudflare_image_path + relation.picture + url.varient} alt="" title="" /></a>
                                                                                                :
                                                                                                <p className=""><span className="initials_class">{initials((relation.given_name + " " + relation.family_name).toUpperCase()).substring(0, 2)}</span> </p>
                                                                                            }
                                                                                        </div>
                                                                                    ))}
                                                                                    <div className="custom_align">
                                                                                        <a><img onClick={() => { this.handleAddRelation(program) }} src={require('../../assets/images/plusOrange2.png')} alt="" title="" /></a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div className="social_activity">
                                                                        <div className="social_activityleft">
                                                                            <label>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.social_share.en : "Social Share"}</label>
                                                                            <p><b>+1 {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</b> for each entered</p>
                                                                        </div>
                                                                        <div className="social_activityright">
                                                                            <label>{program.social_activity.total_gain_social_share_points} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</label>
                                                                            <div>
                                                                                {program.social_activity.total_gain_social_share_points > 0 &&
                                                                                    <a><img src={require('../../assets/images/Xicon.svg')} alt="" title="" />
                                                                                        <img src={require('../../assets/images/twitterRight.svg')} alt="" title="" className="rightsymbol" />
                                                                                    </a>
                                                                                }
                                                                                {program.social_activity.total_gain_social_share_points == 0 &&
                                                                                    <a href={program.twitter_link} rel="noopener noreferrer" target="_blank"><img src={require('../../assets/images/Xicon.svg')} alt="" title="" /></a>
                                                                                }
                                                                                <a href={program.facebook_link} rel="noopener noreferrer" target="_blank"><img src={require('../../assets/images/faceBook3.svg')} alt="" title="" /></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : ""
                                                            }
                                                            {(program.partner_id == '15' && program.show_survey_question && localStorage.getItem(`embark_${program.program_id}_survey`) == 'null') ?
                                                                <div className="lend_hendupr test121">
                                                                    <div className="instant_upr2">
                                                                        <div className="instant_hendupr2 instant_hendupr2SetBg">
                                                                            <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                                                <SuveyQuestions props={this.props} program={program} Back={this.handleSurveyBack} onSubmit={(e, ques) => { this.handleClickSubmit(program, e, '', ques) }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : ""
                                                            }
                                                            {(program.pending_fields && program.pending_fields.length == 0 && program.has_enter == 1 && program.recommended == "false" && program.relative_eligible == true && program.relative_has_eligible_child == true) ?
                                                                <div className="instant_upr3">
                                                                    <div className="social_connect_uprmain">
                                                                        <div className="instant_hendupr3">
                                                                            <div className="instant_hend3">
                                                                                <div className="fiveone_pts3"><a><img src={url.family_one_image_url + 'REWARD STARS.png'} alt="" title="" /> {program.family_activity.gain_family_points + program.social_activity.total_gain_social_share_points + program.has_enter} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</a></div>

                                                                                <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.entered.en : "Entered"}</h3>
                                                                                {program.program_id == "5667" ?
                                                                                    <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.invitation_to_join.en : "An invitation to join was sent to your friends"} </p> :
                                                                                    <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.your_friend_entered.en : "Share contest to earn more points"} </p>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="social_connect_upr">
                                                                            <div className="social_activ">
                                                                                <div className="social_activleft">
                                                                                    <label>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.family_activity.en : "Family Activity"}</label>
                                                                                </div>
                                                                                <div className="social_activright 4545">
                                                                                    <label>{program.family_activity.gain_family_points} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}/{program.family_activity.total_family_points} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="borderProgress">
                                                                                <BorderLinearProgress
                                                                                    variant="determinate"
                                                                                    color="secondary"
                                                                                    value={this.getProgress(program.has_enter, program.family_activity.gain_family_points, program.family_activity.total_family_points)}
                                                                                />
                                                                                <img src={url.family_one_image_url + 'Star 1.png'} alt="" title="" />
                                                                            </div>
                                                                            <div className="social_connect_inr">
                                                                                <div className="one_social 1212">
                                                                                    <p><span>+2 {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</span> per qualifying relative</p>
                                                                                </div>
                                                                                <div className="one_social_connect" id="one_social_connect">
                                                                                    {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                                                                        <div key={index} className="custom_align">
                                                                                            {relation.picture && relation.picture.trim()?.length > 0 && relation.picture.trim() !== 'no_image' ?
                                                                                                <a><img src={url.cloudflare_image_path + relation.picture + url.varient} alt="" title="" /></a>
                                                                                                :
                                                                                                <p className=""><span className="initials_class">{initials((relation.given_name + " " + relation.family_name).toUpperCase()).substring(0, 2)}</span> </p>
                                                                                            }
                                                                                        </div>
                                                                                    ))}
                                                                                    <div className="custom_align">
                                                                                        <a><img onClick={() => { this.handleAddRelation(program) }} src={require('../../assets/images/plusOrange2.png')} alt="" title="" /></a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div className="social_activity">
                                                                        <div className="social_activityleft">
                                                                            <label>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.social_share.en : "Social Share"}</label>
                                                                            <p><b>+1 {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</b> for each entered</p>
                                                                        </div>
                                                                        <div className="social_activityright">
                                                                            <label>{program.social_activity.total_gain_social_share_points} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</label>
                                                                            <div>
                                                                                {program.social_activity.total_gain_social_share_points > 0 &&
                                                                                    <a><img src={url.family_one_image_url + 'Grouptwiter166.png'} alt="" title="" />
                                                                                        <img src={url.family_one_image_url + 'Groupright237.png'} alt="" title="" className="rightsymbol" />
                                                                                    </a>
                                                                                }
                                                                                {program.social_activity.total_gain_social_share_points == 0 &&
                                                                                    <a href={program.twitter_link} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'Group166.png'} alt="" title="" /></a>
                                                                                }
                                                                                <a href={program.facebook_link} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'Groupfb165.png'} alt="" title="" /></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : ""
                                                            }

                                                            {(program.pending_fields && program.pending_fields.length == 0 && program.has_enter == 1 && program.recommended == "false" && program.relative_eligible == true && program.relative_has_eligible_child == false) ?
                                                                <div>
                                                                    {program.allowed_relative_data && program.allowed_relative_data.length > 0 &&
                                                                        <div className="instant_upr2">
                                                                            <div className="instant_hendupr2 rel_block">
                                                                                <div className="instant_hend2">
                                                                                    {program.pending_fields && program.pending_fields.length > 0 &&
                                                                                        <div className="fiveone_pts"><a>{program.has_enter} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</a></div>
                                                                                    }
                                                                                    {program.pending_fields && program.pending_fields.length == 0 &&
                                                                                        <div className="fiveone_pts"><a>{program.family_activity.gain_family_points + program.social_activity.total_gain_social_share_points + program.has_enter} {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</a></div>
                                                                                    }
                                                                                    <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.are_pending_entry.en : "Are Pending for Entry"}</h3>
                                                                                    <p>{"Your entry is pending connection with a qualifying friend/relative. Connect with qualifying friends/relatives now."} </p>
                                                                                </div>
                                                                                <div className="program_entry_loader">
                                                                                    <LottieLoader />
                                                                                </div>
                                                                            </div>
                                                                            <div className="instant_redem2">
                                                                                <div>
                                                                                    <Button size="medium" color="primary" onClick={() => { this.handleAddRelation(program) }}>
                                                                                        {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.add_a_relative.en : "Add a relative"}
                                                                                    </Button>
                                                                                    {/* Add left right arrows */}
                                                                                    {this.state.show_arrows == true &&
                                                                                        <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left 77" >
                                                                                            <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                        </div>
                                                                                    }

                                                                                    {this.state.show_arrows == true &&
                                                                                        <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                                            <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                        </div>
                                                                                    }
                                                                                    {/* End of Add left right arrows */}
                                                                                </div>
                                                                                <div className="declaration_instant2 declaration_short">
                                                                                    <p>{ReactHtmlParser(program.short_description)}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {program.allowed_relative_data && program.allowed_relative_data.length == 0 &&
                                                                        <div className="lend_hendupr_ne1">
                                                                            <div className="instant_upr3_ne1">
                                                                                <div className="social_ne1">
                                                                                    <div className="instant_hendupr3_ne1">
                                                                                        <div className="instant_hend3_ne1">
                                                                                            <div className="cmbImg"><a><img src={url.family_one_image_url + "Group241.png"} alt="" title="" /></a>
                                                                                                <a><img src={url.family_one_image_url + "Group2.png"} alt="" title="" /></a>
                                                                                            </div>
                                                                                            <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.recommend_pending.en : "Your recommendation is pending with your connection. Remind them to connect on family.one"}</p>
                                                                                            <div className="editChildInfo">
                                                                                                <Button size="medium" color="primary" onClick={() => {
                                                                                                    this.SendReminder(program)
                                                                                                }}>
                                                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.send_reminder.en : "SEND REMINDER"}
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="social_connect_ne1">
                                                                                        <div className="social_connect_inr_ne1">
                                                                                            <div className="one_social_ne1">
                                                                                                <p> {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.add_a_relative.en : "Add a relative"}</p>
                                                                                            </div>
                                                                                            <div className="one_social_connect_ne1">
                                                                                                {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                                                                                    <div key={index} className="custom_align">
                                                                                                        {relation.picture && relation.picture.trim()?.length > 0 && relation.picture.trim() !== 'no_image' ?
                                                                                                            <a><img src={url.cloudflare_image_path + relation.picture + url.varient} alt="" title="" /></a>
                                                                                                            :
                                                                                                            <p className=""><span className="initials_class">{initials((relation.given_name + " " + relation.family_name).toUpperCase()).substring(0, 2)}</span> </p>
                                                                                                        }
                                                                                                    </div>
                                                                                                ))}
                                                                                                <div className="custom_align_ne1 plus_ne1">
                                                                                                    <a onClick={() => { this.handleAddRelation(program) }}><img src={require('../../assets/images/plusOrange2.png')} alt="" title="" /></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div> : ""
                                                            }

                                                            {(program.has_enter == 1 && program.recommended == "true" && program.relative_has_eligible_child == true) ?
                                                                <div className="lend_hendupr">
                                                                    <div className="lend_hendBG freebie_redeeemedNw">
                                                                        <div className="lend_hend">

                                                                            <div><img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" /></div>
                                                                            <h3>Recommended</h3>
                                                                            <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.share_giveaway.en : "Share giveaway to friends and family"}</p>
                                                                        </div>
                                                                        <div className="plus_connect_upr">
                                                                            <div className="familyshare_submit">
                                                                                <Button size="medium" color="primary" onClick={() => { this.handleAddRelation(program) }}>
                                                                                    Family Share
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="detailsSocial_share redeem_social_share">
                                                                            <div><p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.social_share.en : "Social share"}</p></div>
                                                                            <div>
                                                                                <a href={program.twitter_link} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'Group166.png'} alt="" title="" className="social_nw" /></a>
                                                                                <a href={program.facebook_link} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'Group165.png'} alt="" title="" className="social_nw" /></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    {/* Load More section */}
                                                                    {!this.state.load_more_loader &&
                                                                        <div className="load_more want_more_div" style={{ marginTop: "9%" }}>
                                                                            {/* <Button size="medium" color="primary" className="next_offer" onClick={() => { this.handleClickArrows(program, "forward") }}>
                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.next_offer.en : "Next Offer"}
                                                            </Button> */}
                                                                            <Button className="explore_div" size="medium" color="primary" onClick={() => { this.handleLoadMore(program) }} >
                                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_more.en : "Quick View"}
                                                                            </Button>
                                                                            {program && program?.show_next_button &&
                                                                                <div className="instant_redem2">
                                                                                    <Button className="next_offer_div" size="medium" color="primary" onClick={() => { this.handleClickArrows(program, "forward") }} >
                                                                                        {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.next_offer.en : "Next"}
                                                                                    </Button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {this.state.load_more_loader &&
                                                                        <div className="load_more" >
                                                                            <LottieLoader style={{ color: "red" }} />
                                                                        </div>
                                                                    }
                                                                    {/* End of Load More section */}
                                                                </div> : ""
                                                            }

                                                            {/* div for direct user and freebie not entered */}
                                                            {(program.has_enter == 1 && program.recommended == "true" && program.relative_has_eligible_child == false) ?
                                                                <div className="ends_date_cutm 3">

                                                                    <div className="rel_block ht-200">
                                                                        <div className="program_entry_loader">
                                                                            <LottieLoader />
                                                                        </div>
                                                                    </div>

                                                                    {/* new no edit design  */}
                                                                    {program?.allowed_relative_data?.length > 0 && program.relative_has_eligible_child == false &&
                                                                        <div className="lend_hendupr_ne1">
                                                                            <div className="instant_upr3_ne1">
                                                                                <div className="social_ne1">
                                                                                    <div className="instant_hendupr3_ne1">
                                                                                        <div className="instant_hend3_ne1">
                                                                                            <div className="cmbImg"><a><img src={url.family_one_image_url + "Group241.png"} alt="" title="" /></a>
                                                                                                <a><img src={url.family_one_image_url + "Group2.png"} alt="" title="" /></a>
                                                                                            </div>
                                                                                            <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.recommend_pending_eligible_child.en : "Your recommendation is pending connection with a qualifying friend/relative. Connect with qualifying friends/relatives now."}</p>
                                                                                            <div className="editChildInfo">
                                                                                                <Button size="medium" color="primary" onClick={() => { this.handleAddRelation(program) }}>
                                                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.add_a_relative.en : "Add a relative"}
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {program?.allowed_relative_data?.length == 0 &&
                                                                        <div className="lend_hendupr_ne1">
                                                                            <div className="instant_upr3_ne1">
                                                                                <div className="social_ne1">
                                                                                    <div className="instant_hendupr3_ne1">
                                                                                        <div className="instant_hend3_ne1">
                                                                                            <div className="cmbImg"><a><img src={url.family_one_image_url + "Group241.png"} alt="" title="" /></a>
                                                                                                <a><img src={url.family_one_image_url + "Group2.png"} alt="" title="" /></a>
                                                                                            </div>
                                                                                            <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.recommend_pending.en : "Your recommendation is pending with your connection. Remind them to connect on family.one"}</p>
                                                                                            <div className="editChildInfo">
                                                                                                <Button size="medium" color="primary" onClick={() => {
                                                                                                    this.SendReminder(program)
                                                                                                }}>
                                                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.send_reminder.en : "SEND REMINDER"}
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="social_connect_ne1">
                                                                                        <div className="social_connect_inr_ne1">
                                                                                            <div className="one_social_ne1">
                                                                                                <p> {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.add_a_relative.en : "Add a relative"}</p>
                                                                                            </div>
                                                                                            <div className="one_social_connect_ne1">
                                                                                                {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                                                                                    <div key={index} className="custom_align">
                                                                                                        {relation.picture && relation.picture.trim()?.length > 0 && relation.picture.trim() !== 'no_image' ?
                                                                                                            <a><img src={url.cloudflare_image_path + relation.picture + url.varient} alt="" title="" /></a>
                                                                                                            :
                                                                                                            <p className=""><span className="initials_class">{initials((relation.given_name + " " + relation.family_name).toUpperCase()).substring(0, 2)}</span> </p>
                                                                                                        }
                                                                                                    </div>
                                                                                                ))}
                                                                                                <div className="custom_align_ne1 plus_ne1">
                                                                                                    <a onClick={() => { this.handleAddRelation(program) }}><img src={require('../../assets/images/plusOrange2.png')} alt="" title="" /></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {/* End of no child edit design  */}
                                                                    <div className="instant_upr2">
                                                                        <div className="instant_redem2">
                                                                            <div className="declaration_instant2 declaration_short">
                                                                                <p>{ReactHtmlParser(program.short_description)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : ""
                                                            }

                                                            {/*  */}

                                                            {/* Load More section */}
                                                            {(program.pending_fields && program.pending_fields.length == 0 && program.has_enter == 1 && !this.state.load_more_loader) ?
                                                                <div className="load_more want_more_div" style={{ marginTop: "9%" }}>
                                                                    {/* <Button size="medium" color="primary" className="next_offer" onClick={() => { this.handleClickArrows(program, "forward") }}>
                                                        {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.next_offer.en : "Next Offer"}
                                                    </Button> */}
                                                                    <Button className="explore_div" size="medium" color="primary" onClick={() => { this.handleLoadMore(program) }} >
                                                                        {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_more.en : "Quick View"}
                                                                    </Button>
                                                                    {program && program?.show_next_button &&
                                                                        <div className="instant_redem2">
                                                                            <Button className="next_offer_div" size="medium" color="primary" onClick={() => { this.handleClickArrows(program, "forward") }} >
                                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.next_offer.en : "Next"}
                                                                            </Button>
                                                                        </div>
                                                                    }
                                                                </div> : ""
                                                            }
                                                            {(program.pending_fields && program.pending_fields.length == 0 && program.has_enter == 1 && this.state.load_more_loader) ?
                                                                <div className="load_more" >
                                                                    <LottieLoader style={{ color: "red" }} />
                                                                </div> : ""
                                                            }
                                                            {/* End of Load More section */}
                                                            {program.has_enter == 0 && program?.is_lock_program == 0 && this.state.child_data && this.state.child_data.length > 0 && !program.show_survey_question &&
                                                                <div>
                                                                    {program.is_eligible == 0 && program.only_eligible_entry != 1 &&
                                                                        <div>
                                                                            <div className="instant_upr2">
                                                                                <div className="instant_redem2">
                                                                                    {program.notify_me && program.notify_me == 'true' &&
                                                                                        <Button size="medium" color="primary" onClick={() => { this.handleNotifyMe(program) }}>
                                                                                            {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.notify_me.en : "Notify Me"}
                                                                                        </Button>
                                                                                    }
                                                                                    {!program.notify_me &&
                                                                                        <div>
                                                                                            <div id={"clicked_giveaway_" + program.program_id} >
                                                                                                <Button className={"contest_enter_fixed "} id={program.program_id} size="medium" color="primary" onClick={() => { this.handleClick(program, '', '', 'redeem/enter') }}>
                                                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.enter.en : "Enter"}
                                                                                                </Button>
                                                                                            </div>
                                                                                            {/* Add left right arrows */}
                                                                                            {this.state.show_arrows == true &&
                                                                                                <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left 66" >
                                                                                                    <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                </div>
                                                                                            }

                                                                                            {this.state.show_arrows == true &&
                                                                                                <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                                                    <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                </div>
                                                                                            }
                                                                                            {/* End of Add left right arrows */}
                                                                                        </div>
                                                                                    }
                                                                                    <div className="declaration_instant2 declaration_short">
                                                                                        {ReactHtmlParser(program.short_description)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="rel_block ht-200">
                                                                                <div className="program_entry_loader">
                                                                                    <LottieLoader />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {program.is_eligible == 0 && program.only_eligible_entry == 1 &&
                                                                        <div>
                                                                            <div className="rel_block ht-200">
                                                                                <div className="program_entry_loader">
                                                                                    <LottieLoader />
                                                                                </div>
                                                                            </div>
                                                                            {program.relative_eligible == true &&
                                                                                <div className="instant_upr2">
                                                                                    <div className="instant_redem2">
                                                                                        {program.notify_me && program.notify_me == 'true' &&
                                                                                            <Button size="medium" color="primary" onClick={() => { this.handleNotifyMe(program) }}>
                                                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.notify_me.en : "Notify Me"}
                                                                                            </Button>
                                                                                        }
                                                                                        {!program.notify_me &&
                                                                                            <div>
                                                                                                <div id={"clicked_giveaway_" + program.program_id}>
                                                                                                    <Button className={"contest_enter_fixed "} id={program.program_id} size="medium" color="primary" onClick={() => { this.handleClick(program, '', '', 'redeem/enter') }}>
                                                                                                        {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.enter.en : "Enter"}
                                                                                                    </Button>
                                                                                                </div>
                                                                                                {/* Add left right arrows */}
                                                                                                {this.state.show_arrows == true &&
                                                                                                    <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left 55" >
                                                                                                        <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                    </div>
                                                                                                }

                                                                                                {this.state.show_arrows == true &&
                                                                                                    <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                                                        <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                    </div>
                                                                                                }
                                                                                                {/* End of Add left right arrows */}
                                                                                            </div>
                                                                                        }
                                                                                        <div className="declaration_instant2 declaration_short">
                                                                                            {ReactHtmlParser(program.short_description)}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {program.relative_eligible == false &&
                                                                                <div>
                                                                                    <div className="lend_hendupr_ne1">
                                                                                        <div className="instant_upr3_ne1">
                                                                                            <div className="social_ne1">
                                                                                                <div className="instant_hendupr3_ne1">
                                                                                                    <div className="instant_hend3_ne1">
                                                                                                        <div className="cmbImg"><a><img src={url.family_one_image_url + "Group241.png"} alt="" title="" /></a>
                                                                                                            <a><img src={url.family_one_image_url + "Group2.png"} alt="" title="" /></a>
                                                                                                        </div>
                                                                                                        <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_this_giveaway.en : "Want this Giveaway?"}</h3>
                                                                                                        <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_this_giveaway_desc.en : "It looks like you need to add a qualifying child to enter."}</p>
                                                                                                        <div className="not_eligible_add_child">
                                                                                                            <Button size="medium" color="primary" onClick={() => { this.handleAddChild(program) }}>
                                                                                                                {"Add Child"}
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="social_connect_ne1">
                                                                                                    <div className="social_connect_inr_ne1">
                                                                                                        <div className="one_social_ne1">
                                                                                                            <p> {"Don't have a qualifying child?"}</p>
                                                                                                        </div>
                                                                                                        <div className="one_social_connect_ne1">
                                                                                                            <div className="not_eligible_enter plus_ne1">
                                                                                                                <a onClick={() => { this.handleClick(program, '', '', 'redeem/enter') }}>Enter</a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="instant_upr2">
                                                                                        <div className="instant_redem2">
                                                                                            <div className="declaration_instant2 declaration_short">
                                                                                                <p>{ReactHtmlParser(program.short_description)}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {program.is_eligible == 1 &&
                                                                        <div>
                                                                            {!program.cst_consent_required && <div className="instant_upr2">
                                                                                <div className="instant_redem2">
                                                                                    {program.notify_me && program.notify_me == 'true' &&
                                                                                        <Button size="medium" color="primary" onClick={() => { this.handleNotifyMe(program) }}>
                                                                                            {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.notify_me.en : "Notify Me"}
                                                                                        </Button>
                                                                                    }
                                                                                    {!program.notify_me &&
                                                                                        <div>
                                                                                            <div id={"clicked_giveaway_" + program.program_id} >
                                                                                                <Button className={"contest_enter_fixed "} id={program.program_id} size="medium" color="primary" onClick={() => { this.handleClick(program, '', '', 'redeem/enter') }}>
                                                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.enter.en : "Enter"}
                                                                                                </Button>
                                                                                            </div>

                                                                                            {/* Add left right arrows */}
                                                                                            {this.state.show_arrows == true &&
                                                                                                <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left  44" >
                                                                                                    <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                </div>
                                                                                            }


                                                                                            {this.state.show_arrows == true &&
                                                                                                <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                                                    <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                </div>
                                                                                            }
                                                                                            {/* End of Add left right arrows */}
                                                                                        </div>
                                                                                    }
                                                                                    <div className="declaration_instant2 declaration_short">
                                                                                        {ReactHtmlParser(program.short_description)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>}
                                                                            {program.cst_consent_required &&
                                                                                <div className="instant_upr5 instantRmvWht">
                                                                                    <div className="instant_redem2">
                                                                                        {!program.notify_me &&
                                                                                            <div>
                                                                                                <div id={"clicked_giveaway_" + program.program_id} >
                                                                                                    <Button className={"contest_enter_fixed "} id={program.program_id} size="medium" color="primary" onClick={() => { this.contestEntryCST(program, 'yes') }}>
                                                                                                        I consent
                                                                                                    </Button>
                                                                                                </div>

                                                                                                {/* Add left right arrows */}
                                                                                                {this.state.show_arrows == true &&
                                                                                                    <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left  44" >
                                                                                                        <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                    </div>
                                                                                                }


                                                                                                {this.state.show_arrows == true &&
                                                                                                    <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                                                        <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                    </div>
                                                                                                }
                                                                                                {/* End of Add left right arrows */}
                                                                                            </div>
                                                                                        }
                                                                                        {/* <div className="declaration_instant2 declaration_short 55555">
                                                                            {ReactHtmlParser(program.short_description)}
                                                                        </div> */}
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {!program.cst_consent_required && <div className="rel_block ht-200">
                                                                                <div className="program_entry_loader">
                                                                                    <LottieLoader />
                                                                                </div>
                                                                            </div>}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }

                                                            {program.has_enter == 0 && program?.is_lock_program == 0 && this.state.child_data && this.state.child_data.length == 0 &&
                                                                <div>
                                                                    <div className="rel_block ht-200">
                                                                        <div className="program_entry_loader">
                                                                            <LottieLoader />
                                                                        </div>
                                                                    </div>
                                                                    {program.is_eligible == 1 ?
                                                                        <div>
                                                                            <div className="instant_upr2">
                                                                                <div className="instant_redem2">
                                                                                    {program.notify_me && program.notify_me == 'true' &&
                                                                                        <Button size="medium" color="primary" onClick={() => { this.handleNotifyMe(program) }}>
                                                                                            {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.notify_me.en : "Notify Me"}
                                                                                        </Button>
                                                                                    }
                                                                                    {!program.notify_me &&
                                                                                        <div>
                                                                                            <div id={"clicked_giveaway_" + program.program_id} >
                                                                                                <Button className={"contest_enter_fixed "} id={program.program_id} size="medium" color="primary" onClick={() => { this.handleClick(program, '', '', 'redeem/enter') }}>
                                                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.enter.en : "Enter"}
                                                                                                </Button>
                                                                                            </div>
                                                                                            {/* Add left right arrows */}
                                                                                            {this.state.show_arrows == true &&
                                                                                                <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left  33" >
                                                                                                    <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                </div>
                                                                                            }

                                                                                            {this.state.show_arrows == true &&
                                                                                                <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                                                    <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                </div>
                                                                                            }
                                                                                            {/* End of Add left right arrows */}
                                                                                        </div>
                                                                                    }
                                                                                    <div className="declaration_instant2 declaration_short">
                                                                                        {ReactHtmlParser(program.short_description)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> :
                                                                        <div>
                                                                            {program.relative_eligible == true &&
                                                                                <div className="instant_upr2">
                                                                                    <div className="instant_redem2">
                                                                                        {program.notify_me && program.notify_me == 'true' &&
                                                                                            <Button size="medium" color="primary" onClick={() => { this.handleNotifyMe(program) }}>
                                                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.notify_me.en : "Notify Me"}
                                                                                            </Button>
                                                                                        }
                                                                                        {!program.notify_me &&
                                                                                            <div>
                                                                                                <div id={"clicked_giveaway_" + program.program_id}>
                                                                                                    <Button className={"contest_enter_fixed "} id={program.program_id} size="medium" color="primary" onClick={() => { this.handleClick(program, '', '', 'redeem/enter') }}>
                                                                                                        {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.enter.en : "Enter"}
                                                                                                    </Button>
                                                                                                </div>
                                                                                                {/* Add left right arrows */}
                                                                                                {this.state.show_arrows == true &&
                                                                                                    <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left  22" >
                                                                                                        <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                    </div>
                                                                                                }

                                                                                                {this.state.show_arrows == true &&
                                                                                                    <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                                                        <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                                    </div>
                                                                                                }
                                                                                                {/* End of Add left right arrows */}
                                                                                            </div>
                                                                                        }
                                                                                        <div className="declaration_instant2 declaration_short">
                                                                                            {ReactHtmlParser(program.short_description)}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {program.relative_eligible == false &&
                                                                                <div className="lend_hendupr_ne1">
                                                                                    <div className="instant_upr3_ne1">
                                                                                        <div className="social_ne1">
                                                                                            <div className="instant_hendupr3_ne1">
                                                                                                <div className="instant_hend3_ne1">
                                                                                                    <div className="cmbImg"><a><img src={url.family_one_image_url + "Group241.png"} alt="" title="" /></a>
                                                                                                        <a><img src={url.family_one_image_url + "Group2.png"} alt="" title="" /></a>
                                                                                                    </div>
                                                                                                    <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_this_giveaway.en : "Want this Giveaway?"}</h3>
                                                                                                    <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_this_giveaway_desc.en : "It looks like you need to add a qualifying child to enter."}</p>
                                                                                                    <div className="not_eligible_add_child">
                                                                                                        <Button size="medium" color="primary" onClick={() => { this.handleAddChild(program) }}>
                                                                                                            {"Add Child"}
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="social_connect_ne1">
                                                                                                <div className="social_connect_inr_ne1">
                                                                                                    <div className="one_social_ne1">
                                                                                                        <p> {"Don't have a qualifying child?"}</p>
                                                                                                    </div>
                                                                                                    <div className="one_social_connect_ne1">
                                                                                                        <div className="not_eligible_enter plus_ne1">
                                                                                                            <a onClick={() => { this.handleClick(program, '', '', 'redeem/enter') }}>Enter</a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }

                                                                </div>
                                                            }

                                                            {program.direct_member == 1 && program.program_type == "contest" && program.has_enter == 0 && program?.is_lock_program == 1 && program.is_still_locked == false &&
                                                                <div>
                                                                    <div className="rel_block ht-200">
                                                                        <div className="program_entry_loader">
                                                                            <LottieLoader />
                                                                        </div>
                                                                    </div>
                                                                    <div className="instant_upr2">
                                                                        <div className="instant_redem2">
                                                                            {program.notify_me && program.notify_me == 'true' &&
                                                                                <Button size="medium" color="primary" onClick={() => { this.handleNotifyMe(program) }}>
                                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.notify_me.en : "Notify Me"}
                                                                                </Button>
                                                                            }
                                                                            {!program.notify_me &&
                                                                                <div >
                                                                                    <div id={"clicked_giveaway_" + program.program_id}>
                                                                                        <Button className={"contest_enter_fixed "} id={program.program_id} size="medium" color="primary" onClick={() => { this.handleClick(program, '', '', 'redeem/enter') }}>
                                                                                            {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.enter.en : "Enter"}
                                                                                        </Button>
                                                                                    </div>
                                                                                    {/* Add left right arrows */}
                                                                                    {this.state.show_arrows == true &&
                                                                                        <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left 11" >
                                                                                            <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                        </div>
                                                                                    }

                                                                                    {this.state.show_arrows == true &&
                                                                                        <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                                            <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                                        </div>
                                                                                    }
                                                                                    {/* End of Add left right arrows */}

                                                                                </div>
                                                                            }
                                                                            <div className="declaration_instant2 declaration_short">
                                                                                <p>{ReactHtmlParser(program.short_description)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            }
                                                            {this.state.show_winner_data && this.state.show_winner_data.length > 0 &&
                                                                <div className="show_winer2" onClick={() => { this.allwinners(program) }}>
                                                                    <div className="show_winer_left2"><img src={url.family_one_image_url + 'Group4242.png'} alt="" title="" /><span>Show Winners</span></div>
                                                                    <div className="show_winer_rght2"><a>{this.state.show_winner_data.length}</a><img src={url.family_one_image_url + 'Vectorqw.png'} alt="" title="" /> </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    }

                                                    {program.direct_member == 1 && program.term_and_condition_required && !program.cst_consent_required && !program.birthdate_required && !program.phone_number_required && !program.policyadvisor_consent_required && !program.epilogue_question_required && !program.policy_terms_required && !program.add_relative_required && !program.baby_registry_required && !program.guardtree_text_required &&
                                                        <div className="instant_upr2_cstm 3781">
                                                            <div className={'instant_upr2 ' + (this.state.show_terms ? 'instantUprTerm' : '')}>
                                                                <div className="rel_block ht-200">
                                                                    <div className="prgrmFreebieDetail">
                                                                        <LottieLoader />
                                                                    </div>
                                                                </div>
                                                                {this.state.show_terms &&
                                                                    //instant_hendupr2
                                                                    <div className="freeBeiSetUpr">
                                                                        <div className="1212">
                                                                            <EnterTerms props={this.props} Back={this.handleTermsBack.bind(this)} onSubmit={this.handleClickSubmit.bind(this, program)} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {this.state.show_terms && program.program_id == '1636' ? '': <div className="instant_redem2">
                                                                    <div className="declaration_instant2 declaration_short">
                                                                        {ReactHtmlParser(program.short_description)}
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    }

                                                    {program.direct_member == 1 && !program.cst_consent_required && program.policyadvisor_consent_required && program.epilogue_question_required && !program.term_and_condition_required && !program.birthdate_required && !program.phone_number_required && !program.policy_terms_required && !program.add_relative_required && !program.baby_registry_required && !program.guardtree_text_required &&
                                                        <div className="instant_upr2_cstm 3807">
                                                            <div className="instant_upr2">

                                                                <div className="rel_block ht-200">
                                                                    <div className="program_entry_loader">
                                                                        <LottieLoader />
                                                                    </div>
                                                                </div>

                                                                {/* {this.state.show_policyadvisor_consent && */}
                                                                <div className="instant_hendupr2 addGiveAwayCards">
                                                                    <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                                        <EnterPolicyAdvisor props={this.props} Back={this.handlePolicyAdvisorBack.bind(this)} onSubmit={this.handleClickSubmit.bind(this, program)} />
                                                                    </div>
                                                                </div>
                                                                {/* } */}
                                                                <div className="instant_redem2">
                                                                    <div className="declaration_instant2 declaration_short">
                                                                        {ReactHtmlParser(program.short_description)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {program.direct_member == 1 && !program.policyadvisor_consent_required && program.epilogue_question_required && !program.term_and_condition_required && !program.cst_consent_required && !program.birthdate_required && !program.phone_number_required && !program.policy_terms_required && !program.add_relative_required && !program.baby_registry_required && !program.guardtree_text_required &&
                                                        <div className="instant_upr2_cstm 3833">
                                                            <div className="instant_upr2">

                                                                <div className="rel_block ht-200">
                                                                    <div className="program_entry_loader">
                                                                        <LottieLoader />
                                                                    </div>
                                                                </div>

                                                                {/* {this.state.show_policyadvisor_consent && */}
                                                                <div className="instant_hendupr2">
                                                                    <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                                        <EpilogueQuestion props={this.props} Back={this.handleEpilogueBack.bind(this)} onSubmit={this.handleClickSubmit.bind(this, program)} />
                                                                    </div>
                                                                </div>
                                                                {/* } */}
                                                                <div className="instant_redem2">
                                                                    <div className="declaration_instant2 declaration_short">
                                                                        {ReactHtmlParser(program.short_description)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {/* div for direct user and contest not entered with user entry criteria*/}
                                                    {program.direct_member == 1 && program.is_still_locked == false && program.phone_number_required && !program.cst_consent_required && !program.policyadvisor_consent_required && !program.epilogue_question_required && !program.policy_terms_required && !program.add_relative_required && !program.birthdate_required && !program.baby_registry_required && !program.guardtree_text_required &&
                                                        <div className="lend_hendupr" id="phoneTop">
                                                            <div className="instant_upr2">
                                                                <div className="rel_block ht-200">
                                                                    <div className="program_entry_loader">
                                                                        <LottieLoader />
                                                                    </div>
                                                                </div>
                                                                {this.state.show_phone &&
                                                                    <div className="instant_hendupr2">
                                                                        <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                                            <EnterPhone partner_id={program.partner_id} props={this.props} program_id={program.program_id} Back={this.handleBack.bind(this)} onSubmit={(phone_verified) => this.handleClickSubmit(program, null, null, null, phone_verified)} contestEntryKff={(consent) => this.contestEntryKff(program, consent)} />
                                                                        </div></div>
                                                                }
                                                                {program.partner_id === "14" && <div className="instant_redem2">
                                                                    <div className="declaration_instant2 declaration_short">
                                                                        {ReactHtmlParser(program.cst_consent_message)}
                                                                    </div>
                                                                </div>}
                                                                {program.partner_id !== "14" && <div className="instant_redem2">
                                                                    <div className="declaration_instant2 declaration_short">
                                                                        {ReactHtmlParser(program.short_description)}
                                                                    </div>
                                                                </div>}
                                                            </div>

                                                            {this.state.show_winner_data && this.state.show_winner_data.length > 0 &&
                                                                <div className="show_winer2" onClick={() => { this.allwinners(program) }}>
                                                                    <div className="show_winer_left2"><img src={url.family_one_image_url + 'Group4242.png'} alt="" title="" /><span>Show Winners</span></div>
                                                                    <div className="show_winer_rght2"><a>{this.state.show_winner_data.length}</a><img src={url.family_one_image_url + 'Vectorqw.png'} alt="" title="" /> </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {/* {program.partner_id == '15' && program.show_survey_question &&
                                            <div className="lend_hendupr">
                                                <div className="instant_upr2">
                                                    <div className="rel_block ht-200">
                                                        <div className="program_entry_loader">
                                                            <LottieLoader />
                                                        </div>
                                                    </div>
                                                    <div className="instant_hendupr2">
                                                        <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                            <SuveyQuestions props={this.props} program={program} Back={this.handleSurveyBack.bind(this)} onSubmit={(e, ques) => { this.handleClickSubmit(program, e, '', ques) }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                {this.state.show_winner_data && this.state.show_winner_data.length > 0 &&
                                                    <div className="show_winer2" onClick={() => { this.allwinners(program) }}>
                                                        <div className="show_winer_left2"><img src={url.family_one_image_url + 'Group4242.png'} alt="" title="" /><span>Show Winners</span></div>
                                                        <div className="show_winer_rght2"><a>{this.state.show_winner_data.length}</a><img src={url.family_one_image_url + 'Vectorqw.png'} alt="" title="" /> </div>
                                                    </div>
                                                }
                                            </div>
                                        } */}
                                                    {/* div for direct user and contest not entered with user entry criteria*/}
                                                    {/* {program.direct_member == 1 && program.is_still_locked == false && program.cst_consent_required && !program.phone_number_required && !program.policyadvisor_consent_required && !program.epilogue_question_required && !program.policy_terms_required && !program.add_relative_required && !program.birthdate_required && !program.baby_registry_required && !program.guardtree_text_required &&
                                            <div className="lend_hendupr">
                                                <div className="instant_upr2">
                                                    <div className="rel_block ht-200">
                                                        <div className="program_entry_loader">
                                                            <LottieLoader />
                                                        </div>
                                                    </div>
                                                    {this.state.show_cst_consent &&
                                                        <div className="instant_hendupr2">
                                                            <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                                <EnterCSTConsent props={this.props} program_id={program.program_id} Back={this.handleCSTBack.bind(this)} onSubmit={this.handleClickSubmit.bind(this, program)} contestEntryCST={(consent) => this.contestEntryCST(program, consent)} />
                                                            </div></div>
                                                    }
                                                    <div className="instant_redem2">
                                                        <div className="declaration_instant2 declaration_short">
                                                            {ReactHtmlParser(program.short_description)}
                                                        </div>
                                                    </div>
                                                </div>

                                                {this.state.show_winner_data && this.state.show_winner_data.length > 0 &&
                                                    <div className="show_winer2" onClick={() => { this.allwinners(program) }}>
                                                        <div className="show_winer_left2"><img src={url.family_one_image_url + 'Group4242.png'} alt="" title="" /><span>Show Winners</span></div>
                                                        <div className="show_winer_rght2"><a>{this.state.show_winner_data.length}</a><img src={url.family_one_image_url + 'Vectorqw.png'} alt="" title="" /> </div>
                                                    </div>
                                                }
                                            </div>
                                        } */}
                                                    {program.consent_yes ? <div className="lend_hendupr">
                                                        <div className="instant_upr2 999999">
                                                            <div className="rel_block ht-200">
                                                                <div className="program_entry_loader">
                                                                    <LottieLoader />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : ""}
                                                    {/* div for direct user and contest not entered with user entry criteria*/}
                                                    {program.direct_member == 1 && program.is_still_locked == false && program.cst_consent_required && !program.phone_number_required && !program.upload_video_required && !program.policyadvisor_consent_required && !program.epilogue_question_required && !program.policy_terms_required && !program.add_relative_required && !program.birthdate_required && !program.baby_registry_required && !program.guardtree_text_required &&
                                                        <div className="lend_hendupr instant_upr2Upr">
                                                            <div className="instant_upr2 instant_upr2Upr 66">

                                                                <div className="rel_block ht-200">

                                                                    <div className="program_entry_loader">
                                                                        <LottieLoader />
                                                                    </div>
                                                                </div>
                                                                {/* {this.state.show_cst_consent &&
                                                        <div className="instant_hendupr2">
                                                            <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                                <EnterCSTConsent props={this.props} program_id={program.program_id} Back={this.handleCSTBack.bind(this)} onSubmit={this.handleClickSubmit.bind(this, program)} contestEntryCST={(consent) => this.contestEntryCST(program, consent)} />
                                                            </div></div>
                                                    } */}

                                                                {!this.state.animationStart && <div className='instant_redem2Main instant_redem2MainAdd'>
                                                                    <div className="instant_redem2 instant_redem2Upr">
                                                                        {!showAll && <div className="declaration_instant2 declaration_short">
                                                                            <p> {(ReactHtmlParser((this.renderShortDescription(program.short_description).substring(0, 120) + "...")))}<span className="read-more-cst" onClick={this.showMore}> Read more</span></p>

                                                                        </div>}
                                                                        {showAll && <div className="declaration_instant2 declaration_short">
                                                                            <p>  {ReactHtmlParser(this.renderShortDescription(program.short_description))}<span className="read-more-cst" onClick={this.showLess}> Read less</span></p>

                                                                        </div>}
                                                                    </div>
                                                                    {this.state.show_cst_consent && <div className="instant_redem2 instant_redem2Inr">
                                                                        <div className="declaration_instant2 declaration_short">
                                                                            {ReactHtmlParser(program.cst_consent_message_without_phone)}
                                                                        </div>
                                                                    </div>}
                                                                </div>}

                                                            </div>

                                                            {this.state.show_winner_data && this.state.show_winner_data.length > 0 &&
                                                                <div className="show_winer2" onClick={() => { this.allwinners(program) }}>
                                                                    <div className="show_winer_left2"><img src={url.family_one_image_url + 'Group4242.png'} alt="" title="" /><span>Show Winners</span></div>
                                                                    <div className="show_winer_rght2"><a>{this.state.show_winner_data.length}</a><img src={url.family_one_image_url + 'Vectorqw.png'} alt="" title="" /> </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    {/* div for direct user and contest not entered with user entry criteria*/}
                                                    {program.direct_member == 1 && program.is_still_locked == false && !program.policyadvisor_consent_required && !program.epilogue_question_required && program.policy_terms_required && !program.cst_consent_required && !program.phone_number_required && !program.add_relative_required && !program.birthdate_required && !program.baby_registry_required && !program.guardtree_text_required &&
                                                        <div className="lend_hendupr">
                                                            <div className="instant_upr2">
                                                                <div className="rel_block ht-200">
                                                                    <div className="program_entry_loader">
                                                                        <LottieLoader />
                                                                    </div>
                                                                </div>
                                                                {this.state.show_policy_terms &&
                                                                    <div className="instant_hendupr2">
                                                                        <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                                            <EnterPolicyTerms props={this.props} program_id={program.program_id} Back={this.handlePolicyTermsBack.bind(this)} onSubmit={this.handleClickSubmit.bind(this, program)} />
                                                                        </div></div>
                                                                }
                                                                <div className="instant_redem2">
                                                                    <div className="declaration_instant2 declaration_short">
                                                                        {ReactHtmlParser(program.short_description)}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {this.state.show_winner_data && this.state.show_winner_data.length > 0 &&
                                                                <div className="show_winer2" onClick={() => { this.allwinners(program) }}>
                                                                    <div className="show_winer_left2"><img src={url.family_one_image_url + 'Group4242.png'} alt="" title="" /><span>Show Winners</span></div>
                                                                    <div className="show_winer_rght2"><a>{this.state.show_winner_data.length}</a><img src={url.family_one_image_url + 'Vectorqw.png'} alt="" title="" /> </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    {program.direct_member == 1 && program.is_still_locked == false && !program.phone_number_required && !program.cst_consent_required && !program.policyadvisor_consent_required && !program.epilogue_question_required && !program.policy_terms_required && program.add_relative_required && !program.birthdate_required && !program.baby_registry_required && !program.guardtree_text_required &&
                                                        <div className="lend_hendupr">
                                                            <div className="instant_upr2">
                                                                <div className="rel_block ht-200">
                                                                    <div className="program_entry_loader">
                                                                        <LottieLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="likeDisMain3">

                                                                    <div className="likeDisMain2">

                                                                        <div className="likeDisMain">

                                                                            <div className="instant_hendupr2">
                                                                                <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                                                    <div className="three_hearts_div">
                                                                                        <img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" />
                                                                                        <img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" />
                                                                                        <img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" />
                                                                                    </div>
                                                                                    <h3>Friends & Family Giveaway</h3>
                                                                                    <p>Invite 3 or more friends to win with</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="likeDis">
                                                                                <div className="likeDisFrst"> <a><img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" /></a>
                                                                                    <span>1</span>
                                                                                </div>
                                                                                <div className="likeDisMid">
                                                                                    {this.state.relations && this.state.relations.length < 1 ?
                                                                                        <label>Invite a Friend</label> :
                                                                                        <label>{this.state.relations[0].given_name + " " + this.state.relations[0].family_name}</label>
                                                                                    }
                                                                                </div>
                                                                                {this.state.relations && this.state.relations.length < 1 ?
                                                                                    <div onClick={() => { this.handleGiveawayAddRelation(program) }} className="likeDisLst likeDisLstAdd">
                                                                                        <a>+</a>
                                                                                    </div>
                                                                                    : <div className="likeDisLst">
                                                                                        <a onClick={() => { this.handleColonClick(this.state.relations[0], 0, 'no') }}>:</a>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="likeDis" style={this.state.relations && this.state.relations.length > 0 ? {} : { opacity: "70%" }}>
                                                                            <div className="likeDisFrst"> <a><img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" /></a>
                                                                                <span>2</span>
                                                                            </div>
                                                                            <div className="likeDisMid">
                                                                                {this.state.relations && this.state.relations.length < 2 ?
                                                                                    <label>Invite a Friend</label> :
                                                                                    <label>{this.state.relations[1].given_name + " " + this.state.relations[1].family_name}</label>
                                                                                }
                                                                            </div>
                                                                            {this.state.relations && this.state.relations.length == 1 &&
                                                                                <div onClick={() => { this.handleGiveawayAddRelation(program) }} className="likeDisLst likeDisLstAdd">
                                                                                    <a>+</a>
                                                                                </div>
                                                                            }
                                                                            {this.state.relations && this.state.relations.length == 0 &&
                                                                                <div className="likeDisLst">
                                                                                </div>
                                                                            }
                                                                            {this.state.relations && this.state.relations.length > 1 &&
                                                                                <div className="likeDisLst">
                                                                                    <a onClick={() => { this.handleColonClick(this.state.relations[1], 1, 'no') }}>:</a>
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                    </div>

                                                                    <div className="likeDis" style={this.state.relations && this.state.relations.length > 1 ? {} : { opacity: "70%" }}>
                                                                        <div className="likeDisFrst"> <a><img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" /></a>
                                                                            <span>3</span>
                                                                        </div>
                                                                        <div className="likeDisMid">
                                                                            {this.state.relations && this.state.relations.length < 3 ?
                                                                                <label>Invite a Friend</label> :
                                                                                <label>{this.state.relations[2].given_name + " " + this.state.relations[2].family_name}</label>
                                                                            }
                                                                        </div>
                                                                        {this.state.relations && this.state.relations.length == 2 &&
                                                                            <div onClick={() => { this.handleGiveawayAddRelation(program) }} className="likeDisLst likeDisLstAdd">
                                                                                <a>+</a>
                                                                            </div>
                                                                        }

                                                                        {this.state.relations && this.state.relations.length > 2 &&
                                                                            <div className="likeDisLst">
                                                                                <a onClick={() => { this.handleColonClick(this.state.relations[2], 2, 'no') }}>:</a>
                                                                            </div>
                                                                        }
                                                                        {this.state.relations && this.state.relations.length < 2 &&
                                                                            <div className="likeDisLst">
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </div>
                                                                {/* {this.state.show_relative &&
                                                        <div className="instant_hendupr2">
                                                            <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                                <div className="three_hearts_div">
                                                                    <img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" />
                                                                    <img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" />
                                                                    <img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" />
                                                                </div>
                                                                <h3>Friends & Family Giveaway</h3>
                                                                <p>Invite 3 or more friends to win with</p>
                                                            </div></div>
                                                    }
                                                    <div className="instant_redem2">
                                                        <div className="declaration_instant2 declaration_short">
                                                            <img src={url.family_one_image_url + 'Vector11.png'} alt="" title="" />
                                                            <div>
                                                                <p>1</p>
                                                                <p>Name Last Name</p>
                                                                <div className="all_children_close">
                                                                    <a
                                                                    >:</a>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                                {/* </div> */}
                                                            </div>

                                                            {this.state.show_winner_data && this.state.show_winner_data.length > 0 &&
                                                                <div className="show_winer2" onClick={() => { this.allwinners(program) }}>
                                                                    <div className="show_winer_left2"><img src={url.family_one_image_url + 'Group4242.png'} alt="" title="" /><span>Show Winners</span></div>
                                                                    <div className="show_winer_rght2"><a>{this.state.show_winner_data.length}</a><img src={url.family_one_image_url + 'Vectorqw.png'} alt="" title="" /> </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    {/* div for direct user and contest not entered with birthdate entry criteria*/}
                                                    {program.direct_member == 1 && program.is_still_locked == false && program.birthdate_required && !program.cst_consent_required && !program.phone_number_required && !program.policyadvisor_consent_required && !program.epilogue_question_required && !program.policy_terms_required && !program.add_relative_required && !program.baby_registry_required && !program.guardtree_text_required &&
                                                        <div className="lend_hendupr">
                                                            <div className="instant_upr2">
                                                                <div className="rel_block ht-200">
                                                                    <div className="program_entry_loader">
                                                                        <LottieLoader />
                                                                    </div>
                                                                </div>
                                                                {this.state.show_dob &&
                                                                    <div className="instant_hendupr2">
                                                                        <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                                            <Enterdob props={this.props} DOBBack={this.handleDOBBack.bind(this)} onSubmit={this.handleClickSubmit.bind(this, program)} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="instant_redem2">
                                                                    <div className="declaration_instant2 declaration_short">
                                                                        {ReactHtmlParser(program.short_description)}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {this.state.show_winner_data && this.state.show_winner_data.length > 0 &&
                                                                <div className="show_winer2" onClick={() => { this.allwinners(program) }}>
                                                                    <div className="show_winer_left2"><img src={url.family_one_image_url + 'Group4242.png'} alt="" title="" /><span>Show Winners</span></div>
                                                                    <div className="show_winer_rght2"><a>{this.state.show_winner_data.length}</a><img src={url.family_one_image_url + 'Vectorqw.png'} alt="" title="" /> </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    {/* div for direct user and contest not entered with baby_registry criteria*/}
                                                    {program.direct_member == 1 && !program.birthdate_required && !program.phone_number_required && !program.cst_consent_required && !program.policyadvisor_consent_required && !program.epilogue_question_required && !program.policy_terms_required && !program.add_relative_required && program.baby_registry_required && !program.guardtree_text_required &&
                                                        <div className="">
                                                            <div className="">
                                                                <div className="rel_block ht-200">
                                                                    <div className="program_entry_loader">
                                                                        <LottieLoader />
                                                                    </div>
                                                                </div>
                                                                {this.state.show_baby_registry &&
                                                                    <div>
                                                                        <div className='babyRegUprMain'>
                                                                            <BabyRegistry props={this.props} BabyRegistryBack={this.handleBabyRegistryBack.bind(this)} onSubmit={this.handleClickSubmit.bind(this, program)} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="instant_redem2 instantRedmBabyReg">
                                                                    <div className="declaration_instant2 declaration_short">
                                                                        {ReactHtmlParser(program.short_description)}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {this.state.show_winner_data && this.state.show_winner_data.length > 0 &&
                                                                <div className="show_winer2" onClick={() => { this.allwinners(program) }}>
                                                                    <div className="show_winer_left2"><img src={url.family_one_image_url + 'Group4242.png'} alt="" title="" /><span>Show Winners</span></div>
                                                                    <div className="show_winer_rght2"><a>{this.state.show_winner_data.length}</a><img src={url.family_one_image_url + 'Vectorqw.png'} alt="" title="" /> </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    {/* div for direct user and contest not entered with user entry criteria*/}
                                                    {program.direct_member == 1 && program.is_still_locked == false && !program.phone_number_required && !program.cst_consent_required && !program.policyadvisor_consent_required && !program.epilogue_question_required && !program.policy_terms_required && !program.add_relative_required && !program.birthdate_required && !program.baby_registry_required && program.guardtree_text_required &&
                                                        <div className="lend_hendupr">
                                                            <div className="instant_upr2">
                                                                <div className="rel_block ht-200">
                                                                    <div className="program_entry_loader">
                                                                        <LottieLoader />
                                                                    </div>
                                                                </div>
                                                                {this.state.show_guardTreeText &&
                                                                    <div className="instant_hendupr2">
                                                                        <div className="instant_hend2" style={{ padding: "28px 22px" }}>
                                                                            <EnterGuardTreeText props={this.props} program_id={program.program_id} Back={this.handleGuardTreeTextBack.bind(this)} onSubmit={this.handleClickSubmit.bind(this, program)} />
                                                                        </div></div>
                                                                }
                                                                <div className="instant_redem2">
                                                                    <div className="declaration_instant2 declaration_short">
                                                                        {ReactHtmlParser(program.short_description)}
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            {this.state.show_winner_data && this.state.show_winner_data.length > 0 &&
                                                                <div className="show_winer2" onClick={() => { this.allwinners(program) }}>
                                                                    <div className="show_winer_left2"><img src={url.family_one_image_url + 'Group4242.png'} alt="" title="" /><span>Show Winners</span></div>
                                                                    <div className="show_winer_rght2"><a>{this.state.show_winner_data.length}</a><img src={url.family_one_image_url + 'Vectorqw.png'} alt="" title="" /> </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    {/* div for direct user and locked contest */}
                                                    {program.program_type == "contest" && program.direct_member == 1 && program.has_enter == 0 && program?.is_lock_program == 1 && program.is_still_locked == true &&
                                                        <div className="lend_hendupr">
                                                            <div>

                                                                {/* <div className="lend_baby">
                                                                    <div>
                                                                        <img style={{ width: "30px" }} src={url.family_one_image_url + 'exclusive_giveaway.png'} alt="" title="" />
                                                                        </div>
                                                                    <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.exclusive_contest.en : "Bonus Giveaway"}</h3>
                                                                    <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.enter_all_others_contest.en : "Enter all others giveaways to unlock"}</p>
                                                                </div> */}

                                                                <div className='bonuseInfo'>
                                                                    <div><img className="" src={require('../../assets/images/BonuseStar.svg')} alt="" title="" /></div>
                                                                    <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.exclusive_contest.en : "Bonus Giveaway"}</h3>
                                                                    <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.enter_all_others_contest.en : "Enter all others giveaways to unlock"}</p>
                                                                </div>

                                                            </div>

                                                            <div className="enterGiveUp">
                                                                <div><p>Enter <span>{program.locked_number} more</span> {dynamic_content.static_text.program_detail && dynamic_content.static_text.program_detail.enter_giveaways_to_unlock.en ? dynamic_content.static_text.program_detail.enter_giveaways_to_unlock.en : "giveaways to unlock"}</p></div>
                                                                {/* <div>
                                                        <button className="lets_doit " onClick={() => { this.handleLetsdoit(program) }}>
                                                            {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.let_do_it.en : "Let's Do It!"}
                                                        </button>
                                                    </div> */}

                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <div className="instant_redem2">
                                                                        <div id={"clicked_giveaway_" + program.program_id}>
                                                                            <Button className={"contest_enter_fixed "} id={program.program_id} size="medium" color="primary" onClick={() => { this.handleLetsdoit(program) }}>
                                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.let_do_it.en : "Let's Do It!"}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    }
                                                    {/* div for direct user and locked contest and Add left right arrows */}
                                                    {(program.program_type == "contest" || program.program_type == "survey") && program.direct_member == 1 && program.has_enter == 0 && program?.is_lock_program == 1 && program.is_still_locked == true &&
                                                        <div>
                                                            {this.state.show_arrows == true &&
                                                                <div onClick={() => { this.handleClickArrows(program, "backward") }} className="arrow_left ee" >
                                                                    <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                </div>
                                                            }

                                                            {this.state.show_arrows == true &&
                                                                <div onClick={() => { this.handleClickArrows(program, "forward") }} className="arrow_rght">
                                                                    <a ><img src={require('../../assets/images/AddArrow.svg')} alt="" title="" /></a>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {/* End of Add left right arrows */}

                                                    {/* div for indirect users */}
                                                    {program.direct_member == 0 &&
                                                        <div className="lend_hendupr detailsSocial_upr">
                                                            <div className="">

                                                                <div className="lend_hend">
                                                                    <div><img style={{ width: "30px" }} src={url.family_one_image_url + 'lend_a_hand.png'} alt="" title="" /></div>
                                                                    <h3>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.lend_hand.en : "Lend a Hand"}</h3>
                                                                    <p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.share_freebie.en : "Share freebie to friends and family"}</p>
                                                                </div>

                                                            </div>

                                                            <div className="detailsSocial_share">
                                                                <div><p>{dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.social_share.en : "Social share"}</p></div>
                                                                <div>
                                                                    <a href={program.twitter_link} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'Group166.png'} alt="" title="" className="social_nw" /></a>
                                                                    <a href={program.facebook_link} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'Group165.png'} alt="" title="" className="social_nw" /></a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    }

                                                    {/* Load More section */}
                                                    {
                                                        program.direct_member == 0 && !this.state.load_more_loader &&
                                                        <div className="load_more want_more_div" style={{ marginTop: "9%" }}>
                                                            {/* <Button size="medium" color="primary" className="next_offer" onClick={() => { this.handleClickArrows(program, "forward") }}>
                                                                    {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.next_offer.en : "Next Offer"}
                                                                </Button> */}
                                                            <Button className="explore_div" size="medium" color="primary" onClick={() => { this.handleLoadMore(program) }} >
                                                                {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.want_more.en : "Quick View"}
                                                            </Button>
                                                            {program && program?.show_next_button &&
                                                                <div className="instant_redem2">
                                                                    <Button className="next_offer_div" size="medium" color="primary" onClick={() => { this.handleClickArrows(program, "forward") }} >
                                                                        {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.next_offer.en : "Next"}
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        program.direct_member == 0 && this.state.load_more_loader &&
                                                        <div className="loadmore_loading" >
                                                            <LottieLoader style={{ color: "red" }} />
                                                        </div>
                                                    }
                                                    {/* End of Load More section */}

                                                </div >

                                                {/* End of  three in One section */}

                                            </div >

                                        ))}
                                        <div id="kff_fresh_entry"></div>

                                        {this.state.show_popup &&
                                            <PolicyPopup Back={this.handleClosePopup.bind(this)} program={this.state.program_array[0]} />
                                        }
                                        {/* New Model  */}
                                        <Modal isOpen={this.state.modal} fade={false} toggle={this.togglePoints} className={this.props.className + " rspmodel"}>
                                            <ModalBody className="rspBody">
                                                <div className="rsp_model" style={{ marginTop: "5px" }}>
                                                    {/* <div className="closepopup_rel"><ModalHeader>{closeAddchild}</ModalHeader></div> */}
                                                    <img src={require('../../assets/images/closeChild.svg')} alt="" title="" className="clearcontest_img popup_close_button" onClick={() => { this.toggle() }} />
                                                    <div className="rsp_modelCntnt">
                                                        {this.state.load_more_programs && this.state.load_more_programs.length > 0 && this.state.load_more_programs.map((element, index) => (
                                                            <div key={index} className="rsp_content" onClick={() => { this.handleClickLoad(element) }}>
                                                                <div className="rsp_left">
                                                                    <img src={(element.program_short_image ? url.cloudflare_image_path + element.program_short_image : element.show_alternative_detail && element.show_alternative_detail == "true" ? url.cloudflare_image_path + element.alt_program_image : url.cloudflare_image_path + element.program_image) + url.varient} alt="" title="" />
                                                                </div>
                                                                <div className="rsp_rght">
                                                                    <label>{element.show_alternative_detail && element.show_alternative_detail == "true" ? ReactHtmlParser(element.alt_program_title) : ReactHtmlParser(element.program_title)}</label>
                                                                    {/* <p>{element.show_alternative_detail && element.show_alternative_detail == "true" ? ReactHtmlParser(element.alt_program_subtitle) : ReactHtmlParser(element.program_subtitle)}</p> */}
                                                                    <Button className='rspView' size="medium" color="primary">View</Button>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        {this.state.load_more == true &&
                                                            <div className="findMoreBtn">
                                                                <Button size="medium" onClick={() => { this.handleFindMore() }} color="primary">Find more</Button>
                                                            </div>
                                                        }

                                                        {this.state.load_notify_me == true && !this.state.program_notifyme_loader && !this.state.program_notified &&
                                                            <div>
                                                                {/* notify me  */}
                                                                <div className="rspNot_content">
                                                                    <div className="rspNot_left">
                                                                        <img src={require('../../assets/images/sendNofify.svg')} alt="" title="" />
                                                                    </div>
                                                                    <div className="rspNot_rght">
                                                                        <label>Send me a notification </label>
                                                                        <p>once more offers are available</p>
                                                                        <Button size="medium" color="primary" onClick={() => { this.handleProgramNotify() }}>
                                                                            {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.notify_me.en : "Notify Me"}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                {/* End of notify me  */}
                                                            </div>
                                                        }
                                                        {/* notify me Cancel  */}
                                                        {this.state.load_notify_me == true && !this.state.program_notifyme_loader && this.state.program_notified &&
                                                            // <div className="rsp_notify">
                                                            <div className="rspCncl_content">
                                                                <div className="rspCncl_left">
                                                                    <img src={require('../../assets/images/notify-me-request-icon.png')} alt="" title="" />
                                                                    <img src={require('../../assets/images/notify-me-request-icon-check.png')} alt="" title="" className="rspCnclImg" />
                                                                </div>
                                                                <div className="rspCncl_rght">
                                                                    <div>{dynamic_content.static_text.program_detail ? ReactHtmlParser(dynamic_content.static_text.program_detail.notification_will_sent.en) : "A notification will be sent to:"}</div>
                                                                    <Button size="medium" color="primary" onClick={() => { this.handleCancelProgramNotify() }}>
                                                                        {dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.cancel_request.en : "Cancel request"}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            // </div>
                                                        }
                                                        {/* End of notify me Cancel  */}
                                                        {this.state.load_notify_me == true && this.state.program_notifyme_loader &&
                                                            <div className="rsp_notify">
                                                                <LottieLoader style={{ color: "red" }} />
                                                            </div>
                                                        }
                                                    </div>

                                                </div>
                                            </ModalBody>
                                        </Modal>
                                        {/* End of New Model  */}
                                        {/* Relation model */}
                                        <div className="open_mail_model">
                                            <Modal isOpen={this.state.edit_delete_modal} toggle={this.toggleEditDelete} className={this.props.className + " custom_Addchildmodel"} >
                                                <ModalBody className="custom_editchildBody">
                                                    <div className="editchild_content" style={{ postion: "relative" }}>
                                                        <img src={require('../../assets/images/closeChild.svg')} onClick={() => { this.closeToggle(this, 'edit_delete_modal') }} alt="" title="" className="clearcontest_img popup_close_button" />
                                                        {this.state.relation_status != "approved_relations" &&
                                                            <Button
                                                                size="medium"
                                                                style={{ marginTop: "20px" }}
                                                                color="primary"
                                                                onClick={() => { this.handleToggleClick('edit', this.state.relation_status) }}
                                                            >
                                                                {this.state.relation_status == "pending_invitation" ? "Accept" : "Edit"}
                                                            </Button>
                                                        }
                                                        <Button
                                                            size="medium"
                                                            color="primary"
                                                            className="deleteButton closeModel childdel_btn"
                                                            onClick={() => { this.handleToggleClick('delete', this.state.relation_status) }}
                                                        >
                                                            {this.state.relation_status == "pending_invitation" ? "Decline" : "Delete"}
                                                        </Button>
                                                    </div>
                                                </ModalBody>
                                            </Modal>
                                        </div>
                                        {/* End of Relation model */}
                                        <div className="open_mail_model">
                                            <Modal isOpen={delete_confirm_modal} toggle={this.toggleDeleteConfirm} className={this.props.className + " skilltestmodel"}>
                                                <ModalHeader style={{ borderBottom: "none" }}>
                                                    <img src={require('../../assets/images/closeChild.svg')} onClick={() => { this.closeToggle(this, 'delete_confirm_modal') }} alt="" title="" className="clearcontest_img confirm_close_button" />
                                                </ModalHeader>
                                                <ModalBody className="skilltestBody">
                                                    <div className="support_model" style={{ postion: "relative" }}>
                                                        <div className="skill_query">
                                                            <p style={{ fontSize: "20px" }}>{this.state.modal_message}</p>
                                                        </div>
                                                        <div className="skilltest_content">
                                                            <Button size="medium" color="primary" onClick={() => { this.handleYes() }}>
                                                                Yes
                                                            </Button>
                                                            <Button size="medium" color="primary" onClick={() => { this.closeToggle(this, 'delete_confirm_modal') }}>
                                                                No
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </ModalBody>
                                            </Modal>
                                        </div>
                                        <Modal isOpen={this.state.modalReview} className="discardVdoUpr">
                                            <ModalBody className="revwPending">
                                                <a onClick={() => this.setState({ modalReview: false })}>
                                                    <img src={url.family_one_image_url + 'Group5.png'} alt="" title="" className="closeRvew" /></a>
                                                <div className="revwPendingTxt">
                                                    <div><label>Review is Pending</label></div>
                                                    <div><img src={require('../../assets/images/preview.svg')} alt="" title="" /></div>
                                                    <p>Your video has submitted successfully, but pending for reivew when admin approved it.
                                                        We’ll send you an email regarding the rewards.</p>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                    </div >
                                </div>
                            }
                        </div >
                        {!this.state.isVideoRecordOpen &&
                            <Footer onClick={() => { this.handleMenu() }} props={this.props} />
                        }
                        {this.state.show_t_and_c &&
                            <TermsCondition Back={this.handleCloseTerms.bind(this)} program={program_detail} />
                        }
                    </div>

                }
            </div>
        );
    }
}

const mapStateToProps = ({ program }) => {
    const { program_detail, program_list, all_program } = program;
    return { program_detail, program_list, all_program }
}

const mapDispatchToProps = dispatch => {
    return {
        handleProgramDetail: (program) => dispatch({ type: 'ADD_PROGRAM_DETAIL', payload: program }),
        handleprogramList: (program) => dispatch({ type: 'ADD_PROGRAM_LIST', payload: program }),
        handleAllprogramList: (all_program) => dispatch({ type: 'ADD_ALL_PROGRAM_LIST', payload: all_program })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Sharegaindetail);

