import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import functions from '../../assets/functions/common_functions';
import session from '../../assets/functions/session';
import cookie from 'react-cookies';
import axios from "axios";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
// import { Offline, Online } from "react-detect-offline";
// import OfflineComponent from '../boarding/index';
import LottieLoader from '../LottieLoader';
import url from '../../assets/url';
import InvalidEmail from './invalidEmailNotice';

export default class Signup extends React.Component {
  constructor(props) {
    super(props)
    this.scrollToTopRef = React.createRef();
    // if (this.props.history.action == "POP") {
    //   this.props.history.goForward()
    // }
    let given_name = localStorage.getItem("given_name") ? localStorage.getItem("given_name") : "";
    let family_name = localStorage.getItem("family_name") ? localStorage.getItem("family_name") : "";
    let email = localStorage.getItem("email") ? localStorage.getItem("email") : "";
    let picture = localStorage.getItem("picture") ? localStorage.getItem("picture") : "";
    this.state = {
      given_name: given_name ? given_name : "",
      given_name_complete: false,
      family_name_complete: false,
      family_name: family_name ? family_name : "",
      email: email ? email : "",
      alternative_email: "",
      alternative_family_name: "",
      alternative_given_name: "",
      provider: "familyone",
      facebook_id: "",
      google_id: "",
      loader: false,
      migrate_note: false,
      picture: picture ? picture : "",
      temp_email: email,
      enter_email: localStorage.getItem("enter_email") == "false" ? false : true,
      modal: false,
      password: '',
      dynamic_content: "",
      user_data: {
        email: '',
        name: ''
      },
      isInvalidEmail: false,
      isUIReady: false
    }
  }
  
  scrollToTop = () => {
    if (this.scrollToTopRef.current) {
      this.scrollToTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.state.isUIReady && !prevState.isUIReady) || (this.state.dynamic_content !== "" && prevState.dynamic_content === "")) {
      // Perform scroll to top action
      this.scrollToTop();
    }
  }
  componentDidMount = () => {
    this.setState({ isUIReady: true });

  }



  componentWillMount = () => {
    let current_state = localStorage.getItem("current_state");
    let current_auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : "";
    current_auto_id = localStorage.getItem("auto_id") ? localStorage.getItem("auto_id") : "";
    if (current_state !== 'signup' && current_auto_id && current_auto_id != "") {
      functions.getLandingPage(this);
    }

    let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
    if (dynamic_content != "") {
      this.setState({ dynamic_content: dynamic_content })
    }
    let session_id = cookie.load("session_id") ? cookie.load("session_id") : "null"
    if (session_id != "null") {
      session.updatePageSession(session_id, "signup")
    }
    let user_action = "User got error on signup/index file handleSignup function";
    let image = 'signup_page_landing.png'
    functions.logUserActivity("User land on Signup page", image)
    let self = this;
    if (cookie.load("accept_auto_id") && typeof cookie.load("accept_auto_id") !== undefined && cookie.load("accept_auto_id") !== "undefined" && cookie.load("accept_auto_id").trim() !== "") {
      this.setState({ loader: true })
      functions.getDetailByRelativeID(cookie.load("accept_auto_id"), function (response_json) {
        let response = JSON.parse(response_json);
        if (response.catch_error) {
          self.setState({ loader: false })
          let error_body = response.message
          functions.logUserError(user_action, error_body);
          self.setState({ show_error_message: response.message });
        }
        else if (!response.catch_error) {
          self.setState({ loader: false })
          if (response.data.error === 1) {
            let error_body = response.data.message
            functions.logUserError(user_action, error_body);
            self.setState({ show_error_message: response.data.message });
          } else if (response.data.statusCode === 400) {
            let error_body = response.data.message
            functions.logUserError(user_action, error_body);
            self.setState({ show_error_message: response.data.message });
          }
          else if (response.data && response.data.length > 0) {
            self.setState({ loader: false })
            self.setState({
              given_name: response.data[0].first_name,
              family_name: response.data[0].last_name,
              email: response.data[0].email,
              alternative_email: response.data[0].email,
              given_name_complete: true,
              family_name_complete: true,
              picture: "",
              enter_email: false,
            })
          }
        }
      })
    }
    else if (cookie.load("provider") && (cookie.load("provider") == "facebook" || cookie.load("provider") == "google" || cookie.load("provider") == "signup_or_login" === "twitter")) {

      self.setState({
        given_name: localStorage.getItem("given_name"),
        given_name_complete: true,
        family_name_complete: true,
        family_name: localStorage.getItem("family_name"),
        email: localStorage.getItem("email"),
        alternative_email: localStorage.getItem("email"),
        alternative_family_name: localStorage.getItem("family_name"),
        alternative_given_name: localStorage.getItem("given_name"),
        provider: cookie.load("provider"),
        picture: localStorage.getItem("picture"),
        enter_email: false,
      });
      if (cookie.load("provider") == "facebook") {
        self.setState({
          facebook_id: cookie.load("facebook_id") ? cookie.load("facebook_id") : ""
        })
      }
      else if (cookie.load("provider") == "google") {
        self.setState({
          google_id: cookie.load("google_id") ? cookie.load("google_id") : ""
        })
      }
      else if (cookie.load("provider") == "twitter") {
        self.setState({
          twitter: cookie.load("twitter") ? cookie.load("twitter") : ""
        })
      }
      self.setState({ loader: false });
      cookie.remove("provider", { path: "/" })
      cookie.remove("facebook_id", { path: "/" })
      cookie.remove("twitter_id", { path: "/" })
      cookie.remove("google_id", { path: "/" })
    }

  }

  handleSignup = () => {
    let user_action = "User got error on signup/index file handleSignup function";
    let self = this;
    this.setState({ loader: true })
    this.setState({ user_data: { email: this.state.email, name: this.state.given_name + " " + this.state.family_name } })
    let email = this.state.email
    let username = this.state.email
    let password = this.state.password ? this.state.password : this.makeid(8)
    let name = this.state.given_name + " " + this.state.family_name
    let given_name = this.state.given_name
    let family_name = this.state.family_name
    let picture = this.state.picture ? this.state.picture : "no_image"
    let session_id = cookie.load("session_id") ? cookie.load("session_id") : ""
    localStorage.setItem("email", email)
    localStorage.setItem("name", name)
    localStorage.setItem("email_verified", false)
    let finaljson = {
      email
    }
    functions.ValidateEmailForBounce(this, finaljson, function (response_json1) {
      let response1 = JSON.parse(response_json1);
      // console.log("response1", response1)
      if (response1 && response1.data && response1.data.success == 0) {
        self.setState({ loader: false })
        let error_body = "Your email is not valid"
        functions.logUserError(user_action, error_body);
        if (response1.data.data.isBlockedDomain) {
          if (response1.data.data.did_you_mean_email && response1.data.data.did_you_mean_email != "") {
            let did_you_mean = email.split('@')[0] + response1.data.data.did_you_mean_email
            self.setState({ show_error_message: "You typed: " + email + ".<br/> Did you mean " + did_you_mean })
          } else {
            self.toggleInvalidEmailPopup();
          }
        } else {
          self.setState({ show_error_message: "Your email is not valid" })
        }
      } else {
        let valid = response1?.data?.data?.response?.result ?? null;
        // console.log("valid", valid)
        let email_verified = valid == "catchall" || valid == "valid" ? "true" : "false";
        let signup_json = {
          username: username,
          password: password,
          session_id: session_id,
          provider: self.state.provider,
          email_changed: (self.state.alternative_email != '' && self.state.alternative_email.trim() != self.state.email) ? self.state.alternative_email.trim() : null,
          "attributes": [
            {
              "Name": "email",
              "Value": email
            },
            {
              "Name": "name",
              "Value": name
            },
            {
              "Name": "given_name",
              "Value": given_name
            },
            {
              "Name": "family_name",
              "Value": family_name
            },
            {
              "Name": "picture",
              "Value": picture
            }
          ]
        }
        functions.adminCreateUser(this, signup_json, function (response_json) {
          let response = JSON.parse(response_json);
          // console.log("response", response)
          if (response.data && response?.data?.code == "UsernameExistsException") {
            let image = 'user_already_exist_error_signup_page.png'
            functions.logUserActivity("User already exist error on signup", image)
            self.setState({ loader: false })
            self.setState({ show_error_message: url.dynamic_content?.error_message?.user_exist?.en });
          }
          else if (response.data && response?.data?.code == "NotAuthorizedException") {
            self.setState({ loader: false })
            let image = 'user_disabled_error_signup_page.png'
            functions.logUserActivity("User is disabled error on signup function", image)
            self.setState({ show_error_message: url.dynamic_content?.error_message?.account_disabled?.en });
          }
          else if (response && response.data && response.data.success == 1) {
            if (response?.data?.message == "User Already Exist" && response?.data?.user_exist_in_old_system == 1) {
              self.migrateUser(email)
            }
            else if (response?.data?.message == "User Already Exist") {
              self.setState({ loader: false })
              let image = 'user_already_exist_error_signup_page.png'
              functions.logUserActivity("User already exist error on signup", image)
              self.SendLoginLink(email)
              self.setState({ show_error_message: url.dynamic_content?.error_message?.user_exist?.en });
            } else {
              let send_active_mail = true
              if (response.data.message == "User Already Exist In BBBX") {
                // console.log("here", response.data)
                if (response.data.userprofiledata.UserStatus && response.data.userprofiledata.UserStatus == "CONFIRMED") {

                  send_active_mail = false
                }
              }

              self.setState({ loader: false })
              let auto_id = response.data.userprofiledata ? response.data.userprofiledata.UserAttributes.auto_id : ""
              let username = response.data.userprofiledata ? response.data.userprofiledata.Username : "";
              cookie.save("auto_id", auto_id, { path: "/" })
              localStorage.setItem("auto_id", auto_id)
              cookie.save("username", username, { path: "/" })
              localStorage.setItem("email", username)
              let email_changed = 0;
              // console.log("self.state.alternative_email", self.state.alternative_email)
              if (self.state.alternative_email != '') {
                // console.log("self.state.email", self.state.email)
                if (self.state.alternative_email.trim() != self.state.email) {
                  email_changed = 1;
                }
              }
              email_changed = email_changed;
              let alternative_email = self.state.alternative_email == email ? " " : self.state.alternative_email
              cookie.save("email_changed", email_changed, { path: "/" });
              cookie.save("alternative_email", self.state.alternative_email, { path: "/" });
              let double_optin = "false"
              let neverbounce_email_status = valid
              let send_pending_mails = 1;
              let plus_sign = 'hidden';
              let userdata = [];
              let dynamo_userdata = { 'email_changed': email_changed, 'alternative_email': alternative_email, 'email_verified': email_verified, 'send_pending_mails': send_pending_mails, 'plus_sign': plus_sign, double_optin: double_optin, neverbounce_email_status: neverbounce_email_status };
              if (self.state.provider != 'familyone') {
                if (send_active_mail == true) {
                  dynamo_userdata.email_verified = "true";
                  dynamo_userdata.double_optin = "true"
                  dynamo_userdata.neverbounce_email_status = "true"
                }
                dynamo_userdata.send_pending_mails = 1;
              }
              if (self.state.provider == 'facebook') {
                email_verified = "true"
                dynamo_userdata.facebook_id = self.state.facebook_id ? self.state.facebook_id : " "
                if (send_active_mail == true) {
                  dynamo_userdata.email_verified = email_verified;
                  dynamo_userdata.double_optin = "true"
                  dynamo_userdata.neverbounce_email_status = "true"
                  if (email_changed) {
                    dynamo_userdata.alternative_email_status = "true"
                  }
                }
              } else if (self.state.provider == 'google') {
                email_verified = "true"
                dynamo_userdata.google_id = self.state.google_id ? self.state.google_id : " "
                if (send_active_mail == true) {
                  dynamo_userdata.email_verified = email_verified;
                  dynamo_userdata.double_optin = "true"
                  dynamo_userdata.neverbounce_email_status = "true"
                  if (email_changed) {
                    dynamo_userdata.alternative_email_status = "true"
                  }
                }
              }
              dynamo_userdata.sync_log = false
              dynamo_userdata.bbbx_inactive = "false"
              localStorage.setItem("email", username)
              localStorage.setItem("name", name)
              localStorage.setItem("email_verified", email_verified)
              localStorage.setItem("given_name", self.state.given_name)
              localStorage.setItem("family_name", self.state.family_name)
              localStorage.setItem("session_type", 'signup');
              localStorage.setItem("user_signup_process", "true")
              functions.updateUserData(self, userdata, dynamo_userdata);
              let signup_json = {
                username: username,
                "auto_id": auto_id
              }
              functions.adminSignupConfirm(self, signup_json, function (response_json) {
                let response = JSON.parse(response_json);
              })
              let element = document.getElementById("signup_complete")
              localStorage.setItem("signup_user", 'true')
              // console.log("element", element)
              if (cookie.load("accept_auto_id") && cookie.load("relative_id")) {
                functions.addRelative(self, auto_id, cookie.load("relative_id"), username);
              }
              element.click();
              if (self.state.provider != 'familyone') {
                functions.pushToRoute(self, "gender", 'signup')
              }
              else {
                let http_referer = "null"
                if (typeof cookie.load("http_referer") !== undefined &&
                  cookie.load("http_referer") !== "undefined" &&
                  cookie.load("http_referer") !== "null" &&
                  cookie.load("http_referer") !== null &&
                  cookie.load("http_referer")) {
                  http_referer = cookie.load("http_referer")
                }
                let finaljson = {
                  sub_id: auto_id,
                  profile_data: {
                    email: username,
                    sub_id: auto_id,
                    given_name: self.state.given_name,
                    family_name: self.state.family_name
                  },
                  http_referer: http_referer,
                  activation_email: send_active_mail == true ? "true" : "false"
                }
                functions.SendActivationEmail(self, finaljson)
                if (send_active_mail == true) {
                  functions.pushToRoute(self, "verify_account", 'signup')
                }
                else {
                  functions.pushToRoute(self, "gender", 'signup')
                }
              }
            }

          }
        })
      }
    })
  }

  migrateUser = (email) => {
    let self = this
    let migration_uri = 'https://request.family.one/request/cron_makeSync.php';
    let dataset = {
      "email": email,
      sync: 0
    }
    self.setState({
      migrate_note: true
    })
    axios
      .request({
        url: migration_uri,
        data: JSON.stringify(dataset),
        method: "post"
      })
      .then(function (response) {
        // console.log("getUserData response", response);
        self.SendLoginLink(email)
        self.setState({ loader: false, show_error_message: url.dynamic_content?.error_message?.user_exist?.en });
      });
  }

  SendLoginLink = (email) => {
    let user_action = "User got error on signup/index file handleSignup function";
    let self = this
    let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
    let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
    let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : "";
    let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
    let relative_id = cookie.load("relative_id") ? cookie.load("relative_id") : "";
    let http_referer = "null"
    let alternative_popup = cookie.load("showAlternativeEmailPopup") ? cookie.load("showAlternativeEmailPopup") : false;
    let accept_auto_id = cookie.load("accept_auto_id") ? cookie.load("accept_auto_id") : "";

    if (typeof cookie.load("http_referer") !== undefined &&
      cookie.load("http_referer") !== "undefined" &&
      cookie.load("http_referer") !== "null" &&
      cookie.load("http_referer") !== null &&
      cookie.load("http_referer")) { http_referer = cookie.load("http_referer") }
    functions.sendLoginLink(self, email, session_entered_id, relative_id, perks_or_main, http_referer, alternative_popup, accept_auto_id, function (response_json) {
      let response = JSON.parse(response_json);
      if (response.catch_error) {
        self.setState({ loader: false })
        let error_body = response.message
        functions.logUserError(user_action, error_body);
        self.setState({ show_error_message: response.message })
      }
      else if (!response.catch_error) {
        self.setState({ loader: false })
        if (response.data.error === 1) {
          let error_body = response.data.message
          functions.logUserError(user_action, error_body);
          self.setState({ show_error_message: response.data.message })
        } else if (response.data.statusCode === 400) {
          let error_body = response.data.message
          functions.logUserError(user_action, error_body);
          self.setState({ show_error_message: response.data.message })
        }
        if (response && response.data && response.data.success === 1) {

          let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
          let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
          let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
          // localStorage.clear();
          Object.keys(localStorage).forEach(key => !['phone_transfer', 'email_transfer'].includes(key) && localStorage.removeItem(key));
          localStorage.setItem("session_entered_id", session_entered_id)
          localStorage.setItem("previous_state", previous_state)
          localStorage.setItem("current_state", current_state)
          localStorage.setItem("program", JSON.stringify(program))
          localStorage.setItem("perks_or_main", perks_or_main)
          localStorage.setItem("program_list", JSON.stringify(program_list))
          localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
          localStorage.setItem("email", email)
        }
      }
    })
  }

  handleContinue = () => {
    let user_action = "User got error on signup/index file handleContinue function";
    // console.log("this.state", this.state)
    let given_name_validate = "false"
    if (!this.state.given_name.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
    }
    else {
      given_name_validate = "true"
    }
    let family_name_validate = "false"
    if (!this.state.family_name.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
    }
    else {
      family_name_validate = "true"
    }
    if (this.state.given_name != "") {

      if (given_name_validate == "false") {

        let error_body = "First name is not valid"
        functions.logUserError(user_action, error_body);
        this.setState({ show_error_message: error_body })
      }
      else if (this.state.given_name.length < 2) {

        let error_body = url.dynamic_content?.error_message?.given_name_length?.en
        functions.logUserError(user_action, error_body);
        this.setState({ show_error_message: url.dynamic_content?.error_message?.given_name_length?.en })
      }
      else {

        if (this.state.family_name != "") {

          if (family_name_validate == "false") {

            let error_body = "Last name is not valid"
            functions.logUserError(user_action, error_body);
            this.setState({ show_error_message: error_body })
          }
          else if (this.state.family_name.length < 2) {

            let error_body = url.dynamic_content?.error_message?.given_name_length?.en
            functions.logUserError(user_action, error_body);
            this.setState({ show_error_message: url.dynamic_content?.error_message?.given_name_length?.en })
          }
          else {

            if (this.state.email.trim() === '') {

              let error_body = url?.dynamic_content?.error_message?.enter_email?.en
              functions.logUserError(user_action, error_body);
              this.setState({ show_error_message: error_body  })
            }
            else if (this.state.email.length < 10) {

              let error_body = url.dynamic_content?.error_message?.email_length?.en
              functions.logUserError(user_action, error_body);
              this.setState({ show_error_message: url.dynamic_content?.error_message?.email_length?.en })
            }
            else if (
              !this.state.email.match(/^([a-zA-Z0-9_.-]+@([\w-]+\.)+[\w-]{2,10})?$/)
            ) {

              let error_body = url.dynamic_content?.error_message?.invalid_email?.en
              functions.logUserError(user_action, error_body);
              this.setState({ show_error_message: url.dynamic_content?.error_message?.invalid_email?.en })
            }
            else {
              // console.log("call signup")
              let image = 'continue_button_signup_page.png'
              functions.logUserActivity("User click on continue button from signup", image)
              this.handleSignup()
            }
          }
        } else {
          let error_body = url.dynamic_content?.error_message?.enter_last_name?.en
          functions.logUserError(user_action, error_body);
          this.setState({ show_error_message: url.dynamic_content?.error_message?.enter_last_name?.en })
        }
      }
    } else {

      let error_body = url?.dynamic_content?.error_message?.all_fields_required?.en
      functions.logUserError(user_action, error_body);
      this.setState({ show_error_message: url.dynamic_content?.error_message?.all_fields_required?.en });
    }
  }

  handleFirstname = (event) => {

    if (!event.target.value.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
    }
    else {
      this.setState({
        given_name: event.target.value.trim(),
        show_error_message: ''
      });
      if (event.target.value.trim().length > 1) {
        this.setState({
          given_name_complete: true
        });
      } else {
        this.setState({
          given_name_complete: false
        });
      }
    }
  }

  handleLastname = (event) => {

    if (!event.target.value.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
    }
    else {
      this.setState({
        family_name: event.target.value.trim(),
        show_error_message: ''
      });
      if (event.target.value.trim().length > 1) {
        this.setState({
          family_name_complete: true
        });
      } else {
        this.setState({
          family_name_complete: false
        });
      }
    }
  }

  makeid = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$!%^&*()';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  handleBack = () => {
    let image = 'back_button_signup_page.png'
    functions.logUserActivity("User click on back button from entering email in signup to first name and last name", image)
    this.setState({ enter_email: false });
    localStorage.setItem("enter_email", "false")
  }

  handleGoBack = () => {
    let image = 'back_button_signup_page.png'
    functions.logUserActivity("User click on back button from signup", image)
    this.props.history.push("/home")
  }

  toggleInvalidEmailPopup = () => {
    this.setState(prevState => ({
      isInvalidEmail: !prevState.isInvalidEmail,
    }));
  };

  handleContinueInvalidEmail = () => {
    this.setState({ email: '', given_name: '', family_name: '' });
    this.toggleInvalidEmailPopup();
  }

  render() {
    const { dynamic_content } = this.state
    return (
      <div ref={this.scrollToTopRef} className="container cstmSignUp">
        {/* <Online> */}
        <div className="row">
          <div id="scrollToTop" className="common_mainAddOn">
            <div className='signupCirclBg'>
              <div className="common_main common_mainIner">


                <div className="sign_backlink sign_backlinkNw" onClick={() => { this.handleGoBack() }}>
                  <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                </div>

                <div className="sign_up">
                  <h1>{dynamic_content ? dynamic_content.static_text.signup.sign_up.en : "Sign Up"}</h1>
                </div>
                {this.state.loader &&
                  <div className="loader_div">
                    <center>
                      <LottieLoader className="loader_class" />
                    </center>
                    {this.state.migrate_note &&
                      <div style={{ marginTop: "20px" }}>
                        <p className="migrate_note">Welcome to the new family.one</p>
                        <p className="migrate_note">It may take up to 10 seconds to migrate your profile.</p>
                        <p className="migrate_note">We'll email you a login link when migration is complete.</p>
                      </div>
                    }
                  </div>
                }
                {!this.state.loader &&
                  <div className="signup_form">
                    {/* <form autoComplete="off"> */}
                    {/* {!this.state.enter_email && */}
                    <h3>Create Account</h3>
                    {/* <TextField
                      autoComplete='off'
                      autoFocus={true}
                      label="What is your first name?"
                      // placeholder="What is your first name?"
                      type="text"
                      margin="normal"
                      inputRef={(input) => { this.given_name = input; }}
                      className="signup_first"
                      value={this.state.given_name ? this.state.given_name : ""}
                      onKeyPress={event => {
                        if (event.key === "Enter" && this.family_name) {
                          this.family_name.focus()
                        }
                      }}
                      onChange={event => {
                        this.handleFirstname(event);
                      }}
                    /> */}
                    <input className="signup_first"
                      onKeyPress={event => {
                        if (event.key === "Enter") {
                          this.family_name.focus()
                        }

                      }}
                      ref={(input) => { this.given_name = input; }}
                      placeholder='First Name'
                      type="text"
                      id="fname" name="fname"
                      onChange={event => {
                        this.handleFirstname(event);
                      }} value={this.state.given_name ? this.state.given_name : ""}>

                    </input>
                    {/* }
                  {!this.state.enter_email && */}
                    {/* <TextField
                      autoComplete='off'
                      label="What is your last name?"
                      // placeholder="What is your last name?"
                      type="text"
                      margin="normal"
                      inputRef={(input) => { this.family_name = input; }}
                      autoFocus={false}
                      className="signup_last"
                      value={this.state.family_name ? this.state.family_name : ""}
                      onKeyPress={event => {
                        if (event.key === "Enter" && this.email) {
                          this.email.focus()
                        }
                      }}
                      onChange={event => {
                        this.handleLastname(event);
                      }}
                    /> */}
                    <input
                      className="signup_last"
                      ref={(input) => { this.family_name = input; }}
                      autoFocus={false}
                      onKeyPress={event => {
                        if (event.key === "Enter") {
                          this.email.focus()
                        }
                      }}
                      placeholder='Last Name'
                      type="text"
                      id="family_name" name="family_name"
                      onChange={event => {
                        this.handleLastname(event);
                      }}
                      value={this.state.family_name ? this.state.family_name : ""}>

                    </input>
                    {/* }
                  {this.state.enter_email && */}
                    {/* <TextField
                      autoComplete='off'
                      autoFocus={false}
                      label="Your email address"
                      // placeholder="Your email address"
                      type="email"
                      margin="normal"
                      inputRef={(input) => { this.email = input; }}
                      className="signup_first"
                      value={this.state.email ? this.state.email : ""}
                      onKeyPress={event => {
                        if (event.key === "Enter") {
                          this.handleContinue()
                        }
                      }}
                      onChange={(e) => { this.setState({ email: e.target.value.trim().toLowerCase(), show_error_message: '' }) }}
                    /> */}
                    <input className="signup_first"
                      onKeyPress={event => {
                        if (event.key === "Enter") {
                          this.handleContinue()
                        }
                      }}
                      ref={(input) => { this.email = input; }}
                      placeholder='Email'
                      type="email"
                      id="email" name="email"
                      onChange={e => {
                        this.setState({ email: e.target.value.trim().toLowerCase(), show_error_message: '' })
                      }}
                      value={this.state.email ? this.state.email : ""}>

                    </input>
                    {/* } */}
                    {this.state.show_error_message && this.state.show_error_message != "" &&
                      <p className="error_message">
                        {ReactHtmlParser(this.state.show_error_message)}
                      </p>
                    }
                    <div className="signup_submit">
                      <Button size="medium" color="primary" onClick={() => { this.handleContinue() }}>
                        {dynamic_content ? dynamic_content.static_text.signup.continue.en : "Continue"}
                      </Button>
                    </div>

                  </div>
                }
                <div className="sign_up_declare 11">
                  <p> {dynamic_content ? dynamic_content.static_text.signup.sign_up_declare.en : "We collect your first name, last name and email address as the most basic account identifiers and default means for you to access your account and us to contact you."}</p>
                </div>



                {/* Add Animation Here */}
                <div className="signupConectCircleInr">
                  <div className="signupConectInr"></div>
                </div>
                {/* End of Animation Here */}

              </div>
            </div>
          </div>
        </div>
        <div id="signup_complete"></div>
        <InvalidEmail
          isInvalidEmail={this.state.isInvalidEmail}
          toggleInvalidEmailPopup={this.toggleInvalidEmailPopup}
          handleContinueInvalidEmail={this.handleContinueInvalidEmail}
        />
        {/* </Online>
        <Offline>
          <OfflineComponent props={this} />
        </Offline> */}
      </div>
    );
  }
}