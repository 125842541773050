import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox"
import InputAdornment from '@material-ui/core/InputAdornment';
import '../../assets/css/style.css';
import country_list from '../../assets/countries';
import state_list from '../../assets/state';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import url from '../../assets/url';
import CircularProgress from '@material-ui/core/CircularProgress';
import functions from '../../assets/functions/common_functions'
import $ from 'jquery';
import address from '../../assets/get_address';
import cookie from 'react-cookies';
import fsa from "./fsa.js";
import AWS from 'aws-sdk';

export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelectedAddress = this.handleSelectedAddress.bind(this);
        this.scrollToTopRef = React.createRef();
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        if (!auto_id) {
            this.props.history.push("/")
        }
        this.state = {
            isUIReady: false,
            session_type: localStorage.getItem("session_type") ? localStorage.getItem("session_type") : "",
            loader: false,
            last_address: false,
            show_error_message: "",
            address_autocomplete: [],
            step: 1,
            modal: false,
            main_loader: false,
            province: '',
            states: [],
            modal_account: false,
            show_province: false,
            addressline1: '',
            dynamic_content: "",
            city: '',
            postalcode: '',
            manual_address: localStorage.getItem("manual_address") && localStorage.getItem("manual_address") == "true" ? true : false,
            openedSub: false,
            openedRural: false,
            modal_account: false,
            modalAI: false,
            modalAI_Content: "",
            modalAI_Response: "",
            country: "Canada",
            show_form: false,
            show_address_select: true,
            toggle_element: "",
            show_province: false,
            search_key: "",
            country_options: ["Canada", "USA", "Other"],
            // canada_address_options: ["House", "Sub", "Rural"],
            sub_options: ["Apt", "Unit", "Suite", "Penthouse", "Townhouse", "Condo"],
            sub_options_extra: ["Buzzer code", "Entrance", "Building"],
            rural_options: ["RR", "Site", "Comp"],
            RR: localStorage.getItem("RR") ? localStorage.getItem("RR") : "",
            Site: localStorage.getItem("Site") ? localStorage.getItem("Site") : "",
            Comp: localStorage.getItem("Comp") ? localStorage.getItem("Comp") : "",
            selected_country: "",
            RR_select: "false",
            Site_select: "false",
            Comp_select: "false",
            toggle_street_address: true,
            address_change: false,
            sub_selected: "",
            rural_selected: "",
            countries: [],
            show_additional: false,
            street_address_focus: true,
            general_delivery: "false",
            LCD: "false",
            "Buzzer code_select": localStorage.getItem("Buzzer code") ? localStorage.getItem("Buzzer code") : "false",
            Entrance_select: localStorage.getItem("Entrance_select") ? localStorage.getItem("Entrance_select") : "false",
            Building_select: localStorage.getItem("Building_select") ? localStorage.getItem("Building_select") : "false",
            "PO Box": localStorage.getItem("PO Box") ? localStorage.getItem("PO Box") : "",
            STN: localStorage.getItem("STN") ? localStorage.getItem("STN") : "",
            countryModal: false,
            addressTypeModal: false,
            showSuggestions: false,
            filteredAddresses: [],
            addresses: [],
            formattedAI_address1: "",
            formattedAI_address2: "",
            close_match: "",
            po_boxGD: "",
            normal_address: false,
            selectedAddress: "",
            buttonDisabled: false,
            which_match: "",
            addressReportData: {
                sub_id: localStorage.getItem("auto_id"),
                initial_address_type: "normal",
                final_address_type: "",
                start_time: new Date(),
                end_time: "",
                type: "new",
                functionaction: "add",
                action: "migrate_address_data",
                method: 'add',
            },
            cont_btn_disabled: false,

        };
    }



    toggleSub = () => {
        this.setState({
            openedSub: true,
            openedRural: false,
            general_delivery: "false",
            LCD: "false",
            manual_address: false
        });
    }

    toggleHome = () => {
        this.setState({
            openedRural: false,
            openedSub: false,
            general_delivery: "false",
            LCD: "false",
            manual_address: false
        });
    }

    toggleRural = () => {
        this.setState({
            openedRural: !this.state.openedRural,
            openedSub: false,
            general_delivery: "false",
            LCD: "false",
            po_boxGD: "",
            STN: "",
            RR: "",
            Site: "",
            Comp: "",
        });
    }

    scrollToTop = () => {
        if (this.scrollToTopRef.current) {
            this.scrollToTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    componentDidMount = () => {

        $('html, body').css('overflow', 'initial');
        let countries = []
        country_list.forEach((element, index) => {
            countries.push(<option key={index} value={element}>{element}</option>)
        })
        this.setState({ countries: country_list })
        this.setState({ states: state_list })
        if(localStorage.getItem("session_type") === "signup"){
        this.handleSelectCountry("Canada");
        }
        this.setState({ isUIReady: true });

       
    };

    handleSelectCountry = (value) => {
        if (value == "Other") {
            if (this.state.country != "" && this.state.country != "Canada" && this.state.country != "USA") {
                this.setState({ modal_account: true, show_province: false, show_form: true })
            }
            else {
                this.setState({ modal_account: true, show_province: false, show_form: false })
            }
        }
        else {
            if (value == "Canada") {
                this.setState({ show_additional: false, additional: false, toggle_street_address: false, toggle_element: "House" })
            }
            else {
                this.setState({ show_form: true })
            }
            this.setState({
                country: value
            })
            this.foucs_address()
        }
        this.toggleHome()
        this.setState({
            selected_country: value,
        })
        this.handleEmptyForm()
    }

    openSelector = (modal) => {
        this.setState({ [modal]: true })
    }

    toggleModal = (modal) => {
        this.setState({ [modal]: false })
    }

    handleEmptyForm = () => {
        this.setState({
            addressline1: "",
            city: "",
            province: "",
            postalcode: "",
            show_error_message: "",
            Apt: "",
            Unit: "",
            Suite: "",
            Penthouse: "",
            Townhouse: "",
            Condo: "",
            ["Buzzer code"]: "",
            Entrance: "",
            Building: "",
            ["PO Box"]: "",
            STN: "",
            Apt_select: "false",
            Unit_select: "false",
            Suite_select: "false",
            Penthouse_select: "false",
            Townhouse_select: "false",
            Condo_select: "false",
            ["Buzzer code_select"]: "false",
            Entrance_select: "false",
            Building_select: "false",
            general_delivery: "false",
            LCD: "false",
            RR: "",
            Site: "",
            Comp: "",
            RR_select: "false",
            Site_select: "false",
            Comp_select: "false"
        })
    }



    handleSubOption = (value) => {
        this.handleEmptyForm()
        this.setState({ sub_selected: value, toggle_street_address: true, show_additional: true, additional: false, rural_selected: "" })

    }

    handleSubExtraOption = (value) => {
        let prev_value = this.state[value + "_select"]
        if (prev_value == "true") {
            this.setState({ [value + "_select"]: "false" })
        } else {
            this.setState({ [value + "_select"]: "true" })
        }
    }

    handleRuralClick = (element) => {
        let prev_value = this.state[element + "_select"]
        if (prev_value == "true") {
            this.setState({
                [element + "_select"]: "false",
            })
        }
        else {
            this.setState({
                [element + "_select"]: "true",
            })
        }
        this.setState({
            rural_selected: element
        })
    }

    closeToggle(value, state) {
        let action = "User clicked on close country popup address page"
        let image = 'close_button_country_popup_address_page.png'
        functions.logUserActivity(action, image)
        value.setState({
            [state]: !value.state[state]
        });
    }

    // Handle change of country
    handleCountry = (value) => {
        let action = "User selected country on address page"
        let image = 'country_select_address_page.png'
        functions.logUserActivity(action, image)
        this.setState({
            addressline1: "",
            city: "",
            province: "",
            postalcode: "",
            show_error_message: "",
            modal_account: false,
            show_form: true,
            country: value,
        })
        this.foucs_address()
    }

    foucs_address = () => {
        $('#addressline1').click();
    }

    replaceStreetType = (address) => {

        
        const streetTypeMapping = {
            "Abbey": "Abbey",
            "Acres": "Acres",
            "Alley": "Alley",
            "Avenue": "Ave",
            "Bay": "Bay",
            "Beach": "Beach",
            "Bend": "Bend",
            "Boulevard": "Blvd",
            "By-pass": "Bypass",
            "Byway": "Byway",
            "Campus": "Campus",
            "Cape": "Cape",
            "Centre": "Ctr",
            "Chase": "Chase",
            "Circle": "Cir",
            "Circuit": "Circt",
            "Close": "Close",
            "Common": "Common",
            "Concession": "Conc",
            "Corners": "Crnrs",
            "Court": "Crt",
            "Cove": "Cove",
            "Crescent": "Cres",
            "Crossing": "Cross",
            "Cul-de-sac": "Cds",
            "Dale": "Dale",
            "Dell": "Dell",
            "Diversion": "Divers",
            "Downs": "Downs",
            "Drive": "Dr",
            "End": "End",
            "Esplanade": "Espl",
            "Estates": "Estate",
            "Expressway": "Expy",
            "Extension": "Exten",
            "Farm": "Farm",
            "Field": "Field",
            "Forest": "Forest",
            "Freeway": "Fwy",
            "Front": "Front",
            "Gardens": "Gdns",
            "Gate": "Gate",
            "Glade": "Glade",
            "Glen": "Glen",
            "Green": "Green",
            "Grounds": "Grnds",
            "Grove": "Grove",
            "Harbour": "Harbr",
            "Heath": "Heath",
            "Heights": "Hts",
            "Highlands": "Hghlds",
            "Highway": "Hwy",
            "Hill": "Hill",
            "Hollow": "Hollow",
            "Inlet": "Inlet",
            "Island": "Island",
            "Key": "Key",
            "Knoll": "Knoll",
            "Landing": "Landng",
            "Lane": "Lane",
            "Limits": "Lmts",
            "Line": "Line",
            "Link": "Link",
            "Lookout": "Lkout",
            "Loop": "Loop",
            "Mall": "Mall",
            "Manor": "Manor",
            "Maze": "Maze",
            "Meadow": "Meadow",
            "Mews": "Mews",
            "Moor": "Moor",
            "Mount": "Mount",
            "Mountain": "Mtn",
            "Orchard": "Orch",
            "Parade": "Parade",
            "Park": "Pk",
            "Parkway": "Pky",
            "Passage": "Pass",
            "Path": "Path",
            "Pathway": "Ptway",
            "Pines": "Pines",
            "Place": "Pl",
            "Plateau": "Plat",
            "Plaza": "Plaza",
            "Point": "Pt",
            "Port": "Port",
            "Private": "Pvt",
            "Promenade": "Prom",
            "Quay": "Quay",
            "Ramp": "Ramp",
            "Range": "Rg",
            "Ridge": "Ridge",
            "Rise": "Rise",
            "Road": "Rd",
            "Route": "Rte",
            "Row": "Row",
            "Run": "Run",
            "Square": "Sq",
            "Street": "St",
            "Subdivision": "Subdiv",
            "Terrace": "Terr",
            "Thicket": "Thick",
            "Towers": "Towers",
            "Townline": "Tline",
            "Trail": "Trail",
            "Turabout": "Trnabt",
            "Vale": "Vale",
            "Via": "Via",
            "View": "View",
            "Village": "Villge",
            "Villas": "Villas",
            "Vista": "Vista",
            "Walk": "Walk",
            "Way": "Way",
            "Wharf": "Wharf",
            "Wood": "Wood",
            "Wynd": "Wynd"
        };
        for (let [full, abbrev] of Object.entries(streetTypeMapping)) {
            // Use regex with 'i' flag for case-insensitive matching and '\\b' to match whole words
            let regex = new RegExp(`\\b${full}\\b`, 'gi');
            address = address.replace(regex, abbrev);
        }
        return address;
    };

      

    handleFormatAddressAI = async () => {
        try {      
            const { original_city, city, province, RR, Site, Comp, general_delivery, "PO Box": POBox, Apt, addressline1, postalcode } = this.state;

            let formattedAI_address12, formattedAI_address1;
            // Construct address based on user input
            const address = [
                RR ? `RR ${RR}` : "",
                Site ? `Site ${Site}` : "",
                Comp ? `Comp ${Comp}` : "",
                general_delivery === "true" ? `GD` : "",
                POBox ? `PO Box ${POBox}` : "",
                Apt ? `Apt ${Apt}-${addressline1}` : addressline1,
                `, ${city}, ${province}, ${postalcode}`
            ]
                .filter(Boolean) // Remove empty fields
                .join(' ');    // Join fields with a comma and space

            const lambdaEndpoint = 'https://7qduefcepd.execute-api.us-east-1.amazonaws.com/dev/OpenAIHandler'; // Default Lambda endpoint

            // Make API call for user-entered city
            const lambdaResponse1 = await fetch(lambdaEndpoint, {
                method: 'POST',
                body: JSON.stringify({
                    address: address  // Example address
                }),
            });
            const data1 = await lambdaResponse1.json();
            if (data1 && data1.choices && data1.choices.message && data1.choices.message.content) {
                formattedAI_address12 = data1.choices.message.content;
            }
            
            // // If original_city is different, manipulate the existing address string
            // if (original_city !== city) {
            //     // Analyze formattedAI_address1 and replace city with original_city
            //     const commaIndex = formattedAI_address1.indexOf(','); // Find the first comma
            //     if (commaIndex !== -1) {
            //         const secondCommaIndex = formattedAI_address1.indexOf(',', commaIndex + 1); // Find the second comma
            //         if (secondCommaIndex !== -1) {
            //             // Split the address into parts
            //             const parts = formattedAI_address1.split(', ');
            //             // Swap the city and province
            //             parts.splice(1, 2, city, province);
            //             // Reconstruct the address
            //             formattedAI_address2 = parts.join(', ');
            //             console.log("formattedAI_address2", formattedAI_address2)

            //         }
            //     }
            // }
            formattedAI_address1 = this.replaceStreetType(formattedAI_address12);
            this.setState({ formattedAI_address1 }, () => {
               this.handleAutoComplete(formattedAI_address1);
           });


            // Set states accordingly
            // this.handleAutoComplete(data.choices.message.content);
            // // Function to check if the address contains specific keywords in a case-insensitive manner
            // const containsKeywords = (address, keywords) => {
            //     const lowerAddress = address.toLowerCase();
            //     return keywords.some(keyword => lowerAddress.includes(keyword));
            // };

            // // Check if the address contains certain keywords
            // if (containsKeywords(data.choices.message.content, ['po box', 'rr', 'gd', 'site', 'comp'])) {
            //     // Send diversity to handleAutoComplete
            //     this.handleAutoComplete(data.choices.message.content);
            // } else {
            //     // Check for street abbreviation
            //     const streetAbbreviations = [
            //         "ABB",
            //         "ACRES",
            //         "ALLEY",
            //         "AVE",
            //         "BAY",
            //         "BEACH",
            //         "BEND",
            //         "BLVD",
            //         "BYPASS",
            //         "BYWAY",
            //         "CAMPUS",
            //         "CAPE",
            //         "CTR",
            //         "CHASE",
            //         "CIR",
            //         "CIRCT",
            //         "CLOSE",
            //         "COMMON",
            //         "CONC",
            //         "CRNRS",
            //         "CRT",
            //         "COVE",
            //         "CRES",
            //         "CROSS",
            //         "CDS",
            //         "DALE",
            //         "DELL",
            //         "DIVERS",
            //         "DOWNS",
            //         "DR",
            //         "END",
            //         "ESPL",
            //         "ESTATE",
            //         "EXPY",
            //         "EXTEN",
            //         "FARM",
            //         "FIELD",
            //         "FOREST",
            //         "FWY",
            //         "FRONT",
            //         "GDNS",
            //         "GATE",
            //         "GLADE",
            //         "GLEN&",
            //         "GREEN",
            //         "GRNDS",
            //         "GROVE",
            //         "HARBR",
            //         "HEATH",
            //         "HTS",
            //         "HGHLDS",
            //         "HWY",
            //         "HILL",
            //         "HOLLOW",
            //         "INLET",
            //         "ISLAND",
            //         "KEY",
            //         "KNOLL",
            //         "LANDNG",
            //         "LANE",
            //         "LMTS",
            //         "LINE",
            //         "LINK",
            //         "LKOUT",
            //         "LOOP",
            //         "MALL",
            //         "MANOR",
            //         "MAZE",
            //         "MEADOW",
            //         "MEWS",
            //         "MOOR",
            //         "MOUNT",
            //         "MTN",
            //         "ORCH",
            //         "PARADE",
            //         "PK",
            //         "PKY",
            //         "PASS",
            //         "PATH",
            //         "PTWAY",
            //         "PINES",
            //         "PL",
            //         "PLAT",
            //         "PLAZA",
            //         "PT",
            //         "PORT",
            //         "PVT",
            //         "PROM",
            //         "QUAY",
            //         "RAMP",
            //         "RG",
            //         "RIDGE",
            //         "RISE",
            //         "RD",
            //         "RTE",
            //         "ROW",
            //         "RUN",
            //         "SQ",
            //         "ST",
            //         "SUBDIV",
            //         "TERR",
            //         "THICK",
            //         "TOWERS",
            //         "TLINE",
            //         "TRAIL",
            //         "TRNABT",
            //         "VALE",
            //         "VIA",
            //         "VIEW",
            //         "VILLGE",
            //         "VILLAS",
            //         "VISTA",
            //         "WALK",
            //         "WAY",
            //         "WHARF",
            //         "WOOD",
            //         "WYND"
            //     ];

            //      // Common Canadian street abbreviations
            //     let streetFound = false;
            //     let houseFound = false;

            //     const lowerAddress = data.choices.message.content.toLowerCase();

            //     for (const abbreviation of streetAbbreviations) {
            //         if (lowerAddress.includes(abbreviation.toLowerCase())) {
            //             streetFound = true;
            //             break;
            //         }
            //     }

            //     if (!streetFound) {
            //         // Prompt user to enter street correctly
            //         this.setState({
            //             modalAI: true,
            //             modalAI_Content: "Please enter the street correctly."
            //         });
            //     } else {
            //         // Check for house number
            //         if (lowerAddress.match(/\b(\d+-)?\d+\b/i)) {
            //             houseFound = true;
            //         }

            //         if (!houseFound) {
            //             // Prompt user to enter house correctly
            //             this.setState({
            //                 modalAI: true,
            //                 modalAI_Content: "Please enter the house correctly."
            //             });
            //         } else {
            //             // Both street and house found, send to handleAutoComplete
            //             this.handleAutoComplete(data.choices.message.content);
            //         }
            //     }
            // }
        } catch (error) {
            console.error('Error calling Lambda function:', error);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.isUIReady && !prevState.isUIReady) || (this.state.dynamic_content !== "" && prevState.dynamic_content === "")) {
            // Perform scroll to top action
            this.scrollToTop();
        }
        // Check if po_boxGD has changed
        if (this.state.po_boxGD != null  && this.state.po_boxGD !== prevState.po_boxGD) {
            // Execute your logic when po_boxGD changes
            const inputValue = this.state.po_boxGD;
            const numericPart = inputValue.replace(/\D/g, ''); // Extract only numeric characters
            if (numericPart !== '') {
                // Numeric characters found, store in PO Box
                this.setState({
                    "PO Box": numericPart,
                    show_error_message: '',
                    general_delivery: "false"
                });
            } else {
                // No numeric characters found, set general delivery to true
                this.setState({
                    "PO Box": '',
                    show_error_message: '',
                    general_delivery: "true"
                });
            }
        }
    }

    // Function to handle manual address either canada or other countries
    handleManualAddress = async () => {
        try {
            await functions.addressReportData(this.state?.addressReportData);  // Call handleSubmit without event
        } catch (error) {
            console.error('Error in handleSubmit', error);  // Optional: handle error if needed
        } finally {
            let temp_address = {}
            let user_action = "User got error on signup/address file handleManualAddress function";
            let action = "User clicked on Continue while entering manual address on address page";
            let image = 'continue_button_manual_address_address_page.png'
            functions.logUserActivity(action, image)
            let toggle_element = this.state.toggle_element;
            let Apt = this.state.Apt ? this.state.Apt : "";
            let addressline1 = this.state.addressline1 ? this.state.addressline1.trim() : "";
            let city = this.state.city ? this.state.city.trim() : ""
            let province = this.state.province ? this.state.province.trim() : ""
            let postalcode = this.state.postalcode ? this.state.postalcode.trim() : "";
            let postalcode_length = postalcode.length;
            let show_additional = this.state.show_additional;
            let toggle_street_address = this.state.toggle_street_address;
            let STN = this.state.STN ? this.state.STN.trim() : "";
            let PO_Box = this.state["PO Box"] ? this.state["PO Box"].trim() : "";
            temp_address.Apt = Apt
            temp_address.addressline1 = addressline1
            temp_address.city = city
            temp_address.province = province
            temp_address.postalcode = postalcode
            temp_address.postalcode_length = postalcode_length

            if (this.state.country == "Canada") {
                temp_address.show_additional = show_additional
                temp_address.toggle_street_address = toggle_street_address
                temp_address.STN = STN
                temp_address.PO_Box = PO_Box
                if (toggle_element == "") {
                    let error_body = url.dynamic_content.error_message.select_category.en
                    functions.logUserError(user_action, error_body);
                    this.setState({ show_error_message: url.dynamic_content.error_message.select_category.en })
                }
                if (toggle_element == "Rural") {
                    let RR = this.state.RR ? this.state.RR.trim() : ""
                    let Site = this.state.Site ? this.state.Site.trim() : ""
                    let Comp = this.state.Comp ? this.state.Comp.trim() : ""
                    let LCD = this.state.LCD ? this.state.LCD : "false"
                    let general_delivery = this.state.general_delivery ? this.state.general_delivery : "false"

                    temp_address.LCD = LCD
                    temp_address.general_delivery = general_delivery
                    temp_address.rural_selected = this.state.rural_selected

                    let RR_select = this.state.RR_select ? this.state.RR_select : "false"
                    let Site_select = this.state.Site_select ? this.state.Site_select : "false"
                    let Comp_select = this.state.Comp_select ? this.state.Comp_select : "false"

                    if (RR_select == "true") {
                        temp_address["RR"] = this.state["RR"] ? this.state["RR"].trim() : ""
                    }
                    if (Site_select == "true") {
                        temp_address["Site"] = this.state["Site"] ? this.state["Site"].trim() : ""
                    }
                    if (Comp_select == "true") {
                        temp_address["Comp"] = this.state["Comp"] ? this.state["Comp"].trim() : ""
                    }

                    // if (RR == "" && Site == "" && Comp == "") {
                    //     let error_body = url.dynamic_content.error_message.all_fields_required.en
                    //     functions.logUserError(user_action, error_body);
                    //     this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
                    // } else {
                    this.makeAddressJson(temp_address)
                    // }
                }
                // if (toggle_element == "Sub") {
                //     let sub_selected = this.state.sub_selected
                //     let Entrance_select = this.state.Entrance_select ? this.state.Entrance_select : "false"
                //     let Building_select = this.state.Building_select ? this.state.Building_select : "false"
                //     let Buzzer_code_select = this.state["Buzzer code_select"] ? this.state["Buzzer code_select"] : "false"
                //     temp_address[sub_selected] = this.state[sub_selected] ? this.state[sub_selected].trim() : ""
                //     if (Buzzer_code_select == "true") {
                //         temp_address["Buzzer code"] = this.state["Buzzer code"] ? this.state["Buzzer code"].trim() : ""
                //     }
                //     if (Entrance_select == "true") {
                //         temp_address["Entrance"] = this.state["Entrance"] ? this.state["Entrance"].trim() : ""
                //     }
                //     if (Building_select == "true") {
                //         temp_address["Building"] = this.state["Building"] ? this.state["Building"].trim() : ""
                //     }



                //     console.log("temp_address", temp_address)
                //     // if (temp_address[sub_selected] == "") {
                //     //     console.log("temp_address", temp_address)
                //     //     let error_body = url.dynamic_content.error_message.all_fields_required.en
                //     //     functions.logUserError(user_action, error_body);
                //     //     this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
                //     // }
                //     // else if ((Entrance_select == "true" && temp_address.Entrance == "") || (Building_select == "true" && temp_address.Building == "") || (Buzzer_code_select == "true" && temp_address["Buzzer code"] == "")) {
                //     //     console.log("temp_address", temp_address)
                //     //     let error_body = url.dynamic_content.error_message.all_fields_required.en
                //     //     functions.logUserError(user_action, error_body);
                //     //     this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
                //     // } else {
                //     console.log("temp_address", temp_address)
                //     this.makeAddressJson(temp_address)
                //     // }
                // }
                if (toggle_element == "House") {
                    this.makeAddressJson(temp_address)
                }
            } else {
                this.makeAddressJson(temp_address)
            }
        }
    }

    makeAddressJson = (temp_address) => {
        let user_action = "User got error on signup/address file makeAddressJson function";
        if (this.state.country == "Canada") {
            if (temp_address.toggle_street_address == true) {
                if (temp_address.addressline1 == "" || temp_address.city == "" || temp_address.province == "" || temp_address.postalcode == "") {
                    let error_body = url.dynamic_content.error_message.all_fields_required.en
                    functions.logUserError(user_action, error_body);
                    this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
                }
                else {
                    if (temp_address.postalcode_length != 7) {
                        let error_body = url.dynamic_content.error_message.incorrect_postal_code.en
                        functions.logUserError(user_action, error_body);
                        this.setState({ show_error_message: url.dynamic_content.error_message.incorrect_postal_code.en })
                    }
                    else {
                        this.setState({ show_error_message: "All good" })
                        this.handleSubmitAddress(temp_address)
                    }
                }
            }
            else {
                if (temp_address.addressline1 == "" || temp_address.city == "" || temp_address.province == "" || temp_address.postalcode == "") {
                    let error_body = url.dynamic_content.error_message.all_fields_required.en
                    functions.logUserError(user_action, error_body);
                    this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
                }
                else {
                    if (temp_address.postalcode_length != 7) {
                        let error_body = url.dynamic_content.error_message.incorrect_postal_code.en
                        functions.logUserError(user_action, error_body);
                        this.setState({ show_error_message: url.dynamic_content.error_message.incorrect_postal_code.en })
                    }
                    else {
                        this.setState({ show_error_message: "All good" })
                        this.handleSubmitAddress(temp_address)
                    }
                }
            }
        }
        else if (this.state.country == "USA") {
            if (temp_address.addressline1 == "" || temp_address.city == "" || temp_address.province == "" || temp_address.postalcode == "") {
                let error_body = url.dynamic_content.error_message.all_fields_required.en
                functions.logUserError(user_action, error_body);
                this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
            }
            else {
                this.setState({ show_error_message: "All good" })
                this.handleSubmitAddress(temp_address)
            }
        }
        else if (this.state.country != "" && this.state.country != "USA" && this.state.country != "Canada") {
            if (temp_address.addressline1 == "" || temp_address.city == "" || temp_address.province == "" || temp_address.postalcode == "") {
                let error_body = url.dynamic_content.error_message.all_fields_required.en
                functions.logUserError(user_action, error_body);
                this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
            }
            else {
                this.setState({ show_error_message: "All good" })
                this.handleSubmitAddress(temp_address)
            }
        }
        else {
            this.setState({ show_error_message: "All good" })
            this.handleSubmitAddress(temp_address)
        }
    }

    handleformateDate = (date) => {
        var d = new Date(date)
        if (d == "Invalid Date") {
            let temp_date = date.split(" ")
            if (temp_date.length > 1) {
                try {
                var month = temp_date[0].split("/")[1],
                    day = temp_date[0].split("/")[0],
                    year = temp_date[0].split("/")[2],
                    hours = temp_date[1].split(":")[0],
                    minutes = temp_date[1].split(":")[1],
                    seconds = temp_date[1].split(":")[2]
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                let final_date = [year, month, day].join('/');
                return final_date;
            } catch (error) {
                // Handle any errors from parsing the string
                console.error('Error parsing date string:', error);
                return "0000-00-00 00:00:00";
            }
            } else {
                return "0000-00-00 00:00:00";
            }
        } else {
            var month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear(),
                hours = d.getHours(),
                minutes = d.getMinutes(),
                seconds = d.getSeconds();
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            let final_date = [year, month, day].join('/');
            return final_date
        }
    }

    handleSubmitAddress = (temp_address) => {
        delete temp_address.postalcode_length
        let manual_address = true
        if (this.state.manual_address == false) {
            manual_address = false
        }
        let address = temp_address
        address.country = this.state.country
        address.toggle_element = this.state.toggle_element
        address.sub_selected = this.state.sub_selected
        let userdata = [{ Name: 'address', Value: JSON.stringify(address) }, { Name: 'custom:manual_address', Value: (manual_address).toString() }];
        let dynamo_userdata = { 'address': JSON.stringify(address), 'country': this.state.country, 'postalcode': temp_address.postalcode, 'province': temp_address.province, 'manual_address': manual_address, 'address_updated_by_user': "true", address_update_by_user_date: this.handleformateDate(new Date().toLocaleDateString()), address_update_by_user_time: new Date().toLocaleTimeString() };
        localStorage.setItem("address", JSON.stringify(address))
        functions.updateUserData(this, userdata, dynamo_userdata);
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        let up_add = localStorage.getItem("upadd") == "true" ? localStorage.getItem("upadd") : "";
        if (up_add == "true") {
            cookie.remove("auto_id", { path: "/" })
            let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
            localStorage.clear();
            localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
            // this.props.history.push("/")
            this.setState({ main_loader: true })
            let username_search = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
            functions.getUserData(this, username_search, 'login')
        }
        else {
            if (previous_state == "editprofile") {
                functions.pushToRoute(this, "editprofile", 'address');
            }
            else {
                functions.pushToRoute(this, "buildyourfamily", "address");
            }
        }
    }

    handleChange = (event) => {
       
        let state = this.state
        this.setState({ ...state, [event.target.name]: event.target.checked });
    }

    handleSubOptionsChange = (event, element) => {
        if (element == 'Apt' || element == 'Unit' || element == 'Suite' || element == 'Entrance') {
            let value = event.target.value;
            if (value.length > 0) {
                this.setState({ toggle_street_address: true, street_address_focus: false, show_additional: true })
            } else {
                this.setState({ toggle_street_address: true, street_address_focus: false, show_additional: true })
            }
            if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                this.setState({
                    [element]: event.target.value
                })
            } 
        }
        else {
            if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                let value = event.target.value;
                if (value.length > 0) {
                    this.setState({ toggle_street_address: true, street_address_focus: false, show_additional: true })
                } else {
                    this.setState({ toggle_street_address: true, street_address_focus: false, show_additional: true })
                }
                this.setState({ [element]: event.target.value })
            }
        }
    }

    handleRuralExtraClick = (element) => {
        let prev_value = this.state[element]
        if (prev_value == "true") {
            this.setState({
                [element]: "false"
            })
        } else {
            this.setState({
                [element]: "true",
                PO_Box: "",
                ["PO Box"]: ""
            })
        }
    }

    getAddressData = async (addressline1) => {
        if (addressline1.length < 5) {
            return {}; // or return null, or any empty value you prefer
        }

        this.setState({ main_loader: true });

        const lambdaEndpoint = 'https://7qduefcepd.execute-api.us-east-1.amazonaws.com/dev/OpenAIHandler3';
        let parsedContent = '';
        let attempts = 0;
        const maxAttempts = 5;
    
        while (attempts < maxAttempts) {
            const lambdaResponse = await fetch(lambdaEndpoint, {
                method: 'POST',
                body: JSON.stringify({ address: addressline1 })
            });
    
            const data = await lambdaResponse.json();
    
            // Validate and parse the JSON content
            if (data && data.choices && data.choices.message && data.choices.message.content) {
                try {
                    parsedContent = JSON.parse(data.choices.message.content);
                    break; // Exit the loop if valid JSON is parsed
                } catch (e) {
                    // If JSON parsing fails, continue to retry
                    attempts++;
                }
            } else {
                // If the response format is incorrect, continue to retry
                attempts++;
            }
    
            if (attempts >= maxAttempts) {
                this.setState({ main_loader: false });
                return {}; // Return an empty object or handle the error as needed
            }
        }
    
        this.setState({ main_loader: false });
        return parsedContent;
    };
    


    autocomplete_toggle = async (value) => {
        if (value != "close") {
            let action = "User clicked on " + value + " on address page"
            let image = value + '_button_address_page.png'
            functions.logUserActivity(action, image)
        }
        if (value == "cancel") {
            this.setState(({
                search_key: ''
            }));
        }
        if (value == "open") {
            this.setState(({
                search_key: this.state.addressline1
            }));
        }
        if (value == "enter_manually") {
            this.setState(prevState => ({
                addressReportData: {
                    ...prevState.addressReportData,
                    initial_address_type: "manual"
                }
            }));
            const addressData = await this.getAddressData(this.state.addressline1);
            // const { addressline1, Stn, city, RR, Site, Comp, province, po_box, general_delivery, postalcode } = addressData;
            const {
                addressline1 = "",
                Stn = "",
                city = "",
                RR = "",
                Site = "",
                Comp = "",
                province = "",
                po_box = "",
                general_delivery = "",
                postalcode = ""
            } = addressData || {};
            localStorage.setItem("manual_address", "true")
            this.setState(({
                search_key: this.state.addressline1,
                address_autocomplete: [],
                Apt: addressData["Apt/Unit/Suite"] || "",
                manual_address: true,
                addressline1: addressline1,
                second_city: city,
                second_province: province,
                city: "",
                province: "",
                postalcode: postalcode,
                show_error_message: "",
                address_change: false,
                show_address_select: true,
                modalAI_Response: "",
                RR: RR,
                Site: Site,
                Comp: Comp,
                po_boxGD: po_box || general_delivery

            }));
            if (RR || Site || Comp || po_box || general_delivery) {
                // If any of RR, Site, or Comp exist, set state toggle_element as "Rural" and openDrural as true
                this.setState({
                    toggle_element: "Rural",
                    openedRural: true
                });
            }
            if(postalcode){
                this.processPostalCode(postalcode);
            }
        }
    }

    // Helper function to calculate the Levenshtein distance
    levenshteinDistance = (a, b) => {
        const matrix = [];

        for (let i = 0; i <= b.length; i++) {
            matrix[i] = [i];
        }

        for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
        }

        for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
                if (b.charAt(i - 1) === a.charAt(j - 1)) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                } else {
                    matrix[i][j] = Math.min(
                        matrix[i - 1][j - 1] + 1, // substitution
                        Math.min(matrix[i][j - 1] + 1, // insertion
                            matrix[i - 1][j] + 1) // deletion
                    );
                }
            }
        }

        return matrix[b.length][a.length];
    }

    // Helper function to calculate similarity percentage
    calculateSimilarity = (a, b) => {
        const distance = this.levenshteinDistance(a, b);
        const maxLength = Math.max(a.length, b.length);
        return (1 - distance / maxLength) * 100;
    }



    handleSelectedAddress = async  (value, match) => {
        if (match === "exact_match") {
            this.setState(prevState => ({
                addressReportData: {
                    ...prevState.addressReportData,
                    final_address_type: "exact_match",
                    end_time: new Date()
                }
            }));
            const addressData = await this.getAddressData(value);

            const {
                addressline1 = "",
                Stn = "",
                city = "",
                RR = "",
                Site = "",
                Comp = "",
                province = "",
                po_box = "",
                general_delivery = "",
                postalcode = ""
            } = addressData || {};
            this.setState({
                addressline1,
                RR: RR,
                Site: Site,
                Comp: Comp,
                "PO Box": po_box,
                Apt: addressData["Apt/Unit/Suite"] || "",
                general_delivery: general_delivery ? "true" : "false",
                which_match: "",
                Stn : Stn,
                city: city,
                province: province,
                postalcode: postalcode
            });
            this.setState({ manual_address: false }, () => {
                this.handleManualAddress();
            });
        }
        if (match === "close_match") {

            if (value === this.state.close_match) {

                this.setState(prevState => ({
                    addressReportData: {
                        ...prevState.addressReportData,
                        final_address_type: "exact_match",
                        end_time: new Date()

                    }
                }));
                const addressData = await this.getAddressData(value);

                const {
                    addressline1 = "",
                    Stn = "",
                    city = "",
                    RR = "",
                    Site = "",
                    Comp = "",
                    province = "",
                    po_box = "",
                    general_delivery = "",
                    postalcode = ""
                } = addressData || {};
                this.setState({
                    addressline1,
                    RR: RR,
                    Site: Site,
                    Comp: Comp,
                    "PO Box": po_box,
                    Apt: addressData["Apt/Unit/Suite"] || "",
                    general_delivery: general_delivery ? "true" : "false",
                    which_match: "",
                    Stn : Stn,
                    city: city,
                    province: province,
                    postalcode: postalcode
                });
                this.setState({ manual_address: false }, () => {
                    this.handleManualAddress();
                });
            }
            if (value === this.state.formattedAI_address1) {

                this.setState(prevState => ({
                    addressReportData: {
                        ...prevState.addressReportData,
                        final_address_type: "close_match",
                        end_time: new Date()

                    }
                }));
                const addressData = await this.getAddressData(value);

                const {
                    addressline1 = "",
                    Stn = "",
                    city = "",
                    RR = "",
                    Site = "",
                    Comp = "",
                    province = "",
                    po_box = "",
                    general_delivery = "",
                    postalcode = ""
                } = addressData || {};
                this.setState({
                    addressline1,
                    RR: RR,
                    Site: Site,
                    Comp: Comp,
                    "PO Box": po_box,
                    Apt: addressData["Apt/Unit/Suite"] || "",
                    general_delivery: general_delivery ? "true" : "false",
                    which_match: "",
                    Stn : Stn,
                    city: city,
                    province: province,
                    postalcode: postalcode
                });
                this.setState({ manual_address: true }, () => {
                    this.handleManualAddress();
                });
            }
        }

        if (match === "no_match") {

            this.setState(prevState => ({
                addressReportData: {
                    ...prevState.addressReportData,
                    final_address_type: "no_match",
                    end_time: new Date()

                }
            }));
            const addressData = await this.getAddressData(value);

            const {
                addressline1 = "",
                Stn = "",
                city = "",
                RR = "",
                Site = "",
                Comp = "",
                province = "",
                po_box = "",
                general_delivery = "",
                postalcode = ""
            } = addressData || {};
            this.setState({
                addressline1,
                RR: RR,
                Site: Site,
                Comp: Comp,
                "PO Box": po_box,
                Apt: addressData["Apt/Unit/Suite"] || "",
                general_delivery: general_delivery ? "true" : "false",
                which_match: "",
                Stn : Stn,
                city: city,
                province: province,
                postalcode: postalcode
            });
            this.setState({ manual_address: true }, () => {
                this.handleManualAddress();
            });

        }

    }

    extractNumericParts = (str) => {
        // Remove the postal code from the string
        const withoutPostalCode = str.replace(/\b[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d\b/g, '');
    
        // Extract numeric parts from the remaining string
        return withoutPostalCode.match(/\d+/g) || [];
    };
    

    // handleAutoComplete = (value) => {
    //     console.log("value", value)
    //     let { show_address_select } = this.state
    //     let self = this;
    //     this.setState({ search_key: value, show_error_message: '', loader: true })
    //     let country = this.state.country == "Canada" ? "CAN" : "USA"
    //     address.getAddress(this, value, country, function (response_json) {
    //         if (show_address_select == true) {
    //             if (response_json && response_json.length > 0 && response_json[0].Error == "1001") {
    //                 response_json[0].Description = "Enter Key To Search";
    //                 response_json[0].Text = "";
    //                 self.setState({ loader: false, last_address: false, address_autocomplete: response_json })
    //                 if (self.state.manual_address == true) {
    //                     self.setState({ show_address_select: true, address_change: true })

    //                 }
    //             }
    //             else {

    //                 self.setState({ loader: false, last_address: false, address_autocomplete: response_json })
    //                 if (self.state.manual_address == true) {
    //                     self.setState({ show_address_select: true, address_change: true })

    //                 }
    //             }

    //             if (self.state.manual_address === true) {
    //                 // Check if formattedAI_address matches any element.Text + " " + element.Description
    //                 let isExactMatch = response_json.some(element => {
    //                     return `${element.Text}, ${element.Description}` === value;
    //                 });

    //                 if (isExactMatch) {

    //                     // If exact match found, set manual_address to false and handleManualAddress

    //                     self.setState({
    //                         step: 2,
    //                         which_match: "exact_match"
    //                     });
    //                 } else {
    //                     // If no exact match found, find the closest match
    //                     let closestMatch = null;
    //                     let highestSimilarity = 0;
    //                     const threshold = 70; // Minimum similarity percentage threshold

    //                     response_json.forEach(element => {
    //                         const combinedText = `${element.Text}, ${element.Description}`;
    //                         const similarity = self.calculateSimilarity(combinedText, value);
    //                         if (similarity >= threshold && similarity > highestSimilarity) {
    //                             highestSimilarity = similarity;
    //                             closestMatch = combinedText;
    //                         }
    //                     });

    //                     if (closestMatch) {
    //                         console.log("2", closestMatch)

    //                         const valueNumericParts = self.extractNumericParts(value);
    //                         const closestMatchNumericParts = self.extractNumericParts(closestMatch);

    //                         const numericPartsAreEqual = JSON.stringify(valueNumericParts) === JSON.stringify(closestMatchNumericParts);

    //                         if (numericPartsAreEqual) {
    //                             console.log("21", valueNumericParts, closestMatchNumericParts)
    //                             self.setState({ close_match: closestMatch, step: 2, which_match: "close_match" });
    //                         } else {
    //                             console.log("22", valueNumericParts, closestMatchNumericParts)


    //                             self.setState({
    //                                 step: 2,
    //                                 which_match: "no_match"
    //                             });
    //                         }
    //                     } else {
    //                         console.log("3")
                

    //                         self.setState({
    //                             step: 2,
    //                             which_match: "no_match"
    //                         });
    //                     }
    //                 }
    //             }

    //         }

    //     })
    // }


    handleAutoComplete = (value) => {
        let { show_address_select } = this.state;
        let self = this;
        this.setState({ search_key: value, show_error_message: '', loader: true });
        let country = this.state.country == "Canada" ? "CAN" : "USA";
    
        address.getAddress(this, value, country, function (response_json) {
            if (show_address_select == true) {
                if (response_json && response_json.length > 0 && response_json[0].Error == "1001") {
                    response_json[0].Description = "Enter Key To Search";
                    response_json[0].Text = "";
                    self.setState({ loader: false, last_address: false, address_autocomplete: response_json });
                    if (self.state.manual_address == true) {
                        self.setState({ show_address_select: true, address_change: true });
                    }
                } else {
                    self.setState({ loader: false, last_address: false, address_autocomplete: response_json });
                    if (self.state.manual_address == true) {
                        self.setState({ show_address_select: true, address_change: true });
                    }
                }
    
                if (self.state.manual_address === true) {
                    // Call getPlacesApiAddress if manual_address is true


                        let isExactMatch = response_json.some(element => {
                            return `${element.Text}, ${element.Description}` === value;
                        });
    
                        if (isExactMatch) {
                            self.setState({
                                step: 2,
                                which_match: "exact_match"
                            });
                        } else {
                            // If no exact match found, find the closest match
                            let closestMatch = null;
                            let highestSimilarity = 0;
                            const threshold = 70; // Minimum similarity percentage threshold
    
                            response_json.forEach(element => {
                                const combinedText = `${element.Text}, ${element.Description}`;
                                const similarity = self.calculateSimilarity(combinedText, value);
                                if (similarity >= threshold && similarity > highestSimilarity) {
                                    highestSimilarity = similarity;
                                    closestMatch = combinedText;
                                }
                            });
    
                            if (closestMatch) {
                                
                                const valueNumericParts = self.extractNumericParts(value);
                                const closestMatchNumericParts = self.extractNumericParts(closestMatch);
    
                                const numericPartsAreEqual = JSON.stringify(valueNumericParts) === JSON.stringify(closestMatchNumericParts);
    
                                if (numericPartsAreEqual) {
                                    self.setState({ close_match: closestMatch, step: 2, which_match: "close_match" });
                                } else {
                                    self.setState({
                                        step: 2,
                                        which_match: "no_match"
                                    });
                                }
                            } else {
                                self.setState({
                                    step: 2,
                                    which_match: "no_match"
                                });
                            }
                        }
                
                }
            }
        });
    };
    
    


    handleAddressOption = (value) => {
       this.setState({ toggle_element: value })
        this.toggleRural()

    }

    getFindAddress = (value) => {
        let self = this;
        this.setState({ show_error_message: '' })
        self.setState({ loader: true })
        let country = this.state.country == "Canada" ? "CAN" : "USA";
        address.findAddress(this, value.Text, value.Id, country, function (response_json) {
            self.setState({ loader: false })
            if (response_json[0].Error == "1001") {
                response_json[0].Description = "Enter Key To Search";
                response_json[0].Text = "";
                self.setState({ last_address: false, address_autocomplete: response_json })
            }
            else {
                self.setState({ last_address: false, address_autocomplete: response_json })
            }
        })
        self.handleManualAddress()
    }

    getFindAddressById = (value) => {
        let self = this;
        this.setState({ show_error_message: '' })
        self.setState({ loader: true })
        let country = this.state.country == "Canada" ? "CAN" : "USA";
        address.findAddressById(this, this.state.search_key, value.Id, country, function (response_json) {
            self.setState({ loader: false })
            if (response_json[0].Error == "1001") {
                response_json[0].Description = "Enter Key To Search";
                response_json[0].Text = "";
                self.setState({ last_address: false, address_autocomplete: response_json })
            }
            else {
                self.setState({ last_address: true, address_autocomplete: response_json })
            }
        })
        self.handleManualAddress()
    }

    handleSetAddress = (value) => {
        this.setState({ show_address_select: false })
        if (value.Next == "Retrieve" || value.IsRetrievable == true) {
            this.autocomplete_toggle('close')
            this.setState({ address_autocomplete: [] })
            this.handleSetAddressFindByID(value)
        } else if (value.Next == "Find") {
            if (value.Description.split(',').length == 3) {
                this.setState({ address_autocomplete: [] })
                this.handleSetAddressFindByID(value)
            } else {
                this.getFindAddress(value)
            }
        }
        else if (value.IsRetrievable == false) {
            this.getFindAddressById(value)
        }
        // if (value.Next == "Find") {
        //     this.getFindAddress(value)
        // }
        // if (value.IsRetrievable == false) {
        //     this.getFindAddressById(value)
        // }
    }

    handleState = (value) => {

        this.setState({ province: value, modal_account: false })
        this.setState({ normal_address: false, manual_address: true, show_error_message: '' });
    }

    handleSetAddressFindByID = async (value) => {
        let self = this;
        self.setState({ loader: true })
        let address_part = value.Description.trim().split(',')
        self.setState({ loader: false })

        const addressData = await this.getAddressData(value.Text + ' ' + value.Description);
        const { addressline1, Stn, city, RR, Site, Comp, province, po_box, general_delivery, postalcode } = addressData;
        localStorage.setItem("manual_address", "false")
        this.setState(({
            search_key: this.state.addressline1,
            address_autocomplete: [],
            Apt: addressData["Apt/Unit/Suite"],
            normal_address: true,
            manual_address: false,
            addressline1: addressline1,
            second_city: city,
            second_province: province,
            city: "",
            province: "",
            postalcode: postalcode,
            show_error_message: "",
            address_change: false,
            show_address_select: true,
            modalAI_Response: "",
            RR: RR,
            Site: Site,
            Comp: Comp,
            po_boxGD: po_box || general_delivery

        }));
        if (RR || Site || Comp || po_box || general_delivery) {
            // If any of RR, Site, or Comp exist, set state toggle_element as "Rural" and openDrural as true
            this.setState({
                toggle_element: "Rural",
                openedRural: true
            });
        }
        this.processPostalCode(postalcode);
        self.autocomplete_toggle('close')
    }

    GetInfo = () => {
        let self = this;
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
        sub_id = sub_id ? sub_id : "";
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email");
        email = email ? email : "";
        
        if (sub_id !== "" && email !== "" && email !== null) {
            functions.getuserChildAndrelative(self, email, sub_id, function (response_json) {
                let response;
                try {
                    response = JSON.parse(response_json);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    return;
                }
                
                if (response.catch_error) {
                } else if (!response.catch_error) {
                    if (response.status === 200) {
                        let child_data = (response.data.child_data.Items[0] && response.data.child_data.Items[0].child_data) ? response.data.child_data.Items[0].child_data : [];
                        let relation_data = response.data.relation_data ? response.data.relation_data : {};
    
                        if (child_data && child_data.length > 0) {
                            localStorage.setItem("child_data", JSON.stringify(child_data));
                        }

                        let relation_data_final = [];
                        if (Object.keys(relation_data).length > 0) {
                            for (var key in relation_data) {
                                relation_data[key].forEach(element => {
                                    if (key === 'approvedRelations') {
                                        element.relation_status = 'approved_relations';
                                    }
                                    if (key === 'pendingRelation') {
                                        element.relation_status = 'pending_relation';
                                    }
                                    if (key === 'pendingInvitation') {
                                        element.relation_status = 'pending_invitation';
                                    }
                                    relation_data_final.push(element);
                                });
                            }
                            localStorage.setItem("relation_data", JSON.stringify(relation_data_final));
                        }
                    }
                }
            });
        }
    };
    

    componentWillMount = () => {
        let current_state = localStorage.getItem('current_state');
        let previous_state = localStorage.getItem('previous_state');
        if (previous_state !== "editprofile" && current_state !== 'address')
            functions.getLandingPage(this);
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let upadd = localStorage.getItem("upadd") ? localStorage.getItem("upadd") : ""
        if (upadd == "") {
            this.GetInfo()
        }

        let action = "User landed on address page"
        let image = 'address_page_landing.png'
        functions.logUserActivity(action, image)
        let address = localStorage.getItem("address") ? JSON.parse(localStorage.getItem("address")) : {}

        if (address.country == "Canada") {
            

            this.setState({
                normal_address: true,
                manual_address: false,
                Apt: address.Apt ? address.Apt : "",
                country: address.country ? address.country : "",
                selected_country: address.country ? address.country : "",
                addressline1: address.addressline1 ? address.addressline1 : "",
                city: address.city ? address.city : "",
                province: address.province ? address.province : "",
                postalcode: address.postalcode ? address.postalcode : "",
                STN: address.STN ? address.STN : "",
                ["PO Box"]: address.PO_Box ? address.PO_Box : "",
                [address.sub_selected]: address[address.sub_selected] ? address[address.sub_selected] : "",

                sub_selected: address.sub_selected ? address.sub_selected : "",
                show_additional: address.show_additional ? address.show_additional : false,
                additional: (address.show_additional == true && (address.PO_Box != "" || address.STN != "")) ? true : false,
                toggle_street_address: address.toggle_street_address ? address.toggle_street_address : false,
                toggle_element: address.toggle_element ? address.toggle_element : "",
            })
            if (address.toggle_element == "Sub") {
                this.setState({
                    openedSub: true,
                    ["Buzzer code"]: address["Buzzer code"] ? address["Buzzer code"] : "",
                    Entrance: address.Entrance ? address.Entrance : "",
                    Building: address.Building ? address.Building : "",
                    ["Buzzer code_select"]: address["Buzzer code"] && address["Buzzer code"] != "" ? "true" : "false",
                    Entrance_select: address.Entrance && address.Entrance != "" ? "true" : "false",
                    Building_select: address.Building && address.Building != "" ? "true" : "false",
                })

            }

            if (address.toggle_element == "Rural") {
                this.setState({
                    openedRural: true,
                    RR: address.RR ? address.RR : "",
                    Site: address.Site ? address.Site : "",
                    Comp: address.Comp ? address.Comp : "",

                    RR_select: address.RR && address.RR != "" ? "true" : "false",
                    Site_select: address.Site && address.Site != "" ? "true" : "false",
                    Comp_select: address.Comp && address.Comp != "" ? "true" : "false",

                    LCD: address.LCD ? address.LCD : "false",
                    general_delivery: address.general_delivery ? address.general_delivery : "false",
                    rural_selected: address.rural_selected,
                    [address.rural_selected]: address[address.rural_selected]
                })

            }
        }
    }

    handleAddressFormat = (event, state_name) => {

        if (state_name === 'addressline1') {
            if (!event.target.value.match(/^[a-zA-Z0-9-'àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ. ]*$/)) {
            }
            else {
                this.setState({
                    addressline1: event.target.value
                });
            }
        }
        else if (state_name === 'city') {
            if (!event.target.value.match(/^[a-zA-Z-'àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ. ]*$/)) {
            }
            else {
                this.setState({
                    city: event.target.value
                });
            }
        }
        else if (state_name === 'province') {
            if (!event.target.value.match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
            }
            else {
                this.setState({
                    province: event.target.value
                });
            }
        }
        //for postal code
        else if (state_name === 'postalcode' && event.target.value.length < 8) {
            if (this.state.postalcode) {
                if (this.state.postalcode.length < event.target.value.length) {
                    if (event.keyCode === 0) {
                        return false;
                    }
                    else if (
                        event.target.value.length === 3 &&
                        this.state.postalcode.length === 2
                    ) {
                        if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                        }
                        else {
                            this.setState({
                                postalcode: event.target.value.toUpperCase() + " ",
                                show_error: false
                            });
                        }

                    }
                    else if (
                        event.target.value.length === 4 &&
                        this.state.postalcode.length === 3
                    ) {
                        if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                        }
                        else {
                            this.setState({
                                postalcode: this.state.postalcode.toUpperCase() + " " + event.target.value.toUpperCase().slice(-1),
                                show_error: false
                            });
                        }

                    }

                    else if ((event.target.value).length < 4) {
                        if ((event.target.value).length % 2 === 0) {
                            if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                        else {
                            if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                    }

                    else if ((event.target.value).length > 4) {
                        if ((event.target.value).length % 2 !== 0) {
                            if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                        else {
                            if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                    }
                }
                else {
                    if (event.keyCode === 0) {
                        return false;
                    }
                    else if (
                        event.target.value.length === 3 &&
                        this.state.postalcode.length === 2
                    ) {
                        this.setState({
                            postalcode: event.target.value.toUpperCase() + " ",
                            show_error: false
                        });
                    }

                    else if ((event.target.value).length < 4) {
                        if ((event.target.value).length % 2 === 0) {
                            if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                        else {
                            if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                    }

                    else if ((event.target.value).length > 4) {
                        if ((event.target.value).length % 2 !== 0) {
                            if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                        else {
                            if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                    }
                    else if ((event.target.value).length === 4) {
                        this.setState({
                            postalcode: (event.target.value.toUpperCase()).trim(),
                            show_error: false
                        });
                    }
                }
            } else {
                if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                }
                else {
                    this.setState({
                        postalcode: event.target.value.toUpperCase(),
                        show_error: false
                    });
                }
            }
        }
    }

    handleFocusTextField = () => {
        if (this.state.toggle_element == "Rural") {
            this.Site.focus()
        }
        else if (this.state.toggle_element == "House") {
            this.city.focus()
        }
        else {
            this[this.state.toggle_element].focus()
        }
    }

    handleBack = () => {
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        if (previous_state == "editprofile") {
            this.props.history.push("/editprofile")
        }
        else {
            this.props.history.push("/create_password")
        }
    }

    // handleFormatAddressAI = async () => {
    //     try {
    //         const lambdaEndpoint = 'https://7qduefcepd.execute-api.us-east-1.amazonaws.com/dev/OpenAIHandler2'; // Default Lambda endpoint

    //         const lambdaResponse = await fetch(lambdaEndpoint, {
    //             method: 'POST',
    //             body: JSON.stringify({
    //                 address: this.state.addressline1 // Example address
    //             }),
    //         });

    //         const data = await lambdaResponse.json();

    //         if (data.choices.message.content.toLowerCase().includes('yes')) {
    //             // If the response contains "Yes" or "yes", execute a different Lambda endpoint
    //             const secondLambdaEndpoint = 'https://7qduefcepd.execute-api.us-east-1.amazonaws.com/dev/OpenAIHandler'; // Another Lambda endpoint

    //             const secondLambdaResponse = await fetch(secondLambdaEndpoint, {
    //                 method: 'POST',
    //                 body: JSON.stringify({
    //                     address: this.state.addressline1 // Example address
    //                 }),
    //             });

    //             const secondData = await secondLambdaResponse.json();
    //             this.setState({
    //                 modalAI_Response: secondData.choices.message.content,

    //             });
    //             this.handleAutoComplete(secondData.choices.message.content);
    //         } else {
    //             // If the response is not "Yes" or doesn't contain "Yes", continue with the default endpoint
    //             this.setState({
    //                 modalAI: true,
    //                 modalAI_Content: data.choices.message.content,
    //             });
    //         }
    //     } catch (error) {
    //         console.error('Error calling Lambda function:', error);
    //     }
    // };
    handleInputChange = (event) => {
        const { value } = event.target;
        const { addresses } = this.state;

        // Check if value is empty
        if (!value.trim()) {
            this.setState({
                filteredAddresses: [],
                showSuggestions: false
            });
            return;
        }

        // Filter addresses based on input
        const filteredAddresses = addresses.filter(address =>
            address && address.toLowerCase().includes(value.toLowerCase())
        );

        this.setState({
            filteredAddresses: filteredAddresses,
            showSuggestions: filteredAddresses.length > 0
        });
    };


    handleSuggestionClick = (suggestion) => {
        // Split the suggestion string by space
        const suggestionParts = suggestion.split(" ");

        // // Check if the first two parts are numbers
        // if (!isNaN(suggestionParts[0]) && !isNaN(suggestionParts[1])) {
        //     // If yes, extract the first number as Apt/Unit/Suite
        //     const AptUnitSuite = suggestionParts.shift();

        //     // Join the remaining parts to form the address line
        //     const addressline1 = suggestionParts.join(" ");

        //     // Update the state
        //     this.setState({
        //         addressline1: addressline1,
        //         Apt: AptUnitSuite,
        //         showSuggestions: false
        //     });
        // } else {
        // If not, set the entire suggestion as the address line
        this.setState({
            addressline1: suggestion,
            Apt: "", // Reset Apt/Unit/Suite
            showSuggestions: false
        });
        // }
    };


    processCSVData = (csvData) => {
        // Split the CSV data by lines
        const lines = csvData.split('\n');

        if (lines.length === 0) {
            console.error("CSV file is empty.");
            return;
        }
        // Extract city and province from the first line
        const [_, city, ...rest] = lines[0].split(',');

        const addresses = lines.map((line) => {
            const [address, ...rest] = line.split(',');
            return address.trim();
        });
        

        // Update state with the entire list of addresses
        this.setState({
            addresses: addresses,
            city: city || this.state.second_city,
            original_city: city || this.state.second_city
        });

    };

    processPostalCode = async (postalCode) => {
        const postalCodePrefix = postalCode.substring(0, 3);
        const key = `Canada_address/${postalCodePrefix}/${postalCode}/address1.csv`;

        try {
            const s3 = new AWS.S3({
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                region: process.env.REACT_APP_AWS_REGION
            });

            const params = {
                Bucket: 'familyonecanadaaddress', // Replace with your S3 bucket name
                Key: key
            };

            let csvData = null;
            try {
                const data = await s3.getObject(params).promise();
                csvData = data.Body.toString('utf-8');
                
            } catch (error) {
                this.setState({
                    city: this.state.second_city, // Default value for city state
                    original_city: this.state.second_city
                });
            }

            // Process CSV data if available
            if (csvData) {
                this.processCSVData(csvData);
            }


            const addressInfo = fsa[postalCodePrefix];

            if (addressInfo) {
                const parts = addressInfo.split(' ');
                let province = null;
                let STN = '';
                const shortForms = [
                    "AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "QC", "SK", "NT", "NU", "YT"
                ];

                for (let i = 0; i < parts.length; i++) {
                    const part = parts[i];
                    const match = shortForms.find(shortForm => part.toLowerCase() === shortForm.toLowerCase());
                    if (match) {
                        province = match;
                        if (i < parts.length - 1) {
                            STN = parts.slice(i + 1).join(' ');
                        }
                        break;
                    }
                }

                if (province) {
                    this.setState({
                        province: province || this.state.second_province, STN: STN, toggle_element: "Rural"
                    });
                } else {
                    console.log(`No province/territory found in addressInfo`);
                }
            } else {
                console.log(`No address information found for postal code prefix ${postalCodePrefix}`);
            }
        } catch (error) {
            console.log('Error reading CSV file from S3:', error);
        }
    };


    onChangeZipCode = (event) => {
        const enteredPostalCode = event.target.value.trim().toUpperCase();

        // Check if the entered postal code matches the pattern
        if (/^[A-Z]\d[A-Z] \d[A-Z]\d$/.test(enteredPostalCode)) {
            // Call the function to process the postal code
            this.processPostalCode(enteredPostalCode);
        } else {
            // Invalid postal code format
            console.log("Invalid postal code format");
        }

        // Update state with entered postal code
        this.setState({ postalcode: enteredPostalCode });
    };


    render() {
        const { dynamic_content, step } = this.state
        const closePoints = <button className="closeAddressModel" onClick={() => { this.closeToggle(this, 'modal_account') }}>&times;</button>;
        const closeSelection = <button className="closeAddressModel" onClick={() => { this.toggleModal('countryModal') }}>&times;</button>;
        const closeSubSelection = <button className="closeAddressModel" onClick={() => { this.toggleModal('subOptionModal') }}>&times;</button>;
        return (
            <div  ref={this.scrollToTopRef} id="scrollToTop" className="container cstmSignUp">
                {(dynamic_content != "" && step === 1) &&
                    <div className="row">
                        {this.state.main_loader &&
                            <div className="common_main common_mainSgn">
                                <center>
                                    <CircularProgress className="loader_class" color="secondary" />
                                </center>
                            </div>
                        }
                        {!this.state.main_loader &&
                            <div className="common_main common_mainSgn">
                                <div className="mailAdd mailAddCstm">
                                    {this.state.session_type != "signup" &&
                                        <div className="sharegain_backlink white_circle_div"><img onClick={() => { functions.pushToRoute(this, "editprofile", 'address') }} src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div>
                                    }
                                    <h1>{this.state.session_type == "signup" ? "Sign Up" : ""}</h1>
                                    <h2>{this.state.session_type == "signup" ? "Mailing Address" : "Edit Address"}</h2>
                                </div>
                                {this.state.selected_country == "Canada" && (this.state.manual_address || this.state.normal_address) ? "" :
                                    <div className="addParagraph">
                                        <p>This is where your Freebies and Prizes are delivered.
                                            Please use the address found on a letter addressed to you.</p>
                                    </div>
                                }
                                {/* For country options */}
                                <div className="cstmSelCountry selCntryAddon" onClick={() => this.openSelector('countryModal')}>
                                    {/* {this.state.country_options && this.state.country_options.length > 0 && this.state.country_options.map((element, index) => ( */}
                                    {/* <div key={"country_options_" + index} className="selCntryBtn"><Button onClick={() => { this.handleSelectCountry(element) }} size="medium" color="primary" className={this.state.selected_country == element ? "selCntryActv" : ""} >{element}</Button></div> */}
                                    <p >{!this.state.country ? "Select" : this.state.country}</p>
                                    <img src={require('../../assets/images/updown.svg')} alt="" title=""
                                        className="cstmSelectArow" />
                                    {/* ))} */}
                                </div>
                                {/* For country options */}
                                {/* Address form screen for Canada country*/}
                                {this.state.selected_country == "Canada" && !this.state.manual_address && !this.state.normal_address &&
                                    <div className="houseFormWrap 222">
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                hiddenlabel={"Address"}
                                                placeholder="Full Address or PO Box"
                                                inputRef={(input) => { this.addressline1 = input; }}
                                                value={this.state.addressline1}
                                                type="text"
                                                //disabled={this.state.manual_address ? false : true}
                                                autoFocus={false}

                                                onChange={(event) => {
                                                    if (this.state.manual_address == true) {
                                                        if (event.target.value.match(/^[a-zA-Z-0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                            this.setState({ addressline1: event.target.value, show_error_message: '', modalAI_Response: "" })
                                                        }
                                                    } else {
                                                        this.setState({ show_address_select: true, address_change: true })
                                                        if (event.target.value.match(/^[a-zA-Z-0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                            this.setState({ addressline1: event.target.value, show_error_message: '' })
                                                        }
                                                        this.handleAutoComplete(event.target.value)
                                                    }
                                                }}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter" && this.state.manual_address == true) {
                                                        this.handleFocusTextField();
                                                    }
                                                }}
                                            />
                                        </div>

                                        {this.state.address_change && this.state.show_address_select &&
                                            <div className="addressParentUpr">
                                                {this.state.address_autocomplete && this.state.address_autocomplete.length > 0 && this.state.address_autocomplete.map((element, index) => (
                                                    <div key={index} className="addressChildUpr">
                                                        {this.state.last_address == true &&
                                                            <p onClick={() => { this.handleSetAddress(element) }}>{element.Suggestion}</p>
                                                        }
                                                        {this.state.last_address == false &&
                                                            <p onClick={() => { this.handleSetAddress(element) }}>{element.Text + " " + element.Description}</p>
                                                        }
                                                    </div>

                                                ))}
                                                {this.state.address_autocomplete && this.state.address_autocomplete.length == 0 &&
                                                    <div className="addressChildUpr">
                                                        <p>No matching address found</p>
                                                    </div>
                                                }
                                            </div>
                                        }

                                        {this.state.show_error_message && this.state.show_error_message != "" &&
                                            <p className="error_message">
                                                {this.state.show_error_message}
                                            </p>
                                        }
                                        <div className="enterManullyBtn">
                                            <Button color="secondary" onClick={() => { this.autocomplete_toggle('enter_manually') }}>{dynamic_content.static_text.address ? dynamic_content.static_text.address.enter_manually.en : "Enter Manually"}</Button>
                                        </div>

                                    </div>
                                }
                                {this.state.selected_country == "Canada" && (this.state.manual_address || this.state.normal_address) &&
                                    <div className="houseFormWrap">
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                hiddenlabel={"Zip Code"}
                                                placeholder={"Postal Code"}
                                                name={"Zip Code"}
                                                type="text"

                                                inputRef={(input) => { this.postalcode = input; }}
                                                value={this.state.postalcode}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.city.focus()
                                                    }
                                                }}
                                                onChange={event => {
                                                    if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                        this.setState({ normal_address: false, manual_address: true });

                                                        if (event.target.value.length < 8) {
                                                            if (event.keyCode === 0) {
                                                                return false;
                                                            }
                                                            else if (
                                                                event.target.value.length === 3 &&
                                                                this.state.postalcode.length === 2
                                                            ) {
                                                                this.setState({
                                                                    show_error_message: '',
                                                                    postalcode:
                                                                        event.target.value.toUpperCase() + " "
                                                                });
                                                            } else {
                                                                this.onChangeZipCode(event)
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                hiddenlabel={dynamic_content.static_text.address ? dynamic_content.static_text.address.city_label.en : "City"}
                                                placeholder={dynamic_content.static_text.address ? dynamic_content.static_text.address.city_label.en : "City"}
                                                name={dynamic_content.static_text.address ? dynamic_content.static_text.address.city_label.en : "City"}
                                                type="text"
                                                inputRef={(input) => { this.city = input; }}

                                                value={this.state.city}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.province.focus()
                                                    }
                                                }}
                                                onChange={event => {
                                                    if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                        this.setState({ normal_address: false, manual_address: true });

                                                        this.setState({ city: event.target.value, show_error_message: '' })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                        <TextField
                                                autoComplete='off'
                                                hiddenlabel={this.state.selected_country == "USA" ? "State" : "Province/State"}
                                                placeholder={this.state.selected_country == "USA" ? "State" : "Province/State"}
                                                type="text"
                                                name={this.state.selected_country == "USA" ? "State" : "Province/State"}
                                                inputRef={(input) => { this.province = input; }}

                                                value={this.state.province}

                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.Apt.focus();
                                                    }
                                                    else {

                                                        this.setState({ modal_account: true, show_province: true })
                                                    }
                                                }}
                                                autoFocus={false}

                                                // onChange={event => {
                                                //     if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                //         this.setState({ normal_address: false, manual_address: true });

                                                //         this.setState({ province: event.target.value, show_error_message: '' })
                                                //     }
                                                // }}
                                                onClick={() => {
                                               
                                                    this.setState({ modal_account: true, show_province: true })
                                                }}
                                            />
                                        </div>



                                        <div className='strAdress'>
                                            <div className='strAdressLft'>
                                                <label>Apt</label>
                                                <TextField
                                                    autoComplete='off'
                                                    hiddenlabel="Apt/Unit/Suite"
                                                    //placeholder="Apt/Unit/Suite"
                                                    type="text"
                                                    name=""
                                                    inputRef={(input) => { this.Apt = input; }}

                                                    value={this.state.Apt}
                                                    onKeyPress={event => {
                                                        if (event.key === "Enter") {
                                                            this.addressline1.focus()
                                                        }
                                                    }}
                                                    onChange={event => {
                                                        if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                            this.setState({ normal_address: false, manual_address: true });

                                                            this.setState({ Apt: event.target.value, show_error_message: '' })
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className='strAdressMid'></div>
                                            <div className='strAdressRght '>
                                                <label>Street Address</label>
                                                <TextField
                                                    autoComplete='off'
                                                    hiddenlabel={"Street Address"}
                                                    //placeholder={"Street Address"}
                                                    inputRef={(input) => { this.addressline1 = input; }}
                                                    type="text"
                                                    name={this.state.selected_country == "USA" ? "State" : "Province/State"}
                                                    value={this.state.addressline1}
                                                    onKeyPress={event => {
                                                        if (event.key === "Enter") {

                                                        }
                                                    }}
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        const isValid = value.match(/^[a-zA-Z-0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/);
                                                        this.setState({ normal_address: false, manual_address: true });

                                                        // Check if value matches the pattern
                                                        if (isValid) {
                                                            // Update addressline1 state
                                                            this.setState({ addressline1: value });

                                                            // Call handleInputChange for suggestions
                                                            this.handleInputChange(event);
                                                        }
                                                    }}
                                                />
                                                {this.state.showSuggestions && (
                                                    <ul>
                                                        {this.state.filteredAddresses.map((address, index) => (
                                                            <li key={index} onClick={() => this.handleSuggestionClick(address)}>
                                                                {address}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>

                                        <div className='additionalFields'>
                                            <div className='notifyCheck'>
                                                <input
                                                    className="inp-cbx"
                                                    style={{ display: 'none' }}
                                                    type="checkbox"
                                                    id="PartnersFirst"
                                                    checked={this.state.openedRural}
                                                    onChange={event => {
                                                        this.handleAddressOption("Rural")
                                                    }}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                                <label className="cbx" for="PartnersFirst">
                                                    <span>
                                                        <svg width="12px" height="9px" viewbox="0 0 12 9">
                                                            <polyline points="1 5 4 8 11 1"></polyline>
                                                        </svg>
                                                    </span>
                                                </label>
                                            </div>
                                            <label>Additional Fields</label>
                                        </div>

                                        <div className='addinalInfo'>
                                            {this.state.openedRural &&
                                                <div className="poBoxOptional">
                                                    <TextField
                                                        autoComplete='off'
                                                        hiddenlabel="PO Box/GD"
                                                        placeholder="PO Box/GD"
                                                        type="text"

                                                        inputRef={(input) => { this.po_boxGD = input; }}
                                                        value={this.state.po_boxGD}
                                                        onKeyPress={event => {
                                                            if (event.key === "Enter") {
                                                                this.city.focus();
                                                            }
                                                        }}
                                                        onChange={event => {
                                                            const inputValue = event.target.value.trim();
                                                            this.setState({ normal_address: false, manual_address: true, po_boxGD: inputValue });

                                                        }}
                                                    />
                                                </div>
                                            }
                                            {this.state.openedRural &&
                                                <div>
                                                    <TextField
                                                        autoComplete='off'
                                                        hiddenlabel={"STN"}
                                                        placeholder={"STN"}
                                                        type="text"

                                                        inputRef={(input) => { this.STN = input; }}
                                                        value={this.state.STN}
                                                        onKeyPress={event => {
                                                            if (event.key === "Enter") {
                                                                this.city.focus();
                                                            }
                                                        }}
                                                        onChange={event => {
                                                            if (event.target.value.match(/^[a-zA-Z0-9- 'àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ. ]*$/)) {
                                                                this.setState({ STN: event.target.value, show_error_message: '' })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>

                                        <div className='rurlFrmAddUpr'>
                                            {this.state.openedRural &&
                                                <div className="rurlFormAddon">

                                                    <TextField
                                                        autoComplete='off'
                                                        //hiddenlabel={element}
                                                        placeholder="RR:"
                                                        type="text"
                                                        // InputProps={{
                                                        //     startAdornment: <InputAdornment position="start">RR:</InputAdornment>,
                                                        // }}
                                                        inputRef={(input) => { this.RR = input; }}
                                                        value={this.state.RR}
                                                        onChange={(event) => {
                                                            if (event.target.value.match(/^[a-zA-Z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                                                                this.setState({ normal_address: false, manual_address: true });

                                                                this.setState({ RR: event.target.value, show_error_message: '' })
                                                            }
                                                        }}
                                                        onKeyPress={event => {
                                                            if (event.key === "Enter") {
                                                                // this.city.focus();
                                                            }
                                                        }}

                                                    />

                                                </div>
                                            }
                                            {this.state.openedRural &&
                                                <div className="rurlFormAddon">
                                                    <TextField
                                                        autoComplete='off'
                                                        //hiddenlabel={element}
                                                        placeholder="Site:"
                                                        type="text"
                                                        // InputProps={{
                                                        //     startAdornment: <InputAdornment position="start">Site:</InputAdornment>,
                                                        // }}
                                                        inputRef={(input) => { this.Site = input; }}
                                                        value={this.state.Site}
                                                        onChange={(event) => {
                                                            if (event.target.value.match(/^[a-zA-Z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                                                                this.setState({ normal_address: false, manual_address: true });

                                                                this.setState({ Site: event.target.value, show_error_message: '' })
                                                            }
                                                        }}
                                                        onKeyPress={event => {
                                                            if (event.key === "Enter") {
                                                                // this.city.focus();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {this.state.openedRural &&
                                                <div className="rurlFormAddon">
                                                    <TextField
                                                        autoComplete='off'
                                                        //hiddenlabel={element}
                                                        placeholder="Comp:"
                                                        type="text"
                                                        // InputProps={{
                                                        //     startAdornment: <InputAdornment position="start">Comp:</InputAdornment>,
                                                        // }}
                                                        inputRef={(input) => { this.Comp = input; }}
                                                        value={this.state.Comp}
                                                        onChange={(event) => {
                                                            if (event.target.value.match(/^[a-zA-Z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                                                                this.setState({ normal_address: false, manual_address: true });

                                                                this.setState({ Comp: event.target.value, show_error_message: '' })
                                                            }
                                                        }}
                                                        onKeyPress={event => {
                                                            if (event.key === "Enter") {
                                                                // this.city.focus();
                                                            }
                                                        }}

                                                    />

                                                </div>
                                            }
                                        </div>

                                        {this.state.show_error_message && this.state.show_error_message != "" &&
                                            <p className="error_message">
                                                {this.state.show_error_message}
                                            </p>
                                        }

<div className="open_mail_model">
                            <Modal isOpen={this.state.modal_account} className="custom_Addchildmodel">

                                <ModalBody className="skilltestAddons chooseCountryAddons">

                                    <div className="country_data">

                                        <div className="country_ac">
                                            <div className="country_acLft">
                                                {!this.state.show_province &&
                                                    <label>Choose Country</label>
                                                }
                                                {this.state.show_province &&
                                                    <label>Choose Province</label>
                                                }
                                            </div>
                                            <div className="country_acRght">
                                                <ModalHeader close={closePoints} style={{ textAlign: "right" }}></ModalHeader></div>
                                        </div>

                                        <div className="country_dataList">
                                            {/* <div className="country_active"><a>Canada</a> <img src={url.family_one_image_url + 'Vector21.png'} alt="" title="" className="cntrySelect" /></div> */}
                                            {!this.state.show_province && this.state.countries && this.state.countries.length > 0 && this.state.countries.map((element, index) => (
                                                <div key={index} className={this.state.country == element ? "country_active" : ""} onClick={() => { this.handleCountry(element) }}>
                                                    <a className="other_country">
                                                        {element}
                                                    </a>
                                                    {this.state.country == element &&
                                                      <img src={require('../../assets/images/selectedCountry.svg')} alt="" title="" className="cntrySelect" />
                                                    }
                                                </div>
                                            ))}
                                            {this.state.show_province && this.state.states && this.state.states.length > 0 && this.state.states.map((element, index) => (
                                                <div key={index} className={this.state.province == element ? "country_active" : ""} onClick={() => { this.handleState(element) }}><a className="other_country">{element}</a>

                                                    {this.state.province == element &&
                                                        <img src={url.family_one_image_url + 'Vector21.png'} alt="" title="" className="cntrySelect" />
                                                    }
                                                </div>
                                            ))}

                                        </div>

                                    </div>

                                </ModalBody>
                            </Modal>
                        </div>



                                        <div className="houseFormWrapBtn">
                                        <Button
                                        disabled={this.state.cont_btn_disabled}
    onClick={() => {
        // Initialize error message
        let errorMessage = '';

        if (this.state.postalcode.trim() === '') {
            errorMessage = 'Postal code is required.';
        } else if (this.state.city.trim() === '') {
            errorMessage = 'City is required.';
        } else if (this.state.province.trim() === '') {
            errorMessage = 'Province/State is required.';
        }

        // If there's an error, update state to show the error message
        if (errorMessage) {
            this.setState({ show_error_message: errorMessage });
            return; // Stop further execution if validation fails
        }

        // Clear error message if validation passes
        this.setState({ show_error_message: '', cont_btn_disabled: true });

        // Proceed with further actions
        if (this.state.normal_address) {
            this.setState(prevState => ({
                addressReportData: {
                    ...prevState.addressReportData,
                    final_address_type: "exact_match",
                    end_time: new Date()
                },
                manual_address: false
            }), () => {
                // Callback function after state update
                this.handleManualAddress();
            });
        } else {
            this.handleFormatAddressAI();
        }
    }}
    color="secondary"
>
    {dynamic_content.static_text.address ? dynamic_content.static_text.address.continue.en : "Continue"}
</Button>
                                        </div>

                                    </div>
                                }
                                {/* End of  Address form screen  */}

                                {/* Address form screen for country other than Canada*/}
                                {this.state.selected_country != "" && this.state.selected_country != "Canada" && this.state.show_form == true &&
                                    <div className="houseFormWrap">
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                hiddenlabel={dynamic_content.static_text.address ? dynamic_content.static_text.address.street_address.en : "Street Address"}
                                                placeholder={dynamic_content.static_text.address ? dynamic_content.static_text.address.street_address.en : "Street Address"}
                                                name={dynamic_content.static_text.address ? dynamic_content.static_text.address.street_address.en : "Street Address"}
                                                type="text"
                                                id="addressline1"
                                                inputRef={(input) => { this.addressline1 = input; }}

                                                value={this.state.addressline1}
                                                autoFocus={true}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.city.focus()
                                                    }
                                                }}
                                                onChange={event => {
                                                    if (event.target.value.match(/^[a-zA-Z-0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                        this.setState({ addressline1: event.target.value, show_error_message: '' })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                hiddenlabel={dynamic_content.static_text.address ? dynamic_content.static_text.address.city_label.en : "City"}
                                                placeholder={dynamic_content.static_text.address ? dynamic_content.static_text.address.city_label.en : "City"}
                                                name={dynamic_content.static_text.address ? dynamic_content.static_text.address.city_label.en : "City"}
                                                type="text"
                                                inputRef={(input) => { this.city = input; }}

                                                value={this.state.city}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.province.focus()
                                                    }
                                                }}
                                                onChange={event => {
                                                    if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                        this.setState({ city: event.target.value, show_error_message: '' })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                hiddenlabel={this.state.selected_country == "USA" ? "State" : "Province/State"}
                                                placeholder={this.state.selected_country == "USA" ? "State" : "Province/State"}
                                                type="text"
                                                name={this.state.selected_country == "USA" ? "State" : "Province/State"}
                                                inputRef={(input) => { this.province = input; }}

                                                value={this.state.province}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.postalcode.focus()
                                                    }
                                                }}
                                                onChange={event => {
                                                    if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                        this.setState({ province: event.target.value, show_error_message: '' })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                hiddenlabel={"Zip Code"}
                                                placeholder={"Zip Code"}
                                                name={"Zip Code"}
                                                type="text"

                                                inputRef={(input) => { this.postalcode = input; }}
                                                value={this.state.postalcode}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.handleManualAddress();
                                                    }
                                                }}
                                                onChange={event => {
                                                    if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                        if (event.target.value.length < 8) {
                                                            if (event.keyCode === 0) {
                                                                return false;
                                                            }
                                                            else if (
                                                                event.target.value.length === 3 &&
                                                                this.state.postalcode.length === 2
                                                            ) {
                                                                this.setState({
                                                                    show_error_message: '',
                                                                    postalcode:
                                                                        event.target.value.toUpperCase() + " "
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    show_error_message: '',
                                                                    postalcode: event.target.value.toUpperCase()
                                                                });
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                        {this.state.show_error_message && this.state.show_error_message != "" &&
                                            <p className="error_message">
                                                {this.state.show_error_message}
                                            </p>
                                        }
                                        <div className="houseFormWrapBtn">
                                            <Button onClick={() => { this.handleFormatAddressAI() }} color="secondary">{dynamic_content.static_text.address ? dynamic_content.static_text.address.continue.en : "Continue"}</Button>
                                        </div>
                                    </div>
                                }
                                {/* End of  Address form screen  */}

                                {/* End of  Address form screen  */}

                                {/* Add Animation Here */}
                                <div className="signupConectCircleInr">
                                    <div className="signupConectInr"></div>
                                </div>
                                {/* End of Animation Here */}


                            </div>
                        }


                    </div>
                }

                {(dynamic_content != "" && step === 2) &&
                    <div className="row">
                        {this.state.main_loader &&
                            <div className="common_main common_mainSgn">
                                <center>
                                    <CircularProgress className="loader_class" color="secondary" />
                                </center>
                            </div>
                        }
                        {!this.state.main_loader &&
                            <div className="common_main common_mainSgn">
                                <div className="mailAdd mailAddCstm">
                                    {this.state.session_type != "signup" &&
                                        <div className="sharegain_backlink white_circle_div"><img onClick={() => { functions.pushToRoute(this, "editprofile", 'address') }} src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div>
                                    }
                                    <h1>{this.state.session_type == "signup" ? "Sign Up" : ""}</h1>
                                    <h2>{this.state.session_type == "signup" ? "Mailing Address" : "Edit Address"}</h2>
                                </div>


                                {/* For country options */}
                                {/* Address form screen for Canada country*/}
                                {this.state.selected_country == "Canada" && !this.state.manual_address &&
                                    <div className="houseFormWrap 111">
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                hiddenlabel={"Address"}
                                                placeholder={"Address"}
                                                inputRef={(input) => { this.addressline1 = input; }}
                                                value={this.state.addressline1}
                                                type="text"
                                                // disabled={this.state.manual_address ? false : true}
                                                autoFocus={false}

                                                onChange={(event) => {
                                                    if (this.state.manual_address == true) {
                                                        if (event.target.value.match(/^[a-zA-Z-0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                            this.setState({ addressline1: event.target.value, show_error_message: '', modalAI_Response: "" })
                                                        }
                                                    } else {
                                                        this.setState({ show_address_select: true, address_change: true })
                                                        if (event.target.value.match(/^[a-zA-Z-0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                            this.setState({ addressline1: event.target.value, show_error_message: '' })
                                                        }
                                                        this.handleAutoComplete(event.target.value)
                                                    }
                                                }}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter" && this.state.manual_address == true) {
                                                        this.handleFocusTextField();
                                                    }
                                                }}
                                            />
                                        </div>

                                        {this.state.address_change && this.state.show_address_select &&
                                            <div className="addressParentUpr">
                                                {this.state.address_autocomplete && this.state.address_autocomplete.length > 0 && this.state.address_autocomplete.map((element, index) => (
                                                    <div key={index} className="addressChildUpr">
                                                        {this.state.last_address == true &&
                                                            <p onClick={() => { this.handleSetAddress(element) }}>{element.Suggestion}</p>
                                                        }
                                                        {this.state.last_address == false &&
                                                            <p onClick={() => { this.handleSetAddress(element) }}>{element.Text + " " + element.Description}</p>
                                                        }
                                                    </div>

                                                ))}
                                                {this.state.address_autocomplete && this.state.address_autocomplete.length == 0 &&
                                                    <div className="addressChildUpr">
                                                        <p>No matching address found</p>
                                                    </div>
                                                }
                                            </div>
                                        }

                                        {this.state.show_error_message && this.state.show_error_message != "" &&
                                            <p className="error_message">
                                                {this.state.show_error_message}
                                            </p>
                                        }
                                        <div className="enterManullyBtn">
                                            <Button color="secondary" onClick={() => { this.autocomplete_toggle('enter_manually') }}>{dynamic_content.static_text.address ? dynamic_content.static_text.address.enter_manually.en : "Enter Manually"}</Button>
                                        </div>

                                    </div>
                                }
                                {this.state.selected_country == "Canada" && this.state.manual_address &&
                                    <div className="houseFormWrap">
                                        {this.state.formattedAI_address1 && this.state.close_match && this.state.which_match === "close_match" && (
                                            <div>
                                                {/* <div className='mailAdrsList'>
                                                    <input type="radio" id="address1" checked name="formattedAddress" value="address1" onChange={() => this.setState({ selectedAddress: this.state.close_match })} />
                                                    {this.state.formattedAI_address1.split(' ').map((word, index) => (
                                                    <span key={index} style={{ fontWeight: word !== this.state.close_match.split(' ')[index] ? 'bold' : 'normal', color: word !== this.state.close_match.split(' ')[index] ? 'darkred' : 'inherit' }}>
                                                        {this.state.close_match.split(' ')[index]}
                                                    </span>
                                                    ))}
                                                </div> */}

                                                <div className='selctCrctAddress'>
                                                    <label>Select the correct address</label>
                                                </div>
                                                {/* <div
                                                    className={`mailAdrsList ${this.state.selectedAddress === this.state.close_match ? 'checked' : ''}`}
                                                    onClick={() => this.setState({ selectedAddress: this.state.close_match })}
                                                >
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="address1"
                                                            name="formattedAddress"
                                                            value="address1"
                                                            checked={this.state.selectedAddress === this.state.close_match}
                                                            onChange={() => { }}
                                                        />
                                                        {this.state.formattedAI_address1.split(' ').map((word, index) => (
                                                            <span
                                                                key={index}
                                                                style={{
                                                                    fontWeight: word !== this.state.close_match.split(' ')[index] ? 'bold' : 'normal',
                                                                    color: word !== this.state.close_match.split(' ')[index] ? 'darkred' : 'inherit'
                                                                }}
                                                            >
                                                                {this.state.close_match.split(' ')[index]}
                                                            </span>
                                                        ))}
                                                    </label>
                                                </div> */}

                                                {/* <div className='mailAdrsList'>
                                                    <input type="radio" id="address2" name="formattedAddress" value="address2" onChange={() => this.setState({ selectedAddress: this.state.formattedAI_address1 })} />
                                                    {this.state.close_match.split(' ').map((word, index) => (
                                                    <span key={index} style={{ fontWeight: word !== this.state.formattedAI_address1.split(' ')[index] ? 'bold' : 'normal', color: word !== this.state.formattedAI_address1.split(' ')[index] ? 'darkred' : 'inherit' }}>
                                                        {this.state.formattedAI_address1.split(' ')[index]}
                                                    </span>
                                                    ))}
                                                </div> */}
                                                {/* <div className={`mailAdrsList ${this.state.selectedAddress === this.state.close_match ? 'checked' : ''}`} onClick={() => this.setState({ selectedAddress: this.state.close_match })}>
    <label>
        <input type="radio" id="address1" name="formattedAddress" value="address1" checked={this.state.selectedAddress === this.state.close_match} onChange={() => {}} />
        {this.state.close_match.split(',').map((part, index) => (
            <span
                key={index}
                style={{
                    fontWeight: part.trim() !== this.state.formattedAI_address1.split(',')[index].trim() ? 'bold' : 'normal',
                    color: part.trim() !== this.state.formattedAI_address1.split(',')[index].trim() ? 'darkred' : 'inherit'
                }}
            >
                {part.trim()}
                {index !== this.state.close_match.split(',').length - 1 && ','}
            </span>
        ))}
    </label>
</div> */}
                                                  <div
  className={`mailAdrsList ${this.state.selectedAddress === this.state.close_match ? 'checked' : ''}`}
  onClick={() => this.setState({ selectedAddress: this.state.close_match })}
>
  <label>
    <input
      type="radio"
      id="address1"
      name="formattedAddress"
      value="address1"
      checked={this.state.selectedAddress === this.state.close_match}
      onChange={() => {}}
    />
    {this.state.close_match.split(',').map((part, index) => {
      const formattedAI_address1 = this.state.formattedAI_address1 || ''; // Default to empty string if undefined
      const formattedParts = formattedAI_address1.split(',').map(part => part.trim());
      const partTrimmed = part.trim();
      const isDifferent = formattedParts[index] ? partTrimmed !== formattedParts[index] : true;

      return (
        <span
          key={index}
          style={{
            fontWeight: isDifferent ? 'bold' : 'normal',
            color: isDifferent ? 'darkred' : 'inherit'
          }}
        >
          {partTrimmed}
          {index !== this.state.close_match.split(',').length - 1 && ','}
        </span>
      );
    })}
  </label>
</div>

{/* <div className={`mailAdrsList ${this.state.selectedAddress === this.state.formattedAI_address1 ? 'checked' : ''}`} onClick={() => this.setState({ selectedAddress: this.state.formattedAI_address1 })}>
    <label>
        <input type="radio" id="address2" name="formattedAddress" value="address2" checked={this.state.selectedAddress === this.state.formattedAI_address1} onChange={() => {}} />
        {this.state.formattedAI_address1.split(',').map((part, index) => (
            <span
                key={index}
                style={{
                    fontWeight: part.trim() !== this.state.close_match.split(',')[index].trim() ? 'bold' : 'normal',
                    color: part.trim() !== this.state.close_match.split(',')[index].trim() ? 'darkred' : 'inherit'
                }}
            >
                {part.trim()}
                {index !== this.state.formattedAI_address1.split(',').length - 1 && ','}
            </span>
        ))}
    </label>
</div> */}
<div className={`mailAdrsList ${this.state.selectedAddress === this.state.formattedAI_address1 ? 'checked' : ''}`} onClick={() => this.setState({ selectedAddress: this.state.formattedAI_address1 })}>
    <label>
        <input
            type="radio"
            id="address2"
            name="formattedAddress"
            value="address2"
            checked={this.state.selectedAddress === this.state.formattedAI_address1}
            onChange={() => {}}
        />
        {this.state.formattedAI_address1.split(',').map((part, index) => {
            const closeMatchPart = this.state.close_match?.split(',')[index]?.trim();
            const formattedPart = part.trim();
            const isMismatch = formattedPart !== closeMatchPart;

            return (
                <span
                    key={index}
                    style={{
                        fontWeight: isMismatch ? 'bold' : 'normal',
                        color: isMismatch ? 'darkred' : 'inherit',
                    }}
                >
                    {formattedPart}
                    {index !== this.state.formattedAI_address1.split(',').length - 1 && ','}
                </span>
            );
        })}
    </label>
</div>



                                                {/* <div
                                                    className={`mailAdrsList ${this.state.selectedAddress === this.state.formattedAI_address1 ? 'checked' : ''}`}
                                                    onClick={() => this.setState({ selectedAddress: this.state.formattedAI_address1 })}
                                                >
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="address2"
                                                            name="formattedAddress"
                                                            value="address2"
                                                            checked={this.state.selectedAddress === this.state.formattedAI_address1}
                                                            onChange={() => { }}
                                                        />
                                                        {this.state.close_match.split(' ').map((word, index) => (
                                                            <span
                                                                key={index}
                                                                style={{
                                                                    fontWeight: word !== this.state.formattedAI_address1.split(' ')[index] ? 'bold' : 'normal',
                                                                    color: word !== this.state.formattedAI_address1.split(' ')[index] ? 'darkred' : 'inherit'
                                                                }}
                                                            >
                                                                {this.state.formattedAI_address1.split(' ')[index]}
                                                            </span>
                                                        ))}
                                                    </label>
                                                </div> */}

                                                <div className='mailAddresGrp'>
                                                    <div className='mailAddresGrpEdt'><button disabled={this.state.buttonDisabled} onClick={() => { this.setState({ step: 1, buttonDisabled: true, cont_btn_disabled: false, manual_address: true, close_match: "" }) }}>Or, Modify</button></div>
                                                    <div className='mailAddresGrpCntnu'><button onClick={() => { this.handleSelectedAddress(this.state.selectedAddress, "close_match") }}>Continue</button></div>
                                                </div>

                                            </div>
                                        )}
                                        {this.state.which_match === "exact_match" &&
                                            <>
                                                <div className='adresCorectQues'>
                                                    <label>Is this correct?</label>
                                                </div>
                                                <div className="adrsDetailSet">

                                                    <p>{this.state.formattedAI_address1}</p>
                                                </div>
                                                <div className='mailAddresGrp'>
                                                    <div className='mailAddresGrpEdt'><button disabled={this.state.buttonDisabled} onClick={() => { this.setState({ step: 1, buttonDisabled: true,  cont_btn_disabled: false, manual_address: true, close_match: "" }) }}>Or, Modify</button></div>
                                                    <div className='mailAddresGrpCntnu'><button onClick={() => { this.handleSelectedAddress(this.state.formattedAI_address1, "exact_match") }}>Continue</button></div>
                                                </div>
                                            </>
                                        }
                                        {this.state.which_match === "no_match" &&
                                            <>
                                                <div className='adresCorectQues'>
                                                    <label>We were unable to find your address. Please confirm this is correct.</label>
                                                </div>
                                                <div className="adrsDetailSet">

                                                    <p>{this.state.formattedAI_address1}</p>
                                                </div>
                                                <div className='mailAddresGrp'>
                                                    <div className='mailAddresGrpEdt'><button disabled={this.state.buttonDisabled} onClick={() => { this.setState({ step: 1, buttonDisabled: true,  cont_btn_disabled: false, manual_address: true, close_match: "" }) }}>Or, Modify</button></div>
                                                    <div className='mailAddresGrpCntnu'><button onClick={() => { this.handleSelectedAddress(this.state.formattedAI_address1, "no_match") }}>Continue</button></div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                }
                                {/* End of  Address form screen  */}

                                {/* Address form screen for country other than Canada*/}
                                {/* {this.state.selected_country != "" && this.state.selected_country != "Canada" && this.state.show_form == true &&
                                    <div className="houseFormWrap">
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                label={dynamic_content.static_text.address ? dynamic_content.static_text.address.street_address.en : "Street Address"}
                                                name={dynamic_content.static_text.address ? dynamic_content.static_text.address.street_address.en : "Street Address"}
                                                type="text"
                                                id="addressline1"
                                                inputRef={(input) => { this.addressline1 = input; }}
                                                
                                                value={this.state.addressline1}
                                                autoFocus={true}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.city.focus()
                                                    }
                                                }}
                                                onChange={event => {
                                                    if (event.target.value.match(/^[a-zA-Z-0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                        this.setState({ addressline1: event.target.value, show_error_message: '' })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                label={dynamic_content.static_text.address ? dynamic_content.static_text.address.city_label.en : "City"}
                                                name={dynamic_content.static_text.address ? dynamic_content.static_text.address.city_label.en : "City"}
                                                type="text"
                                                inputRef={(input) => { this.city = input; }}
                                                
                                                value={this.state.city}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.province.focus()
                                                    }
                                                }}
                                                onChange={event => {
                                                    if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                        this.setState({ city: event.target.value, show_error_message: '' })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                label={this.state.selected_country == "USA" ? "State" : "Province/State"}
                                                type="text"
                                                name={this.state.selected_country == "USA" ? "State" : "Province/State"}
                                                inputRef={(input) => { this.province = input; }}
                                                
                                                value={this.state.province}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.postalcode.focus()
                                                    }
                                                }}
                                                onChange={event => {
                                                    if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                        this.setState({ province: event.target.value, show_error_message: '' })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                label={"Zip Code"}
                                                name={"Zip Code"}
                                                type="text"
                                                
                                                inputRef={(input) => { this.postalcode = input; }}
                                                value={this.state.postalcode}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.handleManualAddress();
                                                    }
                                                }}
                                                onChange={event => {
                                                    if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                        if (event.target.value.length < 8) {
                                                            if (event.keyCode === 0) {
                                                                return false;
                                                            }
                                                            else if (
                                                                event.target.value.length === 3 &&
                                                                this.state.postalcode.length === 2
                                                            ) {
                                                                this.setState({
                                                                    show_error_message: '',
                                                                    postalcode:
                                                                        event.target.value.toUpperCase() + " "
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    show_error_message: '',
                                                                    postalcode: event.target.value.toUpperCase()
                                                                });
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                        {this.state.show_error_message && this.state.show_error_message != "" &&
                                            <p className="error_message">
                                                {this.state.show_error_message}
                                            </p>
                                        }
                                        <div className="houseFormWrapBtn">
                                            <Button onClick={() => { this.handleFormatAddressAI() }} color="secondary">{dynamic_content.static_text.address ? dynamic_content.static_text.address.continue.en : "Continue"}</Button>
                                        </div>
                                    </div>
                                } */}
                                {/* End of  Address form screen  */}

                                {/* End of  Address form screen  */}

                                {/* Add Animation Here */}
                                <div className="signupConectCircleInr">
                                    <div className="signupConectInr"></div>
                                </div>
                                {/* End of Animation Here */}


                            </div>
                        }


                    </div>
                }
            </div>
        );
    }
}