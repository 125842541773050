import axios from "axios";
import url from '../url';
import cookie from "react-cookies";
import session from './session';
import uuid from 'react-uuid';
import TagManager from 'react-gtm-module';
import { stat } from "fs";
import 'intl';
import 'intl/locale-data/jsonp/en'; // import locale data as needed
var base64 = require('base-64');
const tagManagerArgs = {
  gtmId: 'GTM-N4WQ2S6'
}
var functions = {

  //  To handle error popup
  handleErrorPopup() {
    // alert("System Error. Please try reloading.")
  },

  Loadscript() {
    const script_1 = document.createElement('script');
    script_1.src = 'https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.2/plugins/CSSPlugin.min.js';
    script_1.type = "text/javascript"
    document.body.appendChild(script_1);

    // <script type="text/javascript" scr="%PUBLIC_URL%/assets/detect_swipe.js"></script>
    const script_2 = document.createElement('script');
    script_2.src = "https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js?key=mm44-yz97-me83-uc98";
    script_2.type = "text/javascript"
    document.body.appendChild(script_2);
    // console.log("tagManagerArgs", tagManagerArgs)
    TagManager.initialize(tagManagerArgs)


    //     <div id="fb-root"></div>
    // <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v7.0" nonce="R4oB7zLq"></script>
  },


  //To log user activity in 3rd party
  logUserActivity(user_activity, image = 'image_not_set.png') {
    let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
    auto_id = auto_id ? auto_id : "null"
    let sub_id = auto_id;
    let session_id = cookie.load("session_id") ? cookie.load("session_id") : "null";
    let prev_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "null";
    let referer_page = prev_state == "null" ? document.referrer : prev_state;
    let current_page = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "main_home";
    let final_json = {
      auto_id: uuid(),
      sub_id: sub_id,
      session_id: session_id,
      user_activity: user_activity,
      referer_page: referer_page,
      current_page: current_page,
      entry_date: new Date().toLocaleDateString(),
      entry_time: new Date().toLocaleTimeString(),
      entry_timestamp: new Date().getTime(),
      timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      activity_image: image,
      device: window.matchMedia("only screen and (max-width: 760px)").matches ? 'phone' : 'desktop',
      success: "1",
      "update": sub_id == "null" ? "0" : "1",
      "method": "insert_data"
    }


    axios
      .request({
        url: url.insertQldb,
        method: "POST",
        data: JSON.stringify(final_json),
      })
      .then(function (response) {
      })
      .catch(error => {
        console.log("error", error)
      });
  },

  logUserErrors(responsefb) {
    let current_page = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "main_home";
    let final_json = {
      error_message: responsefb,
      body_params: { msg: "fbloginfail" },
      File: current_page
    }
    axios
      .request({
        url: url.InsertInErrorLogHTTP,
        method: "POST",
        data: JSON.stringify(final_json),
      })
      .then(function (response) {
      })
      .catch(error => {
        console.log("error", error)
      });
  },

  //To log user activity in 3rd party
  logUserTermsOnEntryActivity(user_activity, program) {
    let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
    auto_id = auto_id ? auto_id : "null"
    let sub_id = auto_id;
    let session_id = cookie.load("session_id") ? cookie.load("session_id") : "null";
    let prev_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "null";
    let referer_page = prev_state == "null" ? document.referrer : prev_state;
    let current_page = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "main_home";
    let final_json = {
      auto_id: uuid(),
      sub_id: sub_id,
      session_id: session_id,
      user_activity: user_activity,
      referer_page: referer_page,
      current_page: current_page,
      entry_date: new Date().toLocaleDateString(),
      entry_time: new Date().toLocaleTimeString(),
      entry_timestamp: new Date().getTime(),
      success: "1",
      terms_and_condition: program.term_and_condition,
      program_id: program.program_id,
      partner_id: program.partner_id,
      "update": sub_id == "null" ? "0" : "1",
      "method": "insert_data"
    }
    // console.log("final_json", final_json)

    axios
      .request({
        url: url.insertTermsInQldb,
        method: "POST",
        data: JSON.stringify(final_json),
      })
      .then(function (response) {
      })
      .catch(error => {
        console.log("error", error)
      });
  },

  // function to save user feedback to mysql
  saveFeedbackMysql(store, finaljson, callback) {
    let self = this;
    axios
      .request({
        url: url.save_feedback,
        method: "POST",
        data: JSON.stringify(finaljson)
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on saveFeedbackMysql function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on saveFeedbackMysql function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });

  },

  // function to get program detail
  getProgramDetail(store, final_json, callback) {
    let self = this;

    // console.log("final_json", final_json)
    axios
      .request({
        url: url.program_detail,
        method: "POST",
        data: JSON.stringify(final_json)
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getProgramDetail function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getProgramDetail function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });

  },

  // function to get program detail in s_o_n
  getProgramDetailSON(store, final_json, callback) {
    let self = this;
    axios
      .request({
        url: url.get_program_detail_s_o_n,
        method: "POST",
        data: JSON.stringify(final_json)
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getProgramDetail function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getProgramDetail function";
        let body = error.message
        self.logUserError(action, body);
        console.log("error_json  error_json", error_json)
        // callback(JSON.stringify(error_json))
      });
  },

  //To log user activity error in 3rd party
  logUserError(action, body) {
    let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : "null";
    let session_id = cookie.load("session_id") ? cookie.load("session_id") : "null";
    let referer_page = document.referrer ? document.referrer : "null";
    let current_page = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "null";

    let final_json = {
      auto_id: uuid(),
      sub_id: sub_id,
      session_id: session_id,
      user_activity: action,
      referer_page: referer_page,
      current_page: current_page,
      entry_date: new Date().toLocaleDateString(),
      success: "0",
      "update": sub_id == "null" ? "0" : "1",
      "method": "insert_data"
    }


    axios
      .request({
        url: url.insertQldb,
        method: "POST",
        data: JSON.stringify(final_json),
      })
      .then(function (response) {
      })
      .catch(error => {
        console.log("error", error)
      });
  },

  // To get authorization key for the api calls
  getAuthKey() {
    let key_api = cookie.load("session_id")
    let encoded = base64.encode(key_api)
    localStorage.setItem("api_auth", encoded)
    return encoded
  },

  //To handle landing page of the users
  getLandingPage(store) {
    let self = this;
    let back_on = localStorage.getItem("back_on") && localStorage.getItem("back_on") == "create_password" ? "create_password" : ""
    if (back_on == "create_password") {
      self.pushToRoute(store, "home", "create_password")
    }
    else {
      let gender = localStorage.getItem("gender");
      let address = localStorage.getItem("address");
      let child_data = localStorage.getItem("child_data");
      let up_add = localStorage.getItem("upadd") == "true" ? localStorage.getItem("upadd") : "";
      let child_popup = cookie.load("child_popup") ? cookie.load("child_popup") : localStorage.getItem("child_popup");
      // console.log("gender 235", gender)
      // console.log("address 236", address)
      // console.log("child_data 237", child_data)
      // console.log("up_add 239", up_add)
      let unsubscribe_red = localStorage.getItem("unsubscribe_red");
      if(unsubscribe_red && unsubscribe_red === "notification_setting")
      {
        this.invokeUserEmailVerify()
        self.pushToRoute(store, "notification_setting", 'setting')

      }
      else if (localStorage.getItem("gender") && localStorage.getItem("gender") !== '' && typeof localStorage.getItem("gender") !== undefined && localStorage.getItem("gender") !== null) {
        // console.log("gender", gender)
        if (up_add != "true" && localStorage.getItem("address") && localStorage.getItem("address") !== '' && typeof localStorage.getItem("address") != undefined && localStorage.getItem("address") !== null) {
          // console.log("gender", gender)
          if (localStorage.getItem("child_data") && localStorage.getItem("child_data") !== '' && typeof localStorage.getItem("child_data") != undefined && localStorage.getItem("child_data") !== null) {
            // console.log("gender", gender)
            localStorage.setItem("detail_page", 'false')
            self.pushToRoute(store, "main", localStorage.getItem("current_state"))
          }
          else {
            // console.log("gender", gender)
            if (localStorage.getItem("relation_data") && localStorage.getItem("relation_data") !== '' && typeof localStorage.getItem("relation_data") != undefined && localStorage.getItem("relation_data") !== null) {
              console.log("gender", gender)
              localStorage.setItem("detail_page", 'false')
              self.pushToRoute(store, "main", localStorage.getItem("current_state"))
            }
            else {
              // console.log("gender", gender)
              let child_deleted = localStorage.getItem("child_deleted") == "yes" ? true : false
              if (child_deleted) {
                self.pushToRoute(store, "main", localStorage.getItem("current_state"))
              } else {
                self.pushToRoute(store, "buildyourfamily", localStorage.getItem("current_state"))
              }
            }
          }
        }
        else {
          // console.log("gender", gender)
          localStorage.setItem("session_type", "signup")
          self.pushToRoute(store, "address", localStorage.getItem("current_state"))
        }
      }
      else {
        // console.log("gender", gender)
        self.pushToRoute(store, "gender", localStorage.getItem("current_state"))
      }
    }
  },

  // function to create user by admin
  adminCreateUser(store, finaljson, callback) {
    let self = this
    axios
      .request({
        url: url.adminCreateUser,
        method: "POST",
        data: JSON.stringify(finaljson),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        // console.log("response", response)
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        } else if (response.data.success == 1) {
          if (response?.data?.message == "User Already Exist" && response?.data?.user_exist_in_old_system == 1) {
            callback(JSON.stringify(response));
          }
          else if (response?.data?.message == "User Already Exist") {
            callback(JSON.stringify(response));
          } else {
            let success_action = "User got success on adminCreateUser function"
            self.logUserActivity(success_action);
            let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
            let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
            let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : "";
            let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
            let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
            let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
            let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
            let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
            // localStorage.clear();
            Object.keys(localStorage).forEach(key => !['phone_transfer', 'email_transfer'].includes(key) && localStorage.removeItem(key));


            localStorage.setItem("session_entered_id", session_entered_id)
            localStorage.setItem("perks_or_main", perks_or_main)
            localStorage.setItem("previous_state", previous_state)
            localStorage.setItem("current_state", current_state)
            localStorage.setItem("program", JSON.stringify(program))
            localStorage.setItem("api_auth", api_auth)
            localStorage.setItem("program_list", JSON.stringify(program_list))
            localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
            let auto_id = response.data.userprofiledata ? response.data.userprofiledata.UserAttributes.auto_id : ""
            let username = response.data.userprofiledata ? response.data.userprofiledata.Username : ""
            session.checkSession(auto_id, 'signup', 'false');
            callback(JSON.stringify(response));
          }
        }
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on adminCreateUser function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });

  },
  CallMigrateToPostgresSQL(store, finaljson, callback) {
    let self = this
    axios
      .request({
        url: url.CallMigrateToPostgresSQL,
        method: "POST",
        data: JSON.stringify(finaljson),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        // console.log("response", response)
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        } else if (response.data.success == 1) {
          callback(JSON.stringify(response));

        }
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on CallMigrateToPostgresSQL function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });

  },

  // function to confirm signup user by admin
  adminSignupConfirm(store, finaljson, callback) {
    let self = this;
    axios
      .request({
        url: url.adminSignupConfirm,
        method: "POST",
        data: JSON.stringify(finaljson),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on adminSignupConfirm function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on adminSignupConfirm function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });

  },

  // function to check email for bounce
  ValidateEmailForBounce(store, finaljson, callback) {
    let self = this;
    if (finaljson && finaljson.email) {
      axios
        .request({
          url: url.ValidateEmailForBounce,
          method: "POST",
          data: JSON.stringify(finaljson),
          headers: {
            "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
          }
        })
        .then(function (response) {
          if (response.data.failed && response.data.failed == 1) {
            self.handleErrorPopup()
          } else {
            let success_action = "User got success on ValidateEmailForBounce function"
            self.logUserActivity(success_action);
            callback(JSON.stringify(response));
          }

        })
        .catch(error => {
          let error_json = {
            message: error.message,
            catch_error: 1
          }
          let action = "User got error on ValidateEmailForBounce function";
          let body = error.message
          self.logUserError(action, body);
          callback(JSON.stringify(error_json))
        });
    }
  },

  ValidateToken(sub_id, login_token, email, callback) {
    let self = this;
    let finaljson = {
      sub_id: sub_id,
      login_token: login_token,
      email: email
    }
    // console.log("finaljson", finaljson)
    // console.log("url.validateToken", url.validateToken)
    axios
      .request({
        url: url.validateToken,
        method: "POST",
        data: JSON.stringify(finaljson)
      })
      .then(function (response) {
        // console.log("response", response)
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on ValidateToken function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on ValidateToken function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },

  // function to get dynamic content

  getDynamicContent(callback) {
    let self = this
    let finaljson = {
      front_end: "true"
    }
    axios
      .request({
        url: url.getDynamicContent,
        // url : 'https://9whgt8xbs3.execute-api.us-east-1.amazonaws.com/dev/GetDynamicContentWeb',
        method: "POST",
        data: JSON.stringify(finaljson),
        // headers: {
        //   "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        // }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        if (response.data) {
          localStorage.setItem("dynamic_content", JSON.stringify(response.data))
        }
        let success_action = "User got success on getDynamicContent function"
        self.logUserActivity(success_action);
        url.dynamic_content = response.data
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getDynamicContent function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },

  //Phone verification
  sendVerifyCode(store, action, phone_number, sub_id, country_code, area_code, mode, program_id = '', callback) {
    let self = this;
    let final_json = {
      "action": action,
      "phone_number": phone_number,
      "sub_id": sub_id,
      "country_code": country_code,
      "area_code": area_code,
      "mode": mode,
      "program_id": program_id
    }

    axios
      .request({
        url: url.phone_verify,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on sendVerifyCode function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on sendVerifyCode function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });

  },

  //Phone verification
  checkVerifyCode(store, action, phone_number, sub_id, country_code, area_code, verify_code, callback) {
    let self = this;
    let { program } = store.props.props
    if (!program) program = store.props.props.program_detail
    let program_id = program ? program.program_id : ""
    let partner_id = program ? program.partner_id : ""
    let final_json = {
      "action": action,
      "phone_number": phone_number,
      "sub_id": sub_id,
      "country_code": country_code,
      "area_code": area_code,
      "verification_code": parseInt(verify_code)
    }
    if (partner_id == "14") {
      final_json["program_id"] = program_id;
      final_json["partner_id"] = partner_id;
    }

    axios
      .request({
        url: url.phone_verify,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on checkVerifyCode function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on checkVerifyCode function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });

  },

  //Sms link verification
  verifySmsLink(store, phoneOtpEncoded, callback) {
    let self = this;
    let final_json = {
      "phoneOtpEncoded": phoneOtpEncoded,
    }

    axios
      .request({
        url: url.sms_link_verify,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on verifySmsLink function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on verifySmsLink function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });

  },

  //Get winner info
  getProgramWinners(store, program_id, callback) {
    let self = this
    let finalJson = {
      "program_id": program_id
    }
    axios
      .request({
        method: "POST",
        url: url.get_program_winner,
        data: JSON.stringify(finalJson),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getProgramWinners function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getProgramWinners function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },

  //Insert in ProgramNotifyMe table
  insertInProgramNotifyMe(store, final_json, callback) {
    let self = this
    axios
      .request({
        url: url.insert_in_program_notify_me,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on insertInProgramNotifyMe function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on insertInProgramNotifyMe function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  CheckPasswordExistence(store, email, callback) {
    let self = this;
    let final_json = {
      email: email
    }
    axios
      .request({
        url: url.check_password_existence,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on CheckPasswordExistence function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on CheckPasswordExistence function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  //To handle updating data of the users
  updateUserData(store, userdata, dynamo_userdata, is_expire = false) {
    let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
    auto_id = auto_id ? auto_id : null
    let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
    email = email ? email : null
    let self = this;
    let delete_ftm_ts = dynamo_userdata['delete_ftm_ts'] ? dynamo_userdata['delete_ftm_ts'] : false;
    if (delete_ftm_ts) {
      delete dynamo_userdata['delete_ftm_ts'];
    }
    // console.log("auto_id", auto_id)
    // console.log("email", email)
    if (auto_id != null && email != null) {
      let finalJson = {
        "userAttributes": userdata,
        "updateData": dynamo_userdata,
        "username": email,
        "sub_id": auto_id,
        "is_expire": is_expire
      }
      // console.log("delete_ftm_ts", delete_ftm_ts)
      axios
        .request({
          method: "POST",
          url: url.update_user,
          data: JSON.stringify(finalJson),
          headers: {
            "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
          }
        })
        .then(function (response) {
          if (response.data.failed && response.data.failed == 1) {
            // console.log(" response.data", response.data, is_expire)
            if (!is_expire) {
              self.handleErrorPopup()
            }
            else if (is_expire && response.data.password_similar) {
              self.handleErrorPopupMsg(response.data.message)
            }
          }
          if (delete_ftm_ts) cookie.remove("ftm_child_time_stamp", { path: "/" })
          let success_action = "User got success on updateUserData function"
          self.logUserActivity(success_action);
          if (response.data.success && response.data.success == 1 && is_expire) {
            self.getLandingPage(self)
          }
        })
        .catch(function (error) {
          let action = "User got error on updateUserData function";
          let body = error.message
          self.logUserError(action, body);
          //call function to log errors
        });
    }
  },

  //To handle updating data of the users
  updateUserDataPass(store, userdata, dynamo_userdata, callback) {
    let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
    auto_id = auto_id ? auto_id : null
    let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
    email = email ? email : null
    let self = this;

    if (auto_id != null && email != null) {
      let finalJson = {
        "userAttributes": userdata,
        "updateData": dynamo_userdata,
        "username": email,
        "sub_id": auto_id,
        "is_expire": true
      }
      // console.log("finalJson", finalJson)
      axios
        .request({
          method: "POST",
          url: url.update_user,
          data: JSON.stringify(finalJson),
          headers: {
            "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
          }
        })
        .then(function (response) {
          if (response.data.failed && response.data.failed == 1) {
            callback(null, response.data);

          }
          else {
            callback(null, response.data);
          }
        })
        .catch(function (error) {
          let action = "User got error on updateUserData function";
          let body = error.message
          self.logUserError(action, body);
          callback(body, null)
          //call function to log errors
        });
    }
  },

  SyncInMysql(store, finaljson) {
    let self = this
    // console.log("finaljson", finaljson)
    axios
      .request({
        method: "POST",
        url: url.SyncMysqlWithDynamo,
        data: JSON.stringify(finaljson)
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on SyncInMysql function"
        self.logUserActivity(success_action);
      })
      .catch(function (error) {
        let action = "User got error on SyncInMysql function";
        let body = error.message
        self.logUserError(action, body);
      });
  },
  //To handle updating data of the users
  updateUserDataForMerge(store, userdata, dynamo_userdata, is_expire = false, merging_child, callback) {
    let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
    auto_id = auto_id ? auto_id : null
    let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
    email = email ? email : null
    let self = this;
    let delete_ftm_ts = dynamo_userdata['delete_ftm_ts'] ? dynamo_userdata['delete_ftm_ts'] : false;
    if (delete_ftm_ts) {
      delete dynamo_userdata['delete_ftm_ts'];
    }
    // console.log("auto_id", auto_id)
    // console.log("email", email)
    if (auto_id != null && email != null) {
      let finalJson = {
        "userAttributes": userdata,
        "updateData": dynamo_userdata,
        "username": email,
        "sub_id": auto_id,
        "is_expire": is_expire,
        "merging_child": merging_child
      }
      // console.log("delete_ftm_ts", delete_ftm_ts)
      axios
        .request({
          method: "POST",
          url: url.update_user,
          data: JSON.stringify(finalJson),
          headers: {
            "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : this.getAuthKey()
          }
        })
        .then(function (response) {
          if (response.data.failed && response.data.failed == 1) {
            // console.log(" response.data", response.data, is_expire)
            if (!is_expire) {
              self.handleErrorPopup()
            }
            else if (is_expire && response.data.password_similar) {
              self.handleErrorPopupMsg(response.data.message)
            }
          }
          if (delete_ftm_ts) cookie.remove("ftm_child_time_stamp", { path: "/" })
          let success_action = "User got success on updateUserData function"
          self.logUserActivity(success_action);
          if (response.data.success && response.data.success == 1 && is_expire) {
            self.getLandingPage(self)
          }
          if (response.data.success && response.data.success == 1 && !is_expire) callback(null, response.data);
        })
        .catch(function (error) {
          let action = "User got error on updateUserData function";
          let body = error.message
          self.logUserError(action, body);
          //call function to log errors
        });
    }
  },

  //To handle updating data of the users
  updateUserDataChildName(store, userdata, dynamo_userdata, is_expire = false, update_rel_child = false) {
    let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
    auto_id = auto_id ? auto_id : null
    let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
    email = email ? email : null
    let self = this;
    // console.log("auto_id", auto_id)
    // console.log("email", email)
    if (auto_id != null && email != null) {
      let finalJson = {
        "userAttributes": userdata,
        "updateData": dynamo_userdata,
        "username": email,
        "sub_id": auto_id,
        "is_expire": is_expire,
        "update_rel_child": update_rel_child
      }

      let signup_session = localStorage.getItem("session_type") ? localStorage.getItem("session_type") : ""
      if (signup_session == "signup") {
        finalJson["signup_session"] = signup_session
      }
      // console.log("finalJson", finalJson)
      axios
        .request({
          method: "POST",
          url: url.update_user,
          data: JSON.stringify(finalJson),
          headers: {
            "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : this.getAuthKey()
          }
        })
        .then(function (response) {
          if (response.data.failed && response.data.failed == 1) {
            // console.log(" response.data", response.data, is_expire)
            if (!is_expire) {
              self.handleErrorPopup()
            }
            else if (is_expire && response.data.password_similar) {
              self.handleErrorPopupMsg(response.data.message)
            }
          }
          let success_action = "User got success on updateUserData function"
          self.logUserActivity(success_action);
          if (response.data.success && response.data.success == 1 && is_expire) {
            self.getLandingPage(self)
          }
        })
        .catch(function (error) {
          let action = "User got error on updateUserData function";
          let body = error.message
          self.logUserError(action, body);
          //call function to log errors
        });
    }
  },

  unsubscribeUserEmail(store, final_json) {
    let self = this
    final_json["action"] = "unsubscribe_user_email"
    axios.request({
      method: "POST",
      url: url.unsubscribe_user_email,
      data: JSON.stringify(final_json)
    })
  },

  callLambdaFunctionUser(store, finaljson) {
    let self = this
    try{
      // console.log("finaljson", finaljson)
    finaljson["action"] = "insert_email_click_log"
    axios
      .request({
        method: "POST",
        url: url.callLambdaFunctionUser,
        data: JSON.stringify(finaljson)
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on SyncInMysql function"
        self.logUserActivity(success_action);
      })
      .catch(function (error) {
        let action = "User got error on SyncInMysql function";
        let body = error.message
        self.logUserError(action, body);
      });
    } catch(error){
      console.log("callLambdaFunctionUser function catch error", error)
    }
    
  },

  // To get User data from email address without callback
  getUserData(store, email, signup_or_login, reset_password) {
    let self = this;
    // console.log("signup_or_login", signup_or_login)
    email = email && email != null ? email : ""
    if (email != "") {
      let finaljson = {
        "email": email
      }
      axios
        .request({
          url: url.get_user,
          method: "POST",
          data: JSON.stringify(finaljson),
          headers: {
            "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
          }
        })
        .then(function (response) {
          // console.log("resposne", response)
          if (response.data.failed && response.data.failed == 1) {
            self.handleErrorPopup()
          }
          let success_action = "User got success on getUserData function"
          self.logUserActivity(success_action);
          if (signup_or_login === "signup/login") {
            if (response?.data?.code === "UserNotFoundException") {
              self.pushToRoute(store, "signup", "home")
            }
          }
          else if (signup_or_login === "facebook" || signup_or_login === "google" || signup_or_login === "twitter") {
            if (response?.data?.code === "UserNotFoundException") {
              self.pushToRoute(store, "signup", "home")
            }
            else {
              let data = response.data;
              let cognito_data = data.cognito ? data.cognito.UserAttributes : {};
              let dynamo_data = data.dynamo.Items ? data.dynamo.Items[0] : {};
              let program_data = data.program_data ? data.program_data : []
              let relation_data = data.relation_data ? data.relation_data : {}
              let winner_data = data.winner_data ? data.winner_data : [];
              let provider = dynamo_data.provider ? JSON.parse(dynamo_data.provider) : []
              let temp_provider = provider
              let email_verified = dynamo_data.email_verified
              let double_optin = dynamo_data.double_optin ? dynamo_data.double_optin : "false"
              if (email_verified == false || email_verified == "false") {
                email_verified = "true";
              }
              if (double_optin == false || double_optin == "false") {
                double_optin = "true"
              }
              let email_update = true;
              if (provider && provider.length > 0) {
                provider.forEach(element => {
                  if (element == signup_or_login) {
                    email_update = false
                  }
                });
                if (email_update == true) {
                  temp_provider.push(signup_or_login)
                }
              }

              localStorage.setItem("provider", JSON.stringify(temp_provider))
              let userdata = [];
              localStorage.setItem("email_verified", "true");
              localStorage.setItem("double_optin", "true");
              let auto_id = dynamo_data.auto_id ? dynamo_data.auto_id : '';
              cookie.save("auto_id", auto_id, { path: "/" });
              session.checkSession(auto_id, 'login', 'false');
              localStorage.setItem("auto_id", auto_id);
              let dynamo_userdata = { 'provider': JSON.stringify(temp_provider), 'email_verified': email_verified, 'double_optin': double_optin, 'send_pending_mails': 1 }
              if (dynamo_data.bbbx_inactive && dynamo_data.bbbx_inactive == true) {
                dynamo_userdata['bbbx_inactive'] = "false"
              }
              self.updateUserData(store, userdata, dynamo_userdata);
              self.storeCookie(cognito_data, dynamo_data, relation_data, program_data, '', store, 'login', '', 'login');
              // self.getLandingPage(store);
            }
          }
          else {
            let data = response.data;
            let cognito_data = data.cognito ? data.cognito.UserAttributes : {};
            let dynamo_data = data.dynamo.Items ? data.dynamo.Items[0] : {};
            let program_data = data.program_data ? data.program_data : []
            let relation_data = data.relation_data ? data.relation_data : {}
            let winner_data = data.winner_data ? data.winner_data : [];
            if (signup_or_login === "login") {
              // console.log("login")
              let dynamo_userdata = {}
              dynamo_userdata['double_optin'] = "true"
              if (dynamo_data.bbbx_inactive && dynamo_data.bbbx_inactive == true) {
                dynamo_userdata['bbbx_inactive'] = "false"
              }
              let email_verified = dynamo_data.email_verified
              localStorage.setItem("email_verified", email_verified);
              if (email_verified == false || email_verified == "false") {
                email_verified = true;
                let signup_json = {
                  username: email,
                  auto_id: dynamo_data.auto_id
                }
                let userdata = [];
                dynamo_userdata['email_verified'] = email_verified
                dynamo_userdata['send_pending_mails'] = 1
                self.adminSignupConfirm(this, signup_json, function (response_json) {
                  let response = JSON.parse(response_json);
                  let finalJson = {
                    "userAttributes": userdata,
                    "updateData": dynamo_userdata,
                    "username": email,
                    "sub_id": dynamo_data.auto_id
                  }
                  axios
                    .request({
                      method: "POST",
                      url: url.update_user,
                      data: JSON.stringify(finalJson),
                      headers: {
                        "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
                      }
                    })
                    .then(function (response) {
                      if (response.data.failed && response.data.failed == 1) {
                        self.handleErrorPopup()
                      }
                      let success_action = "User got success on updateUserData function"
                      self.logUserActivity(success_action);
                    })
                    .catch(function (error) {
                      let action = "User got error on updateUserData function";
                      let body = error.message
                      self.logUserError(action, body);
                      //call function to log errors
                    });
                })

                localStorage.setItem("email_verified", true);
                // console.log("dynamo_userdata", dynamo_userdata)
              } else {
                // console.log("call update")
                let dynamo_userdata = {}
                dynamo_userdata['double_optin'] = "true"
                let userdata = []
                let finalJson = {
                  "userAttributes": userdata,
                  "updateData": dynamo_userdata,
                  "username": email,
                  "sub_id": dynamo_data.auto_id
                }
                // console.log("finalJson", finalJson)
                axios
                  .request({
                    method: "POST",
                    url: url.update_user,
                    data: JSON.stringify(finalJson),
                    headers: {
                      "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
                    }
                  })
                  .then(function (response) {
                  })
              }
              // self.pushToRoute(store,"gender","signup")
            }
            else if (signup_or_login === "signup") {
              // console.log("signup")
              let email_verified = "true"
              dynamo_data.email_verified = email_verified
              localStorage.setItem("email_verified", email_verified);
              email_verified = true;
              let userdata = [];
              let dynamo_userdata = { 'email_verified': email_verified, double_optin: "true", 'send_pending_mails': 1 }
              // localStorage.setItem("email_verified", true);
              self.updateUserData(store, userdata, dynamo_userdata);
              // self.pushToRoute(store,"gender","signup")
            }
            if (Array.isArray(winner_data) && winner_data.length > 0) {
              let final_winner_data = [];
              winner_data.forEach(element => {
                if (element.claim_status == "pending") {
                  let date1 = new Date();
                  let date2 = new Date(element.claim_expiry_date);
                  var Difference_In_Time = date2.getTime() - date1.getTime();
                  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                  if (Difference_In_Days < 8) {
                    final_winner_data.push(element);
                  }
                }
              });
              localStorage.setItem("winner_data", JSON.stringify(final_winner_data))
              cookie.save("winner_later", 'false', { path: '/' })
            }

            let winner_id = cookie.load("winner_id") ? cookie.load("winner_id") : "";
            if (winner_id != "" && typeof winner_id != undefined && winner_id != dynamo_data.auto_id) {
              cookie.remove("winner_id", { path: '/' })
              cookie.remove("winner_program_id", { path: '/' })
              cookie.remove("winner_later", { path: '/' })
            }
            // console.log("here")
            if (signup_or_login == "familyone") {
              self.storeCookie(cognito_data, dynamo_data, relation_data, program_data, '', store, 'login', 'email_verified', 'login');
            }
            else if (signup_or_login == "login") {
              self.storeCookie(cognito_data, dynamo_data, relation_data, program_data, '', store, 'login', 'email_verified', 'login');
            }
            else {
              self.storeCookie(cognito_data, dynamo_data, relation_data, program_data, '', '', 'no', 'email_verified');
            }
            if (reset_password == "reset") {
              let userdata = [];
              let dynamo_userdata = { 'email_verified': "true", double_optin: "true" }
              // localStorage.setItem("email_verified", true);
              self.updateUserData(store, userdata, dynamo_userdata);
            }
            else if (reset_password == "address") {
              let userdata = [];
              let dynamo_userdata = { 'email_verified': "true", double_optin: "true" }
              // localStorage.setItem("email_verified", true);
              self.updateUserData(store, userdata, dynamo_userdata);
              localStorage.setItem("session_type", "signup")
              self.pushToRoute(store, "address", "main_home")
            } else
              if (reset_password == "addchild") {
                self.pushToRoute(store, "addchild", "home_main")
              }
              else if (signup_or_login !== "login") {
                self.getLandingPage(store);
              }
          }
        })
        .catch(error => {
          let action = "User got error on getUserData function";
          let body = error.message
          self.logUserError(action, body);
          // self.pushToRoute(self,"no_network");
          // call function to log errors
        });
    }

  },

        // To get User data from email address with callback
        getUserAddressType(finaljson) {
          let self = this;
          axios
            .request({
              url: url.get_user_address_type,
              method: "POST",
              data: JSON.stringify(finaljson)
            })
            .then(function (response) {
              if (response.data.failed && response.data.failed == 1) {
                self.handleErrorPopup()
              }
              let addressType = response.data.addressType ? response.data.addressType.trim() : "";
              console.log("addressType", typeof addressType)
              // Store addressType in localStorage based on its value
              if (addressType === "new") {
                localStorage.setItem("addressType", "ai_address");
              } else if (addressType === "old") {
                localStorage.setItem("addressType", "address");
              } else {
                console.warn("Unknown addressType:", addressType);
              }
              let success_action = "User got success on getUserAddressType function"
              self.logUserActivity(success_action);
           
            })
            .catch(error => {
              let error_json = {
                message: error.message,
                catch_error: 1
              }
              let action = "User got error on getUserAddressType function";
              let body = error.message
              self.logUserError(action, body);
             
            });
        },
  

  // To get User data from email address with callback
  getUserProfile(store, finaljson, callback) {
    let self = this;
    axios
      .request({
        url: url.get_profile_user,
        method: "POST",
        data: JSON.stringify(finaljson)
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getUserProfile function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getUserProfile function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },

  // To get we transfer key for media upload
  getwetransferkey(store, auto_id, callback) {
    let self = this;
    let finaljson = {
      "auto_id": auto_id
    }
    axios
      .request({
        url: url.wetransfer_api,
        method: "POST",
        data: JSON.stringify(finaljson),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getwetransferkey function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getwetransferkey function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },

  // for login user with password
  passwordLogin(store, email, password, callback) {
    let self = this
    let final_json = {
      "email": email,
      "password": password
    }
    axios
      .request({
        url: url.password_login,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        // localStorage.clear()
        let success_action = "User got success on passwordLogin function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on passwordLogin function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  // to get all perks for the site using auto_id
  getPerks(store, province, callback) {
    let self = this
    let final_json = {
      "auto_id": cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id"),
      province: province ? province : ""
    }
    axios
      .request({
        url: url.get_perks,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getPerks function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response.data));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getPerks function";
        let body = error.message;
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  // to inset entry of user in perks entry table
  insertInPerks(store, perk_id, redeem_type, callback) {
    let self = this;
    let campaign_id = cookie.load("camp_id") ? cookie.load("camp_id") : ""
    let final_json = {
      "sub_id": cookie.load("auto_id"),
      "perk_id": perk_id,
      "redeem_type": redeem_type,
      "campaign_id": campaign_id ? campaign_id : "null"
    }
    axios
      .request({
        url: url.insert_entry_in_perks,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on insertInPerks function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response.data));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on insertInPerks function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  // to check entry criteria for the given program_id
  checkProgramCriteria(store, program_id, sub_id, callback) {
    let self = this
    let relation_data = localStorage.getItem("relation_data") ? JSON.parse(localStorage.getItem("relation_data")) : []
    let relative_number = relation_data ? relation_data.length : 0
    let final_json = {
      "program_id": program_id,
      "relative_number": relative_number,
      "sub_id": sub_id
    }
    if (localStorage.getItem("address")) {
      axios
        .request({
          url: url.check_program_criteria,
          method: "POST",
          data: JSON.stringify(final_json),
          headers: {
            "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
          }
        })
        .then(function (response) {
          if (response.data.failed && response.data.failed == 1) {
            self.handleErrorPopup()
          }
          let success_action = "User got success on checkProgramCriteria function"
          self.logUserActivity(success_action);
          callback(JSON.stringify(response.data));
        })
        .catch(error => {
          let error_json = {
            message: error.message,
            catch_error: 1
          }
          let action = "User got error on checkProgramCriteria function";
          let body = error.message
          self.logUserError(action, body);
          callback(JSON.stringify(error_json))
          // call function to log errors
        });
    }
    else {
      store.props.history.push("/" + "address")
    }
  },

  // get all program for user without any session
  getSessionProgram(store, callback) {
    let self = this;
    let final_json = {
      "sub_id": ""
    }
    axios
      .request({
        url: url.get_program,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getSessionProgram function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response.data));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        switch (error.message) {
          case "Network Error":
            // self.pushToRoute(store, "no_network", localStorage.getItem("current_state"))
            break;

          default:
            break;
        }
        let action = "User got error on getSessionProgram function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  // to send login link to the user
  sendLoginLink(store, email, session_entered_id, relative_id, perks_or_main, http_referer, alternative_popup, accept_auto_id, callback) {
    let self = this;
    let campaign_id = cookie.load("camp_id") ? cookie.load("camp_id") : ""
    let final_json = {
      "email": email,
      "session_entered_id": session_entered_id,
      "campaign_id": campaign_id ? campaign_id : "null",
      "relative_id": relative_id,
      "perks_or_main": perks_or_main,
      "http_referer": http_referer,
      "alternative_popup": alternative_popup,
      "accept_auto_id": accept_auto_id
    }
    axios
      .request({
        url: url.send_login_link,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on sendLoginLink function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on sendLoginLink function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  //  to add relative
  addRelative(store, sub_id, relative_id, email) {
    let self = this;
    let final_json = {
      "sub_id": sub_id,
      "email": email,
      "relative_subid": relative_id,
      "email_verified": localStorage.getItem("email_verified") ? localStorage.getItem("email_verified") : "false",
      "email_changed": cookie.load("email_changed") == 1 ? 1 : 0,
      "alternative_email": cookie.load("alternative_email") ? cookie.load("alternative_email") : ' ',
      "action": "accept",
      "method": "addRelative"
    }
    let parent_invite_id = cookie.load("parent_invite_id") ? cookie.load("parent_invite_id") : localStorage.getItem("parent_invite_id")
    if (parent_invite_id) {
      final_json['parent_invite_id'] = parent_invite_id
    }
    axios
      .request({
        url: url.accept_decline_relation,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        } else {
          if (parent_invite_id) cookie.remove("parent_invite_id", { path: "/" });
          let update_relation_params = {
            action: "migrate_relation_data",
            sub_id: relative_id,
            relative_email: email
          }

          if (update_relation_params.sub_id && update_relation_params.action && update_relation_params.relative_email)
            self.SyncInMysql(self, update_relation_params);
        }
        let success_action = "User got success on addRelative function"
        self.logUserActivity(success_action);
        cookie.remove("accept_auto_id", { path: "/" });
        cookie.remove("email_changed", { path: "/" });
        cookie.remove("alternative_email", { path: "/" });
        cookie.remove("relative_id", { path: "/" });
        self.getUserData(store, email, 'normal')
      })
      .catch(error => {
        let action = "User got error on addRelative function";
        let body = error.message
        self.logUserError(action, body);
        // call function to log errors
      });
  },

  // to accept relation invitation from user
  AcceptRelation(store, sub_id, relative_id, email, callback) {
    let self = this;
    let final_json = {
      "sub_id": sub_id,
      "email": email,
      "relative_subid": relative_id,
      "email_changed": cookie.load("email_changed") == 1 ? 1 : 0,
      "alternative_email": cookie.load("alternative_email") ? cookie.load("alternative_email") : " ",
      "action": "accept",
      "method": "AcceptRelation"
    }
    axios
      .request({
        url: url.accept_decline_relation,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        } else {
          let update_relation_params = {
            action: "migrate_relation_data",
            sub_id: relative_id,
            relative_email: email
          }

          if (update_relation_params.sub_id && update_relation_params.action && update_relation_params.relative_email)
            self.SyncInMysql(self, update_relation_params);
        }
        let success_action = "User got success on AcceptRelation function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on AcceptRelation function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },

  // to decline relation invitation from user
  DeclineRelation(store, sub_id, relative_id, email, callback) {
    let self = this;
    let final_json = {
      "sub_id": sub_id,
      "email": email,
      "relative_subid": relative_id,
      "email_changed": cookie.load("email_changed") == 1 ? 1 : 0,
      "alternative_email": cookie.load("alternative_email") ? cookie.load("alternative_email") : " ",
      "action": "decline"
    }
    axios
      .request({
        url: url.accept_decline_relation,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on DeclineRelation function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on DeclineRelation function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },


  AcceptRelationChild(store, sub_id, relative_id, email, child_id, callback) {
    let self = this;
    let final_json = {
      "sub_id": sub_id,
      "email": email,
      "relative_subid": relative_id,
      "email_changed": cookie.load("email_changed") == 1 ? 1 : 0,
      "alternative_email": cookie.load("alternative_email") ? cookie.load("alternative_email") : " ",
      "action": "accept",
      "method": "AcceptRelation",
      "child_accept_id": child_id
    }
    axios
      .request({
        url: url.accept_decline_relation,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : this.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        } else {
          let update_relation_params = {
            action: "migrate_relation_data",
            sub_id: relative_id,
            relative_email: email
          }

          if (update_relation_params.sub_id && update_relation_params.action && update_relation_params.relative_email)
            self.SyncInMysql(self, update_relation_params);
        }
        let success_action = "User got success on AcceptRelation function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on AcceptRelation function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },

  // to decline relation invitation from user
  DeclineRelationChild(store, sub_id, relative_id, email, child_id, callback) {
    let self = this;
    let final_json = {
      "sub_id": sub_id,
      "email": email,
      "relative_subid": relative_id,
      "email_changed": cookie.load("email_changed") == 1 ? 1 : 0,
      "alternative_email": cookie.load("alternative_email") ? cookie.load("alternative_email") : " ",
      "action": "decline",
      "child_accept_id": child_id
    }
    axios
      .request({
        url: url.accept_decline_relation,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : this.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on DeclineRelation function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on DeclineRelation function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },
  // to accept relation invitation from user
  RequestChildAddToParent(store, final_json, callback) {
    let self = this;
    axios
      .request({
        url: url.addExistingRelativeChildNotification,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : this.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        } else {

        }
        let success_action = "User got success on AcceptRelation function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on AcceptRelation function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },

  // to delete relation 
  deleteRelation(store, email, user_email, relative_id) {
    let self = this;
    let final_json = {
      "sub_id": cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id"),
      "email": email,
      user_email: user_email,
      relative_id: relative_id
    }
    axios
      .request({
        url: url.delete_user_relation,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on deleteRelation function"
        self.logUserActivity(success_action);
      })
      .catch(error => {
        let action = "User got error on deleteRelation function";
        let body = error.message
        self.logUserError(action, body);
        // call function to log errors
      });
  },

  // to delete relation 
  DeleteRelationInMail(store, sub_id, relative_id) {
    let self = this;
    let final_json = {
      "sub_id": sub_id,
      "relative_id": relative_id
    }
    axios
      .request({
        url: url.delete_relation_in_mail,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on deleteRelation function"
        self.logUserActivity(success_action);
      })
      .catch(error => {
        let action = "User got error on deleteRelation function";
        let body = error.message
        self.logUserError(action, body);
        // call function to log errors
      });
  },

  // get detail of user using temp_id
  getDetailByRelativeID(relative_id, callback) {
    let self = this
    let final_json = {
      "temp_userid": relative_id
    }
    axios
      .request({
        url: url.get_relative_info,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        let success_action = "User got success on getDetailByRelativeID function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getDetailByRelativeID function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  storeCookie(cognito_data, dynamo_data, relation_data, program_data, storeall, store, landing, save_email_verified, session_type) {
    if (cognito_data.length > 0) {
      session_type = session_type ? session_type : ''
      for (var key in cognito_data) {
        if (cognito_data[key].Name === "custom:user_password") {
          localStorage.setItem('password', 'true')
        }
        else if (cognito_data[key].Name === "email_verified") {
        }
        else if (cognito_data[key].Name === "phone_number") {
        }
        else if (cognito_data[key].Name === "sub") {
          cookie.save("auto_id", cognito_data[key].Value, { path: "/" });
          localStorage.setItem("auto_id", cognito_data[key].Value);
          // 
        }
        else if (cognito_data[key].Name === "address" || cognito_data[key].Name === "gender") {
          // localStorage.setItem(cognito_data[key].Name, cognito_data[key].Value);
        }
        else {
          localStorage.setItem(cognito_data[key].Name, cognito_data[key].Value);
        }
      }
    }
    if (Object.keys(dynamo_data).length > 0) {
      let auto_id = dynamo_data.auto_id ? dynamo_data.auto_id : '';
      let child_data = dynamo_data.child_data && dynamo_data.child_data.length > 0 ? dynamo_data.child_data : [];
      let email_verified = dynamo_data.email_verified ? dynamo_data.email_verified : '';
      let name = dynamo_data.name ? dynamo_data.name : '';
      let birthdate = dynamo_data.birthdate ? dynamo_data.birthdate : '';
      let alternative_email = dynamo_data.alternative_email ? dynamo_data.alternative_email : '';
      let phone_number = dynamo_data.phone_number ? dynamo_data.phone_number : '';
      let address = dynamo_data.address ? dynamo_data.address : '';
      let gender = dynamo_data.gender ? dynamo_data.gender : '';
      let family_name = dynamo_data.family_name ? dynamo_data.family_name : '';
      let given_name = dynamo_data.given_name ? dynamo_data.given_name : '';

      cookie.save("auto_id", auto_id, { path: "/" });
      localStorage.setItem("auto_id", auto_id);
      if (child_data && child_data.length > 0) {
        localStorage.setItem("child_data", JSON.stringify(child_data));
      }
      if (address && address != "") {
        localStorage.setItem('address', address);
      }
      if (gender && gender != "") {
        localStorage.setItem('gender', gender);
      }
      if (save_email_verified != 'email_verified') {
        localStorage.setItem('email_verified', email_verified);
      }
      localStorage.setItem('birthdate', birthdate);
      localStorage.setItem('alternative_email', alternative_email);
      localStorage.setItem("name", name);
      localStorage.setItem("phone_number", phone_number);
      localStorage.setItem("family_name", family_name);
      localStorage.setItem("given_name", given_name);

    }
    let relation_data_final = [];
    if (Object.keys(relation_data).length > 0) {
      for (var key in relation_data) {
        relation_data[key].forEach(element => {
          if (key == 'approvedRelations') {
            element.relation_status = 'approved_relations'
          }
          if (key == 'pendingRelation') {
            element.relation_status = 'pending_relation'
          }
          if (key == 'pendingInvitation') {
            element.relation_status = 'pending_invitation'
          }
          relation_data_final.push(element)
        });
      }
      localStorage.setItem("relation_data", JSON.stringify(relation_data_final));
    }
    else if (storeall == 'true') {
      localStorage.removeItem("relation_data")
    }

    if (Array.isArray(program_data) && program_data.length > 0) {
      localStorage.setItem("program_list", JSON.stringify(program_data))
      localStorage.setItem("program_index", 1)
      localStorage.setItem("program", JSON.stringify(program_data[0]))
    }
    else {
      localStorage.removeItem("program_list")
      localStorage.removeItem("program_index")
      localStorage.removeItem("program")
    }
    if (landing == "password" || landing == "login") {
      this.getLandingPage(store);
    }
    else {
    }
  },

  pushToRoute(store, path, previous_path, show_popup = false) {
    // console.log("path", path, store)
    if (path == "signup") {
      localStorage.setItem("enter_email", "false");
    }
    localStorage.setItem("current_state", path);
    localStorage.setItem("previous_state", previous_path);
    if (!show_popup) {
      if (previous_path == "main_index" || previous_path == 'perks') localStorage.setItem("CheckMainIndex", previous_path);
      if (path === 'address' && localStorage.getItem("session_type") === "signup") {
        const randomNumber = Math.floor(Math.random() * 10);
        if (randomNumber === 1 || randomNumber === 5 || randomNumber === 4 || randomNumber === 3 || randomNumber === 2 || randomNumber === 6 || randomNumber === 7) {
          path = 'ai_address';
          localStorage.setItem("current_state", path);
          if (store && store.props && store.props.history)
            localStorage.setItem("addressType", "ai_address")
          store.props.history.push("/" + "ai_address", { stateData: store?.state })
        }
        else {
          if (store && store.props && store.props.history)
            localStorage.setItem("addressType", "address")
          store.props.history.push("/" + path, { stateData: store?.state })
        }
      }
      else if (path === 'address' && localStorage.getItem("session_type") === "edit_profile") {


        localStorage.setItem("current_state", localStorage.getItem("addressType"));
        if (store && store.props && store.props.history) {
          store.props.history.push("/" + localStorage.getItem("addressType"), { stateData: store?.state })
        }

      }
      else {
        if (store && store.props && store.props.history)
          store.props.history.push("/" + path, { stateData: store?.state })
      }
    }
    else if (show_popup) {
      if (store && store.props && store.props.history)
        store.props.history.push("/" + path, { show_popup })
    }
  },

  pushBack(store) {
    let path = localStorage.getItem("previous_state");
    store.props.history.push("/" + path);
  },

  // to log entry of user in entrypointcollection table
  contestEntry(store, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, recommended, program_type, term_id, consent = null, video_upload_data = null, survey_ques = null, callback, from_phone) {
    let social_program_id = cookie.load("social_shared_prog_id") ? cookie.load("social_shared_prog_id") : ""
    let refered_id = "";
    let referrer_from = "";
    let self = this
    let campaign_id = cookie.load("camp_id") ? cookie.load("camp_id") : ""
    let kff_past_entry = localStorage.getItem("kff_past_entry") && localStorage.getItem("kff_past_entry") == "true" ? "true" : "false"
    let enfamil_past_entry = localStorage.getItem("enfamil_past_entry") && localStorage.getItem("enfamil_past_entry") == "true" ? "true" : "false"
    let nestle_past_entry = localStorage.getItem("nestle_past_entry") && localStorage.getItem("nestle_past_entry") == "true" ? "true" : "false"
    if (program_id == social_program_id) {
      refered_id = cookie.load("social_shared_refered_id") ? cookie.load("social_shared_refered_id") : ""
      referrer_from = cookie.load("social_medium") ? cookie.load("social_medium") : ""
    }
    let relation_data = localStorage.getItem("relation_data") ? JSON.parse(localStorage.getItem("relation_data")) : []
    let relative_number = relation_data ? relation_data.length : 0
    // let geoLocation = {};

    // if (localStorage.getItem('latitude') && localStorage.getItem('longitude')) {
    //   geoLocation['latitude'] = localStorage.getItem('latitude')
    //   geoLocation['longitude'] = localStorage.getItem('longitude')
    // }
    // else {
    //   const coords = await this.getCoords()
    //   if (coords) {
    //     localStorage.setItem("latitude", coords.lat);
    //     localStorage.setItem("longitude", coords.long);
    //     geoLocation['latitude'] = coords.lat
    //     geoLocation['longitude'] = coords.long
    //   }
    // }
    // console.log("geoLocation", geoLocation)
    let past_entry = "false"
    if (partner_id == "1") {
      past_entry = kff_past_entry
    }
    if (partner_id == "3") {
      past_entry = enfamil_past_entry
    }
    if (partner_id == "10") {
      past_entry = nestle_past_entry
    }
    let final_json = {
      "is_sub_id": is_sub_id,
      "sub_id": sub_id,
      "program_id": program_id,
      "partner_id": partner_id,
      "program_type": program_type,
      "registration_source": registration_source ? registration_source : "",
      "point_type": point_type,
      "relative_number": relative_number,
      "is_direct": is_direct == 1 ? 1 : 0,
      "tracking_id": tracking_id ? tracking_id : "",
      "refered_id": refered_id,
      "referrer_from": referrer_from,
      "past_entry": past_entry,
      "recommended": recommended,
      "campaign_id": campaign_id ? campaign_id : "null",
      "user_eligible": user_eligible ? user_eligible : "null",
      "term_id": term_id ? term_id : "null",
      "consent": consent,
      'survey_ques': survey_ques ? survey_ques : '',
      from_phone
    }
    // if (geoLocation && geoLocation.latitude) final_json["geoLocation"] = geoLocation
    if (video_upload_data) final_json["video_quality_check_auto_id"] = video_upload_data.auto_id
    axios
      .request({
        url: url.contest_entry,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on contestEntry function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        console.log("error", error)
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on contestEntry function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },
  // async getCoords() {
  //   const pos = await new Promise((resolve, reject) => {
  //     navigator.geolocation.getCurrentPosition(resolve, reject);
  //   });

  //   return {
  //     long: pos.coords.longitude,
  //     lat: pos.coords.latitude,
  //   };
  // },

  updateUsercoupon(perk_id, sub_id, callback) {
    let final_json = {
      "sub_id": sub_id,
      "perk_id": perk_id,
      "action": "assign_coupon"
    }
    let self = this
    axios
      .request({
        url: url.perkCouponUpdate,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getting coupon"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getNotifications function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      })
  },
  // to get all notification for the particular user
  getNotifications(sub_id, update, callback) {
    let final_json = {
      "sub_id": sub_id,
      "update": update
    }
    let self = this
    axios
      .request({
        url: url.notification_uri,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getNotifications function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getNotifications function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  //  to get social share link
  getShareLink(store, program_id, sub_id, callback) {
    let share_url = {};
    share_url.facebook_link = "http://www.facebook.com/sharer.php?u=" + url.social_share_url + "?program_sub_id=" + program_id + "$" + sub_id + "$facebook";
    share_url.twitter_link = "https://twitter.com/share?url=" + url.social_share_url + "?program_sub_id=" + program_id + "$" + sub_id + "$twitter";
    callback(JSON.stringify(share_url))
  },

  // to get winnerprogramdetail of given program_id
  getWinnerProgramDetail(store, program_id, sub_id, callback) {
    let self = this
    let final_json = {
      "program_id": program_id,
      "sub_id": sub_id
    }
    axios
      .request({
        url: url.get_winner_program_detail,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getWinnerProgramDetail function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getWinnerProgramDetail function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  // to update winner status from pending to claim
  updateWinnerStatus(store, sub_id, winner_program_id) {
    let final_json = {
      "winner_program_id": winner_program_id,
      "sub_id": sub_id
    }
    let self = this
    axios
      .request({
        url: url.update_winner_status,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on updateWinnerStatus function"
        self.logUserActivity(success_action);
      })
      .catch(error => {
        let action = "User got error on updateWinnerStatus function";
        let body = error.message
        self.logUserError(action, body);
        // call function to log errors
      });
  },

  // to log program entry
  LogInProgramEntry(store, finaljson) {
    let self = this
    axios
      .request({
        url: url.LogInProgramEntry,
        method: "POST",
        data: JSON.stringify(finaljson),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on LogInProgramEntry function"
        self.logUserActivity(success_action);
      })
      .catch(error => {
        let action = "User got error on LogInProgramEntry function";
        let body = error.message
        self.logUserError(action, body);
        // call function to log errors
      });
  },

  LogInSurveyEntry(store, finaljson, callback) {
    let self = this
    axios
      .request({
        url: url.LogInSurveyEntry,
        method: "POST",
        data: JSON.stringify(finaljson),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getuserChildAndrelative function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on LogInSurveyEntry function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  // to get user's child and relative using sub_id
  getuserChildAndrelative(store, email, sub_id, callback) {
    let self = this;
    let final_json = {
      "email": email,
      "sub_id": sub_id
    }
    axios
      .request({
        url: url.get_child_relative,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getuserChildAndrelative function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getuserChildAndrelative function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },
  // to get user's child and relative using sub_id
  getuserChildAndrelativeAndinvitedChild(store, email, sub_id, callback) {
    let self = this;
    let final_json = {
      "email": email,
      "sub_id": sub_id,
      "action": "get_invitation_child"
    }
    axios
      .request({
        url: url.get_child_relative,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getuserChildAndrelative function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getuserChildAndrelative function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  // to log entry of user in review request
  insert_request_for_review(store, username, program_title, sub_id, program_id, for_entry, callback) {
    let self = this
    let final_json = {
      "sub_id": sub_id,
      "username": username,
      "program_title": program_title,
      "program_id": program_id,
      "for_entry": for_entry
    }
    axios
      .request({
        url: url.insert_request_for_review,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on insert_request_for_review function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on insert_request_for_review function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  // to update description and media of review
  update_review(store, review_request_id, request_timestmp, description, review_media, callback) {
    let final_json = {
      "review_request_id": review_request_id,
      "request_timestmp": request_timestmp,
      "description": description,
      "review_media": review_media
    }
    let self = this
    axios
      .request({
        url: url.updateReview,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on update_review function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on update_review function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  // to update description and media for review of the winner user
  updateWinnerReview(store, sub_id, program_id, description, review_media, callback) {
    let final_json = {
      "sub_id": sub_id,
      "program_id": program_id,
      "description": description,
      "review_media": review_media
    }
    let self = this
    axios
      .request({
        url: url.insertWinnerReview,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on updateWinnerReview function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on updateWinnerReview function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  sendEmail(message) {
    let final_json = {
      "to": 'support@webnexus.in',
      "sender_mail": "contactus@family.one",
      "subject": "TEST",
      "text_message": 'message',
      "html_message": message,
      "custom_header": {
        "X-SES-CONFIGURATION-SET": "familyone-cloudwatch",
        "Category": 'TEST'
      }
    }
    let self = this
    axios
      .request({
        url: url.sendSMTPMail,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on sendSMTPMail function"
        self.logUserActivity(success_action);
        // callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on sendSMTPMail function";
        let body = error.message
        self.logUserError(action, body);
        // callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  // to send email to admin
  sendSMTPMail(store, email, subject, text_message, html_message, category, callback) {
    let final_json = {
      "to": email,
      "sender_mail": "contactus@family.one",
      "subject": subject,
      "text_message": text_message,
      "html_message": html_message,
      "custom_header": {
        "X-SES-CONFIGURATION-SET": "familyone-cloudwatch",
        "Category": category ? category : ''
      }
    }
    let self = this
    axios
      .request({
        url: url.sendSMTPMail,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on sendSMTPMail function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on sendSMTPMail function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  // get list of notification in the setting section
  getNotificationsListing(store, sub_id, callback) {
    let final_json = {
      "sub_id": sub_id
    }
    let self = this
    axios
      .request({
        url: url.get_setting_notification,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getNotificationsListing function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getNotificationsListing function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  handleBackOne(store, path) {
    let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
    auto_id = auto_id ? auto_id : ""
    let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : ""
    if (auto_id && auto_id != "") {
      if (current_state !== path) {
        this.getLandingPage(store);
      }
    }
    else {
      store.props.history.push("/")
    }

  },

  // get urgent notification for the user
  getUrgentNotifications(sub_id, callback) {
    let final_json = {
      "sub_id": sub_id
    }
    let self = this
    axios
      .request({
        url: url.get_urgent_notification,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on getUrgentNotifications function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getUrgentNotifications function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  // to delete entry in freebie
  handleDeleteFreebieEntry(store, sub_id, program_id) {
    let final_json = {
      "program_id": program_id,
      "sub_id": sub_id
    }
    let self = this
    axios
      .request({
        url: url.cancel_freebie_entry,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on handleDeleteFreebieEntry function"
        self.logUserActivity(success_action);
      })
      .catch(error => {
        let action = "User got error on handleDeleteFreebieEntry function";
        let body = error.message
        self.logUserError(action, body);
        // call function to log errors
      });
  },

  // to disable urgent notification
  handleDisableUrgentNotification(store, sub_id) {
    let final_json = {
      "view_status": "disabled",
      "auto_id": sub_id
    }
    let self = this
    axios
      .request({
        url: url.update_urgent_notification,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on handleDisableUrgentNotification function"
        self.logUserActivity(success_action);
      })
      .catch(error => {
        let action = "User got error on handleDisableUrgentNotification function";
        let body = error.message
        self.logUserError(action, body);
        // call function to log errors
      });
  },

  // to log for notify me for particular program_id
  insertInNotifyMe(store, program_id, sub_id, callback) {
    let final_json = {
      "program_id": program_id,
      "sub_id": sub_id
    }
    let self = this
    axios
      .request({
        url: url.insert_in_notify_me,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on insertInNotifyMe function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on insertInNotifyMe function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  SendActivationEmail(store, final_json) {
    let self = this
    axios
      .request({
        url: url.activation_mail,
        method: "POST",
        data: JSON.stringify(final_json)
      })
      .then(function (response) {

      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on SendActivationEmail function";
        let body = error.message
        self.logUserError(action, body);
      });
  },

  CheckUserExistenceInOldNewSytsem(store, finaljson, callback) {
    let self = this
    axios
      .request({
        url: url.check_user_existence,
        method: "POST",
        data: JSON.stringify(finaljson)
      })
      .then(function (response) {

        let success_action = "User got success on CheckUserExistenceInOldNewSytsem function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on SendActivationEmail function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },

  SendReminder(store, final_json, callback) {
    let self = this
    axios
      .request({
        url: url.send_reminder,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        let success_action = "User got success on SendReminder function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on SendReminder function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  checkUserExistence(store, finaljson, callback) {
    let self = this;
    // finaljson = {
    //   email: "15dec1@webnexus.in",
    //   sub_id: "jkjgbrejkfjebfjk"
    // }
    axios
      .request({
        url: url.get_profile_user,
        method: "POST",
        data: JSON.stringify(finaljson)
      })
      .then(function (response) {

        if (response.data && response.data.dynamo && response.data.dynamo.Items && response.data.dynamo.Items.length == 0) {
          let success_action = "User not found on checkUserExistence function"
          self.logUserActivity(success_action);
          let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
          let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
          let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
          let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
          let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
          let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
          let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
          let user_signup_process = localStorage.getItem("user_signup_process") ? localStorage.getItem("user_signup_process") : "";
          Object.keys(localStorage).forEach(key => !['phone_transfer', 'email_transfer'].includes(key) && localStorage.removeItem(key));
          // localStorage.clear();
          localStorage.setItem("user_signup_process", user_signup_process)
          localStorage.setItem("perks_or_main", perks_or_main)
          localStorage.setItem("previous_state", previous_state)
          localStorage.setItem("current_state", current_state)
          localStorage.setItem("program", JSON.stringify(program))
          localStorage.setItem("api_auth", api_auth)
          localStorage.setItem("program_list", JSON.stringify(program_list))
          localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
          cookie.remove("auto_id", { path: "/" })
          cookie.remove("social_medium", { path: "/" })
          cookie.remove("social_shared_prog_id", { path: "/" })
          cookie.remove("social_shared_refered_id", { path: "/" })
          cookie.remove("winner_later", { path: "/" })
          cookie.remove("provider", { path: "/" })
          cookie.remove("facebook_id", { path: "/" })
          cookie.remove("google_id", { path: "/" })
          cookie.remove("tracking_id", { path: "/" })
          cookie.remove("username", { path: "/" })
          cookie.remove("accept_auto_id", { path: "/" })
          cookie.remove("relative_id", { path: "/" })
          cookie.remove("winner_id", { path: "/" })
          cookie.remove("winner_program_id", { path: "/" })
          cookie.remove("winner_claim_days", { path: "/" })
          cookie.remove("claim_status", { path: "/" })
          cookie.remove("email_changed", { path: "/" })
          cookie.remove("alternative_email", { path: "/" })
          callback(JSON.stringify(response));
        } else {
          let success_action = "User got success on checkUserExistence function"
          self.logUserActivity(success_action);
          callback(JSON.stringify(response));
        }
      })
      .catch(error => {
        console.log("error", error)
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on checkUserExistence function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },
  getCampaignInfo(store, finaljson, callback) {
    let self = this;
    axios
      .request({
        url: url.get_campaign_info,
        method: "POST",
        data: JSON.stringify(finaljson)
      })
      .then(function (response) {
        // console.log("response", response)
        let success_action = "User got success on getCampaignInfo function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      }).catch((error) => {
        console.log("error", error)
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on getCampaignInfo function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      })
  },
  saveCampaignView(store, finaljson) {
    let self = this;
    axios
      .request({
        url: url.save_campaign_view,
        method: "POST",
        data: JSON.stringify(finaljson)
      })
      .then(function (response) {

        let success_action = "User got success on saveCampaignView function"
        self.logUserActivity(success_action);
      }).catch((error) => {
        console.log("error", error)
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on saveCampaignView function";
        let body = error.message
        self.logUserError(action, body);
      })
  },

  CheckRelationStatus(store, email, sub_id, callback) {
    let self = this;
    let final_json = {
      relative_email: email,
      sub_id: sub_id
    }
    axios
      .request({
        url: url.check_relation_status,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        }
        callback(JSON.stringify(response))
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on CheckRelationStatus function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },

  changeAlternativeEmail(store, auto_id, rlid, parent_invite_email, callback) {
    let self = this;
    let final_json = {
      "auto_id": auto_id,
      "relative_id": rlid,
      "invite_id": parent_invite_email
    }
    axios
      .request({
        url: url.changeAlternativeEmail,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : this.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {

        }

        callback(JSON.stringify(response))
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on changeAlternativeEmail function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },
  getSignedUrl(store, final_json, callback) {
    let self = this;

    axios
      .request({
        url: url.getPresignedUrl,
        method: "POST",
        data: final_json,
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : this.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {

        }

        callback(JSON.stringify(response))
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on changeAlternativeEmail function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },
  invokeMturkApi(store, final_json, callback) {
    let self = this;
    axios
      .request({
        url: url.mturkApi,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : this.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        } else {

        }
        let success_action = "User got success on AcceptRelation function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on AcceptRelation function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },
  sendMailForMacUser(store, sub_id, email, program_id, callback) {
    let self = this;
    let final_json = {
      email: email,
      sub_id: sub_id,
      program_id: program_id
    }
    axios
      .request({
        url: url.SendMailToSafariUser,
        method: "POST",
        data: JSON.stringify(final_json),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : this.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          self.handleErrorPopup()
        } else {

        }
        let success_action = "User got success on Mail send to mac user function"
        self.logUserActivity(success_action);
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on Mail send to mac user function";
        let body = error.message
        self.logUserError(action, body);
        callback(JSON.stringify(error_json))
      });
  },

  UpdateFintelParamsInEpc(store, sub_id, campaignId, campaignSubId, campaignSubIdTimestamp, callback) {
    let self = this;

    if (sub_id != null) {
      let finalJson = {
        "sub_id": sub_id,
        "campaign_id": campaignId,
        "campaign_sub_id": campaignSubId,
        "campaign_sub_id_timestamp": campaignSubIdTimestamp
      }
      console.log("finalJson", finalJson)
      axios
        .request({
          method: "POST",
          url: url.UpdateFintelParamsInEpc,
          data: JSON.stringify(finalJson),
          headers: {
            "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
          }
        })
        .then(function (response) {
          if (response.data.failed && response.data.failed == 1) {
            callback(null, response.data);

          }
          else {
            callback(null, response.data);
          }
        })
        .catch(function (error) {
          let action = "User got error on updateUserData function";
          let body = error.message
          self.logUserError(action, body);
          callback(body, null)
          //call function to log errors
        });
    }
  },

  scrollToDiv(divId) {
    const divElement = document.querySelector(`#${divId}`);
    if (divElement) {
      divElement.scrollIntoView({
        behavior: "instant", block: "nearest"
      });
    }
  },
  callTommeeReminderMail(final_data) {
    let self = this;
    axios
      .request({
        method: "POST",
        url: url.perkCouponUpdate,
        data: JSON.stringify(final_data),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : self.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          // callback(null, response.data);

        }
        else {

          // callback(null, response.data);
        }
      })
      .catch(function (error) {
        let action = "User got error on updateUserData function";
        let body = error.message
        self.logUserError(action, body);
        // callback(body, null)
        //call function to log errors
      });

  }
  ,
  addressReportData(addressReportData, e) {
    try {
      axios.request({
        method: 'POST',
        url: url.ai_AddressReport,
        data: {
          ...addressReportData
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    } catch (error) {
      console.error('There was an error!', error);
    }
  },

  getPlacesApiAddress(store, searchTerm, callback) {

    // Construct parameters
    const params = { input: searchTerm };

    // Make the POST request using Axios
    axios.request({
      method: 'POST',
      url: url.ai_AddressPlacesApi,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      data: params // Use data instead of body for Axios
    })
      .then(response => {
        // Parse the response data
        const responseBody = response.data;
        console.log("response", responseBody);

        // Check if response is an array and has elements
        if (Array.isArray(responseBody) && responseBody.length > 0) {
          // Call the callback function with the response items
          callback(responseBody);
        } else {
          let result = {
            error: 1,
            message: "Sorry, there were no results"
          };
          callback(result);
        }
      })
      .catch(error => {
        // If there's an error, create an error object and pass it to the callback
        let result = {
          error: 1,
          message: "Sorry, an error occurred while fetching data"
        };
        callback(result);
      });
  },
  getFbSetting(store, callback) {

    // Construct parameters
    const finaljson = {
      action: "fetch_facebook_button_stat"
    }

    // Make the POST request using Axios
    axios.request({
      method: 'POST',
      url: url.facebook_setting_uri,
      data: JSON.stringify(finaljson),
      headers: {
        "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : this.getAuthKey()
      }
    })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {

        }
        callback(JSON.stringify(response))
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        let action = "User got error on changeAlternativeEmail function";
        let body = error.message
        this.logUserError(action, body);
        callback(JSON.stringify(error_json))
        // call function to log errors
      });
  },
  invokeUserEmailVerify(auto_id= localStorage.getItem("auto_id")){
    try{
      let Payload = {"sub_id":auto_id || localStorage.getItem("auto_id")}
      console.log("invokeUserEmailVerify invoked", auto_id)
      axios.request({
        method: 'POST',
        url: url.UpdateEmailVerifiedTrue,
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*'
        // },
        data: JSON.stringify(Payload) 
      }).then(response => {
        console.log('invokeUserEmailVerify response', response)
        return
      })
    }catch(err){
      console.log("invokeUserEmailVerify catch error", err)
      return true
    }
    
  }
}
export default functions;